import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const CareerReferDirectApplyAsync = lazyLoad(() => {
    return import('./components/CareerReferDirectApply');
});

const routePath = '/careers/:vendorUUID/:type';

const careerReferDirectRouteDetails = {
    name: 'careerReferDirectApply',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName="candidateDashboard" 
            name= {careerReferDirectRouteDetails.name}
            desktopComponent= {CareerReferDirectApplyAsync}
            mobileComponent= {CareerReferDirectApplyAsync} />
    }
};

export { careerReferDirectRouteDetails as CareerReferDirectRouteDetails };