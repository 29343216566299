import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';

const TalentPoolEditComponentAsync = lazyLoad(() => {
    return import('./components/TalentPoolEdit');
});

const routePath = '/talent-pool/:id/edit';


const talentPoolEditRouteDetails = {
    name: 'talentPoolEdit',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'talentPool',
            breadcrumbName: 'talentPoolEdit',
            desktopComponent: TalentPoolEditComponentAsync,
            mobileComponent: TalentPoolEditComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'talentPoolEdit',
    displayName: 'applicant edit details',
    path: routePath,
    parentName: 'talentPool'
};

export { talentPoolEditRouteDetails as TalentPoolEditRouteDetails, breadcrumbDetails as TalentPoolEditBreadcrumbDetails };