export default function ApplicationDateFormat({
  date,
  showTime = false,
  shortMonth = false,
}) {
  if (date === "") {
    return "-";
  }
  const _date = new Date(date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const shortMonthsName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = shortMonth
    ? shortMonthsName[_date.getMonth()]
    : months[_date.getMonth()];
  const day = _date.getDate();
  const year = _date.getFullYear();
  const time = _date.getHours() + ":" + _date.getMinutes() + ":" + _date.getSeconds();
  return showTime
    ? `${month} ${day}, ${year} | ${time}`
    : `${month} ${day}, ${year}`;
}
