import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { updateNotificationCount } from 'App/screens/Candidate/stores/CandidateStore';

const NOTIFICATION_COUNT_GET_QUERY = gql`
query getNotificationUnreadCount {
    getNotificationUnreadCount {
        count 
    }
}
`;
let getNotificationUnreadCount = ({
    uuid,
    client,
    NotifiactionCountLoaded,
    updateNotificationCount,
    loadingNotificationCount
}) => {
   
    if(loadingNotificationCount === false && NotifiactionCountLoaded === false) {
        client.query({
            query: NOTIFICATION_COUNT_GET_QUERY,
            variables:{
                'uuid': uuid
            }
            
        }).then(resp => {
            updateNotificationCount(false, resp.data.getNotificationUnreadCount.count)
        })
    }
    
    return '';
}

const mapStateWithProps = ({ candidate }) => {
    return {
        loadingNotificationCount: candidate.loadingNotificationCount,
        NotifiactionCountLoaded: candidate.NotifiactionCountLoaded
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateNotificationCount(status, notificationCount) {
            dispatch(updateNotificationCount(status, notificationCount));
        }
    };
};

getNotificationUnreadCount = withApollo(getNotificationUnreadCount);
export default connect(mapStateWithProps, mapDispatchToProps)(getNotificationUnreadCount);