import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from './Authentication';

export default function authenticateRouteRender(Component, props) {
    const redirectUrl = window.location && window.location.pathname;
    return isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={redirectUrl === '/'
            ? '/login' : `/login?redirect_url=${redirectUrl}`} />;
}