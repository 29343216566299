
const defaultState = {
    savingCalculatorDetails: {
        estimatedHeadCount: '',
        headCount: '',
        estimatedAnnualHoursPerResource: '',
        annualHoursPerResource: '',
        averagePayRate: '',
        payRate: '',
        supplierMarkup: '',
        directPayRate: '',
        payRollMarkup: ''
    },
    totalSpend: {
        directSourcedResources: '',
        supplierSourcedResources: ''
    },
    totalSpendPercentange: {
        supplierSourcedPercent: '',
        directSourcedPercent: ''
    }

}
const RESET_SAVING_CALCULATER_STATE = 'RESET_SAVING_CALCULATER_STATE';
const ON_CHANGE_PAY_ROLL_MARKUP = 'ON_CHANGE_PAY_ROLL_MARKUP';
const ON_CHANGE_ESTIMATED_HEAD_COUNT = 'ON_CHANGE_ESTIMATED_HEAD_COUNT';
const ON_CHANGE_HEAD_COUNT = 'ON_CHANGE_HEAD_COUNT';
const ON_CHANGE_ESTIMATED_ANNUAL_HOURS_PER_RESOURCE = 'ON_CHANGE_ESTIMATED_ANNUAL_HOURS_PER_RESOURCE';
const ON_CHANGE_AUUNAL_HOURES_PER_RESOURCE = 'ON_CHANGE_AUUNAL_HOURES_PER_RESOURCE';
const ON_CHANGE_PAY_RATE = 'ON_CHANGE_PAY_RATE';
const ON_CHANGE_DIRECT_PAY_ROLL_MARKUP = 'ON_CHANGE_DIRECT_PAY_ROLL_MARKUP';
const ON_CHANGE_AVERAGE_PAY_RATE = 'ON_CHANGE_AVERAGE_PAY_RATE';
const ON_CHANGE_DIRECT_PAY_RATE = 'ON_CHANGE_DIRECT_PAY_RATE';
const ON_CHANGE_DIRECT_SOURECES = 'ON_CHANGE_DIRECT_SOURECES';
const ON_CHANGE_SUPPLIER_SOURCES = 'ON_CHANGE_SUPPLIER_SOURCES';
const STORED_TOTAL_SPENT = 'STORED_TOTAL_SPENT';
const ON_CLEAR_TOTAL_SPENT = 'ON_CLEAR_TOTAL_SPENT';

function resetState() {
    return {
        type: RESET_SAVING_CALCULATER_STATE
    };
}
function onChangePayRollMarkup(value) {
    return {
        type: ON_CHANGE_PAY_ROLL_MARKUP,
        value
    }

}
function updateToTatalSpent(){
    return {
        type:ON_CLEAR_TOTAL_SPENT
    }
}
function onChangeEstimatedHeadCount(value) {
    return {
        type: ON_CHANGE_ESTIMATED_HEAD_COUNT,
        value
    }
}
function onChangeHeadCount(value) {
    return {
        type: ON_CHANGE_HEAD_COUNT,
        value
    }

}
function onChangeestimatedAnnualHoursPerResource(value) {
    return {
        type: ON_CHANGE_ESTIMATED_ANNUAL_HOURS_PER_RESOURCE,
        value
    }
}
function onChangeAnnualHoursPerResource(value) {
    return {
        type: ON_CHANGE_AUUNAL_HOURES_PER_RESOURCE,
        value
    }
}
function onChangePayRate(value) {
    return {
        type: ON_CHANGE_PAY_RATE,
        value
    }
}
function onChangeDirectPayRollMarkup(value) {
    return {
        type: ON_CHANGE_DIRECT_PAY_ROLL_MARKUP,
        value
    }

}
function onChangeAveragePayRate(value) {
    return {
        type: ON_CHANGE_AVERAGE_PAY_RATE,
        value
    }
}
function onChangeDirectPayRate(value) {
    return {
        type: ON_CHANGE_DIRECT_PAY_RATE,
        value
    }
}
function onChangeDirectsources(value) {
    return {
        type: ON_CHANGE_DIRECT_SOURECES,
        value
    }
}
function onChangeSupplierSources(value) {
    return {
        type: ON_CHANGE_SUPPLIER_SOURCES,
        value
    }
}
function storedTotalSpent(value) {
    return {
        type: STORED_TOTAL_SPENT,
        value
    }
}
const savingCalculatorReducer = (state = defaultState, action) => {
    switch (action.type) {

        case RESET_SAVING_CALCULATER_STATE:
            return {
                ...defaultState
            };
        case ON_CHANGE_ESTIMATED_HEAD_COUNT:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    estimatedHeadCount: action.value
                }
            }
        case ON_CHANGE_HEAD_COUNT:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    headCount: action.value
                }
            }
        case ON_CHANGE_ESTIMATED_ANNUAL_HOURS_PER_RESOURCE:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    estimatedAnnualHoursPerResource: action.value
                }
            }
        case ON_CHANGE_AUUNAL_HOURES_PER_RESOURCE:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    annualHoursPerResource: action.value
                }
            }
        case ON_CHANGE_PAY_RATE:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    payRate: action.value
                }
            }
        case ON_CHANGE_DIRECT_PAY_ROLL_MARKUP:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    supplierMarkup: action.value
                }
            }
        case ON_CHANGE_AVERAGE_PAY_RATE:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    averagePayRate: action.value
                }
            }
        case ON_CHANGE_DIRECT_PAY_RATE:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    directPayRate: action.value
                }
            }
        case ON_CHANGE_DIRECT_SOURECES:
            return {
                ...state,
                totalSpendPercentange: {
                    ...state.totalSpendPercentange,
                    directSourcedPercent: action.value
                }
            }
        case ON_CHANGE_SUPPLIER_SOURCES:
            return {
                ...state,
                totalSpendPercentange: {
                    ...state.totalSpendPercentange,
                    supplierSourcedPercent: action.value
                }
            }
        case ON_CHANGE_PAY_ROLL_MARKUP:
            return {
                ...state,
                savingCalculatorDetails: {
                    ...state.savingCalculatorDetails,
                    payRollMarkup: action.value
                }
            }
        case ON_CLEAR_TOTAL_SPENT: 
            return{
                ...state,
                totalSpendPercentange: {
                    ...state.totalSpendPercentange,
                    directSourcedPercent: '',
                    supplierSourcedPercent: '',
                },
                totalSpend:  {
                    ...state.totalSpend,
                    combineTotalSpent: '',
                    combineTotalSavings: ''
                }
            }
        case STORED_TOTAL_SPENT: 
            return {
                ...state,
                totalSpend: {...action.value}
            }

        default:
            return state;
    }
}

export {
    resetState,
    savingCalculatorReducer as SavingCalculatorReducer,
    onChangePayRollMarkup,
    onChangeEstimatedHeadCount,
    onChangeHeadCount,
    onChangeestimatedAnnualHoursPerResource,
    onChangeAnnualHoursPerResource,
    onChangePayRate,
    onChangeDirectPayRollMarkup,
    onChangeAveragePayRate,
    onChangeDirectPayRate,
    onChangeDirectsources,
    onChangeSupplierSources,
    storedTotalSpent,
    updateToTatalSpent
}