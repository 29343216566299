import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const ContactDetailsComponentAsync = lazyLoad(() => {
    return import('./components/ContactDetails');
});

const routePath = '/contact/:id/details';

const ContactDetailsRouteDetails = {
    name: 'contactDetails',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'candidateProfiles',
            breadcrumbName: 'contactDetails',
            desktopComponent: ContactDetailsComponentAsync,
            mobileComponent: ContactDetailsComponentAsync
        });
    }
};

const contactDetailsBreadcrumbDetails = {
    name: 'contactDetails',
    displayName: 'contact details',
    path: routePath,
    parentName: 'candidate profiles'
};

export { ContactDetailsRouteDetails, contactDetailsBreadcrumbDetails };