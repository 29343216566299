import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
// import './shared/DistributionAd.scss';

const DistributionEditComponentAsync = lazyLoad(() => {
    return import('./components/DistributionEdit');
});

const routePath = '/distribution/:id/edit';

const distributionEditRouteDetails = {
    name: 'distributionEdit',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'distribution',
            breadcrumbName: 'distributionEdit',
            desktopComponent: DistributionEditComponentAsync,
            mobileComponent: DistributionEditComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'distributionEdit',
    displayName: 'edit distribution list',
    path: routePath,
    parentName: 'distribution'
};

export { distributionEditRouteDetails as DistributionEditRouteDetails, breadcrumbDetails as distributionEditBreadcrumbDetails };