const defaultState = {
    isAuthenticating: false,
    isAuthenticated: false,
    invalidCredentials: false,

    // this is temporary and will be removed, just to demostrate the autocomplete component
    // any component which uses autocomplete should manage its own reducer
    stateList: {
        list: [
            'Maharashtra',
            'Kerala',
            'Tamil Nadu',
            'Gujrat',
            'Rajasthan'
        ],
        filteredList: [],
        selectedList: [],
        searchText: ''
    },
    cityList: {
        list: [
            'Mumbai',
            'Pune',
            'Bangalore',
            'Chennai',
            'Hyderabad',
            'Kochi'
        ],
        filteredList: [],
        selectedList: [],
        searchText: ''
    },
    showPassword: false
};

//constants for autocomplete component
const AUTOCOMPLETE_FILTERED_STATE_SEARCH = 'AUTOCOMPLETE_FILTERED_STATE_SEARCH';
const AUTOCOMPLETE_FILTERED_CITY_SEARCH = 'AUTOCOMPLETE_FILTERED_CITY_SEARCH';
const AUTOCOMPLETE_SELECTED_STATE_LIST = 'AUTOCOMPLETE_SELECTED_STATE_LIST';
const AUTOCOMPLETE_SELECTED_CITY_LIST = 'AUTOCOMPLETE_SELECTED_CITY_LIST';
const AUTOCOMPLETE_REMOVE_SELECTED_STATE = 'AUTOCOMPLETE_REMOVE_SELECTED_STATE';
const AUTOCOMPLETE_REMOVE_SELECTED_CITY = 'AUTOCOMPLETE_REMOVE_SELECTED_CITY';

//constants for login authentication
const LOGIN_AUTHENTICATION = 'LOGIN_AUTHENTICATION';

const CHANGE_PASSWORD_INPUT_TYPE = 'CHANGE_PASSWORD_INPUT_TYPE';

function onPasswordInputTypeChange() {
    return {
        type: CHANGE_PASSWORD_INPUT_TYPE
    }
}

//actions for autocomplete
function onAutocompleteFilterStateSearch({ searchText, filteredList }) {
    return {
        type: AUTOCOMPLETE_FILTERED_STATE_SEARCH,
        searchText,
        filteredList
    };
}

function onAutocompleteFilterCitySearch({ searchText, filteredList }) {
    return {
        type: AUTOCOMPLETE_FILTERED_CITY_SEARCH,
        searchText,
        filteredList
    };
}

function onAutocompleteSelectState(selectedList) {
    return {
        type: AUTOCOMPLETE_SELECTED_STATE_LIST,
        selectedList
    };
}

function onAutocompleteSelectCity(selectedList) {
    return {
        type: AUTOCOMPLETE_SELECTED_CITY_LIST,
        selectedList
    };
}

function onAutocompleteRemoveState(selectedList) {
    return {
        type: AUTOCOMPLETE_REMOVE_SELECTED_STATE,
        selectedList
    }
}

function onAutocompleteRemoveCity(selectedList) {
    return {
        type: AUTOCOMPLETE_REMOVE_SELECTED_CITY,
        selectedList
    }
}

function authenticationStatusDispatcher(authenticationDetails) {
    return {
        type: LOGIN_AUTHENTICATION,
        authenticationDetails
    }
}

const loginReducer = (state = defaultState, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_FILTERED_STATE_SEARCH:
            return {
                ...state, stateList: {
                    ...state.stateList,
                    searchText: action.searchText,
                    filteredList: action.filteredList
                }
            };
        case AUTOCOMPLETE_FILTERED_CITY_SEARCH:
            return {
                ...state, cityList: {
                    ...state.cityList,
                    searchText: action.searchText,
                    filteredList: action.filteredList
                }
            };
        case AUTOCOMPLETE_SELECTED_STATE_LIST:
            return {
                ...state, stateList: {
                    ...state.stateList,
                    selectedList: action.selectedList
                }
            };
        case AUTOCOMPLETE_SELECTED_CITY_LIST:
            return {
                ...state, cityList: {
                    ...state.cityList,
                    selectedList: action.selectedList
                }
            };
        case AUTOCOMPLETE_REMOVE_SELECTED_STATE:
            return {
                ...state, stateList: {
                    ...state.stateList,
                    selectedList: action.selectedList
                }
            }
        case AUTOCOMPLETE_REMOVE_SELECTED_CITY:
            return {
                ...state, cityList: {
                    ...state.cityList,
                    selectedList: action.selectedList
                }
            }
        case LOGIN_AUTHENTICATION:
            return {
                ...state,
                isAuthenticating: action.authenticationDetails.isAuthenticating || false,
                isAuthenticated: action.authenticationDetails.isAuthenticated || false,
                invalidCredentials: action.authenticationDetails.invalidCredentials || false
            }
        case CHANGE_PASSWORD_INPUT_TYPE:
            return {
                ...state,
                showPassword: !state.showPassword
            }
        default:
            return state;
    }
};

export {
    loginReducer as LoginReducer,
    onAutocompleteFilterStateSearch,
    onAutocompleteFilterCitySearch,
    onAutocompleteSelectState,
    onAutocompleteSelectCity,
    onAutocompleteRemoveState,
    onAutocompleteRemoveCity,
    authenticationStatusDispatcher,
    onPasswordInputTypeChange
};
