export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_SEARCH_RESULTS_URL = process.env.REACT_APP_GOOGLE_SEARCH_RESULTS_URL;

export const CAREER_PAGE_GOOGLE_ANALYTICS_API_URL = process.env.REACT_APP_CAREER_PAGE_GOOGLE_ANALYTICS_API_URL;

export const FILE_API_URL = process.env.REACT_APP_FILE_API_URL;

export const GOOGLE_ANALYTICS_ACCOUNT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ACCOUNT_ID;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const ENABLE_CAPTCHA = process.env.REACT_APP_ENABLE_CAPTCHA;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const SOCKET_IO_BASE_URL = process.env.REACT_APP_SOCKET_IO_BASE_URL;
