import { connect } from 'react-redux';
import { addBreadcrumb } from 'App/shared/components/Layout/LayoutStore';

const breadcrumbSet = ({ breadcrumbDetails, addBreadcrumb }) => {
    addBreadcrumb(breadcrumbDetails);
    return '';
};

const mapDispatchToProps = (dispatch) => {
    return {
        addBreadcrumb(breadcrumbDetails) {
            return dispatch(addBreadcrumb(breadcrumbDetails));
        } 
    };
};

export default connect(null, mapDispatchToProps)(breadcrumbSet);