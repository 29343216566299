import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const ChangePasswordAsync = lazyLoad(() => {
    return import('./components/ChangePassword');
});
const routePath = '/:vendorUUID/candidate/:accountId/change-password';
const ChangePasswordRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateChangePassword',
    render() {
        return (
            <CandidateLayout
                activeMenuName = 'changePassword'
                name = {ChangePasswordRouteDetails.name}
                desktopComponent = {ChangePasswordAsync}
                mobileComponent = {ChangePasswordAsync} 
            />
        )
    }
};

export { ChangePasswordRouteDetails };