import React, { Component } from 'react';
import Calendar from 'react-calendar';
import './Calendar.scss';

export default class CalendarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: null,
            displayCalendar: false,
            defaultDate: new Date()
        }
        this.onClickDay = this.onClickDay.bind(this);
        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.onCalendarIconClick = this.onCalendarIconClick.bind(this);
    }
    onCalendarChange = date => this.setState({ defaultDate: date });
    onCalendarIconClick = () => this.setState({ displayCalendar: !this.state.displayCalendar });

    onClickDay = (value, day) => {
        let _selectedDate = this.getFormattedDate(value)
        this.setState({
            displayCalendar: false,
            selectedDate: _selectedDate
        });
        this.props.onDateSelect(value)

    }
    getFormattedDate = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth());
        let day = date.getDate();
        return month + '/' + day + '/' + year;
    }

    render() {
        const { calendarStyle } = this.props;
        const { defaultDate, selectedDate, displayCalendar } = this.state;
        const calendarColor = calendarStyle ? "sulross-calendar" : null;

        return (
            <div className={`calendar-container ${calendarColor}`}>
                <input
                    type="text"
                    disabled={true}
                    className="calendar-input"
                    onClick={_ => this.onCalendarIconClick()}
                    placeholder={!selectedDate ? "Select a date" : selectedDate}
                />
                <div className="icon-calendar calendar-icon"
                    style={{ "color": calendarStyle.color }}
                    onClick={_ => this.onCalendarIconClick()} />
                {
                    displayCalendar && (
                        <Calendar
                            value={defaultDate}
                            minDate={defaultDate}
                            onClickDay={this.onClickDay}
                            onChange={this.onCalendarChange}
                            defaultActiveStartDate={!selectedDate
                                ? defaultDate : new Date(selectedDate)}
                            tileClassName={({ date, view }) => {
                                let _selectedDate = !selectedDate
                                    ? this.getFormattedDate(defaultDate) : selectedDate;
                                if (this.getFormattedDate(date) === _selectedDate) {
                                    return 'highlight-date'
                                }
                            }}
                        />
                    )
                }
            </div>
        );
    }
}