import React, { Component } from 'react';
import ChatbotConversation from './ChatbotConversation';
import ChatbotImage from '../../../../shared/images/Background.png';
import { convertToAbsoluteUrl } from '../../../../shared/utils/UrlFormatter';
import '../shared/index.scss';

export default class ChatbotUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatbotName: "",
            startTimer: true,
            minimized: false,
            isChatbotOpened: false
        }
        this.setTimer = 10;
        this.timerInterval = null;
        this.universityFrom = React.createRef();
        this.conversationEnd = React.createRef();
        this.applyFormElement = React.createRef();
        this.jobDetailsElement = React.createRef();
        this.locationFormElement = React.createRef();
        this.programDescriptionElement = React.createRef();
        this.universitylargeOptionList = React.createRef();
        this.timerCountDown = this.timerCountDown.bind(this);
        this.submitRequirementsFormElement = React.createRef();
        this.onClickReferJob = this.onClickReferJob.bind(this);
        this.onClickJobApply = this.onClickJobApply.bind(this);
        this.onClickJobApply = this.onClickJobApply.bind(this);
        this.onClickReferJob = this.onClickReferJob.bind(this);
        this.onChatbotJobClick = this.onChatbotJobClick.bind(this);
        this.onChatbotSelectOption = this.onChatbotSelectOption.bind(this);
        this.onSubmitUniversityForm = this.onSubmitUniversityForm.bind(this);
        this.locationselectedOption = this.locationselectedOption.bind(this);
        this.onApplicantAlreadyApply = this.onApplicantAlreadyApply.bind(this);
        this.onSubmitJoinTalentDomeForm = this.onSubmitJoinTalentDomeForm.bind(this);
        this.onSubmitRequirementsFormSuccess = this.onSubmitRequirementsFormSuccess.bind(this);
    }

    scrollToBottom() {
        const { data = [] } = this.props;

        if (this.universityFrom.current) {
            this.universityFrom.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
        } else if (this.universitylargeOptionList.current) {
            this.universitylargeOptionList.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
        } else if (this.programDescriptionElement.current) {
            this.programDescriptionElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
        } else {
            if (this.conversationEnd && data.length > 3) {
                if ((data) && (data[data.length - 2].type !== "joinTalentdomeForm") &&
                    (data[data.length - 2].type !== "jobDetails") && (data[data.length - 2].type !== "submitRequirementsForm")
                ) {
                    this.conversationEnd.scrollIntoView({ behavior: "smooth", alignTo: false });
                } else {
                    if (this.applyFormElement.current) {
                        this.applyFormElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                    } else if (this.jobDetailsElement.current) {
                        this.jobDetailsElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                    } else if (this.submitRequirementsFormElement.current) {
                        this.submitRequirementsFormElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                    }
                }
            }
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    onMinimizeClick() {
        const { minimized } = this.state;
        this.setState({ minimized: !minimized })
    }

    onChatbotClick() {
        const { isChatbotOpened } = this.state;
        this.setState({
            isChatbotOpened: !isChatbotOpened,
            minimized: false
        });
        if (this.props.toggle) {
            this.props.toggle(!isChatbotOpened)
        }
        if (this.setTimer !== 0) {
            this.setTimer = 0
            clearInterval(this.timerInterval)
        }
    }

    onChatbotSelectOption(optionType, option) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ ...option, optionType })
        }
    }

    locationselectedOption(optionType, locationSelectedOptions) {
        this.props.onUpdateJobCityNames(locationSelectedOptions);
        if (this.props.onSelectOption) {
            const name = locationSelectedOptions.join(', ');
            this.props.onSelectOption({ name, optionType })
        }
    }

    onSubmitJoinTalentDomeForm(optionType) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "", optionType })
        }
    }

    onSubmitUniversityForm(optionType, successMessage) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "", optionType, successMessage })
        }
    }

    onSubmitRequirementsFormSuccess(optionType, successMessage) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "", optionType, successMessage })
        }
    }

    onApplicantAlreadyApply(optionType) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "", optionType })
        }
    }

    onChatbotJobClick(details) {
        if (this.props.fetchChatbotJobDetails) {
            return this.props.fetchChatbotJobDetails(details.jobPositionKey)
        }
    }

    onClickJobApply(optionType, jobPositionId) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "Apply", optionType, jobPositionId })
        }
    }

    onClickReferJob(optionType, jobPositionId) {
        if (this.props.onSelectOption) {
            this.props.onSelectOption({ name: "Refer", optionType, jobPositionId })
        }
    }

    timerCountDown() {
        if (this.setTimer !== 0) {
            this.setTimer = this.setTimer - 1
            if (this.setTimer === 0) {
                this.onChatbotClick()
                this.setState({
                    startTimer: false
                })
                clearInterval(this.timerInterval)
            }
        }
    }

    render() {
        const {
            data,
            userType,
            clientType,
            setUserType,
            chatbotText,
            clientConfig,
            chatbotResetState,
            vendorUUID,
            setBackOptionStatus
        } = this.props;
        const { minimized, isChatbotOpened, startTimer } = this.state;
        const className = minimized ? 'chatbot-opened chatbot-minimized'
            : data.length > 4 ? 'chatbot-opened' : 'chatbot-opened';
        if (startTimer && window.innerWidth > 800) {
            this.timerInterval = setInterval(this.timerCountDown, 1000)
        }

        if (!minimized && isChatbotOpened && window.innerWidth < 600) {
            const _body = document.getElementsByTagName('html')[0];
            _body && _body.setAttribute('class', 'body-scroll-hide');
        } else if (minimized && isChatbotOpened && window.innerWidth < 600) {
            const _body = document.getElementsByTagName('html')[0];
            _body && _body.removeAttribute('class', 'body-scroll-hide')
        } else {
            const _body = document.getElementsByTagName('html')[0];
            _body && _body.removeAttribute('class', 'body-scroll-hide')
        }

        let imageUrl = convertToAbsoluteUrl(ChatbotImage);
        const headerBackgroundStyle = {
            backgroundColor: clientConfig.style.headerBgColor
        }
        const headerTextStyle = {
            color: clientConfig.style.headerFontcolor
        }
        return (
            <React.Fragment>
                <div className={!isChatbotOpened
                    ? "display-none"
                    : `chatbot-ui-container ${className}`}>
                    <div style={headerBackgroundStyle} className={minimized && data.length > 5
                        ? 'chatbot-ui-container__chatbot-header header-padding'
                        : data.length > 3
                            ? 'chatbot-ui-container__chatbot-header header-height'
                            : "chatbot-ui-container__chatbot-header header-data-padding"}
                        onClick={() => {
                            window.innerWidth < 801
                                ? this.onChatbotClick() : this.onMinimizeClick()
                        }}>
                        <div className='chatbot-ui-container__chatbot-header__title' >
                            <img
                                alt="chatbot-img"
                                src={imageUrl}
                                className='chatbot-image' />
                            <span style={headerTextStyle}> {clientConfig.headerTitle} </span>
                            <div className='green-dot' />
                        </div>
                        <div className='chatbot-header-icons'>
                            <div
                                className='icon-minimize minimize-icon'
                                onClick={() => {
                                    window.innerWidth < 801
                                        ? this.onChatbotClick() : this.onMinimizeClick()
                                }} />
                            <div
                                className='icon-clear cross-icon'
                                onClick={() => this.onChatbotClick()} />
                        </div>
                    </div>
                    <div id="chatbot-data"
                        className={minimized ? 'display-none' : 'chatbot-ui-components'}>
                        <ChatbotConversation
                            data={data}
                            vendorUUID={vendorUUID}
                            userType={userType}
                            clientType={clientType}
                            setUserType={setUserType}
                            chatbotText={chatbotText}
                            clientConfig={clientConfig}
                            applyFormRef={this.applyFormElement}
                            chatbotResetState={chatbotResetState}
                            jobDetailsRef={this.jobDetailsElement}
                            onClickJobApply={this.onClickJobApply}
                            onClickReferJob={this.onClickReferJob}
                            universityFromRef={this.universityFrom}
                            setBackOptionStatus={setBackOptionStatus}
                            onChatbotJobClick={this.onChatbotJobClick}
                            locationFormRef={this.locationFormElement}
                            onChatbotSelectOption={this.onChatbotSelectOption}
                            onSubmitUniversityForm={this.onSubmitUniversityForm}
                            onApplicantAlreadyApply={this.onApplicantAlreadyApply}
                            programDescriptionRef={this.programDescriptionElement}
                            onSubmitJoinTalentDomeForm={this.onSubmitJoinTalentDomeForm}
                            onChatbotLocationSelectedOption={this.locationselectedOption}
                            submitRequirementsFormRef={this.submitRequirementsFormElement}
                            universityLargeOptionListRef={this.universitylargeOptionList}
                            onSubmitRequirementsFormSuccess={this.onSubmitRequirementsFormSuccess}
                        />
                        {
                            minimized
                                ? null
                                : <div ref={el => this.conversationEnd = el}></div>
                        }
                    </div>
                </div>
                <div style={headerBackgroundStyle} className={isChatbotOpened
                    ? "display-none"
                    : "chatbot-ui-container chatbot-closed"}
                    onClick={() => this.onChatbotClick()}>
                    <img
                        alt="chatbot-img"
                        src={imageUrl}
                        className='chatbot-image' />
                </div>
            </React.Fragment>
        );
    }
}