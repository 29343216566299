import React from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../../../shared/images/logo.png';
import { FILE_API_URL } from '../../../../constants';
import InfojiniLogo from 'App/shared/images/infojiniPublicLogo.svg';
import './publicLayout.scss';

function PublicHeader({
    vendorFileUUID
}) {
    const venderLogo = vendorFileUUID ? `${FILE_API_URL}/${vendorFileUUID}` : InfojiniLogo;
    return (
        <div className="public-header">
            <div>
                <img src={Logo} alt="logo" />
            </div>
            <div>
                <a href ={'https://www.infojiniconsulting.com/'}>
                    <div className="vender-logo">
                        <img src={venderLogo} alt="vender logo" />
                    </div>
                </a> 
            </div>
                      
        </div>
    )
}
export default withRouter(PublicHeader)
