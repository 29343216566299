import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { ApplicationReducer } from './stores/ApplicationStore';
import './shared/Application.scss';
import Layout from 'App/shared/components/Layout/Layout';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';
import { ApplicationDetailsRouteDetails, applicationDetailsBreadcrumbDetails } from 'App/screens/Application/screens/ApplicationDetails/index';

const ApplicationDesktopComponentAsync = lazyLoad(() => {
    return import('./components/ApplicationDesktopPage');
});

const ApplicationMobileComponentAsync = lazyLoad(() => {
    return import('./components/ApplicationMobilePage');
});

const routePath = '/applications';
const breadcrumbName = 'applications';
const applicationRouteDetails = {
    name: 'applications',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'applications',
            breadcrumbName,
            desktopComponent: ApplicationDesktopComponentAsync,
            mobileComponent: ApplicationMobileComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'Applications',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export { 
    applicationRouteDetails as ApplicationRouteDetails, 
    ApplicationReducer, 
    breadcrumbDetails as applicationBreadcrumbDetails,
    ApplicationDetailsRouteDetails,
    applicationDetailsBreadcrumbDetails
};