import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { updateUserList } from '../../../stores/PublicStore';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';

const GetCandidateList = gql`
    query GetCandidateList (
        $categoryIds: [Int],
    ) {
        getCandidateList(filters: {
            categoryIds: $categoryIds
        }) {
            candidateList {
                candidateId,
                firstName,
                lastName,
                summary,
                willingToRelocate,
                veteranStatus,
                showInTalentpool,
                experience {
                  years
                  months
                },
                primarySkills {
                  skillId,
                  name
                },
                secondarySkills {
                    skillId,
                    name
                },
                location {
                  address {
                    stateName,
                    cityName,
                    addressLine1
                  }
                },
                categoryList {
                  categoryId,
                  displayName
                }
              },
              totalCount
        }
    }`

let getUserListQuery = ({
    match,
    client,
    updateUserList,
    userListLoaded,
    loadingUserList,
}) => {
    const { categoryId } = match.params

    if (loadingUserList === false && userListLoaded === false) {
        updateUserList(true);
        client.query({
            query: GetCandidateList,
            variables: {
                categoryIds: categoryId ? [parseInt(categoryId)] : []
            }
        })
            .then(details => {
                updateUserList(false, details.data.getCandidateList.candidateList);
            });
    }
    return '';
}

const mapStateWithProps = ({ publicTalentPool }) => {
    return {
        userListLoaded: publicTalentPool.userListLoaded,
        loadingUserList: publicTalentPool.loadingUserList,
        selectedCategory: publicTalentPool.selectedCategory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserList(status, userList = []) {
            dispatch(updateUserList(status, userList))
        }
    }
}


getUserListQuery = withApollo(getUserListQuery);

export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(getUserListQuery))