import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import JobDetails from '../shared/JobDetails';
import ChatbotCardView from './ChatbotCardView';
import ChatbotHorizontalScroller from './ChatbotHorizontalScroller';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import '../shared/index.scss';

export default class ChatbotJobList extends Component {
    render() {
        const { data, onOptionClick, onChatbotJobListClick, chatbotResetState } = this.props;

        function onButtonClick(selectedOption) {
            if (selectedOption === "Join Talentdome") {
                ReactGA.event({
                    category: "chatbot-join-talentdone-form",
                    action: "chatbot join talentdone form",
                    label: "chatbot join talentdone form"
                })
            }
            if (onOptionClick) {
                onOptionClick({ name: selectedOption })
            }
        }

        return (
            <div className='chatbot-job-list'>
                <ChatbotHorizontalScroller>
                    {
                        data.map((jobDetails, index) => {
                            return (
                                <ChatbotCardView key={index}>
                                    <JobDetails
                                        details={jobDetails}
                                        isScrollableList={true}
                                        chatbotResetState={chatbotResetState}
                                        onChatbotJobClick={(details) => onChatbotJobListClick(details)}
                                    />
                                </ChatbotCardView>
                            )
                        })
                    }
                    <ChatbotCardView>
                        <div className='view-more-jobs-container scrollable-job-list'>
                            <div className='view-more-jobs-container-details'>
                                <ActionButton
                                    title={"view more jobs"}
                                    className='cancel-button'
                                    onClick={_ => onButtonClick("View More Jobs")}
                                />
                                <div className='or-text'> OR </div>
                                <ActionButton
                                    title={"join talentdome"}
                                    className='submit-button'
                                    onClick={_ => onButtonClick("Join Talentdome")}
                                />
                            </div>
                        </div>
                    </ChatbotCardView>
                </ChatbotHorizontalScroller>
            </div>
        );
    }
}