
const defaultState = {
    categoryListLoaded: false,
    loadingCategoryList: false,
    subCategoryListLoaded: false,
    loadingSubCategoryList: false,
    candidateDatilsLoaded: false,
    loadingCandidateDetails: false,
    candidateDetailsList: [],
    developers: [],
    captcha: null,
    userListHeight: 500,
    subCategoryList: [],
    categoryList: [
        { categoryId: 1, displayName: 'developers' },
        { categoryId: 2, displayName: 'designers' },
        { categoryId: 3, displayName: 'testers' },
        { categoryId: 4, displayName: 'project managers' },
        { categoryId: 5, displayName: 'cloud engineers' },
        { categoryId: 6, displayName: 'system administrators' }
    ],
    selectedCategory: null,
    userList: [],
    userListLoaded: false,
    loadingUserList: false,
    selectedUserId: null,
    mobileSelectedUserId: null
}

const ON_USER_CLICK = 'ON_USER_CLICK';
const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
const RESET_PUBIC_STATE = 'RESET_PUBIC_STATE';
const UPDATE_USER_LIST_HEIGHT = 'UPDATE_USER_LIST_HEIGHT';
const UPDATE_CANDIDATE_DETAILS = 'UPDATE_CANDIDATE_DETAILS';
const UPDATE_USER_LOADING_STATUS = 'UPDATE_USER_LOADING_STATUS';
const UPDATE_SUBCATEGORY_LIST_DATA = 'UPDATE_SUBCATEGORY_LIST_DATA';
const UPDATE_SELECTED_CATEGORY_NAME = 'UPDATE_SELECTED_CATEGORY_NAME';
const UPDATE_INFORMATION_FORM_CAPTCHA = 'UPDATE_INFORMATION_FORM_CAPTCHA';
const UPDATE_TALENT_POOL_CATEGORY_LIST = "UPDATE_TALENT_POOL_CATEGORY_LIST";

function onResetPublicState() {
    return {
        type: RESET_PUBIC_STATE
    }
}
function updateInformationFormCaptcha(captcha) {
    return {
        type: UPDATE_INFORMATION_FORM_CAPTCHA,
        captcha
    }
}
function updateUserLodingStatus(status) {
    return {
        type: UPDATE_USER_LOADING_STATUS,
        status,
    }
}
function updateTalentPoolCategoryList(status, categoryList) {
    return {
        type: UPDATE_TALENT_POOL_CATEGORY_LIST,
        status,
        categoryList
    }
}

function updateUserList(status, userList) {
    return {
        type: UPDATE_USER_LIST,
        status,
        userList
    }
}

function updateUserListHeight(height) {
    return {
        type: UPDATE_USER_LIST_HEIGHT,
        height
    }
}

function updateSelectedCategoryName(data) {
    return {
        type: UPDATE_SELECTED_CATEGORY_NAME,
        data
    }
}

function updateSubCategoryListData(status, subCategoryList) {
    return {
        type: UPDATE_SUBCATEGORY_LIST_DATA,
        status,
        subCategoryList
    }
}

function updateCandidateDetails(status, candidateDetailsList) {
    return {
        type: UPDATE_CANDIDATE_DETAILS,
        status,
        candidateDetailsList
    }
}
function onUserSelect(userId) {
    return {
        type: ON_USER_CLICK,
        userId
    }
}

const publicReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_PUBIC_STATE:
            return {
                ...defaultState,
                selectedUserId: state.selectedUserId
            };
        case UPDATE_SUBCATEGORY_LIST_DATA:
            let subCategoryListLoaded = false;
            if (action.status === false) {
                subCategoryListLoaded = true;
            }
            const _subCategoryList = action.subCategoryList
                ? [...action.subCategoryList]
                : null;
            return {
                ...state,
                subCategoryListLoaded,
                loadingSubCategoryList: action.status,
                subCategoryList: _subCategoryList
            };
        case UPDATE_CANDIDATE_DETAILS:
            let candidateDatilsLoaded = false;
            if (action.status === false) {
                candidateDatilsLoaded = true
            }
            const _candidateDetailsList = action.candidateDetailsList ?
                action.candidateDetailsList.map((candidate, index) => {
                    candidate.isActive = index + 1 === 1 ? true : false;
                    candidate.candidateId = index + 1;

                    return candidate;
                }) : [];

            return {
                ...state,
                candidateDatilsLoaded,
                loadingCandidateDetails: action.status,
                candidateDetailsList: _candidateDetailsList
            };
        case UPDATE_INFORMATION_FORM_CAPTCHA:
            return {
                ...state,
                captcha: action.captcha
            }
        case UPDATE_SELECTED_CATEGORY_NAME:
            return {
                ...state,
                selectedCategory: action.data
            };
        case ON_USER_CLICK:
            return {
                ...state,
                selectedUserId: action.userId
            };
        case UPDATE_USER_LOADING_STATUS:
            return {
                ...state,
                userListLoaded: action.status
            }
        case UPDATE_USER_LIST_HEIGHT:
            return {
                ...state,
                userListHeight: action.height
            };
        case UPDATE_TALENT_POOL_CATEGORY_LIST:
            let categoryListLoaded = false;
            if (action.status === false) {
                categoryListLoaded = true
            }

            return {
                ...state,
                categoryListLoaded,
                loadingCategoryList: action.status,
                categoryList: action.categoryList
            };
        case UPDATE_USER_LIST:
            let userListLoaded = false;
            if (action.status === false) {
                userListLoaded = true
            }
            return {
                ...state,
                userListLoaded,
                loadingUserList: action.status,
                userList: action.userList
            };
        default:
            return state;
    }
}

export {
    onUserSelect,
    updateUserList,
    onResetPublicState,
    updateUserListHeight,
    updateUserLodingStatus,
    updateCandidateDetails,
    updateSubCategoryListData,
    updateSelectedCategoryName,
    updateInformationFormCaptcha,
    updateTalentPoolCategoryList,
    publicReducer as PublicReducer,
}