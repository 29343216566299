import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
import Error from "../Error/Error";
import { fieldPropTypes } from "redux-form";
import { FormGroup, Label, Input } from "reactstrap";
import "./InputField.scss";

const InputField = ({
  id,
  input,
  label,
  min,
  max,
  minLength,
  maxLength,
  inputFieldValue,
  onFieldValueChange,
  isRequired,
  isDisabled,
  meta,
  wrapperClass,
  inputClass = "",
  regex = "",
  accept,
  onInputChangeFocus,
  onInputFocus,
  ...rest
}) => {
  if (inputFieldValue || onFieldValueChange) {
    input = { ...input, value: inputFieldValue, onChange: onInputFieldChange };
  }

  if (onInputChangeFocus) {
    input = { ...input, onBlur: onInputFieldChangeFocus };
  }
  if (onInputFocus) {
    input = { ...input, onFocus: OnInputFieldFocus };
  }
  function onInputFieldChangeFocus(e) {
    onInputChangeFocus(e.target.value);
  }
  function OnInputFieldFocus(e) {
    onInputFocus(e.target.value);
  }
  function onInputFieldChange(e) {
    if (regex) {
      const _regex = new RegExp(regex);
      if (e.target.value === "" || _regex.test(e.target.value)) {
        onFieldValueChange(e.target.value, e);
      }
    } else {
      onFieldValueChange(e.target.value, e);
    }
  }

  return (
    <FormGroup className={wrapperClass || ""}>
      <Label className="d-block ">
        {label ? <span>{label} </span> : null}
        {label && isRequired ? <span className="required-field">*</span> : null}
        <Input
          id={id}
          {...rest}
          {...input}
          className={classnames({
            "invalid-input": meta.touched && !!meta.error,
            [inputClass]: true,
          })}
          disabled={isDisabled}
          min={min}
          max={max}
          accept={accept}
          minLength={minLength}
          maxLength={maxLength}
        />
      </Label>
      {/* <Error show={(meta.touched && !!meta.error && !inputFieldValue)} text={!isVerified && inputFieldValue && label === 'Email'? `Kindly verify your email by clicking the VERIFY button.` : meta.error} /> */}
      
      <Error show={meta.touched && !!meta.error} text={meta.error} />

      {/* {meta.touched && !!meta.error && !inputFieldValue ? <span className="text-danger d-block">Input Field Required</span> : meta.touched && !!meta.error && inputFieldValue && label === 'Email' && !isDisabled ? <span className="text-danger d-block">Kindly verify your email by clicking the VERIFY button</span> : null} */}
    </FormGroup>
  );
};

InputField.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
};

export default InputField;
