import React from "react";
import { Link, withRouter } from "react-router-dom";
import { addRedirectUrlParams } from "App/shared/utils/Url";
import ActionButton from "App/shared/components/ActionButton/ActionButton";
import { isCandidateAuthenticated } from "App/shared/utils/Authentication";
import { getCareerUrl } from "../../utils/CareerUrl";
import "./NoResultsFound.scss";

const NoResultsFound = ({ match, theme, isCareerPage = false }) => {
  const vendorUUID = match.params.vendorUUID;
  const careerDirectUrl = addRedirectUrlParams(
    `${getCareerUrl(vendorUUID)}direct/`
  );
  const careerReferDirectUrl = addRedirectUrlParams(
    `${getCareerUrl(vendorUUID)}refer/`
  );
  const careerDirectRedirectUrl = encodeURIComponent(careerDirectUrl);
  const careerReferRedirectUrl = encodeURIComponent(careerReferDirectUrl);

  const isCandidateLoggedIn = isCandidateAuthenticated(vendorUUID);
  const joinTalentDomeUrl = isCandidateLoggedIn
    ? careerDirectUrl
    : `/${vendorUUID}/candidate/?redirect_url=${careerDirectRedirectUrl}`;
  const referToTalentDomeUrl = isCandidateLoggedIn
    ? careerReferDirectUrl
    : `/${vendorUUID}/candidate/?redirect_url=${careerReferRedirectUrl}`;

  //theme colors
  const textColorTheme = theme ? { color: theme.primaryColor } : {};
  const joinTalentdomeTheme = theme
    ? { borderColor: theme.primaryColor, color: theme.primaryColor }
    : {};
  const referTalentdomeTheme = theme
    ? { backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }
    : {};

  return isCareerPage ? (
    <div className="no-results empty-search-results">
      <div className="icon-search no-results-icon" style={textColorTheme} />
      <div className="no-results-text-content">
        <div className="no-results-text">
          <div className="text" style={textColorTheme}>
            No matching search results found.
          </div>
        </div>
        <div className="no-results-text">
          <div className="notifications-text">
            If you want search related Notifications, Sign in or Join our
            Network.
          </div>
          <Link to={referToTalentDomeUrl}>
            <ActionButton
              type="button"
              title="refer to talentdome"
              style={referTalentdomeTheme}
              className="submit refer-to-talentdome"
            />
          </Link>
          {!isCandidateLoggedIn ? (
            <Link to={joinTalentDomeUrl}>
              <ActionButton
                type="button"
                title="join talentdome"
                style={joinTalentdomeTheme}
                className="submit join-talentdome"
              />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="no-results">No results to display!!!</div>
  );
};
export default withRouter(NoResultsFound);
