import { connect } from "react-redux";
import gql from "graphql-tag";
import queryString from "query-string";
import { withApollo } from "react-apollo";
import { loadSettingsData } from "./stores/SettingStore";
import { setCategory } from "App/shared/settings/stores/SettingStore";

const SETTINGS_GET_QUERY = gql`
  query getSettings($uuid: String) {
    getSettings {
      currencyList {
        currencyId
        htmlCode
        countryName
        countryCode
      }
      globalSettings {
        name
        value
      }
      vendorDetails(uuid: $uuid) {
        vendorId
        name
        uuid
        fileUuid
        settings
        description
      }
    }
  }
`;

const SettingsGetQuery = ({
  uuid,
  client,
  setCategory,
  loadSettingsData,
  settingsDataLoaded,
  loadingSettingsData,
}) => {
  let params = queryString.parse(window.location.search);
  if (params.redirect_url) {
    const value = queryString.parseUrl(params.redirect_url);
    params = value.query;
  }
  if (loadingSettingsData === false && settingsDataLoaded === false) {
    loadSettingsData(true);
    if (params.category) {
      setCategory(params.category);
    }
    client
      .query({
        query: SETTINGS_GET_QUERY,
        variables: { uuid: uuid },
      })
      .then(({ data }) => {
        loadSettingsData(false, data.getSettings);
      });
  }
  return "";
};

const mapStateWithProps = ({ setting }) => {
  return {
    settingsDataLoaded: setting.settingsDataLoaded,
    loadingSettingsData: setting.loadingSettingsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSettingsData(status, data = null) {
      dispatch(loadSettingsData(status, data));
    },
    setCategory(data) {
      dispatch(setCategory(data));
    },
  };
};
const SettingsGetQueryWithApollo = withApollo(SettingsGetQuery);
export default connect(
  mapStateWithProps,
  mapDispatchToProps
)(SettingsGetQueryWithApollo);
