import Actions from "./actions";

function onCategoryListFetch(data) {
    return {
        type: Actions.ON_CATEGORY_LIST_FETCH,
        data
    };
}


export {
    onCategoryListFetch
};
