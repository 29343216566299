import React from 'react';
import { withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { removeSession } from 'App/shared/utils/Authentication';
import 'App/shared/components/MobileMenubar/MobileMenubar.scss';

const mobileMenubar = ({
    history,
    mutate,
    list
}) => {
    
    function redirectToPage(path) {
        path ? history.push(path) : logout();
    }

    function logout() {
        return mutate()
            .then(resp => {
                removeSession();
                history.push('/login');
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <div className="mobile-menubar">
            <ul className="mobile-menubar-items">
                {
                    list.map((item, index) => {
                        return (
                            index === 0 ?
                                <li key={index} className={item.className}>
                                    <span>{item.component} </span>
                                </li> :
                                <li key={index} className={item.className}>
                                    <div onClick={() => redirectToPage(item.path)}>
                                        {item.component}
                                    </div>
                                </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

const LOGOUT_MUTATION = gql`
    mutation logout {
        logout {
            accountId
        }
    }
`;

mobileMenubar.propTypes = {
    'mutate': PropTypes.func.isRequired
};

const mobileMenubarApollo = withApollo(mobileMenubar);

export default graphql(LOGOUT_MUTATION)(withRouter(mobileMenubarApollo));