export default function ApplicationExperienceYearsAndMonths({
   years,
   months,
   onlyInYear = false 
}) {
   years = parseInt(years);
   months = parseInt(months);
   if(onlyInYear&&  years !== 0){
      if(months && (months === 0)){
         return `${years} Years`
      }
      else{
         return `${years}+ Years`
      }
   }
   else if (years !== 0 && months !== 0) {
      return `${years} Years, ${months} Months`;
   }
   else if (years === 0 && months === 0) {
      return `-`;
   }
   else if (years === 0) {
      return `${months} Months`;
   } else {
      return `${years} Years`;
   }

}