import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getCareerUrl } from "App/shared/utils/CareerUrl";
import { addRedirectUrlParams } from "App/shared/utils/Url";
import RightArrowIcon from "App/shared/images/white-arrow.png";
import ActionButton from "App/shared/components/ActionButton/ActionButton";
import CareerHeader from "App/shared/components/CareerHeader/CareerHeader";
import CareerFooter from "App/shared/components/CareerFooter/CareerFooter";

const careerLayout = ({
  name,
  match,
  isMobile,
  isDesktop,
  vendorName,
  mobileComponent: MobileComponent,
  desktopComponent: DesktopComponent,
}) => {
  const { uuid } = match.params;
  function showButtonComponent() {
    return name !== "careers" ? true : false;
  }
  const careerHeaderUrl = addRedirectUrlParams(getCareerUrl(uuid));

  return (
    <div className="career-body-container">
      <div className="app-layout" id="app">
        {/* DeskTop Layout */}
        {isDesktop === true ? (
          <div className="app-desktop-layout">
            <CareerHeader
              showButtonComponent={showButtonComponent()}
              buttonComponent={
                <ButtonComponent careerHeaderUrl={careerHeaderUrl} />
              }
            />
            <div className="main">
              <div className="app-component career-app-component">
                <div className="desktop-component">
                  <DesktopComponent />
                </div>
              </div>
            </div>
            <CareerFooter companyName={vendorName} poweredBy="TalentDome" />
          </div>
        ) : null}

        {/* Mobile Layout */}
        {isMobile === true ? (
          <div className="app-mobile-layout">
            <CareerHeader />
            <div className="main">
              <MobileComponent />
            </div>
            <div className="mobile-footer">
              <CareerFooter companyName={vendorName} poweredBy="TalentDome" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateWithProps = ({ career, deviceIdentifier }) => {
  return {
    vendorName: career.vendorName,
    isMobile: deviceIdentifier.isMobile,
    isDesktop: deviceIdentifier.isDesktop,
  };
};

const ButtonComponent = ({ careerHeaderUrl }) => {
  function renderTitle() {
    return (
      <span>
        current positions <img src={RightArrowIcon} alt="right-arrow-icon" />
      </span>
    );
  }

  return (
    <div className="career-header-button">
      <Link to={careerHeaderUrl}>
        <ActionButton
          title={renderTitle()}
          className="white-button"
          type="button"
        />
      </Link>
    </div>
  );
};

export default connect(
  mapStateWithProps,
  null
)(withRouter(careerLayout));
