import React from 'react';
import Toastr from 'App/shared/utils/Toastr';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorStore from 'App/shared/utils/ErrorStore';
import { required, email } from 'App/shared/utils/Validator';
import { SubmissionError, Field, reduxForm } from 'redux-form';
import InputField from 'App/shared/components/InputField/InputField';
import ActionButton from 'App/shared/components/ActionButton/ActionButton';
import { RECAPTCHA_SITE_KEY, ENABLE_CAPTCHA } from '../../../../../../constants';
import './UserListDetails.scss';

let GetInformationForm = ({
    captcha,
    formDetails,
    onFormSubmit,
    handleSubmit,
    onFieldChange,
    onChangeCaptcha,
    formSubmitSuccess
}) => {

    function _validate(details) {
        const errorInstance = ErrorStore();
        errorInstance.add('email', details.email, [required, email]);
        errorInstance.add('fullName', details.fullName, [required]);
        return errorInstance.get();
    }
    function _handleSubmit(values) {
        let errors = null;
        const formDetails = _validate(values);

        if (formDetails) {
            errors = { ...(errors || {}), ...formDetails };
        }
        if (errors) {
            throw new SubmissionError({ ...errors });
        } else {
            if (ENABLE_CAPTCHA === "true") {
                if (captcha) {
                    onFormSubmit(values);
                } else {
                    Toastr.error('Please enter captcha');
                }
            } else {
                onFormSubmit(values);
            }
        }
    }
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    let trident = ua.indexOf('Trident/');
    let edge = ua.indexOf('Edge/');
    return (
        <form id="get-information-form"
            name="GetInformationForm"
            className="get-information-form row my-3"
            onSubmit={handleSubmit(_handleSubmit)}>
            <div className="p-0 col-lg-12 col-xl-6 input-fields">
                <Field
                    id="fullName"
                    type="text"
                    name="fullName"
                    isRequired={true}
                    validate={[required]}
                    placeholder="Full Name*"
                    component={InputField}
                    regex="^[a-zA-Z '-]+$"
                    inputFieldValue={formDetails.fullName}
                    onFieldValueChange={(value) => onFieldChange(value, "fullName")}
                // regex="^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$"
                />
            </div>
            <div className="p-0 col-lg-12 col-xl-6  input-fields">
                <Field
                    id="email"
                    type="text"
                    name="email"
                    maxLength="50"
                    isRequired={true}
                    placeholder="Email*"
                    component={InputField}
                    validate={[required, email]}
                    inputFieldValue={formDetails.email}
                    onFieldValueChange={(value) => onFieldChange(value, "email")}
                />
            </div>
            {
                edge > 0 || trident > 0 || msie > 0 ?
                    <div className={`p-0 col-lg-12 col-xl-6  input-fields`}>
                        <Field
                            id="phone"
                            type="text"
                            min="0"
                            minLength="10"
                            maxLength="10"
                            regex='[0-9\b]+$'
                            name="phone"
                            component={InputField}
                            placeholder="Phone"
                            inputFieldValue={formDetails.phone}
                            onFieldValueChange={(value) => onFieldChange(value, "phone")}
                        />
                    </div>
                    : <div className={`p-0 col-lg-12 col-xl-6  input-fields`}>
                        <Field
                            id="phone"
                            type="text"
                            min="0"
                            minLength="10"
                            maxLength="10"
                            regex='[0-9\b]+$'
                            name="phone"
                            component={InputField}
                            placeholder="Phone"
                            inputFieldValue={formDetails.phone}
                            onFieldValueChange={(value) => onFieldChange(value, "phone")}
                        />
                    </div>
            }
            {
                ENABLE_CAPTCHA === "true"
                    ? (
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 input-field text-left apply-margin-bottom">
                            <ReCAPTCHA
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={(e) => onChangeCaptcha(e)} />
                        </div>
                    ) : null
            }
            <div className="p-0 col-lg-12 col-xl-4">
                <ActionButton
                    type="submit"
                    title="get information"
                    className="send-button"
                    showDisabledActionButton={formSubmitSuccess}
                />
            </div>

        </form>
    )
}

GetInformationForm = reduxForm({
    'form': 'GetInformationForm',
    'enableReinitialize': true
})(GetInformationForm);

export default GetInformationForm;