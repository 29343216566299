import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import Tag from '../../../../shared/components/Tag/Tag';
import Address from '../../../../shared/components/Address/Address';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import '../shared/ChatbotLocationInput.scss'

class ChatbotLocationInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            addressObject: []
        }
        this.removeTag = this.removeTag.bind(this)
        this.onSubmitApply = this.onSubmitApply.bind(this)
        this.onAddressChange = this.onAddressChange.bind(this)
    }
    onAddressChange(selectedAddress) {
        const { data, addressObject } = this.state
        const { address } = selectedAddress
        if (data.length < 3) {
            let _data = data.filter((value) => value !== address.cityName);
            _data.push(address.cityName)
            addressObject.push(selectedAddress)
            this.setState({
                addressObject: addressObject,
                data: _data,
            })

        }
    }
    onSubmitApply() {
        const { data } = this.state
        this.props.onSubmitLocationOptions(data)
    }
    removeTag(name) {
        const { data, addressObject } = this.state
        let _data = data.filter(cityName => cityName !== name)
        let _addressObject = addressObject.filter((address) => {
            if (address.address.cityName !== name) {
                return address
            }
            return null
        })

        this.setState({
            data: _data,
            addressObject: _addressObject
        })
    }

    render() {
        const { data } = this.state;
        const { clientConfig } = this.props
        const backgroundColorStyle = {
            background: clientConfig.style.serverResponseBgColor
        }
        const selectedCityNameColor = {
            color: clientConfig.style.serverResponseFontColor,
            border: `1px solid ${clientConfig.style.serverResponseFontColor}`
        }
        return (
            <div className="chatbot-location-input" style={backgroundColorStyle}>
                <form name="locationInput" id="locationInput">
                    {
                        data.length > 0 ?
                            <div className="city-name">
                                {
                                    data.map((name, index) => {
                                        return (
                                            <div style={selectedCityNameColor} className="tag-city-name" key={index} onClick={(e) => { this.removeTag(name) }}>
                                                <Tag tag={name} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : ""
                    }
                    <div className={data.length === 0 ? "location-fields" : "location-fields input-field-width"} >
                        <Field
                            type="text"
                            id="location"
                            name="location"
                            autoFocus={true}
                            isChatbot={true}
                            isRequired={false}
                            component={Address}
                            locationDetails={{}}
                            inputFieldValue={""}
                            fieldRef={this.props.locationRef}
                            onAddressChange={this.onAddressChange}
                            isDisabled={data.length === 3 ? true : false}
                            placeholder={data.length >= 1 ? "Add more locations" : "Add location"}
                        />
                        {
                            data.length === 0 ? null :
                                <div className="location-action-button">
                                    <ActionButton
                                        type="button"
                                        title="search"
                                        className='submit-button'
                                        onClick={this.onSubmitApply} />
                                </div>
                        }
                    </div>
                </form>
            </div>
        )
    }

}

ChatbotLocationInput = reduxForm({
    'form': 'locationInput',
    'enableReinitialize': true
})(ChatbotLocationInput);
export default withRouter(ChatbotLocationInput);