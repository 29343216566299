import React from 'react';
import { withCookies } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch } from 'react-router-dom';
import { reducer as formReducer } from 'redux-form';
import { SharedReducer } from './shared/store/reducer';
import { NotFoundRouteDetails } from './screens/NotFound/index';
import { SettingReducer } from './shared/settings/stores/SettingStore';
import { LayoutReducer } from './shared/components/Layout/LayoutStore';
import { LoginRouteDetails, LoginReducer } from './screens/Login/index';
import { SavingCalculatorReducer } from './screens/SavingCalculator/index';
import GoogleSearch from 'App/shared/components/GoogleSearch/GoogleSearch';
import BreadcrumbSet from 'App/shared/components/BreadcrumbSet/BreadcrumbSet';
import PublicTalentPoolRouting from 'App/screens/Public/components/publicTalentPoolRouting';
import DeviceIdentifier from 'App/shared/components/DeviceIdentifier/DeviceIdentifier';
import { LoginSavingCalcultorRouteDetails } from './screens/LoginSavingCalculater/index';
import { deviceIdentifierReducer } from './shared/components/DeviceIdentifier/DeviceIdentifierStore';
import { ChatbotReducer } from 'App/shared/components/talentdome-chatbot/src/App/screens/Chatbot/ChatbotUI/stores/ChatbotStore';
import {
    CandidateReducer,
    loginPageRouteDetails,
    MyProfileRouteDetails,
    candidateRouterDetails,
    UnsubscribeRouteDetails,
    registerPageRouteDetails,
    MyProfileRouteEditDetails,
    ResetPasswordRouteDetails,
    ChangePasswordRouteDetails,
    ForgotPasswordRouteDetails,
    AssessmentHistoryRouteDetails,
    CandidateReferralPathwayRouteDetails,
    CandidateRedemptionHistoryRouteDetails,
    AssessmentRulesRouteDetails,
    AssessmentResultRouteDetails,
    AssessmentQuestionsRouteDetails
} from './screens/Candidate/index';
import {
    DashboardReducer,
    DashboardRouteDetails,
    dashboardBreadcrumbDetails
} from './screens/Dashboard/index';
import {
    CareerReducer,
    CareerRouteDetails,
    CareerAddRouteDetails,
    CareerDirectRouteDetails,
    CareerDetailsRouteDetails,
    CareerReferDirectRouteDetails
} from './screens/Career/index';
import {
    ApplicationReducer,
    ApplicationRouteDetails,
    applicationBreadcrumbDetails,
    ApplicationDetailsRouteDetails,
    applicationDetailsBreadcrumbDetails
} from './screens/Application/index';
import {
    TalentPoolReducer,
    TalentPoolRouteDetails,
    talentPoolBreadcrumbDetails,
    TalentPoolDetailsRouteDetails,
    talentPoolDetailsBreadcrumbDetails,
    TalentPoolEditRouteDetails,
    TalentPoolEditBreadcrumbDetails
} from './screens/TalentPool/index';
import {
    PositionReducer,
    PositionRouteDetails,
    PositionAddRouteDetails,
    PositionEditRouteDetails,
    positionBreadcrumbDetails,
    PositionShareRouteDetails,
    PositionDetailsRouteDetails,
    positionAddBreadcrumbDetails,
    positionEditBreadcrumbDetails,
    positionShareBreadcrumbDetails,
    positionDetailsBreadcrumbDetails,
} from './screens/Position/index';

import {
    DistributionReducer,
    DistributionRouteDetails,
    DistributionAddRouteDetails,
    DistributionEditRouteDetails,
    distributionBreadcrumbDetails,
    distributionDetailsRouteDetails,
    distributionAddBreadcrumbDetails,
    distributionEditBreadcrumbDetails,
    distributionDetailsBreadcrumbDetails
} from './screens/Distribution/index';

import {
    ContactReducer,
    ContactRouteDetails,
    ContactAddRouteDetails,
    ContactEditRouteDetails,
    contactBreadcrumbDetails,
    ContactDetailsRouteDetails,
    contactAddBreadcrumbDetails,
    contactEditBreadcrumbDetails,
    contactDetailsBreadcrumbDetails,
    ContactIndividualAddRouteDetails,
    ContactOrganizationAddRouteDetails,
    contactIndividualAddBreadcrumbDetails,
    ContactOrganizationAddBreadcrumbDetails,
} from './screens/Contact/index';
import {
    VoucherReducer,
    redeemVoucherRouteDetails,
    voucherDetailsRouteDetails
} from './screens/Voucher/index';

import { PublicReducer } from './screens/Public/index';
import ReactGA from 'react-ga4';

const app = ({ cookies }) => {
    const index = window.location.pathname.indexOf("careers")
    if (index !== -1) {
        // ReactGA.pageview(window.location.pathname)
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "App" });
    }
    return (
        // Set breadcrumbs
        <div className="app">
            <DeviceIdentifier />
            <BreadcrumbSet breadcrumbDetails={dashboardBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={applicationBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={applicationDetailsBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={positionBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={positionAddBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={positionEditBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={positionDetailsBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={positionShareBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={talentPoolBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={distributionBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={talentPoolDetailsBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={distributionAddBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={distributionEditBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={distributionDetailsBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={contactBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={contactAddBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={contactDetailsBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={contactEditBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={contactIndividualAddBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={ContactOrganizationAddBreadcrumbDetails} />
            <BreadcrumbSet breadcrumbDetails={TalentPoolEditBreadcrumbDetails} />
            <Switch>
                <Route {...DashboardRouteDetails} />
                <Route {...LoginRouteDetails} />
                <Route {...candidateRouterDetails} />
                <Route {...ChangePasswordRouteDetails} />
                <Route {...loginPageRouteDetails} />
                <Route {...ResetPasswordRouteDetails} />
                <Route {...registerPageRouteDetails} />
                <Route {...ForgotPasswordRouteDetails} />
                <Route {...PositionRouteDetails} />
                <Route {...ApplicationRouteDetails} />
                <Route {...CareerRouteDetails} />
                <Route {...CareerDetailsRouteDetails} />
                <Route {...CareerAddRouteDetails} />
                <Route {...PositionAddRouteDetails} />
                <Route {...PositionEditRouteDetails} />
                <Route {...PositionDetailsRouteDetails} />
                <Route {...PositionShareRouteDetails} />
                <Route {...ApplicationDetailsRouteDetails} />
                <Route {...TalentPoolRouteDetails} />
                <Route {...TalentPoolDetailsRouteDetails} />
                <Route {...DistributionRouteDetails} />
                <Route {...DistributionAddRouteDetails} />
                <Route {...DistributionEditRouteDetails} />
                <Route {...distributionDetailsRouteDetails} />
                <Route {...ContactRouteDetails} />
                <Route {...ContactAddRouteDetails} />
                <Route {...ContactEditRouteDetails} />
                <Route {...ContactDetailsRouteDetails} />
                <Route {...ContactIndividualAddRouteDetails} />
                <Route {...ContactOrganizationAddRouteDetails} />
                <Route {...CareerDirectRouteDetails} />
                <Route {...CareerReferDirectRouteDetails} />
                <Route {...LoginSavingCalcultorRouteDetails} />
                <Route {...UnsubscribeRouteDetails} />
                <Route {...voucherDetailsRouteDetails} />
                <Route {...redeemVoucherRouteDetails} />
                <Route {...CandidateReferralPathwayRouteDetails} />
                <Route {...CandidateRedemptionHistoryRouteDetails} />
                <Route {...AssessmentHistoryRouteDetails} />
                <Route {...MyProfileRouteDetails} />
                <Route {...MyProfileRouteEditDetails} />
                <Route {...AssessmentRulesRouteDetails} />
                <Route {...AssessmentResultRouteDetails} />
                <Route {...AssessmentQuestionsRouteDetails} />
                <Route {...TalentPoolEditRouteDetails} />
                <Route path="/public" component={PublicTalentPoolRouting} />
                <Route {...NotFoundRouteDetails} />
                <Route path="/search/:q/:page" component={GoogleSearch} />

            </Switch>
        </div>
    );
};

const reducers = {
    form: formReducer,
    login: LoginReducer,
    career: CareerReducer,
    layout: LayoutReducer,
    shared: SharedReducer,
    setting: SettingReducer,
    chatbot: ChatbotReducer,
    voucher: VoucherReducer,
    contact: ContactReducer,
    position: PositionReducer,
    candidate: CandidateReducer,
    dashboard: DashboardReducer,
    talentPool: TalentPoolReducer,
    application: ApplicationReducer,
    distribution: DistributionReducer,
    deviceIdentifier: deviceIdentifierReducer,
    savingCalculator: SavingCalculatorReducer,
    publicTalentPool: PublicReducer
};

const appWithCookies = withCookies(app);

export { appWithCookies as App, reducers as Reducers }