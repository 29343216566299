import React from 'react';

const Tags = ({
    tags
}) => {
    return (
        <div className="tags">
            {
                (tags && tags.length > 0) ? (tags.map((value, index) => 
                    (<span key={index}> {value} </span>))) : '-'
            }
        </div>
    );
};

export default Tags;