import React from "react";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { getCareerUrl } from "../../utils/CareerUrl";
import ActionButton from "App/shared/components/ActionButton/ActionButton";
import ApplicationLogo from "App/shared/components/ApplicationLogo/ApplicationLogo";
import ApplicationCustomDropdown from "App/shared/components/ApplicationCustomDropdown";
import {
  getCandidateUserDetails,
  redirectToCandidateLogin,
} from "App/shared/utils/Authentication";
import NotificationlistGetQuery from "App/screens/Candidate/screens/Login/components/notificationListGetQuery";
import NotificationCountGetQuery from "App/screens/Candidate/screens/Login/components/notificationCountGetQuery";
import "./CandidateHeader.scss";
import VendorLogo from "../VendorLogo";

const candidateHeader = ({
  count,
  match,
  theme,
  category,
  client,
  history,
  loginUrl,
  resetCount,
  registerUrl,
  vendorDetails,
  activeMenuName,
  dropdownOptions,
  onDropDownClick,
  notificationList,
  candidateHeaderUrl,
  onNotificationClick,
  isCandidateLoggedIn,
  onCandidateLogoutUrl,
  loadingNotificationList,
}) => {
  const vendorUUID = match.params.vendorUUID || match.params.uuid || null;
  const userDetails = getCandidateUserDetails(vendorUUID);
  const candidateUserName = userDetails
    ? userDetails.firstName + " " + userDetails.lastName
    : "";

  const headerTheme = theme ? { color: theme.primaryColor } : {};
  const loginTheme = theme
    ? {
        backgroundColor: theme.primaryColor,
        borderColor: theme.primaryColor,
      }
    : {};
  const signupTheme = theme
    ? {
        color: theme.primaryColor,
        borderColor: theme.primaryColor,
      }
    : {};

  const notificationBell = (
    <div>
      <span className="icon-bell bell-icon" style={headerTheme} />
      {count ? (
        <div className="candidate-info__notifications__count"> {count} </div>
      ) : null}
    </div>
  );
  const notificationsList =
    notificationList.length > 0
      ? notificationList
      : [{ displayName: "No Result Found" }];

  const isEmptyList = notificationList.length > 0 ? false : true;
  let talentdomeLogo = "";
  if (category && theme.talentdomeLogo) {
    talentdomeLogo = theme.talentdomeLogo;
  } else {
    talentdomeLogo = "/images/talentdome-logo.png";
  }

  function onDropdownClick(value) {
    if (value.displayName === "logout") {
      return client
        .mutate({
          mutation: gql`
            mutation candidateLogout($uuid: String) {
              candidateLogout(uuid: $uuid) {
                accountId
              }
            }
          `,
          variables: {
            uuid: vendorUUID,
          },
        })
        .then((_) => {
          const url = onCandidateLogoutUrl(getCareerUrl(vendorUUID));
          redirectToCandidateLogin(vendorUUID, url);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push(value.path);
    }
  }

  return (
    <div className="app-header career-header">
      <div className="app-header-logo">
        <VendorLogo />
        <Link to={candidateHeaderUrl}>
          <h2 style={headerTheme}> Careers </h2>
        </Link>
      </div>
      <div className="vendor-logo">
        {isCandidateLoggedIn ? (
          <div className="candidate-info">
            <NotificationCountGetQuery uuid={vendorUUID} />
            <NotificationlistGetQuery uuid={vendorUUID} />
            <div className="candidate-info__notifications">
              <ApplicationCustomDropdown
                theme={theme}
                activeMenuName={""}
                isNotification={true}
                resetCount={resetCount}
                title={notificationBell}
                isEmptyList={isEmptyList}
                onDropDownClick={onDropDownClick}
                dropdownOptions={notificationsList}
                loadingList={loadingNotificationList}
                className="application-custom-notifiaction-dropdown-content"
                onDropdownOptionClick={(value) => onNotificationClick(value)}
              />
            </div>
            <div className="candidate-info__dropdown">
              <span className="icon-user-circle Profile" style={headerTheme} />
              <ApplicationCustomDropdown
                theme={theme}
                title={candidateUserName}
                activeMenuName={activeMenuName}
                dropdownOptions={dropdownOptions}
                className="application-custom-dropdown-content"
                onDropdownOptionClick={(value) => onDropdownClick(value)}
              />
            </div>
            <div className="middle-line" />
          </div>
        ) : (
          <div className="candidate-header-buttons">
            <div className="login">
              <Link to={loginUrl}>
                <ActionButton
                  title="login"
                  type="button"
                  style={loginTheme}
                  className="submit text-uppercase"
                />
              </Link>
            </div>
            <div className="signup">
              <Link to={registerUrl}>
                <button className="submit text-uppercase" style={signupTheme}>
                  create an account
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className="app-header-logo product-logo left-border-div">
          <h1>
            <ApplicationLogo logo={talentdomeLogo} />
          </h1>
        </div>
      </div>
    </div>
  );
};

const candidateHeaderWithApollo = withApollo(candidateHeader);
export default withRouter(candidateHeaderWithApollo);
