import { API_URL } from '../../constants';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import Toastr from '../shared/utils/Toastr';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {
  redirectToLogin,
  getSessionToken,
  getCandidateSessionToken
} from '../shared/utils/Authentication';


const authLink = setContext((request, { headers }) => {
    let token = null;
    if (request.variables.uuid || request.variables.vendorUuid) {
      // get candidate token
      token = getCandidateSessionToken(request.variables.uuid || request.variables.vendorUuid);
    } else {
      // get recruiter or admin token
      token = getSessionToken();
    }
  
    return token ? {
      headers: {
        ...headers,
        authorization: `${token}`,
      }
    } : { headers };
  });
  
  const invalidSessionLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors.find(({ errorCode }) => errorCode === 401)) {
      Toastr.error('Session Expired');
      setTimeout(_ => {
        redirectToLogin();
      }, 1000);
    }
  });
  
  const httpLink = createHttpLink({
    uri: API_URL
  });
  
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  };
  
  const client = new ApolloClient({
    link: authLink.concat(invalidSessionLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions
  });

  export default client;
  