import React from 'react';
import { CareerReducer } from './stores/CareerStore';
import CandidateLayout from '../Candidate/shared/CandidateLayout';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { CareerAddRouteDetails } from 'App/screens/Career/screens/CareerAdd/index';
import { CareerDetailsRouteDetails } from 'App/screens/Career/screens/CareerDetails/index';
import { CareerDirectRouteDetails } from 'App/screens/Career/screens/CareerDirectApply/index';
import { CareerReferDirectRouteDetails } from 'App/screens/Career/screens/CareerReferDirectApply/index';
import './shared/Career.scss';

const CareerDesktopComponentAsync = lazyLoad(() => {
    return import('./components/CareerDesktopPage');
});

const CareerMobileComponentAsync = lazyLoad(() => {
    return import('./components/CareerMobilePage');
});

const routePath = '/careers/:vendorUUID';

const careerRouteDetails = {
    name: 'careers',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName = 'candidateDashboard'
            name = {careerRouteDetails.name}
            desktopComponent = { CareerDesktopComponentAsync } 
            mobileComponent = { CareerMobileComponentAsync } />;
    }
};

export { 
    careerRouteDetails as CareerRouteDetails, 
    CareerReducer ,
    CareerDetailsRouteDetails,
    CareerReferDirectRouteDetails,
    CareerAddRouteDetails,
    CareerDirectRouteDetails
};