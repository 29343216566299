import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/DistributionAdd.scss';

const DistributionAddComponentAsync = lazyLoad(() => {
    return import('./components/DistributionAdd');
});

const routePath = '/distribution/add';

const distributionAddRouteDetails = {
    name: 'distributionAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'distribution',
            breadcrumbName: 'distributionAdd',
            desktopComponent: DistributionAddComponentAsync,
            mobileComponent: DistributionAddComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'distributionAdd',
    displayName: 'create distribution list',
    path: routePath,
    parentName: 'distribution'
};

export { distributionAddRouteDetails as DistributionAddRouteDetails, breadcrumbDetails as distributionAddBreadcrumbDetails };