import React, { Component } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
} from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import Error from '../Error/Error';
import { fieldPropTypes } from 'redux-form';
import { FormGroup, Label } from 'reactstrap';
import closeIcon from '../../images/close.svg';
import locationIcon from '../../images/Location-1x.png';
import { convertToAbsoluteUrl } from '../../utils/UrlFormatter';
import './Address.scss';

class Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.inputFieldValue
        }
        this.getAddressDeatils = this.getAddressDeatils.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(address) {
        this.setState({ address });
    };
    getAddressDeatils(details) {
        geocodeByAddress(details)
            .then(results => {
                const _address = this._formatLocationDetails(results[0])
                if (this.props.isChatbot) {
                    this.setState({ address: "" })
                } else {
                    this.setState({
                        address: details
                    })
                }
                this.props.onAddressChange(_address)

            })
            .catch((err) => {
                console.log("err", err)
            })
    }
    _formatLocationDetails(details) {
        let addressLine1 = [];
        const addressDetails = {};
        details.address_components.map((data) => {
            let type = data.types[0];
            switch (type) {
                case 'street_number':
                case 'neighborhood':
                case 'route':
                case 'sublocality_level_2':
                case 'sublocality':
                case 'premise':
                case 'locality':
                case 'colloquial_area':
                case 'administrative_area_level_3':
                case 'establishment':
                    addressLine1.push(data.long_name);
                    addressDetails.cityName = data.long_name;
                    break;
                case 'administrative_area_level_1':
                    addressDetails.stateName = data.long_name;
                    addressDetails.stateCode = data.short_name;
                    break;
                case 'country':
                    addressDetails.country = data.short_name;
                    break;
                case 'postal_code':
                    addressDetails.zipCode = data.long_name;
                    break;
                default:
                    break;
            }
            return null;
        });

        const lat = details.geometry.location.lat();
        const lng = details.geometry.location.lng();
        addressDetails.addressLine1 = addressLine1.length > 0 ? addressLine1.join(',') : '';

        return {
            lat,
            lng,
            address: { ...addressDetails }
        };
    }
    onClear() {
        this.props.onClearButton();
    }

    render() {
        const {
            id,
            meta,
            label,
            fieldRef,
            isRequired,
            placeholder,
            wrapperClass,
            isDisabled = false,
            isChatbot = false,
            isCareerPage = false,
        } = this.props;
        const searchOptions = {
            country: ['us', 'ca', 'in', 'ind'],
            types: ['(cities)']
        }
        let _closeIcon = convertToAbsoluteUrl(closeIcon);
        let _locationIcon = convertToAbsoluteUrl(locationIcon);

        return (
            <div className="address">
                <FormGroup className={wrapperClass || ''}>
                    <Label className="d-block">
                        <span>{label} </span>
                        {
                            (isRequired && label) ? <span className="required-field">*</span> : null
                        }
                        <PlacesAutocomplete
                            id={id}
                            searchOptions={searchOptions}
                            value={this.state.address}
                            placeholder={placeholder}
                            required={isRequired}
                            onChange={this.handleChange}
                            onSelect={(details) => (
                                this.getAddressDeatils(details)
                            )}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            ref: fieldRef,
                                            disabled: isDisabled,
                                            placeholder: `${placeholder}`,
                                            className: meta.error
                                                ? 'location-search-input invalid-input'
                                                : 'location-search-input',
                                        })}
                                    />
                                    <div className={suggestions.length > 0
                                        ? isChatbot && window.innerWidth < 600
                                            ? "autocomplete-dropdown-container suggestions-container open-job-list-up"
                                            : isChatbot && window.innerWidth > 600
                                                ? "autocomplete-dropdown-container suggestions-container set-postion"
                                                : "autocomplete-dropdown-container suggestions-container"
                                        : "autocomplete-dropdown-container"}>
                                        {
                                            loading && <div>Loading...</div>
                                        }
                                        {
                                            suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })} key={index}>
                                                        <div className="suggestion">
                                                            <span className="autocomplete-icon icon-localities"></span>
                                                            {suggestion.description}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Label>
                    {
                        isCareerPage
                            ? <div className='location-icon'>
                                <img src={_locationIcon} alt='locationIcon' />
                            </div> : null
                    }
                    {
                        isCareerPage && this.state.value
                            ? <div className='close-icon' onClick={_ => this.onClear()}>
                                <img src={_closeIcon} alt='close-icon' />
                            </div> : null
                    }
                    <Error show={meta.touched && !!meta.error} text={meta.error} />
                </FormGroup>
            </div>
        );
    }
}

Address.propTypes = {
    ...fieldPropTypes,
    label: PropTypes.string
};

export default Address;