import React from "react";

const applicationLogo = ({ logo, link }) => {
  return (
    <div>
      {link ? (
        <a href={link} target="_parent">
          <img src={logo} alt="TalentDome Logo" className="" />
        </a>
      ) : (
        <img src={logo} alt="TalentDome Logo" className="" />
      )}
    </div>
  );
};

export default applicationLogo;
