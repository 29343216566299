import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { updateNotificationList } from 'App/screens/Candidate/stores/CandidateStore';

const NOTIFICATION_LIST_GET_QUERY = gql`
query getNotificationList {
    getNotificationList {
        notificationId,
        displayName,
        createdAt,
        code,
        score,
        isNotificationRead  
    }
}
`;

let notificationlistGetQuery = ({
    uuid,
    client,
    NotifiactionListLoaded,
    updateNotificationList,
    loadingNotificationList
}) => {
    if(loadingNotificationList === false && NotifiactionListLoaded === false) {
        client.query({
            query: NOTIFICATION_LIST_GET_QUERY,
            variables:{
                'uuid': uuid
            }
            
        }).then(resp => {
            updateNotificationList(false, resp.data.getNotificationList)
        })
    }
    
    return '';
}

const mapStateWithProps = ({ candidate }) => {
    return {
        loadingNotificationList: candidate.loadingNotificationList,
        NotifiactionListLoaded: candidate.NotifiactionListLoaded
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateNotificationList(status, notificationlist) {
            
            dispatch(updateNotificationList(status, notificationlist));
        }
    };
};

const NotificationlistGetQuery = withApollo(notificationlistGetQuery);
export default connect(mapStateWithProps, mapDispatchToProps)(NotificationlistGetQuery);