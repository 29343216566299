import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const ContactAddComponentAsync = lazyLoad(() => {
    return import('./components/ContactAdd');
});

const routePath = '/contact/add';

const ContactAddRouteDetails = {
    name: 'contactAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'candidateProfiles',
            breadcrumbName: 'contactAdd',
            desktopComponent: ContactAddComponentAsync,
            mobileComponent: ContactAddComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'contactAdd',
    displayName: 'add candidate',
    path: routePath,
    parentName: 'candidate profiles'
};

export { ContactAddRouteDetails, breadcrumbDetails as contactAddBreadcrumbDetails };