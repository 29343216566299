
export default function ApplicationLocationFormat({
    location,
    type
}) {
    const cityName = location.cityName.charAt(0).toUpperCase() + location.cityName.slice(1).toLowerCase();
    const stateName = location.stateName.charAt(0).toUpperCase() + location.stateName.slice(1).toLowerCase();
    let updatedLocation;
    if (cityName && stateName) {
        (type === "city and state") ?
            updatedLocation = `${cityName}, ${stateName}`
            : updatedLocation = `${cityName}, ${stateName}`
    }
    if (stateName && !cityName) {
        updatedLocation = `${stateName}`
    }
    if (!stateName && cityName) {
        updatedLocation = `${cityName}`
    }
    return updatedLocation;
}
