import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const AssessmentResult = lazyLoad(() => {
    return import('./components/AssessmentResult.js');
});

const routePath = '/:vendorUUID/candidate/:candidateCode/assessment/result';
const AssessmentResultRouteDetails = {
    exact: true,
    path: routePath,
    name: 'assessmentResult',
    render() {
        return <CandidateLayout
            activeMenuName = 'candidateDashboard'
            name = {AssessmentResultRouteDetails.name}
            desktopComponent = {AssessmentResult}
            mobileComponent = {AssessmentResult} 
        />
    }
};

export { AssessmentResultRouteDetails };