import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const CandidateRedemptionHistory = lazyLoad(() => {
    return import('./components/CandidateRedemptionHistory');
});

const routePath = '/:vendorUUID/candidate/redemption-history';
const CandidateRedemptionHistoryRouteDetails = {
    exact: true,
    path: routePath,
    name: 'CandidateRedemptionHistory',
    render() {
        return <CandidateLayout
            activeMenuName = 'redemptionHistory'
            name = {CandidateRedemptionHistoryRouteDetails.name}
            desktopComponent = {CandidateRedemptionHistory}
            mobileComponent = {CandidateRedemptionHistory} 
        />
    }
};

export { CandidateRedemptionHistoryRouteDetails };