import React from 'react';
import './userlistCardHeader.scss'

function userListCardHeader({
    name,
    onClick,
    // position
}) {
    return (
        <div className="user-list-Card-header-container" onClick={e => onClick()}>
            <div className="user-list-Card-header-container__name">
                {name}
            </div>
            {/* <div className="user-list-Card-header-container__position">
                {position}
            </div> */}
        </div>
    )
}

export default userListCardHeader;