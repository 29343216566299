import React from 'react';
import CandidateLayout from './shared/CandidateLayout';
import { candidateReducer } from './stores/CandidateStore';
import { loginPageRouteDetails } from './screens/Login/index';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { MyProfileRouteDetails } from './screens/MyProfile/index';
import { registerPageRouteDetails } from './screens/Register/index';
import { UnsubscribeRouteDetails } from './screens/Unsubscribe/index';
import { MyProfileRouteEditDetails } from './screens/MyProfileEdit/index';
import { ResetPasswordRouteDetails } from './screens/ResetPassword/index';
import { ChangePasswordRouteDetails } from './screens/ChangePassword/index';
import { ForgotPasswordRouteDetails } from './screens/ForgotPassword/index';
import { AssessmentHistoryRouteDetails } from './screens/AssessmentHistory/index';
import { CandidateReferralPathwayRouteDetails } from './screens/CandidateReferralPathway/index';
import { CandidateRedemptionHistoryRouteDetails } from './screens/CandidateRedemptionHistory/index';
import { AssessmentRulesRouteDetails } from './screens/AssessmentRules/index';
import { AssessmentResultRouteDetails } from './screens/AssementResult/index';
import { AssessmentQuestionsRouteDetails } from './screens/AssessmentQuestionsPage/index';

const CandidateAsync = lazyLoad(() => {
    return import('./components/CandidatePage');
});

const routePath = '/:vendorUUID/candidate';

const candidateRouterDetails = {
    exact: true,
    path: routePath ,
    name: 'candidate',
    render() {
        return <CandidateLayout 
            activeMenuName = 'candidateDashboard'
            name = {candidateRouterDetails.name}
            desktopComponent = {CandidateAsync} 
            mobileComponent = {CandidateAsync}
        />
    }
};

export { 
    MyProfileRouteDetails,
    loginPageRouteDetails,
    candidateRouterDetails, 
    UnsubscribeRouteDetails,
    registerPageRouteDetails,
    MyProfileRouteEditDetails,
    ResetPasswordRouteDetails,
    ChangePasswordRouteDetails,
    ForgotPasswordRouteDetails,
    AssessmentHistoryRouteDetails,
    CandidateReferralPathwayRouteDetails,
    candidateReducer as CandidateReducer,
    CandidateRedemptionHistoryRouteDetails,
    AssessmentRulesRouteDetails,
    AssessmentResultRouteDetails,
    AssessmentQuestionsRouteDetails
};