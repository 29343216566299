import Cookies from 'universal-cookie';

export function setSession(token) {
    const cookies = new Cookies();
    cookies.set('token', token);
}
//for candidate login
export function setCandidateSession(token, uuid) {
    const cookies = new Cookies(); 
    cookies.set(`candidateToken${uuid}`, token, { path: '/' });
}

export function setUserDetails(user) {
    const cookies = new Cookies();
    cookies.set('user', user);
}
//for candidate login
export function setCandidateUserDetails(token, uuid) {
    const cookies = new Cookies();
    cookies.set(`candidate${uuid}`, token, { path: '/' });
}

export function setVendorFileUuid(fileUuid) {
    const cookies = new Cookies();
    cookies.set('fileUuid', fileUuid);
}

export function isAuthenticated() {
    const cookies = new Cookies();
    return cookies.get('token') ? true : false;
}
//for candidate login
export function isCandidateAuthenticated(uuid) {
    const cookies = new Cookies();
    return cookies.get(`candidateToken${uuid}`) ? true : false;
}

export function getSessionToken() {
    const cookies = new Cookies();
    return cookies.get('token');
}
//for candidate login
export function getCandidateSessionToken(uuid) {
    const cookies = new Cookies();
    return cookies.get(`candidateToken${uuid}`);
}

export function getUserDetails() {
    const cookies = new Cookies();
    return cookies.get('user');
}
//for candidate login
export function getCandidateUserDetails(uuid) {
    const cookies = new Cookies();
    return cookies.get(`candidate${uuid}`);
}

export function getVendorFileUuid() {
    const cookies = new Cookies();
    return cookies.get('fileUuid');
}

export function removeSession() {
    _removeSession(['token', 'user']);
}
export function removeCandidateSession(uuid) {
    _removeSession([`candidate${uuid}`, `candidateToken${uuid}`]);
}

export function redirectToLogin() {
    removeSession();
    window.location.href = 'login';
}

export function redirectToCandidateLogin(uuid) {
    removeCandidateSession(uuid);
    window.location.href = `/careers/${uuid}/`;
}

function _removeSession(keys) {
    let cookies = document.cookie.split(";");
    cookies.forEach((value, index) => {
        let key = cookies[index].split("=");
        const cookieKey = key[0].trim();
        if(keys.indexOf(cookieKey) !== -1) {
            document.cookie = key[0] + " =;path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT";
        }
    });    
}