import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CareerLayout from 'App/screens/Career/shared/CareerLayout';
import './shared/Unsubscribe.scss';

const UnsubscribeComponentAsync = lazyLoad(() => {
    return import('./components/Unsubscribe');
});

const routePath = '/vendor/:vendorUuid/user/:userUuid/unsubscribe';

const UnsubscribeRouteDetails = {
    name: 'unsubscribe',
    exact: true,
    path: routePath,
    render() {
        return <CareerLayout
            name = {UnsubscribeRouteDetails.name}
            desktopComponent = {UnsubscribeComponentAsync}
            mobileComponent = {UnsubscribeComponentAsync} 
        />
    }
};

export { 
    UnsubscribeRouteDetails
};