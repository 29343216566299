import { convertDate } from 'App/shared/utils/DateFormat';
import { FILE_API_URL } from 'App/../constants';

const defaultState = {
    loadingApplicationList: false,
    applicationListLoaded: false,
    loadingAssessmentListDetails: false,
    assessmentListDetailsLoaded: false,
    applicationListPageId: 1,
    applicationListLimit: 10,
    assessmentListForEmail: [],
    assessmentListForOnCall: [],
    assessmentNoteDetails: '',
    selectedCheckboxAssessmentList: [],
    applicantList: [],
    totalCount: null,
    filterOptions: {},
    tableColumns: [{
        name: 'Name',
        key: 'title',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }, {
        name: 'Source',
        key: 'source',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }, {
        name: 'Job Title',
        key: 'jobTitle',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }, {
        name: 'Status',
        key: 'status',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2 remove-left-padding'
    },
    {
        name: 'assessment Score',
        key: 'assessmentScore',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2 remove-left-padding'
    }, {
        name: 'Received',
        key: 'received',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }],
    statusOptions: [
        { value: 'new', label: 'New', type: 'status', id: 1 },
        { value: 'underReview', label: 'Under review', type: 'status', id: 2 },
        { value: 'shortlisted', label: 'Submitted to client', type: 'status', id: 3 },
        { value: 'interviewed', label: 'Interviewed', type: 'status', id: 4 },
        { value: 'madeAnOffer', label: 'Made an offer', type: 'status', id: 5 },
        { value: 'acceptOffer', label: 'Accept offer', type: 'status', id: 6 },
        { value: 'preemploymentCheck', label: 'Pre-employment check', type: 'status', id: 7 },
        { value: 'onBoard', label: 'On boarded', type: 'status', id: 8 },
        { value: 'rejected', label: 'Rejected', type: 'status', id: 9 }
    ],
    sortByOptions: [
        { value: 'Date Received(Newest First)', label: 'Date Received(Newest First)', type: 'sortBy', id: 2 },
        { value: 'Date Received(Oldest First)', label: 'Date Received(Oldest First)', type: 'sortBy', id: 1 }
    ],
    durationOptions: [],
    durationOption: [
        {
            id: 1,
            value: 'LAST 3 MONTHS',
            label: 'Last 3 month',
            displayName: 'LAST 3 MONTHS',
            days: '90days',
            type: 'duration'
        }, {
            id: 2,
            value: 'LAST 6 MONTHS',
            label: 'Last 6 month',
            displayName: 'LAST 6 MONTHS',
            days: '181days',
            type: 'duration'
        }, {
            id: 3,
            value: 'LAST 9 MONTHS',
            label: 'Last 9 month',
            displayName: 'LAST 9 MONTHS',
            days: '273days',
            type: 'duration'
        }, {
            id: 4,
            value: 'LAST 12 MONTHS',
            label: 'Last 12 month',
            displayName: 'LAST 12 MONTHS',
            days: '365days',
            type: 'duration'
        }
    ],
    filteredJobCategoryItems: [],
    filteredApplicationTypeItems: [],
    filteredPayTypeItems: [],
    filteredSortByItem: [],
    filteredStatusItems: [],
    filteredDurationItem: [{ id: 1 }],
    filteredAssessmentScoreItem: [{ id: 1 }],
    filterSelectedDateRange: [],
    filteredSearchKeys: [],
    filteredSearchByApplicantKeys: [],
    showMobileFilter: false,
    filteredAssessmentScore: {
        startRange: 0,
        endRange: 100,
        fixedMin: 0,
        fixedMax: 100
    },
    selectedAssessmentScore: [],
    filters: [
        {
            name: 'jobCategory',
            title: 'Job Category',
            displayName: 'Job Category'
        },
        {
            name: 'applicationType',
            title: 'Source',
            displayName: 'Application Type'
        },
        {
            name: 'status',
            title: 'Status',
            displayName: 'Status'
        },
        {
            name: 'payType',
            title: 'Pay Type',
            displayName: 'Pay Type'
        }
    ],
    sortByFilter: {
        name: 'sortBy',
        title: 'Sort By',
        displayName: 'Sort By'
    },
    dateRangeFilter: {
        name: 'dateRange',
        title: 'Date Range',
        displayName: 'Date Range',
    },
    positionId: null,
    selectedMobileFilterItem: 'duration',
    selectedDateRangeObject: {
        startDate: null,
        endDate: null
    },
    currentPage: 0,
    limit: 10,
    mobilePageId: 1,
    applicantDetails: {},
    selectedApplicationStatus: {},
    loadingApplicationDetails: false,
    applicationDetailsLoaded: false,
    isApplicationDetailsPageLoaded: false,
    downloadResumeUrl: ''
};

const ON_DOWNLOAD_RESUME = 'ON_DOWNLOAD_RESUME';
const ON_CHANGE_DURATION = 'ON_CHANGE_DURATION';
const RESET_APPLICATION_STATE = 'RESET_APPLICATION_STATE';
const ON_APPLICATION_PAGINATE = 'ON_APPLICATION_PAGINATE';
const LOADING_APPLICATION_LIST = 'LOADING_APPLICATION_LIST';
const ON_CHANGE_ASSESSMENT_SCORE = 'ON_CHANGE_ASSESSMENT_SCORE';
const ON_CLICK_DATA_METRICS_ITEM = 'ON_CLICK_DATA_METRICS_ITEM';
const LOADING_APPLICATION_DETAILS = 'LOADING_APPLICATION_DETAILS';
const STATUS_ACCORDINA_OPEN_CLOSE = 'STATUS_ACCORDINA_OPEN_CLOSE';
const ON_UPDATE_APPLICATION_STATUS = 'ON_UPDATE_APPLICATION_STATUS';
const ON_CLICK_MOBILE_SIDE_BAR_ITEM = 'ON_CLICK_MOBILE_SIDE_BAR_ITEM';
const CLEAR_ALL_APPLICATION_FILTERS = 'CLEAR_ALL_APPLICATION_FILTERS';
const UPDATE_ASSESSMENT_NOTE_DETAILS = 'UPDATE_ASSESSMENT_NOTE_DETAILS';
const ON_CHANGE_ASSESSMENT_NOTE_TEXT = 'ON_CHANGE_ASSESSMENT_NOTE_TEXT';
const LOADING_ASSESSMENT_LIST_DETAILS = 'LOADING_ASSESSMENT_LIST_DETAILS';
const APPLY_MOBILE_APPLICATION_FILTERS = 'APPLY_MOBILE_APPLICATION_FILTERS';
const ON_CHANGE_APPLICATION_TYPE_FILTER = 'ON_CHANGE_APPLICATION_TYPE_FILTER';
const ON_CHANGE_APPLICATION_SEARCH_FILTER = 'ON_CHANGE_APPLICATION_SEARCH_FILTER';
const SHOW_HIDE_MOBILE_APPLICATION_FILTER = 'SHOW_HIDE_MOBILE_APPLICATION_FILTER';
const ON_CHANGE_APPLICATION_STATUS_FILTER = 'ON_CHANGE_APPLICATION_STATUS_FILTER';
const ON_CHANGE_ASSESSMENT_FOR_EMAIL_LIST = 'ON_CHANGE_ASSESSMENT_FOR_EMAIL_LIST';
const ON_CHANGE_APPLICATION_SORT_BY_FILTER = 'ON_CHANGE_APPLICATION_SORT_BY_FILTER';
const ON_CHANGE_SEARCH_BY_APPLICANT_FILTER = 'ON_CHANGE_SEARCH_BY_APPLICANT_FILTER';
const ON_CHANGE_APPLICATION_PAY_TYPE_FILTER = 'ON_CHANGE_APPLICATION_PAY_TYPE_FILTER';
const ON_CHANGE_APPLICATION_ASSESSMENT_SCORE = 'ON_CHANGE_APPLICATION_ASSESSMENT_SCORE';
const ON_CHANGE_APPLICATION_DATE_RANGE_FILTER = 'ON_CHANGE_APPLICATION_DATE_RANGE_FILTER';
const ON_CHANGE_APPLICATION_JOB_CATEGORY_FILTER = 'ON_CHANGE_APPLICATION_JOB_CATEGORY_FILTER';

function resetState() {
    return {
        type: RESET_APPLICATION_STATE
    };
}

function updateAssessmentNoteDetails(selectedItem, noteDetails) {
    return {
        type: UPDATE_ASSESSMENT_NOTE_DETAILS,
        selectedItem,
        noteDetails
    }
}

function onChangeAssessmentForEmailList(list) {
    return {
        type: ON_CHANGE_ASSESSMENT_FOR_EMAIL_LIST,
        list
    }
}

function onChangeAssessmentNoteText(noteDetails) {
    return {
        type: ON_CHANGE_ASSESSMENT_NOTE_TEXT,
        noteDetails
    }
}

function onChangeSearchFilter(filteredSearchKeys, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_SEARCH_FILTER,
        filteredSearchKeys,
        loadData
    }
}

function onChangeSearchByApplicantFilter(keys, loadData = true) {
    return {
        type: ON_CHANGE_SEARCH_BY_APPLICANT_FILTER,
        keys,
        loadData
    }
}

function updateApplicationListLoadingStatus(status = true, applications = null) {
    return {
        type: LOADING_APPLICATION_LIST,
        status: status,
        applications
    };
}

function onChangeJobCategoryFilter(filteredJobCategoryItems, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_JOB_CATEGORY_FILTER,
        filteredJobCategoryItems,
        loadData
    }
}

function onChangeApplicationTypeFilter(filteredApplicationTypeItems, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_TYPE_FILTER,
        filteredApplicationTypeItems,
        loadData
    }
}
function onChangeApplicationAssessmentScore(filteredAssessmentScore, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_ASSESSMENT_SCORE,
        filteredAssessmentScore,
        loadData
    };
}
function onChangePayTypeFilter(filteredPayTypeItems, loadData) {
    return {
        type: ON_CHANGE_APPLICATION_PAY_TYPE_FILTER,
        filteredPayTypeItems,
        loadData
    }
}

function onChangeDurationFilter(filteredDurationItem, loadData = true) {
    return {
        type: ON_CHANGE_DURATION,
        filteredDurationItem,
        loadData
    }
}
function onChangeApplicationAssessmentScoreRadio(filteredAssessmentScoreItem, loadData = true) {
    return {
        type: ON_CHANGE_ASSESSMENT_SCORE,
        filteredAssessmentScoreItem,
        loadData
    }
}
function onChangeSortByFilter(filteredSortByItem, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_SORT_BY_FILTER,
        filteredSortByItem,
        loadData
    }
}

function onChangeStatusFilter(filteredStatusItems, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_STATUS_FILTER,
        filteredStatusItems,
        loadData
    }
}

function onChangeDateRangeFilter(filterSelectedDateRange, loadData = true) {
    return {
        type: ON_CHANGE_APPLICATION_DATE_RANGE_FILTER,
        filterSelectedDateRange,
        loadData
    }
}

function onClearAllFilters() {
    return {
        type: CLEAR_ALL_APPLICATION_FILTERS
    }
}

function applyMobileFilters() {
    return {
        type: APPLY_MOBILE_APPLICATION_FILTERS
    };
}

function onClickMobileFilter() {
    return {
        type: SHOW_HIDE_MOBILE_APPLICATION_FILTER
    }
}

function onClickMobileSidebarItem(itemName) {
    return {
        type: ON_CLICK_MOBILE_SIDE_BAR_ITEM,
        itemName
    }
}

function onChangePagination(pageId, isScrollEvent = false) {
    return {
        type: ON_APPLICATION_PAGINATE,
        pageId,
        isScrollEvent
    }
}

function onUpdateApplicationStatus(status) {
    return {
        type: ON_UPDATE_APPLICATION_STATUS,
        status
    }
}

function onDownloadResume(resumeId) {
    return {
        type: ON_DOWNLOAD_RESUME,
        resumeId
    }
}

function updateApplicationDetailsLoadingStatus(status, details = null) {
    return {
        type: LOADING_APPLICATION_DETAILS,
        status,
        details
    };
}

function updateAssessmentListLoadingStatus(status, details = null) {
    return {
        type: LOADING_ASSESSMENT_LIST_DETAILS,
        status,
        details
    };
}

function onClickDataMetricsItem(applicationTypes, applicationStatus, loadData = true) {
    return {
        type: ON_CLICK_DATA_METRICS_ITEM,
        applicationTypes,
        applicationStatus,
        loadData
    };
}

const applicationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_APPLICATION_STATE:
            return {
                ...defaultState
            };
        case LOADING_APPLICATION_LIST:
            let applicationListLoaded = false;

            if (action.status === false) {
                // list has been loaded
                applicationListLoaded = true;
            }

            const applications = action.applications ? { ...action.applications } : {};
            const totalItemCount = state.applicationListPageId === 1 && applications.totalCount
                ? applications.totalCount
                : state.totalCount;
            const applicantList = applications.applicantList || [];
            return {
                ...state,
                loadingApplicationList: action.status,
                applicationListLoaded,
                applicantList: [...state.applicantList, ...applicantList],
                totalCount: totalItemCount,
                filterOptions: applicationListLoaded === true
                    ? { ...applications.filterOptions }
                    : { ...state.filterOptions },

                positionId: applications.positionId || state.positionId
            };
        case LOADING_APPLICATION_DETAILS:
            function getFormattedStatusObject(status) {
                const { id, displayName: label, value } = status;
                return {
                    id,
                    label,
                    type: 'status',
                    value
                };
            }

            let applicationDetailsLoaded = false;
            if (action.status === false) {
                applicationDetailsLoaded = true;
            }
            const applicantDetails = action.details ? { ...action.details } : null;
            let selectedApplicationStatus = null;
            if (applicantDetails) {
                selectedApplicationStatus = getFormattedStatusObject(applicantDetails.status);
            }
            const _selectedCheckboxAssessmentList = action.details && action.details.assessmentList.length > 0
                ? [...action.details.assessmentList].map(
                    ({ assessmentId: id, displayName: value }) => ({ id, value }))
                : []

            return {
                ...state,
                loadingApplicationDetails: action.status,
                applicationDetailsLoaded,
                applicantDetails: { ...applicantDetails },
                selectedApplicationStatus,
                assessmentNoteDetails: action.details && action.details.notes ? action.details.notes : '',
                selectedCheckboxAssessmentList: [..._selectedCheckboxAssessmentList]
            };
        case LOADING_ASSESSMENT_LIST_DETAILS:
            let assessmentListDetailsLoaded = false;
            if (action.status === false) {
                assessmentListDetailsLoaded = true;
            }
            const assessmentListForEmail = action.details && action.details.assessmentListForEmail
                ? [...action.details.assessmentListForEmail] : null;
            const assessmentListForOnCall = action.details && action.details.assessmentListForOnCall
                ? [...action.details.assessmentListForOnCall] : null;
            return {
                ...state,
                loadingAssessmentListDetails: action.status,
                assessmentListDetailsLoaded,
                assessmentListForEmail,
                assessmentListForOnCall
            };
        case ON_CHANGE_APPLICATION_JOB_CATEGORY_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredJobCategoryItems: [...action.filteredJobCategoryItems],
                applicationListPageId: 1,
                totalCount: 0
            };

        case ON_CHANGE_APPLICATION_ASSESSMENT_SCORE:
            const selectedAssessmentScore = action.filteredAssessmentScore.startRange !== "" && action.filteredAssessmentScore.endRange !== ""
                ? [{
                    type: 'assessment_score',
                    value: `${action.filteredAssessmentScore.startRange} - ${action.filteredAssessmentScore.endRange} %`
                }] : [];
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredAssessmentScore: { ...action.filteredAssessmentScore, fixedMin: 0, fixedMax: 100 },
                selectedAssessmentScore,
                applicationListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_APPLICATION_TYPE_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: [],
                filteredApplicationTypeItems: [...action.filteredApplicationTypeItems],
                applicationListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_APPLICATION_PAY_TYPE_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: [],
                filteredPayTypeItems: [...action.filteredPayTypeItems],
                applicationListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_APPLICATION_SORT_BY_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredSortByItem: action.filteredSortByItem ? [action.filteredSortByItem] : [],
                applicationListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_DURATION:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredDurationItem: action.filteredDurationItem ? [action.filteredDurationItem] : [],
                applicationListPageId: 1,
                totalCount: 0
            }
        case ON_CHANGE_ASSESSMENT_SCORE:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredAssessmentScoreItem: action.filteredAssessmentScoreItem ? [action.filteredAssessmentScoreItem] : [{ id: 1 }],
                applicationListPageId: 1,
                totalCount: 0
            }
        case ON_CHANGE_APPLICATION_SEARCH_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                applicationListPageId: 1,
                totalCount: 0,
                filteredSearchKeys: [...action.filteredSearchKeys]
            }
        case ON_CHANGE_SEARCH_BY_APPLICANT_FILTER:
            return {
                ...state,
                totalCount: 0,
                applicationListPageId: 1,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true
                    ? [] : [...state.applicantList],
                filteredSearchByApplicantKeys: [...action.keys]
            }
        case ON_CHANGE_APPLICATION_STATUS_FILTER:
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredStatusItems: [...action.filteredStatusItems],
                applicationListPageId: 1,
                totalCount: 0
            };

        case ON_CHANGE_APPLICATION_DATE_RANGE_FILTER:
            let filterSelectedDateRange = [];
            if (action.filterSelectedDateRange.startDate && action.filterSelectedDateRange.endDate) {
                filterSelectedDateRange = [{
                    id: 1,
                    value: `${convertDate(action.filterSelectedDateRange.startDate, ['mm', 'DD', 'YYYY'], '/')} - ${convertDate(action.filterSelectedDateRange.endDate, ['mm', 'DD', 'YYYY'], '/')}`,
                    type: 'dateRange'
                }];
            }

            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filterSelectedDateRange,
                applicationListPageId: 1,
                totalCount: 0,
                selectedDateRangeObject: {
                    startDate: action.filterSelectedDateRange.startDate,
                    endDate: action.filterSelectedDateRange.endDate
                }
            };
        case CLEAR_ALL_APPLICATION_FILTERS:
            return {
                ...state,
                applicationListLoaded: false,
                applicantList: [],
                applicationListPageId: 1,
                selectedFilteredItems: [],
                filteredJobCategoryItems: [],
                filteredDurationItem: [{ id: 1 }],
                filteredApplicationTypeItems: [],
                filteredPayTypeItems: [],
                filteredSortByItem: [],
                filteredStatusItems: [],
                filterSelectedDateRange: [],
                selectedDateRangeObject: {
                    startDate: null,
                    endDate: null
                },
                selectedAssessmentScore: [],
                filteredAssessmentScore: {
                    startRange: 0,
                    endRange: 100,
                    fixedMin: 0,
                    fixedMax: 100
                },
                filteredAssessmentScoreItem: [{ id: 1 }],

            };
        case SHOW_HIDE_MOBILE_APPLICATION_FILTER:
            const status = action.hasOwnProperty('value') ? action.value : !state.showMobileFilter;
            return {
                ...state,
                showMobileFilter: status
            };
        case ON_CLICK_MOBILE_SIDE_BAR_ITEM:
            return {
                ...state,
                selectedMobileFilterItem: action.itemName
            };
        case APPLY_MOBILE_APPLICATION_FILTERS:
            return {
                ...state,
                applicationListLoaded: false,
                applicantList: [],
                showMobileFilter: false
            };
        case ON_APPLICATION_PAGINATE:
            return {
                ...state,
                applicationListPageId: action.pageId,
                applicationListLoaded: false,
                applicantList: action.isScrollEvent === true ? [...state.applicantList] : []
            };
        case ON_UPDATE_APPLICATION_STATUS:
            return {
                ...state,
                selectedApplicationStatus: action.status
            };
        case ON_DOWNLOAD_RESUME:
            function getDownloadResumeUrl() {
                return `${FILE_API_URL}/${action.resumeId}`;
            }
            return {
                ...state,
                downloadResumeUrl: getDownloadResumeUrl()
            };
        case ON_CLICK_DATA_METRICS_ITEM:
            const applicationTypes = action.applicationTypes && action.applicationTypes.length > 0 ? action.applicationTypes : [];
            const applicationStatus = action.applicationStatus && action.applicationStatus.length > 0 ? action.applicationStatus : [];
            return {
                ...state,
                applicationListLoaded: !action.loadData,
                applicantList: action.loadData === true ? [] : [...state.applicantList],
                filteredApplicationTypeItems: [...applicationTypes],
                filteredStatusItems: [...applicationStatus],
                applicationListPageId: 1,
                totalCount: 0
            };
        case STATUS_ACCORDINA_OPEN_CLOSE: {
            return {
                ...state
            }
        }
        case ON_CHANGE_ASSESSMENT_NOTE_TEXT: {
            return {
                ...state,
                assessmentNoteDetails: action.noteDetails.trim() !== '' ? action.noteDetails : ''
            }
        }
        case ON_CHANGE_ASSESSMENT_FOR_EMAIL_LIST: {
            return {
                ...state,
                selectedCheckboxAssessmentList: action.list.length > 0 ? [...action.list] : []
            }
        }
        case UPDATE_ASSESSMENT_NOTE_DETAILS: {
            let _assessmentListForOnCall = [...state.assessmentListForOnCall];
            let modifiedAssessmentListForOnCall = _assessmentListForOnCall.map(({ id, value, noteDetails, isSubmitted }) => {
                if (id === action.selectedItem.id) {
                    return {
                        id,
                        value,
                        isSubmitted: true,
                        noteDetails: action.noteDetails
                    }
                } else {
                    return { id, value, noteDetails, isSubmitted }
                }
            });
            return {
                ...state,
                assessmentListForOnCall: modifiedAssessmentListForOnCall
            }
        }
        default:
            return state;
    }
};

export {
    resetState,
    applicationReducer as ApplicationReducer,
    onDownloadResume,
    onClearAllFilters,
    onChangePagination,
    applyMobileFilters,
    onClickMobileFilter,
    onChangeStatusFilter,
    onChangeSortByFilter,
    onChangeSearchFilter,
    onChangePayTypeFilter,
    onChangeDurationFilter,
    onClickDataMetricsItem,
    onChangeDateRangeFilter,
    onClickMobileSidebarItem,
    onUpdateApplicationStatus,
    onChangeJobCategoryFilter,
    onChangeAssessmentNoteText,
    updateAssessmentNoteDetails,
    onChangeApplicationTypeFilter,
    onChangeAssessmentForEmailList,
    onChangeSearchByApplicantFilter,
    updateAssessmentListLoadingStatus,
    updateApplicationListLoadingStatus,
    onChangeApplicationAssessmentScore,
    updateApplicationDetailsLoadingStatus,
    onChangeApplicationAssessmentScoreRadio
};