import React from 'react';
import { VoucherReducer } from './stores/VoucherStore';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';
import { redeemVoucherRouteDetails } from './screens/RedeemVoucher/index';

const VoucherDetailsAsync = lazyLoad(() => {
    return import('./components/VoucherDetails');
});
const routePath = '/:vendorUUID/vouchers';
const voucherDetailsRouteDetails = {
    exact: true,
    path: routePath,
    name: 'voucherDetails',
    render() {
        return <CandidateLayout
            activeMenuName = 'vouchers'
            name = {voucherDetailsRouteDetails.name}
            desktopComponent = {VoucherDetailsAsync}
            mobileComponent = {VoucherDetailsAsync} 
        />
    }
};

export { 
    VoucherReducer,
    voucherDetailsRouteDetails,
    redeemVoucherRouteDetails
};