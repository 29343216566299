import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Loader from 'App/shared/components/Loader/Loader';
import CategoryBoxContainer from './categoryBoxContainer';
import GetCategoryListQuery from '../components/getCategoryList';
import { updateSelectedCategoryName, updateUserLodingStatus } from '../stores/PublicStore';
import { onCategoryListFetch } from '../../../shared/store/dispatchers';
import '../shared/publicLayout.scss';

class publicTalentpoolCategoryPage extends Component {
    constructor(props) {
        super(props);
        this.onClickCategory = this.onClickCategory.bind(this);
    }
    onClickCategory(category) {
        const {
            history,
            updateUserLodingStatus,
            userListLoaded,
            updateSelectedCategoryName 
        } = this.props;
        if(userListLoaded){
            updateUserLodingStatus(false)
        }
        updateSelectedCategoryName(category);
        history.push(`/public/talent-pool/${category.categoryId}/users`);
    }

    render() {
        const { categoryList, categoryListLoaded, loadingCategoryList } = this.props;
        return (
            <div className="public-talentpool-category-page">
                <div className="public-talentpool-category-page__title">
                    <span>
                        We are the largest, globally-distributed network of top business, design,
                        and technology talent, ready to tackle your most important initiatives.
                    </span>
                </div>
                {
                    categoryListLoaded === false && loadingCategoryList === false ?
                        <GetCategoryListQuery />
                        : null
                }

                {
                    categoryListLoaded === false ?
                        <Loader />
                        :
                        <div className="public-talentpool-category-page__category-container">
                            <div className="public-talentpool-category-page__category-container">
                                <div className="public-talentpool-category-page__category-container__categories">
                                    <CategoryBoxContainer
                                        categoryList={categoryList}
                                        onClickCategory={this.onClickCategory} />
                                </div>
                            </div>
                        </div>
                }
            </div>

        );
    }
}

const mapStateWithProps = ({ deviceIdentifier, publicTalentPool }) => {
    return {
        userList: publicTalentPool.userList,
        isMobile: deviceIdentifier.isMobile,
        categoryList: publicTalentPool.categoryList,
        userListLoaded: publicTalentPool.userListLoaded,
        categoryListLoaded: publicTalentPool.categoryListLoaded,
        loadingCategoryList: publicTalentPool.loadingCategoryList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCategoryListFetch(data = null) {
            dispatch(onCategoryListFetch(data))
        },
        updateUserLodingStatus(status) {
            dispatch(updateUserLodingStatus(status))
        },
        updateSelectedCategoryName(data = null) {
            dispatch(updateSelectedCategoryName(data))
        }
    }
}

const PublicTalentpoolCategoryPageApollo = withApollo(publicTalentpoolCategoryPage);
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(PublicTalentpoolCategoryPageApollo));
