import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const LoginPageAsync = lazyLoad(() => {
    return import('./components/login');
});
const routePath = '/:vendorUUID/candidate/login';
const loginPageRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateLogin',
    render() {
        return (
            <CandidateLayout
                activeMenuName = 'login'
                name = {loginPageRouteDetails.name}
                desktopComponent = {LoginPageAsync}
                mobileComponent = {LoginPageAsync} 
            />
        )
    }
};

export { loginPageRouteDetails };