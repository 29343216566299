import React from 'react';
import '../shared/ChatbotText.scss';

export default function ChatbotLink({
    link,
    text,
    clientConfig
}) {
    const style = {
        color: clientConfig.style.buttonBackgroundColor
    }

    return (
        <a href={link}
            style={style}
            target='_blank'
            className="chatbot-link"
            rel="noopener noreferrer">
            {text}
        </a>
    )
}