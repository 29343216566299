import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { DistributionReducer } from 'App/screens/Distribution/stores/DistributionStore';
import Layout from 'App/shared/components/Layout/Layout';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';
import { DistributionAddRouteDetails, distributionAddBreadcrumbDetails } from './screens/DistributionAdd/index';
import { DistributionEditRouteDetails, distributionEditBreadcrumbDetails } from './screens/DistributionEdit/index';
import { distributionDetailsRouteDetails, distributionDetailsBreadcrumbDetails } from './screens/DistributionDetails/index';

const DistributionDesktopComponentAsync = lazyLoad(() => {
    return import('./components/DistributionDesktopPage');
});
const DistributionMobileComponentAsync = lazyLoad(() => {
    return import('./components/DistributionMobilePage');
});
const routePath = '/distribution';
const breadcrumbName = 'distribution';
const distributionRouteDetails = {
    name: 'distribution',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'distribution',
            breadcrumbName,
            desktopComponent: DistributionDesktopComponentAsync,
            mobileComponent: DistributionMobileComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'distribution',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export {
    distributionRouteDetails as DistributionRouteDetails,
    DistributionReducer,
    breadcrumbDetails as distributionBreadcrumbDetails,
    DistributionAddRouteDetails,
    distributionAddBreadcrumbDetails,
    DistributionEditRouteDetails,
    distributionEditBreadcrumbDetails,
    distributionDetailsRouteDetails,
    distributionDetailsBreadcrumbDetails
};