import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const ContactIndividualAddComponentAsync = lazyLoad(() => {
    return import('./components/ContactIndividualAdd');
});

const routePath = '/contact/individual/add';

const ContactIndividualAddRouteDetails = {
    name: 'ContactIndividualAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'candidateProfiles',
            breadcrumbName: 'ContactIndividualAdd',
            desktopComponent: ContactIndividualAddComponentAsync,
            mobileComponent: ContactIndividualAddComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'ContactIndividualAdd',
    displayName: 'individual',
    path: routePath,
    parentName: 'contactAdd'
};

export { ContactIndividualAddRouteDetails, breadcrumbDetails as contactIndividualAddBreadcrumbDetails };