import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
// import './shared/Contact.scss';
import Layout from 'App/shared/components/Layout/Layout';
import { SavingCalculatorReducer } from './stores/SavingCalculatorStore';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';

const SavingCalulatorDesktopComponentAsync = lazyLoad(() => {
    return import('./components/SavingCalculator');
});

const routePath = '/saving-calculator';
const breadcrumbName = "saving calulator";
const savingCalulatorRouteDetails = {
    name: 'saving calucalator',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'saving calculator',
            breadcrumbName,
            desktopComponent: SavingCalulatorDesktopComponentAsync,
            mobileComponent: SavingCalulatorDesktopComponentAsync
        });
    }
};
const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'saving calulator',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export {
    SavingCalculatorReducer,
    savingCalulatorRouteDetails as SavingCalulatorRouteDetails,
    breadcrumbDetails as SavingCalulatorBreadcrumbDetails,
    
};