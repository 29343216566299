import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const CandidateReferralPathway= lazyLoad(() => {
    return import('./components/CandidateReferralPathway');
});

const routePath = '/:vendorUUID/candidate/referral-pathway';
const CandidateReferralPathwayRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateReferralPathway',
    render() {
        return <CandidateLayout
            activeMenuName = 'referralPathway'
            name = {CandidateReferralPathwayRouteDetails.name}
            desktopComponent = {CandidateReferralPathway}
            mobileComponent = {CandidateReferralPathway} 
        />
    }
};

export { CandidateReferralPathwayRouteDetails };