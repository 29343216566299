import React from 'react';
import './ActionButton.scss';

const actionButton = ({ 
    type, 
    title, 
    onClick,
    className,
    style={},
    showDisabledActionButton 
}) => {
    return (
        <button 
            type = {type} 
            style = {style}
            onClick = {onClick}
            className = {className} 
            disabled = {showDisabledActionButton}> 
            {title} 
        </button>
    );
};

export default actionButton;