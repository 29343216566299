const defaultState = {
    sidebarItems: [
        {
            name: 'dashboard',
            displayName: 'Dashboard',
            path: '/'
        }, {
            name: 'positions',
            displayName:'Jobs',
            path: '/positions'
        }, {
            name: 'applications',
            displayName: 'Applications',
            path: '/applications'
        }, {
            name: 'talentPool',
            displayName: 'Talent Pool',
            path: '/talent-pool'
        }, {
            name: 'distribution',
            displayName: 'Distribution Lists',
            path: '/distribution'
        }, {
            name: 'candidateProfiles',
            displayName: 'Candidate Profiles',
            path: '/contact'
        }
    ],
    executiveSidebarItems: [
        {
            name: 'dashboard',
            displayName: 'Dashboard',
            path: '/'
        }, {
            name: 'positions',
            displayName:'Jobs',
            path: '/positions'
        }, {
            name: 'applications',
            displayName: 'Applications',
            path: '/applications'
        }
    ],
    breadcrumbItems: [],
    filterSidebarItems: [
        'Sort by',
        'Job Category',
        'Status',
        'Assigned to',
        'Pay Type',
        'Date Range'
    ],
    showMenubar: false
};

const SHOW_MOBILE_MENUBAR = 'SHOW_MOBILE_MENUBAR';
const ADD_BREADCRUMB = 'ADD_BREADCRUMB';

function onClickMobileMenubarAction() {
    return {
        type: SHOW_MOBILE_MENUBAR,
    }
}

function addBreadcrumb(breadcrumbDetails) {
    return {
        type: ADD_BREADCRUMB,
        breadcrumbDetails
    }
}

const layoutReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SHOW_MOBILE_MENUBAR:
            state.showMenubar = !state.showMenubar;
            return {
                ...state,
                showMenubar: state.showMenubar
            };
        case ADD_BREADCRUMB:
            return {
                ...state,
                breadcrumbItems: [...state.breadcrumbItems, action.breadcrumbDetails]
            };
        default:
            return state;
    }
};

export {
    layoutReducer as LayoutReducer,
    onClickMobileMenubarAction,
    addBreadcrumb
};
