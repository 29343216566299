import React from 'react';
import PropTypes from 'prop-types';

const Error = props => (props.show ? <span className="text-danger d-block">{props.text}</span> : null);

Error.propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool
};

export default Error;
