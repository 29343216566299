import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const AssessmentRules = lazyLoad(() => {
    return import('./components/AssessmentRules.js');
});

const routePath = '/:vendorUUID/candidate/:candidateCode/assessment/rules';
const AssessmentRulesRouteDetails = {
    exact: true,
    path: routePath,
    name: 'assessmentRules',
    render() {
        return <CandidateLayout
            activeMenuName = 'candidateDashboard'
            name = {AssessmentRulesRouteDetails.name}
            desktopComponent = {AssessmentRules}
            mobileComponent = {AssessmentRules} 
        />
    }
};

export { AssessmentRulesRouteDetails };