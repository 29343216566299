import React from "react";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { redirectToCandidateLogin } from "App/shared/utils/Authentication";
import "App/shared/components/CandidateMobileMenubar/CandidateMobileMenubar.scss";
import { getCareerUrl } from "../../utils/CareerUrl";

const CandidateMobileMenubar = ({
  list,
  match,
  theme,
  client,
  history,
  onCandidateLogoutUrl,
}) => {
  const vendorUUID = match.params.vendorUUID || match.params.uuid || null;
  const menuBackgroundColor = theme
    ? { background: theme.sidebarBackgroundColor }
    : {};
  const activeMenu = theme
    ? { backgroundColor: theme.sidebarTextBackgroundColor, color: theme.sidebarTextColor }
    : {};
  function redirectToPage(path) {
    path ? history.push(path) : logout();
  }
  function logout() {
    return client
      .mutate({
        mutation: gql`
          mutation candidateLogout($uuid: String) {
            candidateLogout(uuid: $uuid) {
              accountId
            }
          }
        `,
        variables: {
          uuid: vendorUUID,
        },
      })
      .then((_) => {
        const url = onCandidateLogoutUrl(getCareerUrl(vendorUUID));
        redirectToCandidateLogin(vendorUUID, url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="candidate-mobile-menubar" style={menuBackgroundColor}>
      <ul className="candidate-mobile-menubar-items">
        {list.map((item, index) => {
          return index === 0 ? (
            <li key={index} className={item.className}>
              <span>{item.component} </span>
            </li>
          ) : item.className === "active-mobile-menu" ? (
            <li key={index} className={item.className} style={activeMenu}>
              <div onClick={() => redirectToPage(item.path)}>
                {item.component}
              </div>
            </li>
          ) : (
            <li key={index} className={item.className}>
              <div onClick={() => redirectToPage(item.path)}>
                {item.component}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const CandidateMobileMenubarApollo = withApollo(CandidateMobileMenubar);
export default withRouter(CandidateMobileMenubarApollo);
