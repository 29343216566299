import React from 'react';
import './AcademicReport.scss'
export default function AcademicReport({
    reportDetails,
    themeConfig,
    reportTitle
}) {
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    reportDetails = [{
        date: new Date(2020, 3, 6),
        description: "Priority Pre-Registration for current students"
    }, {
        date: new Date(2020, 3, 13),
        description: "Open Pre-Registration begins"
    }, {
        date: new Date(2020, 3, 20),
        description: "Open Pre-Registration begins"
    }, {
        date: new Date(2020, 3, 20),
        description: "Open Pre-Registration begins"
    }]
    let themeStyle = {
        reportBgColorStyle: {
            background: themeConfig.style.serverResponseBgColor,
        },
        headerStyle: {
            background: themeConfig.style.headerBgColor,
            
        }

    }

    return (
        <div style={themeStyle.reportBgColorStyle} className="academic-report">
            <div style={themeStyle.headerStyle} className="report-header">
                <span>
                    {reportTitle}
                </span>
            </div>
            <div className="report-details">
                {
                    reportDetails.map((details, index) => {
                        return (
                            <div key={index} className="details-section">
                                <div>
                                    <span>{days[details.date.getDay()]}, {months[details.date.getMonth()]} {details.date.getDate()}</span>
                                </div>
                                <div>
                                    {details.description}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}