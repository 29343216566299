import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { updateTalentPoolCategoryList } from '../stores/PublicStore';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';

const jobDepartmentList = gql`
    query getCategoryListForPublicTalentPool{
        getCategoryListForPublicTalentPool {
            categoryId,
            displayName,
            imageUrl,
            candidateCount
          }
    }`

let getCategoryListQuery = ({
    updateTalentPoolCategoryList,
    loadingCategoryList,
    categoryListLoaded,
    client
}) => {
    if (loadingCategoryList === false && categoryListLoaded === false) {
        updateTalentPoolCategoryList(true);
        client.query({
            query: jobDepartmentList
        })
            .then(details => {
                updateTalentPoolCategoryList(false, details.data.getCategoryListForPublicTalentPool);
            });
    }
    return '';
}

const mapStateWithProps = ({ publicTalentPool }) => {
    return {
        categoryListLoaded: publicTalentPool.categoryListLoaded,
        loadingCategoryList: publicTalentPool.loadingCategoryList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTalentPoolCategoryList(status, categoryList = []) {
            dispatch(updateTalentPoolCategoryList(status, categoryList))
        }
    }
}


getCategoryListQuery = withApollo(getCategoryListQuery);

export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(getCategoryListQuery))