import React, { Component } from "react";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { SOCKET_IO_BASE_URL } from "../../../constants";
import ChatbotUI from "./ChatbotUI/components/ChatbotUI";
import clientConfig from "../Chatbot/ChatbotUI/utils/config.json";
import ChatbotJobListGetQuery from "./ChatbotUI/components/ChatbotJobListGetQuery";
import ChatbotJobDetailsGetQuery from "./ChatbotUI/components/ChatbotJobDetailsGetQuery";
import {
  chatbotResetState,
  fetchChatbotJobList,
  onUpdateJobCityNames,
  fetchChatbotJobDetails,
  onUpdateJobDepartmentId,
  updateClientSelectedIndex,
  updateClientSelectedValue,
  onUpdateJobSubDepartmentId,
} from "./ChatbotUI/stores/ChatbotStore";
import queryString from "query-string";

class Chatbot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: null,
      userType: null,
      clientType: "",
      sessionId: null,
      chatbotText: null,
      jobPositionKey: "",
      formSubmmited: false,
      showBackOption: false,
      careerPageShortUrl: "",
      lastSessionDetails: null,
      data: [{ type: "typing" }],
    };
    this.clientChatbotData = {};
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    let modifiedData = data.filter(({ type }) => type !== "typing");

    if (this.props.vendorDetails.hasOwnProperty("settings")) {
      console.log('here...', this.props.vendorDetails.settings.shortUrl);
      this.setState({
        careerPageShortUrl: this.props.vendorDetails.settings.shortUrl,
      });
    }

    if (
      !nextProps.jobDetails.hasOwnProperty("jobPositionId") &&
      this.props.jobListData.length === 0 &&
      nextProps.jobListDetailsLoaded
    ) {
      if (nextProps.jobListData.length > 0) {
        modifiedData = modifiedData.filter(({ type }) => type !== "options");
        modifiedData.push(
          {
            messageGroup: [
              {
                type: "text",
                name: "jobListMessage",
                message:
                  "Below are the list of jobs matching your requirements. Please select one of the job to view job details",
              },
            ],
          },
          {
            type: "jobs",
            data: nextProps.jobListData,
          },
          {
            type: "options",
            name: "mainMenu",
            data: [{ id: 1, name: "Return to main menu" }],
          }
        );
      } else {
        modifiedData.push(
          {
            messageGroup: [
              {
                type: "text",
                isSessionUser: false,
                message: "Sorry, there are no jobs matching your requirements.",
              },
              {
                type: "text",
                isSessionUser: false,
                message: "Would you like to Join Talentdome?",
              },
            ],
          },
          {
            type: "options",
            name: "joinTalentdome",
            data: [{ id: 1, name: "Yes" }, { id: 2, name: "No" }],
          },
          {
            type: "options",
            name: "mainMenu",
            data: [{ id: 1, name: "Return to main menu" }],
          }
        );
      }
    }
    if (
      !nextProps.loadingJobDetailsData &&
      nextProps.jobDetails.hasOwnProperty("jobPositionId")
    ) {
      modifiedData = modifiedData.filter(
        ({ type }) => type !== "jobs" && type !== "options"
      );
      modifiedData.push(
        {
          type: "jobDetails",
          data: nextProps.jobDetails,
        },
        {
          type: "options",
          name: "mainMenu",
          data: [
            { id: 1, name: "Back to job list" },
            { id: 2, name: "Return to main menu" },
          ],
        }
      );
    }
    this.setState({
      data: modifiedData,
    });
  }

  toggle(status) {
    const { clientType, sessionId } = this.state;
    if (status && !sessionId) {
      if (clientType === "infojini" || clientType === "talentdome") {
        this.intialize();
      } else {
        this.clientChatbotIntialize();
      }
    }
  }

  setUserType(userType) {
    localStorage.setItem("userType", JSON.stringify(userType));
    this.setState({
      userType,
    });
  }

  setBackOptionStatus(value) {
    this.setState({
      showBackOption: value,
    });
  }

  onSelectOption(selectedOption) {
    const {
      clientSelectedIndex,
      fetchChatbotJobList,
      onUpdateJobDepartmentId,
      updateClientSelectedIndex,
      updateClientSelectedValue,
      onUpdateJobSubDepartmentId,
    } = this.props;
    const {
      data,
      socket,
      userType,
      sessionId,
      clientType,
      showBackOption,
      careerPageShortUrl,
    } = this.state;

    localStorage.setItem("selectedOption", JSON.stringify(selectedOption));

    const messageData = this.clientChatbotData.messageData;
    let clientConversationData = messageData
      ? messageData[clientSelectedIndex]
        ? messageData[clientSelectedIndex].options
        : []
      : [];

    let message = "";

    let _data = data.filter(
      ({ name, type, removeTextMsg }) =>
        name !== "remove-margin" &&
        type !== "academic-calendar" &&
        type !== "university-request-form" &&
        name !== selectedOption.name &&
        name !== selectedOption.optionType &&
        type !== "options" &&
        type !== "jobs" &&
        type !== "jobDetails" &&
        type !== "joinTalentdomeForm" &&
        type !== "submitRequirementsForm" &&
        type !== "LocationInput" &&
        removeTextMsg !== "remove-text-msg" &&
        type !== "search-bar-component" &&
        name !== "clientChatbotOptions"
    );

    _data.push({
      type: "text",
      message: selectedOption.name,
      isSessionUser: true,
    });

    if (
      selectedOption &&
      (selectedOption.optionType === "submitRequirementsDetails" ||
        selectedOption.optionType === "SubmitJoinTalentdomeForm" ||
        selectedOption.optionType === "alreadyApply" ||
        selectedOption.optionType === "candidatedAdd")
    ) {
      this.setState({
        formSubmmited: true,
      });
    } else {
      this.setState({
        formSubmmited: false,
      });
    }

    switch (selectedOption.optionType) {
      case "clientChatbotOptions":
        _data = _data.filter(({ message }) => message !== "");
        _data.push({ type: "typing" });
        this.addTimer().then(() => {
          clientConversationData &&
            clientConversationData.map(({ name, options }) => {
              if (options.length > 0) {
                options.map((value) => {
                  if (name === selectedOption.name) {
                    updateClientSelectedValue(name);
                    this.addConversation(value);
                  }
                  return "";
                });
              }
              return "";
            });

          if (selectedOption.name === "Return to Main Menu") {
            updateClientSelectedIndex(0);
            this.addTimer().then((_) => {
              this.addTimer().then(() => {
                updateClientSelectedIndex();
                this.addConversation(messageData[0].message[1]);
              });
            });
            return "";
          }
          updateClientSelectedIndex();
        });
        break;

      case "clientMainMenu":
        updateClientSelectedIndex(0);

        this.addTimer().then((_) => {
          this.addConversation({
            messageGroup: [messageData[0].message[0]],
          });
          this.addTimer().then(() => {
            this.addConversation(messageData[0].message[1]);
            updateClientSelectedIndex();
          });
        });

        break;

      case "user-options":
        if (selectedOption.name === "Want us to respond to an RfX?") {
          _data.push({ type: "typing" });
          setTimeout(() => {
            _data = _data.filter(({ type }) => type !== "typing");
            _data.push(
              {
                type: "submitRequirementsForm",
                name: "submitRequirementsDetails",
                formTitle: "Submit Requirements / RFX",
              },
              {
                type: "options",
                name: "infojiniMainMenu",
                data: [{ id: 1, name: "Return to main menu" }],
              }
            );
            this.setState({
              data: [..._data],
            });
          }, 2500);
        } else if (selectedOption.name === "Need talent immediately?") {
          _data.push({ type: "typing" });
          setTimeout(() => {
            _data = _data.filter(({ type }) => type !== "typing");
            _data.push({
              type: "submitRequirementsForm",
              name: "submitRequirementsDetails",
              formTitle: "Contact Form",
            });
            if (
              userType === "Existing Client" ||
              userType === "Existing Employee"
            ) {
              _data.push({
                type: "options",
                name: "infojiniMainMenu",
                data: [
                  { id: 1, name: "Back to options" },
                  { id: 2, name: "Return to main menu" },
                ],
              });
            } else {
              _data.push({
                type: "options",
                name: "infojiniMainMenu",
                data: [{ id: 1, name: "Return to main menu" }],
              });
            }
            this.setState({
              data: [..._data],
              chatbotText: selectedOption.name,
            });
          }, 2500);
        }
        message =
          selectedOption.name !== "Want us to respond to an RfX?" &&
          selectedOption.name !== "Need talent immediately?"
            ? `${selectedOption.name}`
            : "";
        break;

      case "LocationInput":
        message = `job categories`;
        break;

      case "mainMenu":
        if (selectedOption.name === "Return to main menu") {
          localStorage.removeItem("jobDepartmentId");
          localStorage.removeItem("jobSubDepartmentId");
          localStorage.removeItem("cityNames");
          localStorage.removeItem("jobPositionKey");
          localStorage.removeItem("formSubmmited");
          localStorage.setItem("showBackOptionStatus", JSON.stringify(false));

          const lastIndex = _data.reduce((lastIndex, value, index) => {
            if (value.isSessionUser === true) {
              lastIndex = index;
            }
            return lastIndex;
          }, 0);
          _data = _data.slice(0, lastIndex);
          _data.push({
            type: "text",
            isSessionUser: true,
            message: selectedOption.name,
          });
          message = "main menu";
        } else {
          localStorage.removeItem("jobPositionKey");
          localStorage.removeItem("formSubmmited");
          _data.push({ type: "typing" });
          fetchChatbotJobList();
        }
        break;

      case "infojiniMainMenu":
        if (selectedOption.name === "Return to main menu") {
          localStorage.removeItem("jobDepartmentId");
          localStorage.removeItem("jobSubDepartmentId");
          localStorage.removeItem("cityNames");
          localStorage.removeItem("jobPositionKey");
          localStorage.removeItem("showBackOptionStatus");
        }

        _data = _data.filter(({ type }) => type !== "locationInput");
        message =
          selectedOption.name === "Back to options"
            ? "back to options"
            : "infojini main menu";
        if (selectedOption.name !== "Back to options") {
          this.setUserType(null);
        }
        localStorage.removeItem("formSubmmited");
        break;

      case "JobSubCategoryList":
        _data.push({ type: "typing" });
        setTimeout(() => {
          _data = _data.filter(({ type }) => type !== "typing");
          _data.push(
            {
              messageGroup: [
                {
                  type: "text",
                  name: "subCategory",
                  isSessionUser: false,
                  message:
                    "Please select one of the job sub-category shown below",
                },
              ],
            },
            {
              type: "options",
              name: selectedOption.optionType,
              data: selectedOption.subCategories,
            },
            {
              type: "options",
              name: "mainMenu",
              data: [{ id: 1, name: "Return to main menu" }],
            }
          );
          onUpdateJobDepartmentId(selectedOption.jobDepartmentId);
          this.setState({
            data: [..._data],
          });
        }, 3000);
        break;

      case "JobCategoryList":
        _data.push({ type: "typing" });
        fetchChatbotJobList();
        break;

      case "loadMoreJobs":
        localStorage.removeItem("jobPositionKey");
        if (selectedOption.name === "View More Jobs") {
          _data.push(
            {
              messageGroup: [
                {
                  type: "text",
                  isSessionUser: false,
                  link: careerPageShortUrl,
                  displayLinkName: "to view more jobs",
                },
              ],
            },
            {
              type: "options",
              name: "mainMenu",
              data: [
                { id: 1, name: "Back to Job List" },
                { id: 2, name: "Return to main menu" },
              ],
            }
          );
        } else {
          _data.push(
            {
              type: "joinTalentdomeForm",
              formTitle: "Join Talentpool",
              name: "SubmitJoinTalentdomeForm",
            },
            {
              type: "options",
              name: "mainMenu",
              data: [
                { id: 1, name: "Back to Job List" },
                { id: 2, name: "Return to main menu" },
              ],
            }
          );
        }
        break;

      case "submitRequirementsDetails":
        _data = _data.filter(({ message }) => message !== "");
        let lastIndexValue = _data[_data.length - 1];

        const modifiedSuccessMessage = Array.isArray(
          selectedOption.successMessage
        )
          ? selectedOption.successMessage.map((value) => {
              if (value.message.startsWith("44")) {
                return {
                  ...value,
                  number: value.message,
                };
              }
              return value;
            })
          : [selectedOption.successMessage];

        if (lastIndexValue.messageGroup) {
          _data[_data.length - 1] = {
            messageGroup: modifiedSuccessMessage,
          };
        } else if (lastIndexValue.isSessionUser === true) {
          _data.push({
            messageGroup: modifiedSuccessMessage,
          });
        }

        if (showBackOption) {
          _data.push({
            type: "options",
            name: "infojiniMainMenu",
            data: [
              { id: 1, name: "Back to options" },
              { id: 2, name: "Return to main menu" },
            ],
          });
        } else {
          _data.push({
            type: "options",
            name: "infojiniMainMenu",
            data: [{ id: 1, name: "Return to main menu" }],
          });
        }
        break;

      case "SubmitJoinTalentdomeForm":
        _data = _data.filter(({ message }) => message !== "");
        _data.push(
          {
            messageGroup: [
              {
                type: "text",
                isSessionUser: false,
                message: "Thank you for your feedback",
              },
            ],
          },
          {
            type: "options",
            name: "mainMenu",
            data: [{ id: 1, name: "Return to main menu" }],
          }
        );
        break;

      case "joinTalentdome":
        localStorage.removeItem("jobDepartmentId");
        localStorage.removeItem("jobSubDepartmentId");
        localStorage.removeItem("cityNames");
        localStorage.removeItem("jobPositionKey");

        if (selectedOption.name === "Yes") {
          _data.push({
            type: "joinTalentdomeForm",
            formTitle: "Join Talentpool",
            name: "SubmitJoinTalentdomeForm",
          });
        } else {
          _data.push({
            messageGroup: [
              {
                type: "text",
                isSessionUser: false,
                message: "Thank you, visit again",
              },
            ],
          });
        }
        _data.push({
          type: "options",
          name: "mainMenu",
          data: [{ id: 1, name: "Return to main menu" }],
        });
        break;

      case "applyJob":
        _data.push(
          {
            type: "joinTalentdomeForm",
            formTitle: "Candidate Details",
            alreadyApply: "alreadyApply",
            name: "candidatedAdd",
            jobPositionId: selectedOption.jobPositionId,
          },
          {
            type: "options",
            name: "mainMenu",
            data: [
              { id: 1, name: "Back to Job List" },
              { id: 2, name: "Return to main menu" },
            ],
          }
        );
        break;

      case "referJob":
        _data.push(
          {
            type: "joinTalentdomeForm",
            formTitle: "Refer a Friend",
            name: "candidatedAdd",
            alreadyApply: "alreadyApply",
            jobPositionId: selectedOption.jobPositionId,
          },
          {
            type: "options",
            name: "mainMenu",
            data: [
              { id: 1, name: "Back to Job List" },
              { id: 2, name: "Return to main menu" },
            ],
          }
        );
        break;

      case "candidatedAdd":
        _data = _data.filter(({ message }) => message !== "");
        _data.push(
          {
            messageGroup: [
              {
                type: "text",
                isSessionUser: false,
                message: "Thank you for your feedback",
              },
            ],
          },
          {
            type: "options",
            name: "mainMenu",
            data: [
              { id: 1, name: "Back to Job List" },
              { id: 2, name: "Return to main menu" },
            ],
          }
        );
        break;

      case "alreadyApply":
        _data = _data.filter(({ message }) => message !== "");
        _data.push(
          {
            messageGroup: [
              {
                type: "text",
                isSessionUser: false,
                message: "You have already applied for this job!!!",
              },
            ],
          },
          {
            type: "options",
            name: "mainMenu",
            data: [
              { id: 1, name: "Back to Job List" },
              { id: 2, name: "Return to main menu" },
            ],
          }
        );
        break;

      case "infojiniMenu":
        if (selectedOption.name === "Submit Requirements / RFX") {
          _data.push({
            type: "submitRequirementsForm",
            formTitle: "Submit Requirements / RFX",
            name: "submitRequirementsDetails",
          });
        } else if (selectedOption.name === "Subscribe To Blogs") {
          _data.push({
            type: "submitRequirementsForm",
            formTitle: "Subscribe To Blogs",
            name: "submitRequirementsDetails",
          });
        } else {
          _data.push({
            type: "submitRequirementsForm",
            formTitle: "Drop a line",
            name: "submitRequirementsDetails",
          });
        }
        _data.push({
          type: "options",
          name: "mainMenu",
          data: [{ id: 1, name: "Return to main menu" }],
        });
        break;

      case "universityRequestForm":
        _data = _data.filter(({ message }) => message !== "");
        _data.push({ type: "typing" });
        this.addTimer().then(() => {
          this.addConversation({
            messageGroup: [selectedOption.successMessage],
          });
          this.addConversation({
            type: "options",
            name: "clientChatbotOptions",
            data: [
              {
                id: 1,
                name: "Return to Main Menu",
              },
            ],
          });
        });
        break;

      default:
        break;
    }

    if (selectedOption.jobSubDepartmentId) {
      localStorage.setItem(
        "jobSubDepartmentId",
        JSON.stringify(selectedOption.jobSubDepartmentId)
      );
      onUpdateJobSubDepartmentId(selectedOption.jobSubDepartmentId);
    }
    if (selectedOption.jobDepartmentId) {
      localStorage.setItem(
        "jobDepartmentId",
        JSON.stringify(selectedOption.jobDepartmentId)
      );
      onUpdateJobDepartmentId(selectedOption.jobDepartmentId);
    }

    this.setState({
      data: [..._data],
    });

    if (clientType === "infojini" || clientType === "talentdome") {
      const sessionDetails = {
        userType,
        clientType,
        message,
      };
      localStorage.setItem("lastSocketRequest", JSON.stringify(sessionDetails));
      socket.emit("message", {
        sessionId,
        message,
        userType,
        clientType,
      });
    }
  }

  intialize() {
    const { clientType } = this.state;
    let _baseUrl = SOCKET_IO_BASE_URL ? SOCKET_IO_BASE_URL : this.props.baseUrl;
    const socket = socketIOClient(_baseUrl);

    socket.emit("initializeSession", { clientType });

    socket.on("sessionDetails", (sessionDetails) => {
      this.setState({
        sessionId: sessionDetails.sessionId,
      });

      const { lastSessionDetails, jobPositionKey, data } = this.state;
      let _data = data;
      if (lastSessionDetails !== null) {
        lastSessionDetails.sessionId = sessionDetails.sessionId;
        let jobDepartmentId = localStorage.getItem("jobDepartmentId");
        let _jobPositionKey = localStorage.getItem("jobPositionKey");
        let jobSubDepartmentId = localStorage.getItem("jobSubDepartmentId");
        let formSubmmited = localStorage.getItem("formSubmmited");
        let _formInChat =
          _data.length > 3
            ? _data[_data.length - 3].type === "joinTalentdomeForm"
              ? true
              : _data[_data.length - 3].type === "submitRequirementsForm"
              ? true
              : false
            : false;

        let lastAllITJobList =
          _data.length > 2
            ? _data[_data.length - 2].message === "All"
              ? true
              : false
            : null;

        let _loadMoreJobs =
          _data.length > 4
            ? _data[_data.length - 4].message === "Join Talentdome"
              ? true
              : _data[_data.length - 4].message === "View More Jobs"
              ? true
              : false
            : false;

        if (
          lastSessionDetails.message !==
            "Are you looking for our support team?" &&
          lastSessionDetails.message !== "Don’t see what you need?" &&
          lastSessionDetails.message !== "Need help with an issue?" &&
          lastSessionDetails.message !== "Can’t find the right option?"
        ) {
          socket.emit("message", lastSessionDetails);
        }

        if (
          JSON.parse(_jobPositionKey) !== null &&
          JSON.parse(formSubmmited) !== true &&
          !_formInChat
        ) {
          this.props.fetchChatbotJobDetails(jobPositionKey);
        }

        if (
          _data.length > 0 &&
          _data[_data.length - 2].message !== "Return to main menu" &&
          JSON.parse(formSubmmited) !== true &&
          !_formInChat
        ) {
          if (
            (jobPositionKey === null && !_loadMoreJobs) ||
            (_data.length > 2 &&
              _data[_data.length - 2].message === "Back to Job List")
          ) {
            if (
              (JSON.parse(jobDepartmentId) !== null &&
                JSON.parse(jobDepartmentId) !== 1) ||
              (JSON.parse(jobSubDepartmentId) !== null ||
                lastAllITJobList ||
                (_data.length > 2 &&
                  _data[_data.length - 2].message === "Back to Job List"))
            ) {
              this.props.fetchChatbotJobList();
            }
          }
        }
      }
    });

    socket.on("typing", (_) => {
      const { data } = this.state;
      const isTypingMessageAdded = data.find(
        (value) => value.type === "typing"
      );
      if (!isTypingMessageAdded) {
        data.push({ type: "typing" });
        this.setState({
          data: [...data],
        });
      }
    });

    socket.on("message", (messageData) => {
      const { data, userType, showBackOption } = this.state;
      const { response } = messageData;
      const { type, result, name } = response.data;

      let _data = data.filter((value) => value.type !== "typing");
      _data = JSON.parse(JSON.stringify(_data));
      let lastIndex = _data.length - 1;

      let isSessionUserExists = _data.find(
        (value) =>
          value.isSessionUser &&
          _data[lastIndex].message !== "Return to main menu"
      );

      const contactFormTypes = [
        {
          type: "contact",
          names: [
            "ICantFindAMatchingOptionScript",
            "existingEmployee-ICantFindMatchingOptionScript",
          ],
        },
      ];
      const returnMessageStartsWith = [
        "Infojini offers you",
        "We have built a brief demo for you",
      ];
      const returnMessageArray = [
        "IWantToSetupADirectSourcingDemoScript",
        "KnowMoreAboutServices-IWantToKnowAboutHealthcareStaffingScript",
      ];

      if (type === "text" || type === "locationInput") {
        const linkIndex = result[0].indexOf("https://");

        returnMessageStartsWith.map((value) => {
          if (
            returnMessageArray.indexOf(name) !== -1 &&
            result[0].startsWith(value)
          ) {
            if (showBackOption) {
              _data.push({
                type: "options",
                name: "infojiniMainMenu",
                data: [
                  { id: 1, name: "Back to options" },
                  { id: 2, name: "Return to main menu" },
                ],
              });
            } else {
              _data.push({
                type: "options",
                name: "infojiniMainMenu",
                data: [{ id: 1, name: "Return to main menu" }],
              });
            }
          }
          return "";
        });

        if (_data.length > 0) {
          let lastMessage = _data[lastIndex];
          if (lastMessage.hasOwnProperty("messageGroup")) {
            _data[lastIndex].messageGroup.push({
              name: name,
              type: "text",
              message: result[0],
              isSessionUser: response.isSessionUser,
              link: linkIndex !== -1 ? result[0] : null,
              displayLinkName: linkIndex !== -1 ? "to view the link" : null,
            });

            let displayContactForm = _data[lastIndex].messageGroup.reduce(
              (acc, value) => {
                contactFormTypes.map(({ type, names }) => {
                  if (names.indexOf(value.name) !== -1) {
                    acc[type] = true;
                  }
                  return "";
                });
                return acc;
              },
              {}
            );

            if (displayContactForm.hasOwnProperty("contact")) {
              _data.push({ type: "typing" });
              setTimeout(() => {
                _data = _data.filter(({ type }) => type !== "typing");
                _data.push({
                  type: "submitRequirementsForm",
                  name: "submitRequirementsDetails",
                  formTitle: "Contact Form",
                });
                if (showBackOption) {
                  _data.push({
                    type: "options",
                    name: "infojiniMainMenu",
                    data: [
                      { id: 1, name: "Back to options" },
                      { id: 2, name: "Return to main menu" },
                    ],
                  });
                } else {
                  _data.push({
                    type: "options",
                    name: "infojiniMainMenu",
                    data: [{ id: 1, name: "Return to main menu" }],
                  });
                }
                this.setState({
                  data: [..._data],
                });
              }, 4500);
            }
          } else {
            let lastMessage = _data[lastIndex];
            if (this.state.clientType === "talentdome") {
              if (
                _data.length > 0 &&
                response.data.result[0] !== "Welcome to Talentdome"
              ) {
                if (
                  response.data.result[0] ===
                    "What’s your preferred location? Choose up to 3." &&
                  lastMessage.message !== "Return to main menu"
                ) {
                } else {
                  _data.push({
                    messageGroup: [
                      {
                        name: name,
                        type: "text",
                        message: result[0],
                        isSessionUser: response.isSessionUser,
                        link: linkIndex !== -1 ? result[0] : null,
                        displayLinkName:
                          linkIndex !== -1 ? "to view the link" : null,
                      },
                    ],
                  });
                }
              } else {
                this.setState({
                  isSessionUserExists: false,
                });
              }
            }

            if (this.state.clientType === "infojini") {
              if (_data.length > 0 && name !== "userOptionsScript") {
                _data.push({
                  messageGroup: [
                    {
                      name: name,
                      type: "text",
                      message: result[0],
                      isSessionUser: response.isSessionUser,
                      link: linkIndex !== -1 ? result[0] : null,
                      displayLinkName:
                        linkIndex !== -1 ? "to view the link" : null,
                    },
                  ],
                });
              } else {
                this.setState({
                  isSessionUserExists: false,
                });
              }
            }
          }
        } else {
          if (data.length === 1) {
            _data.push({
              messageGroup: [
                {
                  name: name,
                  type: "text",
                  message: result[0],
                  isSessionUser: response.isSessionUser,
                },
              ],
            });
          }
        }

        if (type === "locationInput") {
          if (this.state.clientType === "infojini") {
            _data.push(response.data);
          }

          if (
            this.state.clientType === "talentdome" &&
            (data.length === 2 ||
              (data[lastIndex].name !== response.data.name &&
                data[lastIndex].message === "Return to main menu"))
          ) {
            _data.push(response.data);
          }
          if (
            userType === "Job Seeker" ||
            userType === "Careers with Infojini"
          ) {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [{ id: 1, name: "Return to main menu" }],
            });
          }
        }
        if (linkIndex !== -1) {
          if (showBackOption) {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [
                { id: 1, name: "Back to options" },
                { id: 2, name: "Return to main menu" },
              ],
            });
          } else {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [{ id: 1, name: "Return to main menu" }],
            });
          }
        }
      } else {
        if (
          _data[lastIndex].message === "Return to main menu" &&
          response.data.data[0].name === "Careers with Infojini"
        ) {
          _data.push(response.data);
        }

        if (
          _data.length === 1 &&
          response.data.data[0].name === "Careers with Infojini"
        ) {
          _data.push(response.data);
        }

        if (
          _data.length > 0 &&
          response.data.data[0].name !== "Careers with Infojini"
        ) {
          _data.push(response.data);
        }
      }

      if (name === "user-options" || name === "JobCategoryList") {
        if (isSessionUserExists) {
          let chatHistory =
            _data.length > 0
              ? _data.find(
                  ({ name }) =>
                    name === "infojiniMainMenu" || name === "mainMenu"
                )
              : null;
          let _lastMsg =
            _data.length > 0
              ? _data.find(({ message }) => message === "Back to options")
              : null;

          if (
            showBackOption &&
            !chatHistory &&
            _data[_data.length - 1].type === "options" &&
            !_lastMsg
          ) {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [
                { id: 1, name: "Back to options" },
                { id: 2, name: "Return to main menu" },
              ],
            });
          } else {
            if (
              !chatHistory &&
              _data[_data.length - 1].type === "options" &&
              _data[_data.length - 2].message !== "Return to main menu"
            ) {
              _data.push({
                type: "options",
                name: "infojiniMainMenu",
                data: [{ id: 1, name: "Return to main menu" }],
              });
            }
          }
        }
      }

      if (
        name === "Existing-Employee -Need-Help" ||
        name === "Existing-Client-IWantToContactYourSupportTeamScript"
      ) {
        let _formTitle =
          name === "Existing-Employee -Need-Help"
            ? "Create Ticket"
            : "Contact Form";
        _data.push({ type: "typing" });

        setTimeout(() => {
          _data = _data.filter(({ type }) => type !== "typing");
          _data.push({
            type: "submitRequirementsForm",
            name: "submitRequirementsDetails",
            formTitle: _formTitle,
          });
          if (showBackOption) {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [
                { id: 1, name: "Back to options" },
                { id: 2, name: "Return to main menu" },
              ],
            });
          } else {
            _data.push({
              type: "options",
              name: "infojiniMainMenu",
              data: [{ id: 1, name: "Return to main menu" }],
            });
          }
          this.setState({
            data: [..._data],
          });
        }, 4500);
      }

      if (userType === "Existing Client" || userType === "Existing Employee") {
        let subOptions = _data.find(
          ({ type, name }) => type === "options" && name === "user-options"
        );
        let subOptionSelected = JSON.parse(
          localStorage.getItem("selectedOption")
        );

        if (
          subOptionSelected &&
          (subOptionSelected.name !== "Are you an existing Client?" &&
            subOptionSelected.name !== "Are you an existing Employee?")
        ) {
          localStorage.setItem("showBackOptionStatus", JSON.stringify(true));
        }
        subOptions &&
          subOptions.data.map((value) => {
            return (value.type = "sub-options");
          });
      }

      let lastUserMessages =
        _data && _data.filter(({ isSessionUser }) => isSessionUser === true);

      const _chatbotText = lastUserMessages[lastUserMessages.length - 1];

      this.setState({
        data: [..._data],
        chatbotText: _chatbotText ? _chatbotText.message : null,
      });
    });

    this.setState({
      socket,
    });
  }

  // ----------------  School - client Type code Starts --------------- //
  addTimer(timer = 2000) {
    let _data = this.removeTyping();
    _data.push({ type: "typing" });
    this.setState({
      data: [..._data],
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, timer);
    });
  }

  addConversation(details) {
    let _data = this.removeTyping();
    _data.push(details);
    this.setState({ data: [..._data] });
  }

  removeTyping() {
    const { data } = this.state;
    let _data = data.filter((value) => value.type !== "typing");
    return _data;
  }

  clientChatbotIntialize() {
    const { clientSelectedIndex, updateClientSelectedIndex } = this.props;
    const messageData = this.clientChatbotData.messageData;

    if (clientSelectedIndex === 0) {
      this.addTimer().then(() => {
        this.addConversation({
          messageGroup: [messageData[0].message[0]],
        });
        this.addTimer().then(() => {
          this.addConversation(messageData[0].message[1]);
          updateClientSelectedIndex();
        });
      });
    }
  }

  componentWillMount() {
    let _clientType;
    let data = localStorage.getItem("chatHistory");
    let userType = localStorage.getItem("userType");
    let showBackOptionStatus = localStorage.getItem("showBackOptionStatus");
    let lastSessionDetails = localStorage.getItem("lastSocketRequest");
    let jobDepartmentId = localStorage.getItem("jobDepartmentId");
    let jobSubDepartmentId = localStorage.getItem("jobSubDepartmentId");
    let cityNames = localStorage.getItem("cityNames");
    let jobPositionKey = localStorage.getItem("jobPositionKey");
    let formSubmmited = localStorage.getItem("formSubmmited");

    if (JSON.parse(cityNames) !== null) {
      this.props.onUpdateJobCityNames(JSON.parse(cityNames));
    }

    if (JSON.parse(jobDepartmentId) !== null) {
      this.props.onUpdateJobDepartmentId(JSON.parse(jobDepartmentId));
    }

    if (JSON.parse(jobSubDepartmentId) !== null) {
      this.props.onUpdateJobSubDepartmentId(JSON.parse(jobSubDepartmentId));
    }

    const params = queryString.parse(window.location.search);
    if (this.props.clientType) {
      _clientType = this.props.clientType;
    } else if (
      params.client_name === "infojini" ||
      params.client_name === "talentdome"
    ) {
      _clientType = params.client_name;
    } else {
      _clientType = params.client_name;
      this.clientChatbotData = require(`../Chatbot/ChatbotUI/utils/${
        params.client_name
      }.json`);
    }
    this.setState({
      clientType: this.props.clientType ? this.props.clientType : _clientType,
      UUID: this.props.UUID ? this.props.UUID : "",
      data: data ? JSON.parse(data) : [],
      lastSessionDetails: lastSessionDetails
        ? JSON.parse(lastSessionDetails)
        : null,
      showBackOption: showBackOptionStatus
        ? JSON.parse(showBackOptionStatus)
        : false,
      userType: userType ? JSON.parse(userType) : null,
      jobPositionKey: jobPositionKey ? JSON.parse(jobPositionKey) : null,
      formSubmmited: formSubmmited ? JSON.parse(formSubmmited) : false,
    });
  }
  // ----------------  School - client Type code Ends --------------- //

  render() {
    const {
      chatbotResetState,
      onUpdateJobCityNames,
      fetchChatbotJobDetails,
    } = this.props;
    const {
      data,
      clientType,
      userType,
      chatbotText,
      formSubmmited,
      UUID,
    } = this.state;
    let _data = data;
    let jobSubCatgory =
      _data.length > 1
        ? _data.find(({ name }) => name === "JobSubCategoryList")
        : null;
    let formInChat =
      _data.length > 1
        ? _data.find(
            ({ type }) =>
              type === "submitRequirementsForm" || type === "joinTalentdomeForm"
          )
        : null;
    let _loadMoreJobs =
      _data.length > 3
        ? _data[_data.length - 3].message === "View More Jobs"
          ? true
          : false
        : null;

    if (formSubmmited) {
      localStorage.setItem("formSubmmited", JSON.stringify(formSubmmited));
      localStorage.setItem("chatHistory", JSON.stringify(_data));
    } else if (formInChat || jobSubCatgory || _loadMoreJobs) {
      localStorage.setItem("chatHistory", JSON.stringify(_data));
    } else {
      const indexOfLastUserResponce = _data
        .map((el) => el.isSessionUser)
        .lastIndexOf(true);
      const localStorageData = _data.slice(0, indexOfLastUserResponce + 1);
      localStorage.setItem("chatHistory", JSON.stringify(localStorageData));
    }

    return (
      <React.Fragment>
        <ChatbotJobListGetQuery UuId={UUID} />
        <ChatbotJobDetailsGetQuery UuId={UUID} />
        <ChatbotUI
          vendorUUID={UUID}
          data={data}
          userType={userType}
          clientType={clientType}
          chatbotText={chatbotText}
          toggle={this.toggle.bind(this)}
          chatbotResetState={chatbotResetState}
          clientConfig={clientConfig[clientType]}
          setUserType={this.setUserType.bind(this)}
          onUpdateJobCityNames={onUpdateJobCityNames}
          onSelectOption={this.onSelectOption.bind(this)}
          fetchChatbotJobDetails={fetchChatbotJobDetails}
          setBackOptionStatus={this.setBackOptionStatus.bind(this)}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ chatbot }) => {
  return {
    jobDetails: chatbot.jobDetails,
    jobListData: chatbot.jobListData,
    vendorDetails: chatbot.vendorDetails,
    clientSelectedIndex: chatbot.clientSelectedIndex,
    clientSelectedValue: chatbot.clientSelectedValue,
    jobListDetailsLoaded: chatbot.jobListDetailsLoaded,
    loadingJobDetailsData: chatbot.loadingJobDetailsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chatbotResetState() {
      dispatch(chatbotResetState());
    },
    fetchChatbotJobList() {
      dispatch(fetchChatbotJobList());
    },
    fetchChatbotJobDetails(jobPositionKey) {
      localStorage.setItem("jobPositionKey", JSON.stringify(jobPositionKey));

      dispatch(fetchChatbotJobDetails(jobPositionKey));
    },
    onUpdateJobCityNames(cityNames) {
      localStorage.setItem("cityNames", JSON.stringify(cityNames));
      dispatch(onUpdateJobCityNames(cityNames));
    },
    onUpdateJobDepartmentId(id) {
      dispatch(onUpdateJobDepartmentId(id));
    },
    onUpdateJobSubDepartmentId(id) {
      dispatch(onUpdateJobSubDepartmentId(id));
    },
    updateClientSelectedIndex(index) {
      dispatch(updateClientSelectedIndex(index));
    },
    updateClientSelectedValue(value) {
      dispatch(updateClientSelectedValue(value));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chatbot);
