import React, { Component } from 'react';
import './Checkbox.scss';

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        }
    }
    onCheckboxClick() {
        this.setState({ checked: !this.state.checked })
        this.props.onChangeStatus(!this.state.checked)
    }


    render() {
        const { text } = this.props;
        const { checked } = this.state;

        return (
            <div id="checkbox-container" onClick={_ => this.onCheckboxClick()}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={_ => this.onCheckboxClick()} />
                <div className="checkbox-text">
                    {text}
                </div>
            </div>
        );
    }
}

export default Checkbox;