import React from 'react';
import AppHeader from 'App/shared/components/AppHeader/AppHeader';
import AppFooter from 'App/shared/components/AppFooter/AppFooter';
import NotFoundImg from 'App/shared/images/not-found.png';

const notFound = ({
    showHeader = true
}) => {
    return (
        <div className='page-not-found'>
            {
                showHeader ? <AppHeader showUserInfoDropdown={false} /> : null
            }

            <div className='container'>
                <div className='page-not-found-content'>
                    <img src={NotFoundImg} alt='Page Not Found' />
                    <p>Oops! Nothing to see here. You found a broken link.</p>
                </div>
            </div>
            {
                showHeader ?  <AppFooter /> : null
            }
           
        </div>
    );
};

export default notFound;