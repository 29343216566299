import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import '../shared/ChatbotSelectOptions.scss'

export default class CharbotSelectOptions extends Component {
    onSelectOption(option) {
        const {
            name,
            clientType,
            setUserType,
            onOptionClick,
            setBackOptionStatus
        } = this.props;

        let optionType = name === "JobCategoryList" || name === "JobSubCategoryList"
            ? option.subCategories ? "JobSubCategoryList" : "JobCategoryList" : name;
        optionType = optionType === "mainMenu" && option.name === "Return to main menu" && clientType === "infojini"
            ? "infojiniMainMenu" : optionType
        onOptionClick(optionType, option);


        //--------------- setting user type for clientType - Infojini ----------------//
        if (option.name === "Are you an existing Employee?") {
            localStorage.setItem("userType", JSON.stringify('Existing Employee'))
            setUserType("Existing Employee");
        } else if (option.name === "Are you an existing Client?") {
            localStorage.setItem("userType", JSON.stringify('Existing Client'))
            setUserType("Existing Client");
        } else if (option.name === "Careers with Infojini") {
            localStorage.setItem("userType", JSON.stringify(option.name))
            setUserType(option.name);
        }

        //--------------- Setting back to Options for sub-options ----------------//
        if (option.type === "sub-options") {
            setBackOptionStatus(true);
        } else {
            setBackOptionStatus(false);
        }

        //--------------- google analytics events ----------------//
        if (option.jobDepartmentId) {
            ReactGA.event({
                category: `chatbot-job-category-${option.name}`,
                action: "Selected job category from chatbot",
                label: option.name
            });
        }
        if (option.jobSubDepartmentId) {
            ReactGA.event({
                category: `chatbot-job-sub-category-${option.name}`,
                action: "Selected job sub category from chatbot",
                label: option.name
            });
        }
    }
    render() {
        const {
            options,
            clientConfig,
            scrollPosition,
            optionRef = null,
            isSessionUser = false } = this.props;
        const className = isSessionUser
            ? "chatbot-select-options session-user" : "chatbot-select-options";
        const textStyle = {
            color: clientConfig.style.listColor,
            borderColor: clientConfig.style.listBorderColor,
            backgroundColor: clientConfig.style.listBackgroundColor
        }

        const hoverClass = clientConfig.style.hoverClass

        return (
            <div className={className} ref={(scrollPosition === "OptionsScrollTop") ? optionRef : null}>
                {
                    options.map((option, index) => {
                        return (
                            <div key={index}
                                style={textStyle}
                                className={`chatbot-select-options__option ${hoverClass}`}
                                onClick={() => this.onSelectOption(option)}>
                                <span> {option.name} </span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}