const defaultState = {
  pageId: 1,
  limit: 10,
  reasons: [],
  totalCount: 5,
  vendorDetails: {},
  notificationList: [],
  loadingOption: false,
  candidateUserName: "",
  perferencesOptions: [],
  notificationCount: null,
  referralPathwayList: null,
  voucherTransactionList: [],
  NotifiactionListLoaded: true,
  loadingNotificationList: false,
  NotifiactionCountLoaded: false,
  loadingNotificationCount: false,
  registerFormDetailsLoaded: false,
  loadingRegisterFormDetails: false,
  assessmentHistoryListLoaded: false,
  loadingAssessmentHistoryList: false,
  referralRewardsPointsListLoaded: false,
  loadingReferralRewardsPointsList: false,
  redeemVoucherTransactionListLoaded: false,
  loadingredeemVoucherTransactionList: false,
  registerFormDetails: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    contactNumber: "",
    phoneNumber: "",
    primarySkills: [],
    secondarySkills: [],
    experience: {
      months: "",
      years: "",
    },
    resumeId: "",
    captcha: "",
    linkedInProfileLink: "",
    relocatOption: 2,
    remoteWorkcms: 2,
    ModalCms: false,
    isVerified: false,
    preferredLocation: "",
  },
  selectedFile: {
    file: null,
    isUploaded: false,
    isFileScanning: null,
    isUploadedError: false,
  },
  assessmentHistoryList: [],
  isAuthenticating: false,
  isAuthenticated: false,
  invalidCredentials: false,
  showPassword: false,
  sidebarItems: [],
  redemptionHistoryTableColumns: [
    {
      name: "Description",
      key: "description",
      className: "col-lg-3 col-md-3 col-sm-3 col-xs-3",
    },
    {
      name: "Date | Time",
      key: "date",
      className: "col-lg-3 col-md-3 col-sm-3 col-xs-3",
    },
    {
      name: "Points Redeemed",
      key: "pointsRedeemed",
      className: "col-lg-3 col-md-3 col-sm-3 col-xs-3",
    },
    {
      name: "Amount",
      key: "amount",
      className: "col-lg-1 col-md-1 col-sm-1 col-xs-1",
    },
    {
      name: "Balance",
      key: "balance",
      className: "col-lg-1 col-md-1 col-sm-1 col-xs-1",
    },
  ],
  assessmentHistoryTableColumns: [
    {
      name: "Assessment",
      key: "assessment",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2",
    },
    {
      name: "Job Title",
      key: "jobTitle",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2",
    },
    {
      name: "Questions (Attempted/Total)",
      key: "questions",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2",
    },
    {
      name: "Duration (Time spent/Total)",
      key: "timeSpent",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2 time-spent",
    },
    {
      name: "Score",
      key: "score",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2",
    },
    {
      name: "Attempted Date",
      key: "attemptDate",
      className: "col-lg-2 col-md-2 col-sm-2 col-xs-2",
    },
  ],
  yesNoOptions: [
    {
      id: 2,
      label: "No",
    },
    {
      id: 1,
      label: "Yes",
    },
  ],
  rulesList: [
    "Test takers may review questions they already answered and change their answers before they submit.",
    "Once a test taker has submitted their assessment, they may not go back to it.",
    "Test takers may skip questions they have difficulty answering and come back to them later before they submit the assessment.",
    "Test can’t be paused.",
    "Once, Test has started test taker can’t go back.",
  ],
  assessmentSelectedAnswers: [],
  assessmentTimeUpReminder: false,
  assessmentQuestionsListDetails: {},
  loadingAssissmentQuestionsList: false,
  assessmentQuestionListLoaded: false,
  stopClock: false,
};

//actions
const RESET_STATE = "RESET_STATE";
const ON_UPLOAD_FILE = "ON_UPLOAD_FILE";
const ON_FILE_SCANNING = "ON_FILE_SCANNING";
const UPDATE_RESUME_ID = "UPDATE_RESUME_ID";
const STOP_CLOCK_TIMER = "STOP_CLOCK_TIMER";
const UNSUBSCRIBE_LOADING = "UNSUBSCRIBE_LOADING";
const ON_UPLOAD_FILE_ERROR = "ON_UPLOAD_FILE_ERROR";
const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
const ON_REMOVE_UPLOADED_FILE = "ON_REMOVE_UPLOADED_FILE";
const RESET_NOTIFICATION_COUNT = "RESET_NOTIFICATION_COUNT";
const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
const ON_CHANGE_LOCATION_OPTION = "ON_CHANGE_LOCATION_OPTION";
const ON_CHANGE_REMOTE_WORK_OPTION = "ON_CHANGE_REMOTE_WORK_OPTION";
const ON_CHANGE_MODAL_STATUS_OPTION = "ON_CHANGE_MODAL_STATUS_OPTION";
const ON_CHANGE_VERIFICATION_STATUS_OPTION = "ON_CHANGE_VERIFICATION_STATUS_OPTION";
const UPDATE_UNSUBSCRIBE_OPTIONS = "UPDATE_UNSUBSCRIBE_OPTIONS";
const ON_CHANGE_ASSESSMENT_ANSWERS = "ON_CHANGE_ASSESSMENT_ANSWERS";
const ON_CHANGE_PREFERRED_LOCATION = "ON_CHANGE_PREFERRED_LOCATION";
const ON_CHANGE_CANDIDATE_PASSWORD = "ON_CHANGE_CANDIDATE_PASSWORD";
const ON_CHANGE_CANDIDATE_PAGINATE = "ON_CHANGE_CANDIDATE_PAGINATE";
const UPDATE_CANDIDATE_FORM_CAPTCHA = "UPDATE_CANDIDATE_FORM_CAPTCHA";
const ON_CHANGE_CANDIDATE_EMAIL_TEXT = "ON_CHANGE_CANDIDATE_EMAIL_TEXT";
const ASSESSMENT_QUESTIONS_LIST_DATA = "ASSESSMENT_QUESTIONS_LIST_DATA";
const CANDIDATE_LOGIN_AUTHENTICATION = "CANDIDATE_LOGIN_AUTHENTICATION";
const EDIT_PROFILE_PHONE_NUMBER_CHANGE = "EDIT_PROFILE_PHONE_NUMBER_CHANGE";
const ASSESSMENT_TIME_UP_STATUS_CHANGE = "ASSESSMENT_TIME_UP_STATUS_CHANGE";
const ON_CHANGE_CANDIDATE_CONTACT_NUMBER = "ON_CHANGE_CANDIDATE_CONTACT_NUMBER";
const ON_CHANGE_CANDIDATE_LAST_NAME_TEXT = "ON_CHANGE_CANDIDATE_LAST_NAME_TEXT";
const UPDATE_ASSESSMENT_HISTORY_LIST_DATA =
  "UPDATE_ASSESSMENT_HISTORY_LIST_DATA";
const ON_CHANGE_CANDIDATE_FIRST_NAME_TEXT =
  "ON_CHANGE_CANDIDATE_FIRST_NAME_TEXT";
const ON_UPDATE_REGISTER_FORM_DETAILS_DATA =
  "ON_UPDATE_REGISTER_FORM_DETAILS_DATA";
const ON_CHANGE_CANDIDATE_EXPERIENCE_YEARS =
  "ON_CHANGE_CANDIDATE_EXPERIENCE_YEARS";
const ON_CHANGE_CANDIDATE_EXPERIENCE_MONTHS =
  "ON_CHANGE_CANDIDATE_EXPERIENCE_MONTHS";
const ON_CHANGE_CANDIDATE_PRIMARY_SKILL_LIST =
  "ON_CHANGE_CANDIDATE_PRIMARY_SKILL_LIST";
const ON_CHANGE_CANDIDATE_SECONDARY_SKILL_LIST =
  "ON_CHANGE_CANDIDATE_SECONDARY_SKILL_LIST";
const UPDATE_REFERRAL_REWARDS_POINTS_LIST_DATA =
  "UPDATE_REFERRAL_REWARDS_POINTS_LIST_DATA";
const UPDATE_REDEEM_VOUCHER_TRANSACTION_LIST_DATA =
  "UPDATE_REDEEM_VOUCHER_TRANSACTION_LIST_DATA";
const ON_CHANGE_CANDIDATE_LINKEDIN_PROFILE_LINK =
  "ON_CHANGE_CANDIDATE_LINKEDIN_PROFILE_LINK";

const ON_CHANGE_SHOW_PASSWORD = "ON_CHANGE_SHOW_PASSWORD"


//dispatchers
function resetState() {
  return {
    type: RESET_STATE,
  };
}

function onChangeShowPassword(value) {
  return {
    type: ON_CHANGE_SHOW_PASSWORD,
    value,
  }
}

function onChangePreferredLocation(locationDetails) {
  return {
    type: ON_CHANGE_PREFERRED_LOCATION,
    locationDetails,
  };
}

function editProfilePhoneNumberChange(value) {
  return {
    type: EDIT_PROFILE_PHONE_NUMBER_CHANGE,
    value,
  };
}

function stopClockUpdate(status) {
  return {
    type: STOP_CLOCK_TIMER,
    status,
  };
}
function updateQuestionsListUsingCandidateCode(status, questionsListDetails) {
  return {
    type: ASSESSMENT_QUESTIONS_LIST_DATA,
    status,
    questionsListDetails,
  };
}
function onChangeAssessmentAnswers(selectedAnswers) {
  return {
    type: ON_CHANGE_ASSESSMENT_ANSWERS,
    selectedAnswers,
  };
}

function disabledRadioButton(status) {
  return {
    type: ASSESSMENT_TIME_UP_STATUS_CHANGE,
    status,
  };
}
function updateNotificationList(status, notificationList) {
  return {
    type: UPDATE_NOTIFICATION_LIST,
    notificationList,
    status,
  };
}
function getNotificationList(status) {
  return {
    type: GET_NOTIFICATION_LIST,
    status,
  };
}
function updateNotificationCount(status, count) {
  return {
    type: UPDATE_NOTIFICATION_COUNT,
    count,
    status,
  };
}
function resetNotificationCount() {
  return {
    type: RESET_NOTIFICATION_COUNT,
  };
}
function updateRegisterFormDetailsData(status, registerFormDetails) {
  return {
    type: ON_UPDATE_REGISTER_FORM_DETAILS_DATA,
    registerFormDetails,
    status,
  };
}

function updateAssessmentHistoryListData(status, assessmentHistoryListDetails) {
  return {
    type: UPDATE_ASSESSMENT_HISTORY_LIST_DATA,
    status,
    assessmentHistoryListDetails,
  };
}

function _updateCandidateDetailsObject(registerFormDetails, detailsToUpdate) {
  return { ...registerFormDetails, ...detailsToUpdate };
}

function updateCandidateFormCaptcha(captcha) {
  return {
    type: UPDATE_CANDIDATE_FORM_CAPTCHA,
    captcha,
  };
}

function updateRedeemVoucherTransactionListData(status, data) {
  return {
    type: UPDATE_REDEEM_VOUCHER_TRANSACTION_LIST_DATA,
    data,
    status,
  };
}

function updateReferralRewardsPointsListData(status, data) {
  return {
    type: UPDATE_REFERRAL_REWARDS_POINTS_LIST_DATA,
    data,
    status,
  };
}

function onChangePagination(pageId, isScrollEvent = false) {
  return {
    type: ON_CHANGE_CANDIDATE_PAGINATE,
    pageId,
    isScrollEvent,
  };
}

function authenticationStatusDispatcher(authenticationDetails) {
  return {
    type: CANDIDATE_LOGIN_AUTHENTICATION,
    authenticationDetails,
  };
}

function loadingUnsubscribeOptions(status) {
  return {
    type: UNSUBSCRIBE_LOADING,
    status,
  };
}

function updateUnsubscribeOption(status, options) {
  return {
    type: UPDATE_UNSUBSCRIBE_OPTIONS,
    options,
    status,
  };
}

function onChangeCandidateFirstNameText(firstName) {
  return {
    type: ON_CHANGE_CANDIDATE_FIRST_NAME_TEXT,
    firstName,
  };
}

function onChangeCandidateLastNameText(lastName) {
  return {
    type: ON_CHANGE_CANDIDATE_LAST_NAME_TEXT,
    lastName,
  };
}

function onChangeCandidateContactNumber(contactNumber) {
  return {
    type: ON_CHANGE_CANDIDATE_CONTACT_NUMBER,
    contactNumber,
  };
}

function onChangeCandidateEmailText(email) {
  return {
    type: ON_CHANGE_CANDIDATE_EMAIL_TEXT,
    email,
  };
}

function onChangeCandidateExperienceYears(years) {
  return {
    type: ON_CHANGE_CANDIDATE_EXPERIENCE_YEARS,
    years,
  };
}

function onChangeCandidateExperienceMonths(months) {
  return {
    type: ON_CHANGE_CANDIDATE_EXPERIENCE_MONTHS,
    months,
  };
}

function onChangeCandidatePrimarySkillList(list) {
  return {
    type: ON_CHANGE_CANDIDATE_PRIMARY_SKILL_LIST,
    list,
  };
}

function onChangeApplicantLinkedInProfileLink(value) {
  return {
    type: ON_CHANGE_CANDIDATE_LINKEDIN_PROFILE_LINK,
    value,
  };
}

function onChangeLocationOption({ id }) {
  //console.log('here candidate');
  return {
    type: ON_CHANGE_LOCATION_OPTION,
    id,
  };
}

function onChangeRemoteWorkOption({ id }) {
  //console.log('here remote');
  return {
    type: ON_CHANGE_REMOTE_WORK_OPTION,
    id,
  };
}

function onChangeModalStatusOption(value) {
  //console.log('here for Modal');
  return {
    type: ON_CHANGE_MODAL_STATUS_OPTION,
    value,
  };
}

function onChangeVerificationStatusOption(value) {
  //console.log('here for Verification');
  return {
    type: ON_CHANGE_VERIFICATION_STATUS_OPTION,
    value,
  };
}


function onChangeCandidateSecondarySkillList(list) {
  return {
    type: ON_CHANGE_CANDIDATE_SECONDARY_SKILL_LIST,
    list,
  };
}

function onChangeCandidatePassword(password) {
  return {
    type: ON_CHANGE_CANDIDATE_PASSWORD,
    password,
  };
}

function onFileScanning(status) {
  return {
    type: ON_FILE_SCANNING,
    status,
  };
}

function updateResumeId(resumeId) {
  return {
    type: UPDATE_RESUME_ID,
    resumeId,
  };
}

function onFileUpload(file) {
  return {
    type: ON_UPLOAD_FILE,
    file,
  };
}

function onFileUploadError(value) {
  return {
    type: ON_UPLOAD_FILE_ERROR,
    value,
  };
}

function onRemoveUploadedFile(value, isUploaded) {
  return {
    type: ON_REMOVE_UPLOADED_FILE,
    value,
    isUploaded,
  };
}
//reducers
const candidateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...defaultState,
      };
    case UPDATE_NOTIFICATION_LIST:
      let NotifiactionListLoaded = false;
      if (action.status === false) {
        NotifiactionListLoaded = true;
      }
      const updatedNotifictaionList =
        action.notificationList &&
        action.notificationList.map((value, index) => {
          return {
            ...value,
            notificationId: index + 1,
          };
        });
      const notificationList = updatedNotifictaionList
        ? [...updatedNotifictaionList]
        : [];
      return {
        ...state,
        loadingNotificationList: action.status,
        NotifiactionListLoaded,
        notificationList,
      };
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        NotifiactionListLoaded: action.status,
      };
    case UPDATE_NOTIFICATION_COUNT:
      let NotifiactionCountLoaded = false;
      if (action.status === false) {
        NotifiactionCountLoaded = true;
      }
      const notificationCount = action.count ? action.count : 0;
      return {
        ...state,
        loadingNotificationCount: action.status,
        NotifiactionCountLoaded,
        notificationCount,
      };
    case RESET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: 0,
      };
    case UPDATE_CANDIDATE_FORM_CAPTCHA:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          captcha: action.captcha,
        },
      };
    case UPDATE_REDEEM_VOUCHER_TRANSACTION_LIST_DATA:
      let redeemVoucherTransactionListLoaded = false;
      if (action.status === false) {
        redeemVoucherTransactionListLoaded = true;
      }
      let details = action.data ? [...action.data] : [];
      return {
        ...state,
        redeemVoucherTransactionListLoaded,
        loadingredeemVoucherTransactionList: action.status,
        voucherTransactionList: details,
      };
    case UPDATE_REFERRAL_REWARDS_POINTS_LIST_DATA:
      let referralRewardsPointsListLoaded = false;
      if (action.status === false) {
        referralRewardsPointsListLoaded = true;
      }
      const referralPathwayList = action.data ? { ...action.data } : null;
      return {
        ...state,
        referralRewardsPointsListLoaded,
        referralPathwayList,
        loadingReferralRewardsPointsList: action.status,
      };
    case UPDATE_ASSESSMENT_HISTORY_LIST_DATA:
      let assessmentHistoryListLoaded = false;
      if (action.status === false) {
        assessmentHistoryListLoaded = true;
      }
      const _assessmentHistoryList = action.assessmentHistoryListDetails
        ? [...action.assessmentHistoryListDetails]
        : null;
      return {
        ...state,
        assessmentHistoryListLoaded,
        loadingAssessmentHistoryList: action.status,
        assessmentHistoryList: _assessmentHistoryList,
      };
    case ON_UPDATE_REGISTER_FORM_DETAILS_DATA:
      let registerFormDetailsLoaded = false;
      if (action.status === false) {
        registerFormDetailsLoaded = true;
      }
      let _selectedFile = {};
      let _linkedInProfileLink =
        action.registerFormDetails &&
          action.registerFormDetails.socialDetails &&
          action.registerFormDetails.socialDetails.length > 0
          ? action.registerFormDetails.socialDetails[0].value
          : "";
      let registerDetails = action.registerFormDetails
        ? { ...action.registerFormDetails }
        : {};
      let phoneNumber =
        registerDetails &&
        registerDetails.contactDetails &&
        registerDetails.contactDetails.phoneNumbers.find(
          (value) => value.isPrimary === true
        );
      registerDetails.contactNumber = phoneNumber
        ? phoneNumber.number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3")
        : "";
      registerDetails.resumeId =
        action.registerFormDetails &&
          action.registerFormDetails.resumeDetails.resumeId
          ? action.registerFormDetails &&
          action.registerFormDetails.resumeDetails.resumeId
          : "";
      registerDetails.linkedInProfileLink = _linkedInProfileLink;
      registerDetails.relocatOption =
        action.registerFormDetails &&
          action.registerFormDetails.willingToRelocate === true
          ? 1
          : 2;

      registerDetails.remoteWorkcms =
        action.registerFormDetails &&
          action.registerFormDetails.remoteWork === true
          ? 1
          : 2;
      // registerDetails.phoneNumber = phoneNumber ? phoneNumber.number : "";
      registerDetails.phoneNumber = action.registerFormDetails && action.registerFormDetails.phoneNumber;
      if (
        action.registerFormDetails &&
        action.registerFormDetails.resumeDetails.resumeId
      ) {
        _selectedFile = {
          file: {
            name: action.registerFormDetails.resumeDetails.name,
            resumeId: action.registerFormDetails.resumeDetails.resumeId,
          },
          isUploaded: true,
          isFileScanning: null,
        };
      } else {
        _selectedFile = {
          file: null,
          isUploaded: false,
          isFileScanning: null,
        };
      }
      return {
        ...state,
        registerFormDetailsLoaded,
        loadingRegisterFormDetails: action.status,
        registerFormDetails: _updateCandidateDetailsObject(
          state.registerFormDetails,
          registerDetails
        ),
        selectedFile: _updateCandidateDetailsObject(
          state.registerFormDetails,
          _selectedFile
        ),
      };
    case CANDIDATE_LOGIN_AUTHENTICATION:
      return {
        ...state,
        isAuthenticating:
          action.authenticationDetails.isAuthenticating || false,
        isAuthenticated: action.authenticationDetails.isAuthenticated || false,
        invalidCredentials:
          action.authenticationDetails.invalidCredentials || false,
      };
    case ON_CHANGE_CANDIDATE_PAGINATE:
      return {
        ...state,
        pageId: action.pageId,
      };

    //firstName
    case ON_CHANGE_CANDIDATE_FIRST_NAME_TEXT:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          firstName: action.firstName,
        },
      };
    //lastName
    case ON_CHANGE_CANDIDATE_LAST_NAME_TEXT:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          lastName: action.lastName,
        },
      };
    //LinkedIn
    case ON_CHANGE_CANDIDATE_LINKEDIN_PROFILE_LINK:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          linkedInProfileLink: action.value,
        },
      };
    case ON_CHANGE_LOCATION_OPTION:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          relocatOption: action.id,
        },
      };
    case ON_CHANGE_REMOTE_WORK_OPTION:
      // console.log(action.id);
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          remoteWorkcms: action.id,
        },
      };
    case ON_CHANGE_MODAL_STATUS_OPTION:
      // console.log(action.id);  
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          ModalCms: action.value,
        },
      };
    case ON_CHANGE_VERIFICATION_STATUS_OPTION:
      // console.log(action.id);  
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          isVerified: action.value,
        },
      };
    //Email-id
    case ON_CHANGE_CANDIDATE_EMAIL_TEXT:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          email: action.email.trim(),
          ModalCms: false
        },
      };
    //contact number
    case ON_CHANGE_CANDIDATE_CONTACT_NUMBER:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          contactNumber: action.contactNumber,
        },
      };
    //IE11 phone number change
    case EDIT_PROFILE_PHONE_NUMBER_CHANGE:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          phoneNumber: action.value,
        },
      };
    //experience-years
    case ON_CHANGE_CANDIDATE_EXPERIENCE_YEARS:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          experience: {
            ...state.registerFormDetails.experience,
            years: action.years,
          },
        },
      };
    //experience-months
    case ON_CHANGE_CANDIDATE_EXPERIENCE_MONTHS:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          experience: {
            ...state.registerFormDetails.experience,
            months: action.months,
          },
        },
      };
    //primary skills
    case ON_CHANGE_CANDIDATE_PRIMARY_SKILL_LIST:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          primarySkills: [...action.list],
        },
      };
    //secondary skills
    case ON_CHANGE_CANDIDATE_SECONDARY_SKILL_LIST:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          secondarySkills: [...action.list],
        },
      };
    //on password
    case ON_CHANGE_CANDIDATE_PASSWORD:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          password: action.password,
        },
      };
    //on file upload
    case ON_UPLOAD_FILE:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          file: action.file,
        },
      };
    //on file upload error
    case ON_UPLOAD_FILE_ERROR:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          isUploadedError: action.value,
        },
      };
    //update resume id
    case UPDATE_RESUME_ID:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          resumeId: action.resumeId,
        },
        selectedFile: {
          ...state.selectedFile,
          isUploaded: true,
        },
      };
    //on remove upload id
    case ON_REMOVE_UPLOADED_FILE:
      return {
        ...state,
        selectedFile: {
          file: null,
          isUploaded: false,
          isFileScanning: null,
        },
        registerFormDetails: {
          ...state.registerFormDetails,
          resumeId: "",
        },
      };
    //on file scanning
    case ON_FILE_SCANNING:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          isFileScanning: action.status,
        },
      };
    //on preferred location
    case ON_CHANGE_PREFERRED_LOCATION:
      return {
        ...state,
        registerFormDetails: {
          ...state.registerFormDetails,
          preferredLocation: action.locationDetails,
        },
      };

    case ON_CHANGE_SHOW_PASSWORD:
      return {
        ...state,
        showPassword: action.value
      };
    case UPDATE_UNSUBSCRIBE_OPTIONS:
      let modifiedPerecesOption = action.options.emailPreferenceList.map(
        (option) => {
          let modifiedOption = {
            id: option.emailPreferenceTypeId,
            value: option.name,
            type: option.__typename,
          };
          return modifiedOption;
        }
      );
      let modifiedReasons = action.options.unsubscribeReasonList.map(
        (option) => {
          let modifiedOption = {
            id: option.unsubscribeEmailReasonId,
            label: option.name,
            value: option.name,
            type: option.__typename,
          };
          return modifiedOption;
        }
      );
      return {
        ...state,
        loadingOption: true,
        perferencesOptions: modifiedPerecesOption,
        reasons: modifiedReasons,
        vendorDetails: action.options.vendorDetails,
      };
    case UNSUBSCRIBE_LOADING:
      return {
        ...state,
        loadingOption: action.status,
      };
    case ON_CHANGE_ASSESSMENT_ANSWERS:
      return {
        ...state,
        assessmentSelectedAnswers: action.selectedAnswers,
      };
    case ASSESSMENT_TIME_UP_STATUS_CHANGE:
      return {
        ...state,
        assessmentTimeUpReminder: action.status,
      };
    case ASSESSMENT_QUESTIONS_LIST_DATA:
      const _assessmentQuestionsListDetails = {};

      let assessmentQuestionListLoaded = false;
      if (action.status === false) {
        assessmentQuestionListLoaded = true;
      }
      _assessmentQuestionsListDetails.subjectTitle = action.questionsListDetails
        ? action.questionsListDetails.displayName
        : "";
      _assessmentQuestionsListDetails.jobTitle = action.questionsListDetails
        ? action.questionsListDetails.jobTitle
        : "";
      _assessmentQuestionsListDetails.test = action.questionsListDetails
        ? action.questionsListDetails.test
        : {};
      _assessmentQuestionsListDetails.score = action.questionsListDetails
        ? action.questionsListDetails.score
        : "";
      _assessmentQuestionsListDetails.startTime = action.questionsListDetails
        ? action.questionsListDetails.startTime
        : "";
      _assessmentQuestionsListDetails.endTime = action.questionsListDetails
        ? action.questionsListDetails.endTime
        : "";
      _assessmentQuestionsListDetails.attemptedQuestions = action.questionsListDetails
        ? action.questionsListDetails.attemptedQuestions
        : "";
      _assessmentQuestionsListDetails.totalQuestions = action.questionsListDetails
        ? action.questionsListDetails.totalQuestions
        : 0;
      _assessmentQuestionsListDetails.remainingTimeInMinutes = action.questionsListDetails
        ? action.questionsListDetails.remainingTimeInMinutes
        : "";
      return {
        ...state,
        assessmentQuestionListLoaded,
        assessmentQuestionsListDetails: { ..._assessmentQuestionsListDetails },
        loadingAssissmentQuestionsList: action.status,
      };
    case STOP_CLOCK_TIMER:
      return {
        ...state,
        stopClock: action.status,
      };

    default:
      return state;
  }
};

export {
  resetState,
  onFileUpload,
  onFileScanning,
  updateResumeId,
  stopClockUpdate,
  candidateReducer,
  onFileUploadError,
  onChangePagination,
  disabledRadioButton,
  getNotificationList,
  onRemoveUploadedFile,
  updateNotificationList,
  resetNotificationCount,
  onChangeLocationOption,
  onChangeRemoteWorkOption, // remote work yes or no option.
  onChangeModalStatusOption, // remote work yes or no option.
  updateNotificationCount,
  updateUnsubscribeOption,
  onChangeAssessmentAnswers,
  onChangePreferredLocation,
  loadingUnsubscribeOptions,
  onChangeCandidatePassword,
  updateCandidateFormCaptcha,
  onChangeCandidateEmailText,
  editProfilePhoneNumberChange,
  updateRegisterFormDetailsData,
  _updateCandidateDetailsObject,
  onChangeCandidateLastNameText,
  onChangeCandidateFirstNameText,
  authenticationStatusDispatcher,
  onChangeCandidateContactNumber,
  updateAssessmentHistoryListData,
  onChangeCandidateExperienceYears,
  onChangeCandidateExperienceMonths,
  onChangeCandidatePrimarySkillList,
  updateReferralRewardsPointsListData,
  onChangeCandidateSecondarySkillList,
  onChangeApplicantLinkedInProfileLink,
  updateQuestionsListUsingCandidateCode,
  updateRedeemVoucherTransactionListData,
  onChangeVerificationStatusOption,
  onChangeShowPassword
};
