import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const MyProfileComponentAsync = lazyLoad(() => {
    return import('./components/MyProfile');
});

const routePath = '/:vendorUUID/candidate/:accountId/details';

const MyProfileRouteDetails = {
    name: 'myProfile',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName = 'myProfile'
            name = {MyProfileRouteDetails.name}
            desktopComponent = {MyProfileComponentAsync}
            mobileComponent = {MyProfileComponentAsync} 
        />
    }
};

export { 
    MyProfileRouteDetails
};