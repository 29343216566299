import React from 'react';
// import CareerLayout from '../../shared/CareerLayout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';
import './shared/CareerDetails.scss';

const CareerDetailsComponentAsync = lazyLoad(() => {
    return import('./components/CareerDetails');
});

const routePath = '/careers/:uuid/:positionKey/details';

const careerDetailsRouteDetails = {
    name: 'careerDetails',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName="candidateDashboard" 
            name= {careerDetailsRouteDetails.name}
            desktopComponent= {CareerDetailsComponentAsync}
            mobileComponent= {CareerDetailsComponentAsync} />
    }
};

export { careerDetailsRouteDetails as CareerDetailsRouteDetails };