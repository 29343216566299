import React, { Component } from 'react';
// import axios from 'axios';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import ErrorStore from '../../../../shared/utils/ErrorStore';
import { onResumeUploadedError } from '../stores/ChatbotStore';
import { Field, SubmissionError, reduxForm } from 'redux-form';
// import { FILE_API_URL, API_URL } from '../../../../../constants';
import loader from '../../../../shared/images/scanning-icon.gif';
import { required, email } from '../../../../shared/utils/Validator';
import Calendar from '../../../../shared/components/Calendar/Calendar';
import Checkbox from '../../../../shared/components/Checkbox/Checkbox';
import InputField from '../../../../shared/components/InputField/InputField';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import './universityForm.scss';

class UniversityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            notes: "",
            emailId: "",
            resumeId: "",
            lastName: "",
            formError: {},
            visitDate: "",
            firstName: "",
            phoneNumber: "",
            companyName: "",
            employeeName: "",
            onSubmitSuceessfull: false,
            takingClassesWhileInHighSchool: false,
            takingClassesAfterGraduate: false
        }
        this.disable = false;
        this.fileUploading = false;
        this._validate = this._validate.bind(this);
        this.onDateSelect = this.onDateSelect.bind(this)
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onSubmitApply = this.onSubmitApply.bind(this);
        this.setDetailsObjects = this.setDetailsObjects.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this._onPhoneNumberFocus = this._onPhoneNumberFocus.bind(this);
        this._onChangePhoneNumberText = this._onChangePhoneNumberText.bind(this);
        this.onChangeTakingClassesInHighSchool = this.onChangeTakingClassesInHighSchool.bind(this);
        this.onChangeTakingClassesAfterGraduate = this.onChangeTakingClassesAfterGraduate.bind(this);
    }

    onChangeTakingClassesAfterGraduate(status) {
        this.setState({
            takingClassesAfterGraduate: status
        })
    }
    onChangeTakingClassesInHighSchool(status) {
        this.setState({
            takingClassesWhileInHighSchool: status
        })
    }
    onDateSelect(value) {
        this.setState({ visitDate: value })
    }
    onChangeInputValue(type, value) {
        switch (type) {
            case "firstName":
                this.setState({ firstName: value });
                break;
            case "lastName":
                this.setState({ lastName: value });
                break;
            case "email":
                this.setState({ emailId: value });
                break;
            case "notes":
                this.setState({ notes: value });
                break;
            case "link":
                this.setState({ link: value });
                break;
            case "phoneNumber":
                this.setState({ phoneNumber: value });
                break;
            case "companyName":
                this.setState({ companyName: value });
                break;
            case "employeeName":
                this.setState({ employeeName: value });
                break;
            default:
                break;
        }
    }
    _onChangePhoneNumberText(value) {
        let _value = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
        this.setState({ phoneNumber: _value });
    }
    _onPhoneNumberFocus(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        this.setState({ phoneNumber: _modifiedValue });
    }
    onSubmitApply() {
        const { formTitle } = this.props
        let errors = null;
        const submitError = this._validate();
        if (submitError) {
            errors = { ...(errors || {}), ...submitError };
        }
        if (errors) {
            this.setState({
                formError: errors,
            })
            throw new SubmissionError({ ...errors });
        } else {
            this.disable = true;
            this.onSubmitForm(formTitle);
        }
    }
    onSubmitForm(formTitle) {
        this.disable = true
        this.setState({
            onSubmitSuceessfull: true
        })

        setTimeout(_ => {
            this.disable = false
            this.setState({ onSubmitSuceessfull: false })
            this.props.onSubmitUniversityForm({
                type: "text",
                message: "Thank you, Your form is submitted sucessfully."
            })
        }, 2000)
        // let apiUrl;
        // const baseUrl = `${API_URL}/chatbot/`
        // if (formTitle === "Submit Requirements / RFX") {
        //     apiUrl = `${baseUrl}rfx`;
        // } else if (formTitle === "Contact Form") {
        //     apiUrl = `${baseUrl}contact`;
        // } else if (formTitle === "Create Ticket") {
        //     apiUrl = `${baseUrl}ticket`;
        // }
        // fetch(apiUrl, {
        //     method: "POST",
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(details)
        // }).then((res) => {
        //     if (res.ok) {
        //         res.json()
        //             .then((data) => {
        //                 this.props.onSubmitRequirementsFormSuccess(data.data);
        //             })
        //             .catch(err => console.log("error", err));
        //     }
        // })
    }
    setDetailsObjects() {
        const { formTitle } = this.props;
        const {
            notes,
            emailId,
            lastName,
            firstName,
            visitDate,
            phoneNumber,
            takingClassesAfterGraduate,
            takingClassesWhileInHighSchool,
        } = this.state;
        let details = {};

        details.email = emailId;
        details.firstName = firstName.trim();
        details.lastName = lastName.trim();
        if (formTitle !== "request for campus visit") { details.notes = notes }
        if (formTitle === "request for campus visit") {
            details.phoneNumber = this.removeNumberFormat(phoneNumber);
            details.visitDate = visitDate;
            details.takingClassesAfterGraduate = takingClassesAfterGraduate;
            details.takingClassesWhileInHighSchool = takingClassesWhileInHighSchool;
        }

        return details;
    }
    removeNumberFormat(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        return _modifiedValue
    }
    _validate() {
        const {
            notes,
            emailId,
            firstName,
            visitDate,
        } = this.state;

        const { formTitle } = this.props;
        const errorInstance = ErrorStore();

        errorInstance.add('firstName', firstName, [required]);
        errorInstance.add('email', emailId, [required, email]);

        if (formTitle !== "request for campus visit") {
            errorInstance.add('notes', notes, [required]);
        }
        if (formTitle === "request for campus visit") {
            errorInstance.add('visitDate', visitDate, [required])
        }
        return errorInstance.get();
    }

    render() {
        const {
            notes,
            emailId,
            formError,
            firstName,
            lastName,
            phoneNumber,
            onSubmitSuceessfull
        } = this.state;

        const { formTitle, handleSubmit, formRef = null, clientConfig } = this.props;

        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        let trident = ua.indexOf('Trident/');
        let edge = ua.indexOf('Edge/');

        const formThemeBgColor = {
            color: clientConfig.style.formTitleFontColor,
            background: clientConfig.style.serverResponseBgColor
        }

        const submitBtnStyle = {
            color: clientConfig.style.buttonFontColor,
            background: clientConfig.style.buttonBackgroundColor
        }

        const calendarStyle = {
            color: clientConfig.style.userResponseBgColor,
            background: clientConfig.style.userResponseBgColor
        }

        return (
            <div className="form-and-loader-parent">
                <div style={formThemeBgColor} className={onSubmitSuceessfull ? "add-opacity university-form" : "university-form"} ref={formRef}>
                    <div style={formThemeBgColor} className="university-form__heading">
                        <h6> {formTitle}</h6>
                    </div>
                    <div className="university-form__details">
                        <form id="UniversityFrom" name='UniversityFrom'>
                            <div className={formError.hasOwnProperty('firstName')
                                ? 'fieldset error-field' : 'fieldset'}>
                                <div style={formThemeBgColor} className='label'>
                                    First Name <span> * </span>
                                </div>
                                <div className="form-fields">
                                    <Field
                                        type="text"
                                        maxLength="50"
                                        id="first-name"
                                        name="firstName"
                                        regex="^[a-zA-Z '-]+$"
                                        component={InputField}
                                        inputFieldValue={firstName}
                                        onFieldValueChange={(value) =>
                                            this.onChangeInputValue("firstName", value)}
                                    />
                                </div>
                            </div>
                            <div className="fieldset remove-requried-field">
                                <div style={formThemeBgColor} className='label'>
                                    Last Name
                                </div>
                                <div className="form-fields">
                                    <Field
                                        type="text"
                                        maxLength="50"
                                        id="first-name"
                                        name="firstName"
                                        isRequired={false}
                                        regex="^[a-zA-Z '-]+$"
                                        component={InputField}
                                        inputFieldValue={lastName}
                                        onFieldValueChange={(value) =>
                                            this.onChangeInputValue("lastName", value)}
                                    />
                                </div>
                            </div>
                            <div className={formError.hasOwnProperty('email')
                                ? 'fieldset error-field' : 'fieldset'}>
                                <div style={formThemeBgColor} className='label'> E-mail Address <span> * </span> </div>
                                <div className="form-fields">
                                    <Field
                                        id="email"
                                        type="text"
                                        name="email"
                                        maxLength="50"
                                        component={InputField}
                                        inputFieldValue={emailId}
                                        onFieldValueChange={(value) =>
                                            this.onChangeInputValue("email", value)}
                                    />
                                </div>
                            </div>
                            {
                                formTitle === "request for campus visit" ?
                                    (
                                        <div className="fieldset remove-requried-field">
                                            <div style={formThemeBgColor} className='label'> Phone </div>
                                            <div className="form-fields">
                                                {
                                                    edge > 0 || trident > 0 || msie > 0 ?
                                                        <Field
                                                            min="0"
                                                            type="text"
                                                            minLength="10"
                                                            maxLength="10"
                                                            regex='[0-9\b]+$'
                                                            id="phone-number"
                                                            name="phoneNumber"
                                                            component={InputField}
                                                            inputFieldValue={phoneNumber}
                                                            onFieldValueChange={(value) =>
                                                                this.onChangeInputValue("phoneNumber", value)}
                                                        /> :
                                                        <Field
                                                            min="0"
                                                            type="text"
                                                            minLength="10"
                                                            maxLength="10"
                                                            regex='[0-9\b]+$'
                                                            id="phone-number"
                                                            name="phoneNumber"
                                                            component={InputField}
                                                            inputFieldValue={phoneNumber}
                                                            onInputFocus={this._onPhoneNumberFocus}
                                                            onInputChangeFocus={this._onChangePhoneNumberText}
                                                            onFieldValueChange={(value) =>
                                                                this.onChangeInputValue("phoneNumber", value)}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    ) : null
                            }
                            {
                                formTitle !== "request for campus visit" ?
                                    <div className={formError.hasOwnProperty('notes')
                                        ? 'fieldset error-field notes-fieldset' : 'fieldset notes-fieldset'}>
                                        <div style={formThemeBgColor} className='label'>Comments <span> * </span> </div>
                                        <div className="form-fields">
                                            <Field
                                                id="notes"
                                                name="notes"
                                                type="textarea"
                                                component={InputField}
                                                inputFieldValue={notes}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("notes", value)}
                                            />
                                        </div>
                                    </div> : null
                            }
                            {
                                formTitle === "request for campus visit" ?
                                    <div className={formError.hasOwnProperty('visitDate')
                                        ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'>
                                            Visit Date <span> * </span>
                                        </div>
                                        <div className="form-fields">
                                            <Calendar
                                                calendarStyle={calendarStyle}
                                                onDateSelect={value => this.onDateSelect(value)} />
                                        </div>
                                        {
                                            formError.hasOwnProperty('visitDate') ? <span className="text-danger d-block">This field is required</span> : null
                                        }
                                    </div> : null
                            }
                            {
                                formTitle === "request for campus visit" ?
                                    <div className="checkboxes">
                                        <Checkbox onChangeStatus={status => this.onChangeTakingClassesInHighSchool(status)}
                                            text={"I am interested in taking college classes while I am in high school"} />
                                        <Checkbox onChangeStatus={status => this.onChangeTakingClassesAfterGraduate(status)}
                                            text={"I am interested in enrolling in WVU Parkersburg after I graduate from high school"} />
                                    </div> : null
                            }
                            <div className="submit-btn-div">
                                <ActionButton
                                    themeStyle={submitBtnStyle}
                                    type="button"
                                    title="submit"
                                    className='submit-btn'
                                    showDisabledActionButton={this.disable}
                                    onClick={handleSubmit(_ => this.onSubmitApply())} />
                            </div>
                        </form>
                    </div>
                </div>
                {
                    onSubmitSuceessfull ? (
                        <div className="loader-div">
                            <img src={loader} alt="loader" />
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

UniversityForm = reduxForm({
    'form': 'UniversityForm',
    'enableReinitialize': true
})(UniversityForm);

const mapStateToProps = ({ chatbot }) => {
    return {
        isResumeUploadedError: chatbot.isResumeUploadedError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onResumeUploadedError(value) {
            dispatch(onResumeUploadedError(value))
        }
    }
}

const UniversityFormApollo = withApollo(UniversityForm);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UniversityFormApollo));

