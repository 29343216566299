import React from 'react'
import Typing from '../../../../shared/images/simple_loading.gif';
import '../shared/ChatbotText.scss';

export default function ChatbotTyping() {
    return (
        <div className="chatbot-text">
            <span><img width="30px" src={Typing} alt="typing" /> </span>
        </div>
    )
}

