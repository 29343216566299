import React from 'react';
import Chatbot from './src/App/screens/Chatbot';
import { Provider } from 'react-redux';
import ApplloClient from './src/App/config/ApolloClient';
import ReduxStore from './src/App/config/Redux';
import { ApolloProvider } from 'react-apollo';

export default function ChatbotClient({
    clientType,
    UUID,
    baseUrl
}) {
    return (
        <ApolloProvider client={ApplloClient}>
            <Provider store={ReduxStore}>
                <Chatbot clientType={clientType} UUID={UUID} baseUrl={baseUrl} />
            </Provider>
        </ApolloProvider>
    )
}