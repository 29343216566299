const defaultState = {
  applyNowPopup: true,
  loadingCareerList: false,
  careerListLoaded: false,
  loadingCareerDetails: false,
  careerDetailsLoaded: false,
  loadingCandidateDetails: false,
  candidateDetailsLoaded: false,
  totalCount: 0,
  positionList: [],
  vendorUUID: null,
  vendorFileUUID: null,
  shareJobUrlLoaded: false,
  loadingShareJobUrl: false,
  vendorCareerPageShortUrl: null,
  isCareerPageShareJobModalOpen: false,
  candidateCareerPageShortUrl: null,
  vendorName: null,
  filterOptions: {},
  hotPositionList: [],
  sortByOptions: [
    {
      value: "Date Published(Newest First)",
      label: "Date Published(Newest First)",
      type: "sortBy",
      id: 1,
    },
    {
      value: "Date Published(Oldest First)",
      label: "Date Published(Oldest First)",
      type: "sortBy",
      id: 2,
    },
  ],
  filteredJobCategoryItems: [],
  filteredSearchKeys: [],
  filteredLocationSearchKeys: [],
  filteredPayTypeItems: [],
  filteredCountryListItems: [],
  filteredSortByItem: [],
  selectedFilteredItems: [],
  careerDesktopFilterData: {
    jobDepartments: [],
    payTypes: [],
    sortBy: 1,
    pageId: 1,
  },
  showMobileFilter: false,
  filters: [
    {
      name: "jobCategory",
      displayName: "Job Category",
      label: "Job Category",
    },
    {
      name: "payType",
      displayName: "Pay Type",
      label: "Pay Type",
    },
    {
      name: "byCountry",
      displayName: "By Country",
      label: "By Country",
    },
  ],
  sortByFilter: {
    name: "sortBy",
    displayName: "Sort By",
    label: "Sort By",
  },
  selectedMobileFilterItem: "byCountry",
  selectedSortByOption: 1,
  selectedJobCategoryOptions: [],
  selectedPayTypeOptions: [],
  currentPage: 0,
  limit: 10,
  careerListPageId: 1,
  positionDetails: null,
  careerDetails: {
    referalDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      ModalCms1: false,
      isVerified1: false,
    },
    applicantDetails: {
      firstName: "",
      lastName: "",
      email: "",
      alternateEmail: "",
      phone: "",
      experience: {
        years: "",
        months: "",
      },
      linkedInProfileLink: "",
      resumeId: "",
      keySkills: [],
      primarySkills: [],
      secondarySkills: [],
      coverLetter: "",
      captcha: "",
      location: {},
      relocatOption: 2,
      ModalCms: false,
      isVerified: false,
    },
  },
  careerDetailsAuto: {
    referalDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    applicantDetails: {
      firstName: "",
      lastName: "",
      email: "",
      alternateEmail: "",
      phone: "",
      experience: {
        years: "",
        months: "",
      },
      linkedInProfileLink: "",
      resumeId: "",
      keySkills: [],
      primarySkills: [],
      secondarySkills: [],
      coverLetter: "",
      captcha: "",
      location: {},
      relocatOption: 2,
      ModalCms: false,
      isVerified: false,
    },
  },
  selectedFile: {
    file: null,
    isUploaded: false,
    isFileScanning: null,
    isUploadedError: false,
  },
  isJobPositionClosed: false,
  isApplicantAdded: false,
  hasApplicantAlreadyAppliedForJobPosition: false,
  isPositionSkillsOptionsLoaded: false,
  isPositionPrimarySkillsOptionsLoaded: false,
  isPositionSecondarySkillsOptionsLoaded: false,
  skillsOptions: [],
  primarySkillsOptions: [],
  secondarySkillsOptions: [],
  isApplicantAddPageLoaded: false,
  yesNoOptions: [
    {
      id: 2,
      label: "No",
    },
    {
      id: 1,
      label: "Yes",
    },
  ],
  ModalCms: false,
  isVerified: false,
  socialPlatformLink:"https://www.facebook.com/Infoijiniconsulting"
};

const RESET_STATE = "RESET_STATE";
const APPLY_POPUP = "APPLY_POPUP";
const ON_UPLOAD_FILE = "ON_UPLOAD_FILE";
const APPLICANT_ADDED = "APPLICANT_ADDED";
const UPDATE_RESUME_ID = "UPDATE_RESUME_ID";
const ON_FILE_SCANNING = "ON_FILE_SCANNING";
const ON_FIND_JOBS_CLICK = "ON_FIND_JOBS_CLICK";
const ON_CHANGE_LOCATION = "ON_CHANGE_LOCATION";
const LOADING_CAREER_LIST = "LOADING_CAREER_LIST";
const SKILL_OPTIONS_LOADED = "SKILL_OPTIONS_LOADED";
const ON_CHANGE_EMAIL_TEXT = "ON_CHANGE_EMAIL_TEXT";
const UPDATE_REFERRAL_DATA = "UPDATE_REFERRAL_DATA";
const ON_UPLOAD_FILE_ERROR = "ON_UPLOAD_FILE_ERROR";
const SKILL_OPTIONS_UPDATE = "SKILL_OPTIONS_UPDATE";
const UPDATE_APPLICANT_DATA = "UPDATE_APPLICANT_DATA";
const ON_CHANGE_COVER_LETTER = "ON_CHANGE_COVER_LETTER";
const ON_CHANGE_PHONE_NUMBER = "ON_CHANGE_PHONE_NUMBER";
const LOADING_CAREER_DETAILS = "LOADING_CAREER_DETAILS";
const ON_CAREER_LIST_PAGINATE = "ON_CAREER_LIST_PAGINATE";
const ON_FETCH_CAREER_DETAILS = "ON_FETCH_CAREER_DETAILS";
const ON_REMOVE_UPLOADED_FILE = "ON_REMOVE_UPLOADED_FILE";
const ON_CHANGE_LAST_NAME_TEXT = "ON_CHANGE_LAST_NAME_TEXT";
const ON_CHANGE_FIRST_NAME_TEXT = "ON_CHANGE_FIRST_NAME_TEXT";
const ON_CHANGE_SELECTED_SKILLS = "ON_CHANGE_SELECTED_SKILLS";
const UPDATE_JOB_POSITION_CLOSED = "UPDATE_JOB_POSITION_CLOSED";
const GET_SELECTED_FILTERED_ITEMS = "GET_SELECTED_FILTERED_ITEMS";
const PRIMARY_SKILL_OPTIONS_LOADED = "PRIMARY_SKILL_OPTIONS_LOADED";
const PRIMARY_SKILL_OPTIONS_UPDATE = "PRIMARY_SKILL_OPTIONS_UPDATE";
const UPDATE_APPLICANT_FORM_CAPTCHA = "UPDATE_APPLICANT_FORM_CAPTCHA";
const CLEAR_ALL_CAREER_LIST_FILTERS = "CLEAR_ALL_CAREER_LIST_FILTERS";
const SECONDARY_SKILL_OPTIONS_LOADED = "SECONDARY_SKILL_OPTIONS_LOADED";
const ON_CHANGE_APPLICANT_EMAIL_TEXT = "ON_CHANGE_APPLICANT_EMAIL_TEXT";
const ON_CHANGE_CAREER_SEARCH_FILTER = "ON_CHANGE_CAREER_SEARCH_FILTER";
const SECONDARY_SKILL_OPTIONS_UPDATE = "SECONDARY_SKILL_OPTIONS_UPDATE";
const GET_MOBILE_FILTER_SELECTED_ITEM = "GET_MOBILE_FILTER_SELECTED_ITEM";
const ON_CHANGE_CAREER_SORT_BY_FILTER = "ON_CHANGE_CAREER_SORT_BY_FILTER";
const CANDIDATE_SHARE_JOB_MODAL_STATUS = "CANDIDATE_SHARE_JOB_MODAL_STATUS";
const ON_CHANGE_APPLICANT_PHONE_NUMBER = "ON_CHANGE_APPLICANT_PHONE_NUMBER";
const APPLY_CAREER_LIST_MOBILE_FILTERS = "APPLY_CAREER_LIST_MOBILE_FILTERS";
const ON_CHANGE_WILLING_LOCATION_CHANGE = "ON_CHANGE_WILLING_LOCATION_CHANGE";
const CLEAR_ALL_SELECTED_MOBILE_FILTERS = "CLEAR_ALL_SELECTED_MOBILE_FILTERS";
const ON_CHANGE_SELECTED_PRIMARY_SKILLS = "ON_CHANGE_SELECTED_PRIMARY_SKILLS";
const ON_CHANGE_APPLICANT_LAST_NAME_TEXT = "ON_CHANGE_APPLICANT_LAST_NAME_TEXT";
const ON_CHANGE_APPLICANT_FIRST_NAME_TEXT =
  "ON_CHANGE_APPLICANT_FIRST_NAME_TEXT";
const ON_CHANGE_SELECTED_SECONDARY_SKILLS =
  "ON_CHANGE_SELECTED_SECONDARY_SKILLS";
const SHOW_HIDE_MOBILE_CAREER_LIST_FILTER =
  "SHOW_HIDE_MOBILE_CAREER_LIST_FILTER";
const ON_CHANGE_APPLICANT_EXPERIENCE_YEARS =
  "ON_CHANGE_APPLICANT_EXPERIENCE_YEARS";
const ON_CHANGE_APPLICANT_EXPERIENCE_MONTHS =
  "ON_CHANGE_APPLICANT_EXPERIENCE_MONTHS";
const ON_CHANGE_CAREER_LIST_PAY_TYPE_FILTER =
  "ON_CHANGE_CAREER_LIST_PAY_TYPE_FILTER";
const REMOVE_MOBILE_SELECTED_PAY_TYPE_OPTION =
  "REMOVE_MOBILE_SELECTED_PAY_TYPE_OPTION";
const ON_CHANGE_APPLICANT_PRIMARY_SKILL_LIST =
  "ON_CHANGE_APPLICANT_PRIMARY_SKILL_LIST";
const ON_CHANGE_CAREER_LOCATION_SEARCH_FILTER =
  "ON_CHANGE_CAREER_LOCATION_SEARCH_FILTER";
const UPDATE_APPLICANT_ADD_PAGE_LOADING_STATUS =
  "UPDATE_APPLICANT_ADD_PAGE_LOADING_STATUS";
const ON_CHANGE_APPLICANT_ALTERNATE_EMAIL_TEXT =
  "ON_CHANGE_APPLICANT_ALTERNATE_EMAIL_TEXT";
const ON_CHANGE_APPLICANT_SECONDARY_SKILL_LIST =
  "ON_CHANGE_APPLICANT_SECONDARY_SKILL_LIST";
const ON_CHANGE_CAREER_LIST_COUNTRY_LIST_FILTER =
  "ON_CHANGE_CAREER_LIST_COUNTRY_LIST_FILTER";
const ON_CHANGE_CAREER_LIST_JOB_CATEGORY_FILTER =
  "ON_CHANGE_CAREER_LIST_JOB_CATEGORY_FILTER";
const ON_CHANGE_APPLICANT_LINKEDIN_PROFILE_LINK =
  "ON_CHANGE_APPLICANT_LINKEDIN_PROFILE_LINK";
const REMOVE_MOBILE_SELECTED_JOB_CATEGORY_OPTION =
  "REMOVE_MOBILE_SELECTED_JOB_CATEGORY_OPTION";
const UPDATE_APPLICANT_ALREADY_APPLIED_FOR_JOB_POSITION =
  "UPDATE_APPLICANT_ALREADY_APPLIED_FOR_JOB_POSITION";
const UPDATE_CANDIDATE_CAREER_PAGE_SHARE_JOB_URL =
  "UPDATE_CANDIDATE_CAREER_PAGE_SHARE_JOB_URL";
const ON_CHANGE_MODAL_STATUS_OPTION = "ON_CHANGE_MODAL_STATUS_OPTION";
const ON_CHANGE_VERIFIED_STATUS_OPTION = "ON_CHANGE_VERIFIED_STATUS_OPTION";
const ON_CHNAGE_VERIFIED_STATUS_OPTION_DIRECT_APPLY = "ON_CHNAGE_VERIFIED_STATUS_OPTION_DIRECT_APPLY";
const ON_CHANGE_VERIFIED_STATUS_OPTION_REFER = "ON_CHANGE_VERIFIED_STATUS_OPTION_REFER";
const ON_CHANGE_MODAL_STATUS_OPTION_REFER_FRIEND = "ON_CHANGE_MODAL_STATUS_OPTION_REFER_FRIEND";
const ON_CLICK_SOCIAL_PLATFORM_ICON = "ON_CLICK_SOCIAL_PLATFORM_ICON"


function resetState() {
  return {
    type: RESET_STATE,
  };
}

function updateCandidateCareerPageShareJobUrl(status, url) {
  return {
    type: UPDATE_CANDIDATE_CAREER_PAGE_SHARE_JOB_URL,
    status,
    url,
  };
}


//use this function for changing modal status
function onChangeModalStatusOption(value) {
  //console.log('here for Modal');
  return {
    type: ON_CHANGE_MODAL_STATUS_OPTION,
    value,
  };
}

function dispatchApplyPopup(value){
  return{
    type: APPLY_POPUP,
    value
  }
}


//function is used for showing modal for verification. this is due to different form-logic of refer a friend
function onChangeModalStatusOptionReferFriend(value) {
  //console.log('here for Modal');
  return {
    type: ON_CHANGE_MODAL_STATUS_OPTION_REFER_FRIEND,
    value,
  };
}
//below function is used for changing the verification status which will help to validate email
//verified or not
function onChangeVerifiedStatusOption(value) {
  //console.log('here for verification', value);
  return {
    type: ON_CHANGE_VERIFIED_STATUS_OPTION,
    value,
  };
}

//below function is used for changing the verification status which will help to validate email
//verified or not for direct Apply on career page
function onChangeVerifiedStatusOptionDirectApply(value) {
  return {
    type: ON_CHNAGE_VERIFIED_STATUS_OPTION_DIRECT_APPLY,
    value
  }
}

//below function is used for changing the verification status which will help to validate email
//verified or not for refer a friend page
function onChangeVerifiedStatusOptionRefer(value) {
  console.log('here for verification refer', value);
  return {
    type: ON_CHANGE_VERIFIED_STATUS_OPTION_REFER,
    value,
  };
}


function candidateShareJobModalStatus(status) {
  return {
    type: CANDIDATE_SHARE_JOB_MODAL_STATUS,
    status,
  };
}

function onFindJobsClick() {
  return {
    type: ON_FIND_JOBS_CLICK,
  };
}

function onChangeCareerLocationSearchFilter(
  filteredLocationSearchKeys,
  loadData = true
) {
  return {
    type: ON_CHANGE_CAREER_LOCATION_SEARCH_FILTER,
    filteredLocationSearchKeys,
    loadData,
  };
}
function onChangeCareerSearchFilter(filteredSearchKeys, loadData = true) {
  return {
    type: ON_CHANGE_CAREER_SEARCH_FILTER,
    filteredSearchKeys,
    loadData,
  };
}

function onChangeCoverLetterText(coverLetter) {
  return {
    type: ON_CHANGE_COVER_LETTER,
    coverLetter,
  };
}

function onChangeApplicantPrimarySkillList(list) {
  return {
    type: ON_CHANGE_APPLICANT_PRIMARY_SKILL_LIST,
    list,
  };
}

function onChangeApplicantSecondarySkillList(list) {
  return {
    type: ON_CHANGE_APPLICANT_SECONDARY_SKILL_LIST,
    list,
  };
}

function updateCareerListLoadingStatus(
  status,
  jobDepartmentName,
  careerDetails
) {
  return {
    type: LOADING_CAREER_LIST,
    status,
    jobDepartmentName,
    careerDetails,
  };
}

function updateCareerDetailsLoadingStatus(status, details) {
  return {
    type: LOADING_CAREER_DETAILS,
    status,
    details,
  };
}

function onChangeJobCategoryFilter(filteredJobCategoryItems, loadData = true) {
  return {
    type: ON_CHANGE_CAREER_LIST_JOB_CATEGORY_FILTER,
    filteredJobCategoryItems,
    loadData,
  };
}

function onChangePayTypeFilter(filteredPayTypeItems, loadData = true) {
  return {
    type: ON_CHANGE_CAREER_LIST_PAY_TYPE_FILTER,
    filteredPayTypeItems,
    loadData,
  };
}
function onChangeCountryListFilter(filteredCountryListItems, loadData = true) {
  return {
    type: ON_CHANGE_CAREER_LIST_COUNTRY_LIST_FILTER,
    filteredCountryListItems,
    loadData,
  };
}

function onChangeSortByFilter(filteredSortByItem, loadData = true) {
  return {
    type: ON_CHANGE_CAREER_SORT_BY_FILTER,
    filteredSortByItem,
    loadData,
  };
}

function getSelectedFilterItems() {
  return {
    type: GET_SELECTED_FILTERED_ITEMS,
  };
}

function onClearAllFilters() {
  return {
    type: CLEAR_ALL_CAREER_LIST_FILTERS,
  };
}

function onChangePagination(pageIndex, isScrollEvent = false) {
  return {
    type: ON_CAREER_LIST_PAGINATE,
    pageIndex,
    isScrollEvent,
  };
}

function onChangeApplicantLocation(location) {
  return {
    type: ON_CHANGE_LOCATION,
    location,
  };
}

function onClickMobileFilter() {
  return {
    type: SHOW_HIDE_MOBILE_CAREER_LIST_FILTER,
  };
}

function onClickMobileSidebarItem(itemName) {
  return {
    type: GET_MOBILE_FILTER_SELECTED_ITEM,
    itemName,
  };
}

function removeMobileSelectedJobCategoryOption(option) {
  return {
    type: REMOVE_MOBILE_SELECTED_JOB_CATEGORY_OPTION,
    option,
  };
}

function removeMobileSelectedPayTypeOption(option) {
  return {
    type: REMOVE_MOBILE_SELECTED_PAY_TYPE_OPTION,
    option,
  };
}

function clearAllSelectedMobileFilters() {
  return {
    type: CLEAR_ALL_SELECTED_MOBILE_FILTERS,
  };
}

function onFetchCareerDetails(careerData) {
  return {
    type: ON_FETCH_CAREER_DETAILS,
    careerData,
  };
}

function onChangeFirstNameText(firstName) {
  return {
    type: ON_CHANGE_FIRST_NAME_TEXT,
    firstName,
  };
}

function onChangeLastNameText(lastName) {
  return {
    type: ON_CHANGE_LAST_NAME_TEXT,
    lastName,
  };
}

function onChangeEmailText(email) {
  return {
    type: ON_CHANGE_EMAIL_TEXT,
    email,
  };
}

function onChangePhoneNumber(phone) {
  return {
    type: ON_CHANGE_PHONE_NUMBER,
    phone,
  };
}

function applyMobileFilters() {
  return {
    type: APPLY_CAREER_LIST_MOBILE_FILTERS,
  };
}

function _updateCareerReferalDetailsObject(careerDetails, detailsToUpdate) {
  return {
    ...careerDetails,
    referalDetails: { ...careerDetails.referalDetails, ...detailsToUpdate },
  };
}

function _updateCareerApplicantDetailsObject(careerDetails, detailsToUpdate) {
  return {
    ...careerDetails,
    applicantDetails: { ...careerDetails.applicantDetails, ...detailsToUpdate },
  };
}

function onChangeApplicantFirstNameText(firstName) {
  return {
    type: ON_CHANGE_APPLICANT_FIRST_NAME_TEXT,
    firstName,
  };
}

function onChangeApplicantLastNameText(lastName) {
  return {
    type: ON_CHANGE_APPLICANT_LAST_NAME_TEXT,
    lastName,
  };
}

function onChangeApplicantEmailText(email) {
  return {
    type: ON_CHANGE_APPLICANT_EMAIL_TEXT,
    email,
  };
}

function onChangeApplicantAlternateEmailText(alternateEmail) {
  return {
    type: ON_CHANGE_APPLICANT_ALTERNATE_EMAIL_TEXT,
    alternateEmail,
  };
}

function onChangeApplicantPhoneNumber(phone) {
  return {
    type: ON_CHANGE_APPLICANT_PHONE_NUMBER,
    phone,
  };
}

function onChangeApplicantExperienceYears(years) {
  return {
    type: ON_CHANGE_APPLICANT_EXPERIENCE_YEARS,
    years,
  };
}

function onChangeApplicantExperienceMonths(months) {
  return {
    type: ON_CHANGE_APPLICANT_EXPERIENCE_MONTHS,
    months,
  };
}

function onChangeApplicantLinkedInProfileLink(linkedInProfileLink) {
  return {
    type: ON_CHANGE_APPLICANT_LINKEDIN_PROFILE_LINK,
    linkedInProfileLink,
  };
}

function onFileUpload(file) {
  return {
    type: ON_UPLOAD_FILE,
    file,
  };
}

function onFileUploadError(value) {
  return {
    type: ON_UPLOAD_FILE_ERROR,
    value,
  };
}

function updateResumeId(resumeId) {
  return {
    type: UPDATE_RESUME_ID,
    resumeId,
  };
}

function onRemoveUploadedFile() {
  return {
    type: ON_REMOVE_UPLOADED_FILE,
  };
}

function onChangeSelectedSkills(skills) {
  return {
    type: ON_CHANGE_SELECTED_SKILLS,
    skills,
  };
}

function onChangeSelectedPrimarySkills(primarySkills) {
  return {
    type: ON_CHANGE_SELECTED_PRIMARY_SKILLS,
    primarySkills,
  };
}

function onChangeSelectedSecondarySkills(secondarySkills) {
  return {
    type: ON_CHANGE_SELECTED_SECONDARY_SKILLS,
    secondarySkills,
  };
}

function updateJobPositionClosed() {
  return {
    type: UPDATE_JOB_POSITION_CLOSED,
  };
}

function applicantAdded() {
  return {
    type: APPLICANT_ADDED,
  };
}

function updateApplicantAlreadyAppliedForJobPosition() {
  return {
    type: UPDATE_APPLICANT_ALREADY_APPLIED_FOR_JOB_POSITION,
  };
}

function updateApplicantFormCaptcha(captcha) {
  return {
    type: UPDATE_APPLICANT_FORM_CAPTCHA,
    captcha,
  };
}

function onSkillOptionsLoaded({ skills, isLoaded }) {
  return {
    type: SKILL_OPTIONS_LOADED,
    skills,
    isLoaded,
  };
}

function onPrimarySkillsOptionsLoaded({ primarySkills, isLoaded }) {
  return {
    type: PRIMARY_SKILL_OPTIONS_LOADED,
    primarySkills,
    isLoaded,
  };
}

function onSecondarySkillsOptionsLoaded({ secondarySkills, isLoaded }) {
  return {
    type: SECONDARY_SKILL_OPTIONS_LOADED,
    secondarySkills,
    isLoaded,
  };
}

function onSkillOptionUpdate({ option, isSelected }) {
  return {
    type: SKILL_OPTIONS_UPDATE,
    option,
    isSelected,
  };
}

function onPrimarySkillOptionUpdate({ option, isSelected }) {
  return {
    type: PRIMARY_SKILL_OPTIONS_UPDATE,
    option,
    isSelected,
  };
}

function onSecondarySkillOptionUpdate({ option, isSelected }) {
  return {
    type: SECONDARY_SKILL_OPTIONS_UPDATE,
    option,
    isSelected,
  };
}

function updateApplicantAddPageLoadingStatus(status) {
  return {
    type: UPDATE_APPLICANT_ADD_PAGE_LOADING_STATUS,
    status,
  };
}

function onFileScanning(status) {
  return {
    type: ON_FILE_SCANNING,
    status,
  };
}
function updateApplicantData(status, details) {
  return {
    type: UPDATE_APPLICANT_DATA,
    details,
    status,
  };
}

function updateReferralData(status, details) {
  return {
    type: UPDATE_REFERRAL_DATA,
    details,
    status,
  };
}

function onChangeLocationOption({ id }) {
  return {
    type: ON_CHANGE_WILLING_LOCATION_CHANGE,
    id,
  };
}

function onSocialPlatformIconClick(link) {
  return {
    type: ON_CLICK_SOCIAL_PLATFORM_ICON,
    link
  }
}

const careerReducer = (state = defaultState, action) => {
  let candidateDetailsLoaded = false;
  switch (action.type) {
    case RESET_STATE:
      return {
        ...defaultState,
      };
    case CANDIDATE_SHARE_JOB_MODAL_STATUS:
      return {
        ...state,
        isCareerPageShareJobModalOpen: action.status,
      };
    case APPLY_POPUP:
      return {
        ...state,
        applyNowPopup: action.value,
      };
    case UPDATE_CANDIDATE_CAREER_PAGE_SHARE_JOB_URL:
      let shareJobUrlLoaded = false;
      if (action.status === false) {
        shareJobUrlLoaded = true;
      }
      return {
        ...state,
        shareJobUrlLoaded,
        loadingShareJobUrl: action.status,
        candidateCareerPageShortUrl: action.url ? action.url : null,
      };
    case ON_CHANGE_CAREER_LOCATION_SEARCH_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        careerListPageId: 1,
        positionList: action.loadData === true ? [] : [...state.positionList],
        filteredLocationSearchKeys: action.filteredLocationSearchKeys.address
          ? [action.filteredLocationSearchKeys.address]
          : [],
      };
    case ON_CHANGE_CAREER_SEARCH_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        careerListPageId: 1,
        positionList: action.loadData === true ? [] : [...state.positionList],
        filteredSearchKeys: [...action.filteredSearchKeys],
      };

    case UPDATE_APPLICANT_DATA:
      if (action.status === false) {
        candidateDetailsLoaded = true;
      }
      const applicantDetails = action.details ? { ...action.details } : {};
      let updatedPrimarySkillOptions = [...state.primarySkillsOptions];
      let updatedSecondarySkillOptions = [...state.secondarySkillsOptions];
      let _selectedFile = {};

      if (
        applicantDetails.primarySkills &&
        state.primarySkillsOptions.length > 0
      ) {
        applicantDetails.primarySkills = applicantDetails.primarySkills.filter(
          (skill) => {
            return state.primarySkillsOptions.find(
              (jobSkill) => jobSkill.id === skill.skillId
            );
          }
        );
        updatedPrimarySkillOptions = updatedPrimarySkillOptions.map(
          (jobSkill) => {
            if (
              applicantDetails.primarySkills.find(
                (skill) => skill.skillId === jobSkill.id
              )
            ) {
              jobSkill.isSelected = true;
            }
            return jobSkill;
          }
        );
      } else if (applicantDetails.primarySkills === null) {
        applicantDetails.primarySkills = [];
      }

      if (
        applicantDetails.secondarySkills &&
        state.secondarySkillsOptions.length > 0
      ) {
        applicantDetails.secondarySkills = applicantDetails.secondarySkills.filter(
          (skill) => {
            return state.secondarySkillsOptions.find(
              (jobSkill) => jobSkill.id === skill.skillId
            );
          }
        );
        updatedSecondarySkillOptions = updatedSecondarySkillOptions.map(
          (jobSkill) => {
            if (
              applicantDetails.secondarySkills.find(
                (skill) => skill.skillId === jobSkill.id
              )
            ) {
              jobSkill.isSelected = true;
            }
            return jobSkill;
          }
        );
      } else if (applicantDetails.secondarySkills === null) {
        applicantDetails.secondarySkills = [];
      }

      if (applicantDetails.experience) {
        applicantDetails.experience = {
          years: applicantDetails.experience.years,
          months: applicantDetails.experience.months,
        };
      }

      //if phone number already exists
      if (
        applicantDetails.contactDetails &&
        applicantDetails.contactDetails.phoneNumbers.length > 0
      ) {
        let phoneNumber = applicantDetails.contactDetails.phoneNumbers.find(
          ({ isPrimary }) => isPrimary === true
        );
        applicantDetails.phone = phoneNumber.number.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2 - $3"
        );
      }

      // selected file
      if (action.details && action.details.resumeDetails.resumeId) {
        applicantDetails.resumeId = action.details.resumeDetails.resumeId;
        _selectedFile = {
          file: {
            resumeId: action.details.resumeDetails.resumeId,
            name: action.details.resumeDetails.name,
          },
          isUploaded: true,
          isFileScanning: null,
        };
      } else {
        _selectedFile = {
          file: null,
          isUploaded: false,
          isFileScanning: null,
        };
      }

      if (
        applicantDetails.socialDetails &&
        applicantDetails.socialDetails.length > 0
      ) {
        applicantDetails.linkedInProfileLink =
          applicantDetails.socialDetails[0].value;
      }

      return {
        ...state,
        candidateDetailsLoaded,
        loadingCandidateDetails: action.status,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            ...applicantDetails,
          }
        ),
        careerDetailsAuto: _updateCareerApplicantDetailsObject(
          state.careerDetailsAuto,
          {
            ...applicantDetails,
          }
        ),
        primarySkillsOptions: [...updatedPrimarySkillOptions],
        secondarySkillsOptions: [...updatedSecondarySkillOptions],
        selectedFile: _selectedFile,
      };
    case UPDATE_REFERRAL_DATA:
      if (action.status === false) {
        candidateDetailsLoaded = true;
      }
      const referalDetails = action.details ? { ...action.details } : {};
      return {
        ...state,
        candidateDetailsLoaded,
        loadingCandidateDetails: action.status,
        careerDetails: _updateCareerReferalDetailsObject(state.careerDetails, {
          firstName: referalDetails.firstName ? referalDetails.firstName : "",
          lastName: referalDetails.lastName ? referalDetails.lastName : "",
          email: referalDetails.email ? referalDetails.email : "",
          phone:
            referalDetails.contactDetails &&
              referalDetails.contactDetails.phoneNumbers.length > 0
              ? referalDetails.contactDetails.phoneNumbers[0].number.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "($1) $2 - $3"
              )
              : "",
          ModalCms1: referalDetails.ModalCms1,
          isVerified1: referalDetails.isVerified1,
        }),
        careerDetailsAuto: _updateCareerReferalDetailsObject(
          state.careerDetailsAuto,
          {
            firstName: referalDetails.firstName ? referalDetails.firstName : "",
            lastName: referalDetails.lastName ? referalDetails.lastName : "",
            email: referalDetails.email ? referalDetails.email : "",
            phone:
              referalDetails.contactDetails &&
                referalDetails.contactDetails.phoneNumbers.length > 0
                ? referalDetails.contactDetails.phoneNumbers[0].number.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  "($1) $2 - $3"
                )
                : "",
            ModalCms: referalDetails.ModalCms
          }
        ),
      };
    case LOADING_CAREER_LIST:
      let careerListLoaded = false;
      if (action.status === false) {
        careerListLoaded = true;
      }
      const jobDepartmentName = action.jobDepartmentName;
      const careerDetails = action.careerDetails
        ? { ...action.careerDetails }
        : {};
      const positionList = careerDetails.positionList
        ? [...careerDetails.positionList]
        : [...state.positionList];
      const hotPositionList = careerDetails.hotPositionList
        ? [...careerDetails.hotPositionList]
        : [];
      const filterOptions = careerDetails.filterOptions
        ? { ...careerDetails.filterOptions }
        : {};
      const totalItemCount =
        state.careerListPageId === 1
          ? careerDetails.totalCount
          : state.totalCount;
      let vendorCareerPageShortUrl =
        (careerDetails.vendorDetails &&
          careerDetails.vendorDetails.careerPageShortUrl) ||
        null;
      const vendorUUID =
        (careerDetails.vendorDetails && careerDetails.vendorDetails.uuid) ||
        null;
      const vendorFileUUID =
        (careerDetails.vendorDetails && careerDetails.vendorDetails.fileUuid) ||
        null;

      if (jobDepartmentName) {
        const settings =
          careerDetails.vendorDetails && careerDetails.vendorDetails.settings
            ? JSON.parse(careerDetails.vendorDetails.settings)
            : null;

        if (settings && settings.categories) {
          const categorySettingDetails = settings.categories.find(
            (value) => value.name === jobDepartmentName
          );
          if (
            categorySettingDetails &&
            categorySettingDetails.careerPageShortUrl
          ) {
            vendorCareerPageShortUrl =
              categorySettingDetails.careerPageShortUrl;
          }
        }
      }

      return {
        ...state,
        loadingCareerList: action.status,
        careerListLoaded,
        hotPositionList,
        positionList: [...positionList],
        filterOptions:
          careerListLoaded === true
            ? { ...filterOptions }
            : { ...state.filterOptions },
        vendorUUID,
        vendorFileUUID,
        vendorCareerPageShortUrl,
        totalCount: totalItemCount,
      };
    case ON_FIND_JOBS_CLICK:
      return {
        ...state,
        loadingCareerList: false,
        careerListLoaded: false,
      };

    case LOADING_CAREER_DETAILS:
      let careerDetailsLoaded = false;
      if (action.status === false) {
        careerDetailsLoaded = true;
      }
      const positionDetails = action.details ? { ...action.details } : null;
      return {
        ...state,
        loadingCareerDetails: action.status,
        careerDetailsLoaded,
        positionDetails,
        vendorUUID:
          (positionDetails &&
            positionDetails.vendorDetails &&
            positionDetails.vendorDetails.uuid) ||
          null,
        vendorFileUUID:
          (positionDetails &&
            positionDetails.vendorDetails &&
            positionDetails.vendorDetails.fileUuid) ||
          null,
        vendorName:
          (positionDetails &&
            positionDetails.vendorDetails &&
            positionDetails.vendorDetails.name) ||
          null,
      };
    case ON_CHANGE_CAREER_LIST_JOB_CATEGORY_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        careerListPageId: 1,
        filteredJobCategoryItems: [...action.filteredJobCategoryItems],
      };
    case ON_CHANGE_CAREER_LIST_PAY_TYPE_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        careerListPageId: 1,
        filteredPayTypeItems: [...action.filteredPayTypeItems],
      };
    case ON_CHANGE_CAREER_LIST_COUNTRY_LIST_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        careerListPageId: 1,
        filteredCountryListItems: [...action.filteredCountryListItems],
      };
    case ON_CHANGE_LOCATION:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            location: action.location,
          }
        ),
      };
    case ON_CHANGE_CAREER_SORT_BY_FILTER:
      return {
        ...state,
        careerListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        careerListPageId: 1,
        filteredSortByItem: action.filteredSortByItem
          ? [action.filteredSortByItem]
          : [],
      };
    case GET_SELECTED_FILTERED_ITEMS:
      return {
        ...state,
        selectedFilteredItems: [
          ...state.filteredJobCategoryItems,
          ...state.filteredPayTypeItems,
          ...state.filteredSortByItem,
        ],
      };
    case CLEAR_ALL_CAREER_LIST_FILTERS:
      return {
        ...state,
        careerListLoaded: false,
        careerListPageId: 1,
        positionList: [],
        filteredSortByItem: [],
        filteredJobCategoryItems: [],
        filteredCountryListItems: [],
        filteredPayTypeItems: [],
      };
    case APPLY_CAREER_LIST_MOBILE_FILTERS:
      return {
        ...state,
        careerListLoaded: false,
        showMobileFilter: false,
        positionList: [],
        careerListPageId: 1,
      };
    case ON_CAREER_LIST_PAGINATE:
      return {
        ...state,
        careerListLoaded: false,
        careerListPageId: action.pageIndex,
        positionList:
          action.isScrollEvent === true ? [...state.positionList] : [],
      };
    case SHOW_HIDE_MOBILE_CAREER_LIST_FILTER:
      return {
        ...state,
        showMobileFilter: !state.showMobileFilter,
      };
    case GET_MOBILE_FILTER_SELECTED_ITEM:
      return {
        ...state,
        selectedMobileFilterItem: action.itemName,
      };
    case REMOVE_MOBILE_SELECTED_JOB_CATEGORY_OPTION:
      const jobCategories = [...state.selectedJobCategoryOptions].filter(
        (option) => option.value !== action.option.value
      );
      return {
        ...state,
        selectedJobCategoryOptions: jobCategories,
      };
    case REMOVE_MOBILE_SELECTED_PAY_TYPE_OPTION:
      const payTypeOptions = [...state.selectedPayTypeOptions].filter(
        (option) => option.value !== action.option.value
      );
      return {
        ...state,
        selectedPayTypeOptions: payTypeOptions,
      };
    case CLEAR_ALL_SELECTED_MOBILE_FILTERS:
      return {
        ...state,
        selectedSortByOption: 1,
        selectedPayTypeOptions: [],
        selectedJobCategoryOptions: [],
      };
    case ON_FETCH_CAREER_DETAILS:
      return {
        ...state,
        positionDetails: action.careerData.positionDetails,
        isCareerDetailsPageLoaded: action.careerData.isCareerDetailsPageLoaded,
      };
    case ON_CHANGE_COVER_LETTER:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            coverLetter:
              action.coverLetter.trim() !== "" ? action.coverLetter : "",
          }
        ),
      };
    case ON_CHANGE_FIRST_NAME_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(state.careerDetails, {
          firstName: action.firstName.trim() !== "" ? action.firstName : "",
        }),
      };
    case ON_CHANGE_LAST_NAME_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(state.careerDetails, {
          lastName: action.lastName.trim() !== "" ? action.lastName : "",
        }),
      };
    case ON_CHANGE_EMAIL_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(state.careerDetails, {
          email: action.email.trim() !== "" ? action.email.trim() : "",
        }),
      };

    case ON_CHANGE_MODAL_STATUS_OPTION_REFER_FRIEND:
      // console.log(action.id);  
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(
          state.careerDetails,
          {
            ModalCms1: action.value,
          }
        )
      };
      case ON_CHNAGE_VERIFIED_STATUS_OPTION_DIRECT_APPLY:
        return {
          ...state,
          careerDetails: _updateCareerApplicantDetailsObject(
            state.careerDetails,
            {
              isVerified: action.value
            }
          )
        }
      case ON_CHANGE_VERIFIED_STATUS_OPTION_REFER:
      console.log('check for refer verification', action.value);
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(
          state.careerDetails,
          {
            isVerified1: action.value,
          }
        )
      };
    case ON_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        careerDetails: _updateCareerReferalDetailsObject(state.careerDetails, {
          phone: action.phone.trim() !== "" ? action.phone : "",
        }),
      };

    case ON_CHANGE_APPLICANT_FIRST_NAME_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            firstName: action.firstName.trim() !== "" ? action.firstName : "",
          }
        ),
      };
    case ON_CHANGE_APPLICANT_PRIMARY_SKILL_LIST:
      const modifiedPrimarySkillsList = action.list.map(
        ({ value: skillId, label: name }) => ({ skillId, name })
      );
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            primarySkills: modifiedPrimarySkillsList,
          }
        ),
      };
    case ON_CHANGE_APPLICANT_SECONDARY_SKILL_LIST:
      const modifiedSecondarySkillsList = action.list.map(
        ({ value: skillId, label: name }) => ({ skillId, name })
      );
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            secondarySkills: modifiedSecondarySkillsList,
          }
        ),
      };
    case ON_CHANGE_APPLICANT_LAST_NAME_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            lastName: action.lastName.trim() !== "" ? action.lastName : "",
          }
        ),
      };
    case ON_CHANGE_APPLICANT_EMAIL_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            email: action.email.trim() !== "" ? action.email.trim() : "",
          }
        ),
      };
    case ON_CHANGE_APPLICANT_ALTERNATE_EMAIL_TEXT:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            alternateEmail:
              action.alternateEmail.trim() !== "" ? action.alternateEmail : "",
          }
        ),
      };
    case ON_CHANGE_APPLICANT_PHONE_NUMBER:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            phone: action.phone.trim() !== "" ? action.phone : "",
          }
        ),
      };
    case ON_CHANGE_APPLICANT_LINKEDIN_PROFILE_LINK:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            linkedInProfileLink:
              action.linkedInProfileLink.trim() !== ""
                ? action.linkedInProfileLink
                : "",
          }
        ),
      };
    case ON_UPLOAD_FILE:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          file: action.file,
        },
      };
    case ON_UPLOAD_FILE_ERROR:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          isUploadedError: action.value,
        },
      };
    case UPDATE_RESUME_ID:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            resumeId: action.resumeId,
          }
        ),
        selectedFile: {
          ...state.selectedFile,
          isUploaded: true,
        },
      };
    case ON_REMOVE_UPLOADED_FILE:
      return {
        ...state,
        selectedFile: {
          file: null,
          isUploaded: false,
          isFileScanning: null,
        },
        careerDetails: {
          ...state.careerDetails,
          applicantDetails: {
            ...state.careerDetails.applicantDetails,
            resumeId: "",
          },
        },
      };
    case ON_CHANGE_SELECTED_SKILLS:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            keySkills: action.skills,
          }
        ),
      };

    case ON_CHANGE_SELECTED_PRIMARY_SKILLS:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            primarySkills: action.primarySkills,
          }
        ),
      };
    case ON_CHANGE_SELECTED_SECONDARY_SKILLS:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            secondarySkills: action.secondarySkills,
          }
        ),
      };
    case UPDATE_JOB_POSITION_CLOSED:
      return {
        ...state,
        isJobPositionClosed: true,
      };
    case UPDATE_APPLICANT_ALREADY_APPLIED_FOR_JOB_POSITION:
      return {
        ...state,
        hasApplicantAlreadyAppliedForJobPosition: true,
      };
    case APPLICANT_ADDED:
      return {
        ...state,
        isApplicantAdded: true,
      };
    case UPDATE_APPLICANT_FORM_CAPTCHA:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            captcha: action.captcha,
          }
        ),
      };
    case ON_CHANGE_APPLICANT_EXPERIENCE_YEARS:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            experience: {
              ...state.careerDetails.applicantDetails.experience,
              years: action.years.trim() !== "" ? parseInt(action.years) : "",
            },
          }
        ),
      };
    case ON_CHANGE_APPLICANT_EXPERIENCE_MONTHS:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            experience: {
              ...state.careerDetails.applicantDetails.experience,
              months:
                action.months.trim() !== "" ? parseInt(action.months) : "",
            },
          }
        ),
      };
    case SKILL_OPTIONS_LOADED:
      const skills = action.skills.map(({ skillId: id, name: value }) => ({
        id,
        value,
        isSelected: false,
      }));
      return {
        ...state,
        isPositionSkillsOptionsLoaded: action.isLoaded,
        skillsOptions: [...skills],
      };
    case PRIMARY_SKILL_OPTIONS_LOADED:
      const primarySkills = action.primarySkills.map(
        ({ skillId: id, name: value }) => ({ id, value, isSelected: false })
      );
      return {
        ...state,
        isPositionPrimarySkillsOptionsLoaded: action.isLoaded,
        primarySkillsOptions: [...primarySkills],
      };
    case SECONDARY_SKILL_OPTIONS_LOADED:
      const secondarySkills = action.secondarySkills.map(
        ({ skillId: id, name: value }) => ({ id, value, isSelected: false })
      );
      return {
        ...state,
        isPositionSecondarySkillsOptionsLoaded: action.isLoaded,
        secondarySkillsOptions: [...secondarySkills],
      };

    case SKILL_OPTIONS_UPDATE:
      const skillsOptions = [...state.skillsOptions];
      const selectedOption = skillsOptions.find(
        ({ id }) => id === action.option.id
      );
      selectedOption.isSelected = action.isSelected;

      return {
        ...state,
        skillsOptions: [...skillsOptions],
      };
    case PRIMARY_SKILL_OPTIONS_UPDATE:
      const primarySkillsOptions = [...state.primarySkillsOptions];
      const selectedPrimaryOption = primarySkillsOptions.find(
        ({ id }) => id === action.option.id
      );
      selectedPrimaryOption.isSelected = action.isSelected;
      let updatedApplicantPrimarySkills = [
        ...state.careerDetails.applicantDetails.primarySkills,
      ];
      if (!action.isSelected) {
        updatedApplicantPrimarySkills = updatedApplicantPrimarySkills.filter(
          (skill) => {
            return skill.skillId !== action.option.id;
          }
        );
      } else {
        updatedApplicantPrimarySkills.push({
          skillId: action.option.id,
          name: selectedPrimaryOption.value,
        });
      }
      return {
        ...state,
        primarySkillsOptions: [...primarySkillsOptions],
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            primarySkills: [...updatedApplicantPrimarySkills],
          }
        ),
      };
    case SECONDARY_SKILL_OPTIONS_UPDATE:
      const secondarySkillsOptions = [...state.secondarySkillsOptions];
      const selectedSecondaryOption = secondarySkillsOptions.find(
        ({ id }) => id === action.option.id
      );
      selectedSecondaryOption.isSelected = action.isSelected;
      let updatedApplicantSecondarySkills = [
        ...state.careerDetails.applicantDetails.secondarySkills,
      ];
      if (!action.isSelected) {
        updatedApplicantSecondarySkills = updatedApplicantSecondarySkills.filter(
          (skill) => {
            return skill.skillId !== action.option.id;
          }
        );
      } else {
        updatedApplicantSecondarySkills.push({
          skillId: action.option.id,
          name: selectedSecondaryOption.value,
        });
      }
      return {
        ...state,
        secondarySkillsOptions: [...secondarySkillsOptions],
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            secondarySkills: [...updatedApplicantSecondarySkills],
          }
        ),
      };
    case UPDATE_APPLICANT_ADD_PAGE_LOADING_STATUS:
      return {
        ...state,
        isApplicantAddPageLoaded: action.status,
      };
    case ON_FILE_SCANNING:
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          isFileScanning: action.status,
        },
      };
    case ON_CHANGE_WILLING_LOCATION_CHANGE:
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            relocatOption: action.id,
          }
        ),
      };
    case ON_CHANGE_MODAL_STATUS_OPTION:
      // console.log(action.id);  
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            ModalCms: action.value,
          }
        )
      };

    
    case ON_CHANGE_VERIFIED_STATUS_OPTION:
      // console.log(action.id);  
      return {
        ...state,
        careerDetails: _updateCareerApplicantDetailsObject(
          state.careerDetails,
          {
            isVerified: action.value,
          }
        )
      };
    case ON_CLICK_SOCIAL_PLATFORM_ICON:
      return {
        ...state,
        socialPlatformLink:action.link
      }
    default:
      return state;
  }
};

export {
  careerReducer as CareerReducer,
  dispatchApplyPopup,
  resetState,
  onFindJobsClick,
  updateReferralData,
  onFileUploadError,
  updateApplicantData,
  updateCandidateCareerPageShareJobUrl,
  candidateShareJobModalStatus,
  onChangeCareerLocationSearchFilter,
  updateCareerListLoadingStatus,
  updateCareerDetailsLoadingStatus,
  onChangeJobCategoryFilter,
  onChangePayTypeFilter,
  onChangeSortByFilter,
  getSelectedFilterItems,
  onClearAllFilters,
  onChangePagination,
  onClickMobileFilter,
  onChangeCareerSearchFilter,
  onClickMobileSidebarItem,
  removeMobileSelectedJobCategoryOption,
  removeMobileSelectedPayTypeOption,
  clearAllSelectedMobileFilters,
  onFetchCareerDetails,
  onChangeFirstNameText,
  onChangeLastNameText,
  onChangeEmailText,
  onChangePhoneNumber,
  applyMobileFilters,
  onChangeApplicantFirstNameText,
  onChangeApplicantLastNameText,
  onChangeApplicantEmailText,
  onChangeApplicantAlternateEmailText,
  onChangeApplicantPhoneNumber,
  onChangeApplicantExperienceYears,
  onChangeApplicantExperienceMonths,
  onChangeApplicantLinkedInProfileLink,
  onFileUpload,
  updateResumeId,
  onRemoveUploadedFile,
  onChangeSelectedSkills,
  onChangeSelectedPrimarySkills,
  onChangeSelectedSecondarySkills,
  updateJobPositionClosed,
  applicantAdded,
  updateApplicantAlreadyAppliedForJobPosition,
  updateApplicantFormCaptcha,
  onSkillOptionsLoaded,
  onPrimarySkillsOptionsLoaded,
  onSecondarySkillsOptionsLoaded,
  onSkillOptionUpdate,
  onPrimarySkillOptionUpdate,
  onSecondarySkillOptionUpdate,
  updateApplicantAddPageLoadingStatus,
  onChangeCoverLetterText,
  onFileScanning,
  onChangeApplicantLocation,
  onChangeApplicantPrimarySkillList,
  onChangeApplicantSecondarySkillList,
  onChangeLocationOption,
  onChangeCountryListFilter,
  onChangeModalStatusOption,
  onChangeModalStatusOptionReferFriend,
  onChangeVerifiedStatusOption,
  onChangeVerifiedStatusOptionDirectApply,
  onChangeVerifiedStatusOptionRefer,
  onSocialPlatformIconClick
};
