import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const RedeemVoucherAsync = lazyLoad(() => {
    return import('./components/RedeemVoucherDetails');
});
const routePath = '/:vendorUUID/voucher/:voucherId/redeem';
const redeemVoucherRouteDetails = {
    exact: true,
    path: routePath,
    name: 'redeemVoucher',
    render() {
        return <CandidateLayout
            activeMenuName = 'vouchers'
            name = {redeemVoucherRouteDetails.name}
            desktopComponent = {RedeemVoucherAsync}
            mobileComponent = {RedeemVoucherAsync} 
        />
    }
};

export { redeemVoucherRouteDetails };