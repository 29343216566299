import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/PositionEdit.scss';

const PositionEditComponentAsync = lazyLoad(() => {
    return import('./components/PositionEdit');
});

const routePath = '/positions/edit/:id';

const positionEditRouteDetails = {
    name: 'positionAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'positions',
            breadcrumbName: 'positionEdit',
            desktopComponent: PositionEditComponentAsync,
            mobileComponent: PositionEditComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'positionEdit',
    displayName: 'edit job',
    path: routePath,
    parentName: 'positions'
};

export { positionEditRouteDetails as PositionEditRouteDetails, breadcrumbDetails as positionEditBreadcrumbDetails };