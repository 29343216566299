import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const ContactEditComponentAsync = lazyLoad(() => {
    return import('./components/ContactEdit');
});

const routePath = '/contact/:id/edit';

const ContactEditRouteDetails = {
    name: 'contactEdit',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'candidateProfiles',
            breadcrumbName: 'contactEdit',
            desktopComponent: ContactEditComponentAsync,
            mobileComponent: ContactEditComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'contactEdit',
    displayName: 'edit contact',
    path: routePath,
    parentName: 'candidate profiles'
};

export { ContactEditRouteDetails, breadcrumbDetails as contactEditBreadcrumbDetails };