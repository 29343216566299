import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/PositionAdd.scss';

const PositionAddComponentAsync = lazyLoad(() => {
    return import('./components/PositionAdd');
});

const routePath = '/positions/add';

const positionAddRouteDetails = {
    name: 'positionAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'positions',
            breadcrumbName: 'positionAdd',
            desktopComponent: PositionAddComponentAsync,
            mobileComponent: PositionAddComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'positionAdd',
    displayName: 'create job',
    path: routePath,
    parentName: 'positions'
};

export { positionAddRouteDetails as PositionAddRouteDetails, breadcrumbDetails as positionAddBreadcrumbDetails };