import React from 'react';
import './userListCardBody.scss'

function userListCardBody({
    onClick,
    location,
    experience,
    availableFrom
}) {
    return (
        <div className="user-list-Card-body-container" onClick={e => onClick()}>
            <div className="user-list-Card-body-container__container">
                <div className="user-list-Card-body-container__title">
                    Experience:
                </div>
                <div className="user-list-Card-body-container__contain">
                    {experience}
                </div>
            </div>
            <div className="user-list-Card-body-container__container">
                <div className="user-list-Card-body-container__title">
                    Available From:
                </div>
                <div className="user-list-Card-body-container__contain">
                    {availableFrom}
                </div>
            </div>
            <div className="user-list-Card-body-container__container ">
                <div className="user-list-Card-body-container__title">
                    Location:
                </div>
                <div className="user-list-Card-body-container__contain">
                    {location}
                </div>
            </div>
        </div>
    )
}

export default userListCardBody;