import { FILE_API_URL } from 'App/../constants';

const defaultState = {
    loadingTalentPoolList: false,
    talentPoolListLoaded: false,
    talentPoolListPageId: 1,
    talentPoolListLimit: 10,
    talentPoolList: [],
    totalCount: null,
    filterOptions: {},
    loadingCategoryList: false,
    loadedCategoryList: false,
    tableColumns: [{
        name: 'Name',
        key: 'title',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    },
    {
        name: 'email',
        key: 'Email',
        className: 'col-lg-3 col-md-3 col-sm-3 col-xs-3'
    },
    {
        name: 'Source',
        key: 'source',
        className: 'col-lg-3 col-md-3 col-sm-3 col-xs-3'
    },
    {
        name: 'Skills',
        key: 'skills',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }, {
        name: 'Experience',
        key: 'experience',
        className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
    }
    ],
    excludeColumns: ['match'],
    showMobileFilter: false,
    filters: [
        {
            name: 'jobCategory',
            title: 'Job Category',
            displayName: 'Job Category'
        },
        // {
        //     name: 'silver',
        //     title: 'Silver Medalist',
        //     displayName: 'Silver'
        // }
    ],
    sortByFilter: {
        name: 'sortBy',
        title: 'Sort By',
        displayName: 'Sort By'
    },
    sortByOptions: [
        { value: 'Date Received(Newest First)', label: 'Date Received(Newest First)', type: 'sortBy', id: 2 },
        { value: 'Date Received(Oldest First)', label: 'Date Received(Oldest First)', type: 'sortBy', id: 1 }
    ],
    silverOptions: [{
        value: 'Show only silver medal',
        label: 'Show only silver medal',
        type: 'silver',
        id: 1
    }],
    filteredJobCategoryItems: [],
    filteredSilverItems: [],
    filteredSortByItem: [],
    filteredSearchKeys: [],
    filteredSourcesItem: [],
    filteredSearchByCandidateKeys: null,
    filteredExperience: {
        startRange: '',
        endRange: ''
    },
    selectedExperienceRange: [],
    selectedMobileFilterItem: 'sources',
    searchInput: '',
    talentPoolDetails: {},
    loadingTalentPoolDetails: false,
    talentPoolDetailsLoaded: false,
    downloadResumeUrl: '',
    yesNoOptions: [
        {
            id: 2,
            label: 'No'
        }, {
            id: 1,
            label: 'Yes'
        }],
    categoryList: []
};

const RESET_TALENT_POOL_STATE = 'RESET_TALENT_POOL_STATE';
const ON_TALENT_POOL_PAGINATE = 'ON_TALENT_POOL_PAGINATE';
const LOADING_TALENT_POOL_LIST = 'LOADING_TALENT_POOL_LIST';
const LOADING_TALENT_POOL_DETAILS = 'LOADING_TALENT_POOL_DETAILS';
const CLEAR_ALL_TALENT_POOL_FILTERS = 'CLEAR_ALL_TALENT_POOL_FILTERS';
const ON_DOWNLOAD_TALENT_POOL_RESUME = 'ON_DOWNLOAD_TALENT_POOL_RESUME';
const UPDATE_TALENT_POOL_SEARCH_INPUT = 'UPDATE_TALENT_POOL_SEARCH_INPUT';
const APPLY_MOBILE_TALENT_POOL_FILTERS = 'APPLY_MOBILE_TALENT_POOL_FILTERS';
const ON_CHANGE_TALENT_POOL_EXPERIENCE = 'ON_CHANGE_TALENT_POOL_EXPERIENCE';
const ON_CLEAR_TALENT_POOL_SEARCH_INPUT = 'ON_CLEAR_TALENT_POOL_SEARCH_INPUT';
const ON_CHANGE_TALENTPOOL_SEARCH_FILTER = 'ON_CHANGE_TALENTPOOL_SEARCH_FILTER';
const ON_CHANGE_TALENT_POOL_SEARCH_INPUT = 'ON_CHANGE_TALENT_POOL_SEARCH_INPUT';
const SHOW_HIDE_MOBILE_TALENT_POOL_FILTER = 'SHOW_HIDE_MOBILE_TALENT_POOL_FILTER';
const ON_CHANGE_TALENT_POOL_SILVER_FILTER = 'ON_CHANGE_TALENT_POOL_SILVER_FILTER';
const ON_CHANGE_TALENT_POOL_SOURCES_FILTER = 'ON_CHANGE_TALENT_POOL_SOURCES_FILTER';
const ON_CHANGE_SEARCH_BY_CANDIDATE_FILTER = 'ON_CHANGE_SEARCH_BY_CANDIDATE_FILTER';
const ON_CHANGE_TALENT_POOL_SORT_BY_FILTER = 'ON_CHANGE_TALENT_POOL_SORT_BY_FILTER';
const ON_CHANGE_TALENT_POOL_JOB_CATEGORY_FILTER = 'ON_CHANGE_TALENT_POOL_JOB_CATEGORY_FILTER';
const ON_CLICK_TALENT_POOL_MOBILE_SIDE_BAR_ITEM = 'ON_CLICK_TALENT_POOL_MOBILE_SIDE_BAR_ITEM';

const ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_YEAR = 'ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_YEAR';
const ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_MONTH = 'ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_MONTH';
const ON_CHANGE_TALENT_POOL_EDIT_CURRENT_CONTACT = 'ON_CHANGE_TALENT_POOL_EDIT_CURRENT_CONTACT';
const ON_CHANGE_TALENT_POOL_EDIT_PRIMARY_SKILLS = 'ON_CHANGE_TALENT_POOL_EDIT_PRIMARY_SKILLS';
const ON_CHANGE_TALENT_POOL_EDIT_SECONDARY_SKILLS = 'ON_CHANGE_TALENT_POOL_EDIT_SECONDARY_SKILLS';
const ON_CHANGE_TALENT_POOL_EDIT_LOCATION = 'ON_CHANGE_TALENT_POOL_EDIT_LOCATION';
const ON_CHANGE_TALENT_POOL_EDIT_VETERAN_STATUS = 'ON_CHANGE_TALENT_POOL_EDIT_VETERAN_STATUS';
const ON_CHANGE_TALENT_POOL_EDIT_SHOW_IN_TALENT_POOL = 'ON_CHANGE_TALENT_POOL_EDIT_SHOW_IN_TALENT_POOL';
const ON_CHANGE_TALENT_POOL_EDIT_RELOCATE_OPTION = 'ON_CHANGE_TALENT_POOL_EDIT_RELOCATE_OPTION';
const ON_CHANGE_TALENT_POOL_EDIT_SUMMARY = 'ON_CHANGE_TALENT_POOL_EDIT_SUMMARY';
const ON_CHANGE_TALENT_POOL_EDIT_CATEGORY_OPTION = 'ON_CHANGE_TALENT_POOL_EDIT_CATEGORY_OPTION';
const ON_LOADED_CATGORY_LIST = 'ON_LOADED_CATGORY_LIST';

function onUpdatedCatgoryList(status, categoryList) {
    return {
        type: ON_LOADED_CATGORY_LIST,
        status,
        categoryList
    }
}
function onChangeSearchFilter(filteredSearchKeys, loadData = true) {
    return {
        type: ON_CHANGE_TALENTPOOL_SEARCH_FILTER,
        filteredSearchKeys,
        loadData
    }
}

function resetState() {
    return {
        type: RESET_TALENT_POOL_STATE
    };
}

function updateTalentPoolListLoadingStatus(status = true, applications = null) {
    return {
        type: LOADING_TALENT_POOL_LIST,
        status: status,
        applications
    };
}

function onChangePagination(pageId, isScrollEvent = false) {
    return {
        type: ON_TALENT_POOL_PAGINATE,
        pageId,
        isScrollEvent
    };
}

function onClickMobileFilter() {
    return {
        type: SHOW_HIDE_MOBILE_TALENT_POOL_FILTER
    };
}

function applyMobileFilters() {
    return {
        type: APPLY_MOBILE_TALENT_POOL_FILTERS
    };
}

function onChangeJobCategoryFilter(filteredJobCategoryItems, loadData = true) {
    return {
        type: ON_CHANGE_TALENT_POOL_JOB_CATEGORY_FILTER,
        filteredJobCategoryItems,
        loadData
    };
}

function onChangeSilverFilter(filteredSilverItems, loadData = true) {
    return {
        type: ON_CHANGE_TALENT_POOL_SILVER_FILTER,
        filteredSilverItems,
        loadData
    };
}

function onChangeSorcesFilter(filteredSourcesItem, loadData = true) {
    return {
        type: ON_CHANGE_TALENT_POOL_SOURCES_FILTER,
        filteredSourcesItem,
        loadData
    }
}

function onChangeSortByFilter(filteredSortByItem, loadData = true) {
    return {
        type: ON_CHANGE_TALENT_POOL_SORT_BY_FILTER,
        filteredSortByItem,
        loadData
    };
}

function onChangeTalentPoolExperience(filteredExperience, loadData = true) {
    return {
        type: ON_CHANGE_TALENT_POOL_EXPERIENCE,
        filteredExperience,
        loadData
    };
}

function onClearAllFilters() {
    return {
        type: CLEAR_ALL_TALENT_POOL_FILTERS
    };
}

function onClickMobileSidebarItem(itemName) {
    return {
        type: ON_CLICK_TALENT_POOL_MOBILE_SIDE_BAR_ITEM,
        itemName
    };
}

function updateSearchInput(searchInput) {
    return {
        type: UPDATE_TALENT_POOL_SEARCH_INPUT,
        searchInput
    };
}

function onChangeSearchInput(searchInput, loadData) {
    return {
        type: ON_CHANGE_TALENT_POOL_SEARCH_INPUT,
        searchInput,
        loadData
    };
}

function clearSearchInput(loadData) {
    return {
        type: ON_CLEAR_TALENT_POOL_SEARCH_INPUT,
        loadData
    };
}

function updateTalentPoolDetailsLoadingStatus(status, details = null) {
    return {
        type: LOADING_TALENT_POOL_DETAILS,
        status,
        details
    };
}

function onDownloadResume(resumeId) {
    return {
        type: ON_DOWNLOAD_TALENT_POOL_RESUME,
        resumeId
    };
}

function onChangeSearchByCandidateFilter(keys, loadData = true) {
    return {
        type: ON_CHANGE_SEARCH_BY_CANDIDATE_FILTER,
        keys,
        loadData
    }
}

// Edit Talent-pool details

function onChangeExperienceYears(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_YEAR,
        value
    }
}

function onChangeExperienceMonths(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_MONTH,
        value
    }
}

function onChangeCureentContactOption(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_CURRENT_CONTACT,
        value
    }
}

function onChangePrimarySkillsList(primarySkills) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_PRIMARY_SKILLS,
        primarySkills
    }
}

function onChangeSecondarySkillsList(secondarySkills) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_SECONDARY_SKILLS,
        secondarySkills
    }
}

function onChangeTalentPoolLocation(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_LOCATION,
        value
    }
}

function onChangeTalentPoolVeteranStatus(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_VETERAN_STATUS,
        value
    }
}

function onChangeShowInTalentPoolOption(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_SHOW_IN_TALENT_POOL,
        value
    }
}

function onChangeWillingToRelocateOption(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_RELOCATE_OPTION,
        value
    }
}

function onChangeSummaryText(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_SUMMARY,
        value
    }
}

function onChangeCategoryOption(value) {
    return {
        type: ON_CHANGE_TALENT_POOL_EDIT_CATEGORY_OPTION,
        value
    }
}


const talentPoolReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_TALENT_POOL_STATE:
            return {
                ...defaultState
            };
        case LOADING_TALENT_POOL_LIST:
            let talentPoolListLoaded = false;
            if (action.status === false) {
                // list has been loaded
                talentPoolListLoaded = true;
            }
            const applications = action.applications ? { ...action.applications } : {};
            const totalItemCount = state.talentPoolListPageId === 1 && applications.totalCount
                ? applications.totalCount
                : state.totalCount;
            const talentPoolList = applications.applicantList || [];
            return {
                ...state,
                loadingTalentPoolList: action.status,
                talentPoolListLoaded,
                talentPoolList: [...state.talentPoolList, ...talentPoolList],
                totalCount: totalItemCount,
                filterOptions: talentPoolListLoaded === true
                    ? { ...applications.filterOptions }
                    : { ...state.filterOptions },
                positionId: applications.positionId || state.positionId
            };
        case ON_TALENT_POOL_PAGINATE:
            return {
                ...state,
                talentPoolListPageId: action.pageId,
                talentPoolListLoaded: false,
                talentPoolList: action.isScrollEvent === true ? [...state.talentPoolList] : []
            };
        case SHOW_HIDE_MOBILE_TALENT_POOL_FILTER:
            const status = action.hasOwnProperty('value') ? action.value : !state.showMobileFilter;
            return {
                ...state,
                showMobileFilter: status
            };
        case APPLY_MOBILE_TALENT_POOL_FILTERS:
            return {
                ...state,
                talentPoolListLoaded: false,
                talentPoolList: [],
                showMobileFilter: false
            };
        case ON_CHANGE_TALENT_POOL_JOB_CATEGORY_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredJobCategoryItems: [...action.filteredJobCategoryItems],
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_TALENT_POOL_SILVER_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredSilverItems: [...action.filteredSilverItems],
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_TALENT_POOL_SORT_BY_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredSortByItem: action.filteredSortByItem ? [action.filteredSortByItem] : [],
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case ON_CHANGE_TALENT_POOL_SOURCES_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredSourcesItem: action.filteredSourcesItem ? [...action.filteredSourcesItem] : [],
                talentPoolListPageId: 1,
                totalCount: 0
            }
        case ON_CHANGE_TALENTPOOL_SEARCH_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                talentPoolListPageId: 1,
                filteredSearchKeys: [...action.filteredSearchKeys]
            }
        case ON_CHANGE_SEARCH_BY_CANDIDATE_FILTER:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                talentPoolListPageId: 1,
                filteredSearchByCandidateKeys: action.keys
            }
        case ON_CHANGE_TALENT_POOL_EXPERIENCE:
            const selectedExperienceRange = action.filteredExperience.startRange !== "" && action.filteredExperience.endRange !== "" ? [{
                type: 'experience',
                value: `${action.filteredExperience.startRange} - ${action.filteredExperience.endRange} yrs`
            }] : [];

            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                filteredExperience: action.filteredExperience,
                selectedExperienceRange,
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case CLEAR_ALL_TALENT_POOL_FILTERS:
            return {
                ...state,
                talentPoolListLoaded: false,
                talentPoolList: [],
                talentPoolListPageId: 1,
                filteredJobCategoryItems: [],
                filteredSilverItems: [],
                filteredSortByItem: [],
                filteredSearchKeys: [],
                filteredSourcesItem: [],
                selectedExperienceRange: [],
                filteredExperience: {
                    startRange: '',
                    endRange: ''
                }
            };
        case ON_CLICK_TALENT_POOL_MOBILE_SIDE_BAR_ITEM:
            return {
                ...state,
                selectedMobileFilterItem: action.itemName
            };
        case UPDATE_TALENT_POOL_SEARCH_INPUT:
            return {
                ...state,
                searchInput: action.searchInput
            };
        case ON_CHANGE_TALENT_POOL_SEARCH_INPUT:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                searchInput: action.searchInput,
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case ON_CLEAR_TALENT_POOL_SEARCH_INPUT:
            return {
                ...state,
                talentPoolListLoaded: !action.loadData,
                talentPoolList: action.loadData === true ? [] : [...state.talentPoolList],
                searchInput: '',
                talentPoolListPageId: 1,
                totalCount: 0
            };
        case LOADING_TALENT_POOL_DETAILS:
            let talentPoolDetailsLoaded = false;
            if (action.status === false) {
                talentPoolDetailsLoaded = true;
            }
            let talentPoolDetails = action.details ? { ...action.details } : null;

            if (action.details && action.details.socialDetails &&
                action.details.socialDetails.length > 0) {
                talentPoolDetails.linkedInProfileLink = action.details.socialDetails[0].value
            }
            if (talentPoolDetails) {
                talentPoolDetails.primarySkills = action.details.primarySkills
                    ? action.details.primarySkills : [];
                talentPoolDetails.secondarySkills = action.details.secondarySkills
                    ? action.details.secondarySkills : [];
                talentPoolDetails.summary = action.details.summary
                    ? action.details.summary : "";
                talentPoolDetails.category = action.details.categoryList &&
                    action.details.categoryList.length > 0 ? action.details.categoryList : [];
                talentPoolDetails.location = action.details.location
                    ? action.details.location : null;
                talentPoolDetails.veteranStatus = action.details.veteranStatus === null
                    ? 0 : action.details.veteranStatus === true ? 1 : 2;
                talentPoolDetails.showInTalentPool = action.details.showInTalentpool === null
                    ? 0 : action.details.showInTalentpool === true ? 1 : 2;
                talentPoolDetails.willingToRelocate = action.details.willingToRelocate === null
                    ? 0 : action.details.willingToRelocate === false ? 2 : 1;
                talentPoolDetails.currentContract = action.details.currentContract === null
                    ? "" : action.details.currentContract === false ? 'No' : 'Yes';
            }
            if (action.details && action.details.experience === null) {
                talentPoolDetails.experience = {
                    years: 0,
                    months: 0
                }
            } else if (action.details && action.details.experience) {
                talentPoolDetails.experience = {
                    years: action.details.experience.years === null ? 0 : action.details.experience.years,
                    months: action.details.experience.months === null ? 0 : action.details.experience.months
                }
            }
            return {
                ...state,
                loadingTalentPoolDetails: action.status,
                talentPoolDetailsLoaded,
                talentPoolDetails: { ...talentPoolDetails }
            };
        case ON_DOWNLOAD_TALENT_POOL_RESUME:
            function getDownloadResumeUrl() {
                return `${FILE_API_URL}/${action.resumeId}`;
            }
            return {
                ...state,
                downloadResumeUrl: getDownloadResumeUrl()
            };
        case ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_YEAR:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    experience: {
                        ...state.talentPoolDetails.experience,
                        years: action.value
                    }
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_EXPERIENCE_MONTH:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    experience: {
                        ...state.talentPoolDetails.experience,
                        months: action.value
                    }
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_CURRENT_CONTACT:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    currentContract: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_LOCATION:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    location: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_VETERAN_STATUS:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    veteranStatus: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_SHOW_IN_TALENT_POOL:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    showInTalentPool: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_RELOCATE_OPTION:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    willingToRelocate: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_SUMMARY:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    summary: action.value
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_PRIMARY_SKILLS:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    primarySkills: action.primarySkills
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_SECONDARY_SKILLS:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    secondarySkills: action.secondarySkills
                }
            };
        case ON_CHANGE_TALENT_POOL_EDIT_CATEGORY_OPTION:
            return {
                ...state,
                talentPoolDetails: {
                    ...state.talentPoolDetails,
                    category: action.value
                }
            };
        case ON_LOADED_CATGORY_LIST:
            let loadedCategoryList = false;
            if (action.status === false) {
                loadedCategoryList = true
            }
            return {
                ...state,
                loadedCategoryList,
                loadingCategoryList: action.status,
                categoryList: action.categoryList
            };
        default:
            return state;
    }
};

export {
    talentPoolReducer as TalentPoolReducer,
    resetState,
    updateTalentPoolListLoadingStatus,
    onChangePagination,
    onClickMobileFilter,
    applyMobileFilters,
    onChangeJobCategoryFilter,
    onChangeSilverFilter,
    onChangeSortByFilter,
    onChangeTalentPoolExperience,
    onClearAllFilters,
    onClickMobileSidebarItem,
    updateSearchInput,
    onChangeSearchInput,
    clearSearchInput,
    updateTalentPoolDetailsLoadingStatus,
    onDownloadResume,
    onChangeSearchFilter,
    onChangeSorcesFilter,
    onChangeSearchByCandidateFilter,
    onChangeExperienceYears,
    onChangeExperienceMonths,
    onChangeCureentContactOption,
    onChangeTalentPoolLocation,
    onChangeTalentPoolVeteranStatus,
    onChangeShowInTalentPoolOption,
    onChangeWillingToRelocateOption,
    onChangeSummaryText,
    onChangeCategoryOption,
    onChangePrimarySkillsList,
    onChangeSecondarySkillsList,
    onUpdatedCatgoryList,
};