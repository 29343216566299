import React from 'react';
import ChatbotLink from './ChatbotLink';
import ChatbotImage from '../../../../shared/images/Background.png';
import { convertToAbsoluteUrl } from '../../../../shared/utils/UrlFormatter';
import '../shared/ChatbotText.scss';

export default function ChatbotText({
    text,
    link,
    name,
    number,
    clientConfig,
    isSessionUser,
    textRef = null,
    textScrollPosition,
    displayLinkName = "",
    showChatbotIcon = false
}) {
    const isArray = Array.isArray(text)
    const chatbotTextClass = !showChatbotIcon && !isSessionUser && name !== "remove-margin"
        ? "chatbot-text margin-left" : "chatbot-text";
    const className = isSessionUser
        ? 'chatbot-text-container chatbot-text-right' : 'chatbot-text-container';
    let imageUrl = convertToAbsoluteUrl(ChatbotImage)
    const textStyle = !isSessionUser
        ? {
            backgroundColor: clientConfig.style.serverResponseBgColor,
            color: clientConfig.style.serverResponseFontColor,
        }
        : {
            backgroundColor: clientConfig.style.userResponseBgColor,
            color: clientConfig.style.userResponseFontColor,
        }

    return (
        <div className={className} ref={textScrollPosition === "textScrollTop" ? textRef : null}>
            {
                showChatbotIcon
                    ? <div className="chatbot-icon">
                        <img
                            alt="chatbot-img"
                            src={imageUrl}
                            className="chatbot-text-image"
                        />
                    </div> : null
            }
            <div className={chatbotTextClass} style={textStyle}>
                {
                    isArray ?
                        text.map((value, index) =>
                            <div className="chatbot-text__multiple" key={index}>
                                {value}
                            </div>
                        )
                        : link
                            ? (
                                <React.Fragment>
                                    <ChatbotLink
                                        link={link}
                                        text="Click here"
                                        clientConfig={clientConfig} />
                                    {displayLinkName}
                                </React.Fragment>
                            )
                            : number
                                ? (
                                    <div>
                                        If it is urgent then you can connect on our support number:
                                        <a href={`tel:+91${number}`} className="number">
                                            {number}
                                        </a>
                                    </div>
                                )
                                : <div> {text} </div>
                }
            </div>
        </div>
    )
}