import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const AssessmentHistory = lazyLoad(() => {
    return import('./components/AssessmentHistory');
});

const routePath = '/:vendorUUID/candidate/assessment-history';
const AssessmentHistoryRouteDetails = {
    exact: true,
    path: routePath,
    name: 'AssessmentHistory',
    render() {
        return <CandidateLayout
            activeMenuName = 'assessmentHistory'
            name = {AssessmentHistoryRouteDetails.name}
            desktopComponent = {AssessmentHistory}
            mobileComponent = {AssessmentHistory} 
        />
    }
};

export { AssessmentHistoryRouteDetails };