import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { UUID } from '../../../../../constants';
import { onLoadChatbotJobList } from '../stores/ChatbotStore';

/* GraphQL Queries */
const CAREER_LIST_QUERY = gql`
query GetPositionListForGuestUser (
        $uuid: String!, 
        $cityNames: [String],
        $jobDepartments: [Int],
        $jobSubDepartments: [Int]
    ) {
        getPositionListForGuestUser(
            uuid: $uuid,
            filter: {
                cityNames: $cityNames,
                jobDepartments: $jobDepartments,
                jobSubDepartments: $jobSubDepartments
            }
        ) {
            positionList {
                jobPositionKey,
                jobPositionId,
                title,
                summary,
                description,
                createdAt,
                jobDepartment {
                    jobDepartmentId,
                    name
                },
                totalExperience{
                    type,
                    value {
                        minExperience,
                        maxExperience,
                        totalExperience,
                    }
                },
                primarySkills {
                    skillId,
                    name
                },
                location {
                    address {
                        addressLine1,
                        cityName,
                        stateName,
                        country,
                        zipCode
                    }
                }
            },
            totalCount,
            vendorDetails {
                uuid,
                fileUuid,
                settings
            }
        }
}`;

let chatbotJobListGetQuery = ({
    client,
    cityNames,
    jobDepartmentIds,
    jobSubDepartmentIds,
    onLoadChatbotJobList,
    loadingJobListDetails,
    UuId
}) => {
    
    const uuid = UUID ? UUID : UuId;
    if (loadingJobListDetails === true) {
        client.query({
            query: CAREER_LIST_QUERY,
            variables: {
                uuid,
                cityNames,
                jobDepartments: jobDepartmentIds,
                jobSubDepartments: jobSubDepartmentIds.length > 0 ? jobSubDepartmentIds : null
            }
        })
            .then(details => {
                onLoadChatbotJobList(false, details.data.getPositionListForGuestUser);
            });
    }

    return '';
};

const mapStateWithProps = ({ chatbot }) => {
    return {
        cityNames: chatbot.cityNames,
        jobDepartmentIds: chatbot.jobDepartmentIds,
        jobSubDepartmentIds: chatbot.jobSubDepartmentIds,
        loadingJobListDetails: chatbot.loadingJobListDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadChatbotJobList(status, careerDetails = null) {
            dispatch(onLoadChatbotJobList(status, careerDetails));
        }
    };
};

const chatbotJobListGetQueryWithApollo = withApollo(chatbotJobListGetQuery)
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(chatbotJobListGetQueryWithApollo));