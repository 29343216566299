import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const ForgotPasswordAsync = lazyLoad(() => {
    return import('./components/ForgotPassword');
});
const routePath = '/:vendorUUID/candidate/forgot-password';

const ForgotPasswordRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateForgetPassword',
    render() {
        return (
            <CandidateLayout
                activeMenuName = 'candidateDashboard'
                name = {ForgotPasswordRouteDetails.name}
                desktopComponent = {ForgotPasswordAsync}
                mobileComponent = {ForgotPasswordAsync} 
            />
        )
    }
};

export { ForgotPasswordRouteDetails };