import React from 'react';
import ChatbotText from './ChatbotText';
import ChatbotTyping from './ChatbotTyping';
import ChatbotJobList from './ChatbotJobList';
import JobDetails from '../shared/JobDetails';
import ChatbotCardView from './ChatbotCardView';
import ApplyJobForm from '../shared/ApplyJobForm';
import ChatbotLocationInput from './ChatbotLocationInput';
import ChatbotSelectOptions from './ChatbotSelectOptions';
import ChatbotSelectedOption from './ChatbotSelectedOption';
import AcademicReport from '../shared/AcademicReport';
import UniversityForm from '../shared/universityForm';
import SubmitRequirements from '../shared/SubmitRequirements';
import SearchInput from "../../../../shared/components/SearchInput/SearchInput"
import '../shared/index.scss';

export default function ChatbotConversation({
    data,
    userType,
    vendorUUID,
    clientType,
    setUserType,
    chatbotText,
    applyFormRef,
    clientConfig,
    jobDetailsRef,
    onClickJobApply,
    onClickReferJob,
    locationFormRef,
    universityFromRef,
    onChatbotJobClick,
    chatbotResetState,
    setBackOptionStatus,
    onChatbotSelectOption,
    programDescriptionRef,
    onSubmitUniversityForm,
    onApplicantAlreadyApply,
    submitRequirementsFormRef,
    onSubmitJoinTalentDomeForm,
    universityLargeOptionListRef,
    onChatbotLocationSelectedOption,
    onSubmitRequirementsFormSuccess
}) {
    function getChatbotConversation(values) {
        const {
            type,
            data,
            name,
            link,
            list,
            message,
            formTitle,
            messageGroup,
            alreadyApply,
            isSessionUser,
            scrollPosition,
            displayLinkName,
            jobPositionId = '',
            textScrollPosition
        } = values;
        if (messageGroup && messageGroup.length > 0) {
            return (
                <React.Fragment>
                    <div className="margin-left chatbot-name">
                        {clientConfig.headerTitle}
                    </div>
                    {
                        messageGroup.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ChatbotText
                                        link={value.link}
                                        text={value.message}
                                        number={value.number}
                                        clientConfig={clientConfig}
                                        isSessionUser={value.isSessionUser}
                                        displayLinkName={value.displayLinkName}
                                        showChatbotIcon={index === (messageGroup.length - 1)}
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                </React.Fragment>
            )
        }

        switch (type) {
            case "text":
                return (
                    <ChatbotText
                        name={name}
                        link={link}
                        text={message}
                        clientConfig={clientConfig}
                        isSessionUser={isSessionUser}
                        textRef={programDescriptionRef}
                        displayLinkName={displayLinkName}
                        textScrollPosition={textScrollPosition} />
                )
            case "options":
                return (
                    <ChatbotSelectOptions
                        name={name}
                        options={data}
                        clientType={clientType}
                        scrollPosition={scrollPosition}
                        clientConfig={clientConfig}
                        isSessionUser={isSessionUser}
                        optionRef={universityLargeOptionListRef}
                        setUserType={(userType) => setUserType(userType)}
                        setBackOptionStatus={(value) => setBackOptionStatus(value)}
                        onOptionClick={(name, option) => onChatbotSelectOption(name, option)}
                    />
                );
            case "jobs":
                return (
                    <ChatbotJobList
                        data={data}
                        chatbotResetState={chatbotResetState}
                        onChatbotJobListClick={(details) => onChatbotJobClick(details)}
                        onOptionClick={(option) => onChatbotSelectOption("loadMoreJobs", option)}
                    />
                );
            case "jobDetails":
                return (
                    <div>
                        <ChatbotCardView>
                            <JobDetails
                                details={data}
                                detailsRef={jobDetailsRef}
                                chatbotResetState={chatbotResetState}
                                onClickJobApply={(jobPositionId) => onClickJobApply("applyJob", jobPositionId)}
                                onClickReferJob={(jobPositionId) => onClickReferJob("referJob", jobPositionId)} />
                        </ChatbotCardView>
                    </div>
                );
            case "selectedOption":
                return (
                    <ChatbotSelectedOption
                        selectedOption={message}
                        isSessionUser={isSessionUser} />
                );
            case "typing":
                return (
                    <ChatbotTyping />
                );
            case 'joinTalentdomeForm':
                return (
                    <React.Fragment>
                        <ChatbotCardView>
                            <ApplyJobForm
                                vendorUUID={vendorUUID}
                                clientConfig={clientConfig}
                                formTitle={formTitle}
                                formRef={applyFormRef}
                                jobPositionId={jobPositionId}
                                onApplicantAlreadyApply={() => onApplicantAlreadyApply(alreadyApply)}
                                onSubmitJoinTalentDomeForm={() => onSubmitJoinTalentDomeForm(name)} />
                        </ChatbotCardView>

                    </React.Fragment>
                );
            case "locationInput":
                return (
                    <ChatbotLocationInput
                        clientConfig={clientConfig}
                        locationRef={locationFormRef}
                        onSubmitLocationOptions={(options) => onChatbotLocationSelectedOption(name, options)} />
                );
            case "submitRequirementsForm":
                return (
                    <ChatbotCardView>
                        <SubmitRequirements
                            clientConfig={clientConfig}
                            userType={userType}
                            formTitle={formTitle}
                            chatbotText={chatbotText}
                            formRef={submitRequirementsFormRef}
                            onSubmitRequirementsFormSuccess={(msg) => onSubmitRequirementsFormSuccess(name, msg)}
                        />
                    </ChatbotCardView>
                )
            case "academic-calendar":
                return (<AcademicReport
                    reportTitle="fall 2020"
                    reportDetails={[]}
                    themeConfig={clientConfig}
                >
                </AcademicReport>)
            case "search-bar-component":
                return (<SearchInput list={list} clientConfig={clientConfig} />)
            case "university-request-form":
                return (
                    <ChatbotCardView>
                        <UniversityForm
                            userType={userType}
                            formTitle={formTitle}
                            chatbotText={chatbotText}
                            clientConfig={clientConfig}
                            formRef={universityFromRef}
                            onSubmitUniversityForm={(msg) => onSubmitUniversityForm(name, msg)}
                        />
                    </ChatbotCardView>
                )
            default:
                return null
        }
    }
    return (
        <div className='chatbot-conversation'>
            {
                data && data.length > 0
                    ? data.map((values, index) => {
                        return (
                            <div key={index} className={`chatbot-conversation-type`}>
                                {getChatbotConversation(values)}
                            </div>
                        );
                    })
                    : null
            }
        </div>
    )
}