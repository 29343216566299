const defaultState = {
    applicationChannelsChartData: [{
        name: 'Referrals',
        data: [10, 30, 40, 70, 20, 50, 30, 40, 70, 20, 50, 30],
        color: '#00c160'
    }, {
        name: 'Direct Applications',
        data: [20, 20, 30, 20, 100, 20, 20, 30, 20, 10, 50, 40],
        color: '#0bff82'
    }, {
        name: 'Indeed',
        data: ['', 180, 20, 20, 30, 40, 20, '', 40, 135, 140, 30],
        color: '#007898'
    }, {
        name: 'Monster',
        data: ['', 120, 20, 120, 10, 20, 20, 135, 20, 111, 170, ''],
        color: '#0ae8ae'
    }, {
        name: 'Careerbuilder',
        data: ['', 120, 30, 20, 10, 20, 125, 137, 20, 100, 20, 30],
        color: '#0678d0'
    }],
    careerPageVisitsChartData: [{
        name: 'Total Visitors',
        data: [40, 30, 20, 15, 20, 30, 25, 20, 22, 35, 45, 60],
        color: '#39e890'
    }, {
        name: 'Unique Visitors',
        data: [50, 55, 85, 55, 90, 75, 85, 83, 75, 80, 110, 120],
        color: '#0678d0'
    }],
    onBoardingsChartData: [{
        data: [1, 3, 4, 7, 2, 5, 3, 4, 7, 2, 5, 3],
        color: '#03a8f2'
    }],
    chartDropdownOptions: [{
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    }, {
        id: 2,
        value: 'LAST 6 MONTHS',
        label: 'LAST 6 MONTHS'
    }, {
        id: 3,
        value: 'LAST 9 MONTHS',
        label: 'LAST 9 MONTHS'
    }, {
        id: 4,
        value: 'LAST 12 MONTHS',
        label: 'LAST 12 MONTHS'
    }],
    selectedApplicationStatusDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    selectedPositionDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    selectedApplicationChannelsDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    selectedOnBoardingsDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    selectedTopPositionsDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    selectedCareerPageVisitsDropdownItem: {
        id: 1,
        value: 'LAST 3 MONTHS',
        label: 'LAST 3 MONTHS'
    },
    careersPageAnalyticsData: {},
    dashboardData: {
        statusWiseApplicationDetails: {
            statusCount: []
        },
        statusWisePositionDetails: {
            statusCount: [],
        },
        onBoardingMonthWiseApplicationDetails: {
            statusCount: []
        },
        topPositionWiseApplicationDetails: {
            statusCount: []
        },
        dataMetricsDetails: []
    },
    applicationRatioProportion: {
        statusCount: [{
            title: 'Referrals per Position',
            value: '20'
        }, {
            title: 'Submit to Shortlist',
            value: '3.52:1'
        }, {
            title: 'Submit to Interview',
            value: '4.28:1'
        }, {
            title: 'Interview to Hire',
            value: '3.5:1'
        }, {
            title: 'Submit to Hire',
            value: '15:1'
        }]
    },
    accordionStatus: {
        position: true,
        chartStatus: true,
        channelsChart: true,
        onBoardingsChart: true,
        topPosition: true,
        careePageVisit: true
    },
};

//action varaibles
const ON_POSITION_HOVER = 'ON_POSITION_HOVER';
const ON_CHANGE_APPLICATION_STATUS_DATE_RANGE_OPTION = 'ON_CHANGE_APPLICATION_STATUS_DATE_RANGE_OPTION';
const ON_CHANGE_POSITION_DATE_RANGE_OPTION = 'ON_CHANGE_POSITION_DATE_RANGE_OPTION';
const ON_CHANGE_APPLICATION_CHANNELS_DATE_RANGE_OPTION = 'ON_CHANGE_APPLICATION_CHANNELS_DATE_RANGE_OPTION';
const ON_CHANGE_ON_BOARDING_DATE_RANGE_OPTION = 'ON_CHANGE_ON_BOARDING_DATE_RANGE_OPTION';
const ON_CHANGE_TOP_POSITION_DATE_RANGE_OPTION = 'ON_CHANGE_TOP_POSITION_DATE_RANGE_OPTION';
const ON_CHANGE_CAREER_PAGE_VISITS_DATE_RANGE_OPTION = 'ON_CHANGE_CAREER_PAGE_VISITS_DATE_RANGE_OPTION';
const ON_TOGGLE_DASHBORAD_ACCORDION = 'ON_TOGGLE_DASHBORAD_ACCORDION';
const UPDATE_DASHBOARD_DATA = 'UPDATE_DASHBOARD_DATA';
const CARERE_PAGE_ANALYTICS_DATA = "CARERE_PAGE_ANALYTICS_DATA";
const RESET_DAFAULT_STATE = 'RESET_DAFAULT_STATE';

function onTogglePosition(positionIndex) {
    return {
        type: ON_POSITION_HOVER,
        positionIndex
    };
}

function resetState() {
    return {
        type: RESET_DAFAULT_STATE
    }
}

function updateDashboardCareerAnalyticsData(analyticsData) {
    return {
        type: CARERE_PAGE_ANALYTICS_DATA,
        analyticsData
    }
}

function onChangeApplicationStatusDateRangeOption(data) {
    return {
        type: ON_CHANGE_APPLICATION_STATUS_DATE_RANGE_OPTION,
        data
    };
}

function onChangePositionDateRangeOption(data) {
    return {
        type: ON_CHANGE_POSITION_DATE_RANGE_OPTION,
        data
    };
}
function onToggleAccordion(itemName) {
    return {
        type: ON_TOGGLE_DASHBORAD_ACCORDION,
        itemName
    }
}

function onChangeApplicationChannelsDateRangeOption(data) {
    return {
        type: ON_CHANGE_APPLICATION_CHANNELS_DATE_RANGE_OPTION,
        data
    };
}

function onChangeOnBoardingDateRangeOption(data) {
    return {
        type: ON_CHANGE_ON_BOARDING_DATE_RANGE_OPTION,
        data
    };
}

function onChangeTopPositionDateRangeOption(data) {
    return {
        type: ON_CHANGE_TOP_POSITION_DATE_RANGE_OPTION,
        data
    };
}

function onChangeCareerPageVisitsDateRangeOption(data) {
    return {
        type: ON_CHANGE_CAREER_PAGE_VISITS_DATE_RANGE_OPTION,
        data
    };
}

function updateDashboardData(data) {
    return {
        type: UPDATE_DASHBOARD_DATA,
        data
    };
}

const dashboardReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_DAFAULT_STATE: {
            return {
                ...defaultState
            }
        }
        case ON_POSITION_HOVER:
            const position = state.dashboardData.topPositionWiseApplicationDetails.statusCount[action.positionIndex];
            position.tooltipOpen = !position.tooltipOpen;
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData,
                    topPositionWiseApplicationDetails: {
                        ...state.dashboardData.topPositionWiseApplicationDetails,
                        statusCount: [...state.dashboardData.topPositionWiseApplicationDetails.statusCount]
                    }
                }
            };
        case ON_CHANGE_APPLICATION_STATUS_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedApplicationStatusDropdownItem: action.data.selectedOption
            };
        case ON_CHANGE_POSITION_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedPositionDropdownItem: action.data.selectedOption
            };
        case ON_CHANGE_APPLICATION_CHANNELS_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedApplicationChannelsDropdownItem: action.data.selectedOption
            };
        case ON_CHANGE_ON_BOARDING_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedOnBoardingsDropdownItem: action.data.selectedOption
            };
        case ON_CHANGE_TOP_POSITION_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedTopPositionsDropdownItem: action.data.selectedOption
            };
        case ON_CHANGE_CAREER_PAGE_VISITS_DATE_RANGE_OPTION:
            return {
                ...state,
                selectedCareerPageVisitsDropdownItem: action.data.selectedOption
            };
        case UPDATE_DASHBOARD_DATA:
            return {
                ...state,
                dashboardData: action.data
            };
        case CARERE_PAGE_ANALYTICS_DATA:
            return {
                ...state,
                careersPageAnalyticsData: action.analyticsData
            }
        case ON_TOGGLE_DASHBORAD_ACCORDION:
            const accordionStatus = { ...state.accordionStatus };
            accordionStatus[action.itemName] = !accordionStatus[action.itemName];
            return {
                ...state,
                accordionStatus: { ...accordionStatus }
            }
        default:
            return state;
    }
}

export {
    resetState,
    dashboardReducer as DashboardReducer,
    onTogglePosition,
    onChangeApplicationStatusDateRangeOption,
    onChangePositionDateRangeOption,
    onChangeApplicationChannelsDateRangeOption,
    onChangeOnBoardingDateRangeOption,
    onChangeTopPositionDateRangeOption,
    onChangeCareerPageVisitsDateRangeOption,
    updateDashboardData,
    updateDashboardCareerAnalyticsData,
    onToggleAccordion
};