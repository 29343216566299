import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { UUID } from '../../../../../constants';
import { onLoadChatbotJobDetailsData } from '../stores/ChatbotStore';

/* GraphQL Queries */
const CHATBOT_JOB_DETAILS_GET_QUERY = gql`
query getPositionForGuestUser($vendorUuid: String!, $positionKey: String!) {
    getPositionForGuestUser(vendorUuid: $vendorUuid, positionKey: $positionKey) {
        jobPositionId,
        jobPositionKey,
        title,
        description,
        summary,
        location {
            address {
                addressLine1,
                cityName,
                stateName,
                country,
                zipCode
            }
        },
        totalExperience{
            type,
            value {
                minExperience,
                maxExperience,
                totalExperience
            }
        },
        jobDepartment {
            jobDepartmentId,
            name
        },
        primarySkills {
            skillId,
            name
        }
    }
}`;

let chatbotJobDetailsGetQuery = ({
    client,
    jobPositionKey,
    loadingJobDetailsData,
    onLoadChatbotJobDetailsData,
    UuId
}) => {
    const uuid = UUID ? UUID : UuId;
    if (loadingJobDetailsData === true) {
        client.query({
            query: CHATBOT_JOB_DETAILS_GET_QUERY,
            variables: {
                'positionKey': jobPositionKey,
                'vendorUuid': uuid
            }
        })
            .then(details => {
                onLoadChatbotJobDetailsData(false, details.data.getPositionForGuestUser);
            });
    }

    return '';
};

const mapStateWithProps = ({ chatbot }) => {
    return {
        jobPositionKey: chatbot.jobPositionKey,
        loadingJobDetailsData: chatbot.loadingJobDetailsData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadChatbotJobDetailsData(status, chatbotJobDetails = null) {
            dispatch(onLoadChatbotJobDetailsData(status, chatbotJobDetails));
        }
    };
};

const chatbotJobDetailsGetQueryWithApollo = withApollo(chatbotJobDetailsGetQuery)
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(chatbotJobDetailsGetQueryWithApollo));