import React, { Component } from 'react'
import axios from 'axios';
import gql from 'graphql-tag';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import ErrorStore from '../../../../shared/utils/ErrorStore';
import { FILE_API_URL, UUID } from '../../../../../constants';
import { Field, SubmissionError, reduxForm } from 'redux-form';
import { onResumeUploadedError } from '../stores/ChatbotStore';
import Address from '../../../../shared/components/Address/Address';
import PositionGetFiltersQuery from '../shared/PositionGetFiltersQuery';
import InputField from '../../../../shared/components/InputField/InputField';
import FileUpload from '../../../../shared/components/FileUpload/FileUpload';
import { required, email, minLength10 } from '../../../../shared/utils/Validator';
import Autocomplete from '../../../../shared/components/Autocomplete/Autocomplete';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import './ApplyJobForm.scss';

class applyJobForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrerFirstName: "",
            referrerLastName: "",
            referrerEmail: "",
            referrerPhoneNumber: "",
            firstName: '',
            lastName: '',
            address: {},
            emailId: '',
            number: '',
            skills: [],
            resumeId: '',
            selectedFile: {
                file: null,
                isUploaded: false,
                isFileScanning: null
            },
            yearInExp: '',
            monthInExp: '',
            formError: {}
        }
        this.disable = false
        this.fileUploading = false
        this._validate = this._validate.bind(this)
        this.onUploadFile = this.onUploadFile.bind(this)
        this.onFileUpload = this.onFileUpload.bind(this)
        this.onSubmitApply = this.onSubmitApply.bind(this)
        this.onRemoveResume = this.onRemoveResume.bind(this)
        this.onSubmitObject = this.onSubmitObject.bind(this)
        this.onFileScanning = this.onFileScanning.bind(this)
        this.updateResumeId = this.updateResumeId.bind(this)
        this.setSubmitObject = this.setSubmitObject.bind(this)
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this._onContactNumberFocus = this._onContactNumberFocus.bind(this)
        this._onChangeContactNumberText = this._onChangeContactNumberText.bind(this)
        this.onSubmitCandidateDetailsForm = this.onSubmitCandidateDetailsForm.bind(this)
        this._onReferrerContactNumberFocus = this._onReferrerContactNumberFocus.bind(this)
        this._onReferrerChangeContactNumberText = this._onReferrerChangeContactNumberText.bind(this)
    }
    onChangeInputValue(type, value) {
        switch (type) {
            case "referrerFirstName":
                this.setState({ referrerFirstName: value });
                break;
            case "referrerLastName":
                this.setState({ referrerLastName: value });
                break;
            case "referrerEmail":
                this.setState({ referrerEmail: value });
                break;
            case "referrerPhoneNumber":
                this.setState({ referrerPhoneNumber: value });
                break;
            case "firstName":
                this.setState({ firstName: value });
                break;
            case "lastName":
                this.setState({ lastName: value });
                break;
            case "address":
                this.setState({ address: value });
                break;
            case "emailId":
                this.setState({ emailId: value });
                break;
            case "number":
                this.setState({ number: value });
                break;
            case "skills":
                this.setState({ skills: value });
                break;
            case "yearInExp":
                this.setState({ yearInExp: value });
                break;
            case "monthInExp":
                this.setState({ monthInExp: value });
                break;
            default:
                break;
        }
    }
    onFileScanning(status) {
        const { selectedFile } = this.state
        const { file, isUploaded } = selectedFile
        this.setState({
            selectedFile: {
                file: file,
                isUploaded: isUploaded,
                isFileScanning: status
            }
        })
    }
    updateResumeId(uuid) {
        this.setState({
            resumeId: uuid
        })
    }
    onFileUpload(file) {
        this.setState({
            selectedFile: {
                file: file,
                isUploaded: false,
                isFileScanning: null,
            }
        })
    }
    onSubmitApply() {
        const { formTitle } = this.props
        let errors = null;
        const jobApplyError = this._validate();
        if (jobApplyError) {
            errors = { ...(errors || {}), ...jobApplyError };
        }
        if (errors) {
            this.setState({
                formError: errors
            })
            throw new SubmissionError({ ...errors });
        } else {
            this.disable = true
            if (formTitle === "Join Talentpool") {
                this.onSubmitObject()
            } else {
                this.onSubmitCandidateDetailsForm()
            }

        }
    }
    onSubmitCandidateDetailsForm() {
        const applicantDetails = this.setSubmitObject();
        const { client, onApplicantAlreadyApply, onSubmitJoinTalentDomeForm, formTitle } = this.props;

        const _gql = this.getMutationForApplyCandidate();

        return client.mutate({
            mutation: gql`${_gql}`,
            variables: {
                'applicantDetails': applicantDetails
            }
        })
            .then(data => {
                if (data.data.addApplicant) {
                    if (formTitle === "Candidate Details") {
                        // Google Analytics Event
                        ReactGA.event({
                            category: "chatbot-job-apply-form-success",
                            action: "chatbot job apply form success",
                            label: "chatbot job apply form success"
                        })
                    }
                    if (formTitle === "Refer a Friend") {
                        ReactGA.event({
                            category: "chatbot-job-refer-form-success",
                            action: "chatbot job refer form success",
                            label: "chatbot job refer form success"
                        })
                    }
                    onSubmitJoinTalentDomeForm()
                } else {
                    onApplicantAlreadyApply()
                }

                client.resetStore();
            })
            .catch(err => {
                console.log("err", err)
            });

    }
    onSubmitObject() {
        const applicantDetails = this.setSubmitObject();
        const client = this.props.client;

        const _gql = this.getMutationGql();
        return client.mutate({
            mutation: gql`${_gql}`,
            variables: {
                'applicantDetails': applicantDetails
            }
        })
            .then(({ data }) => {
                // Google Analytics Event
                ReactGA.event({
                    category: "chatbot-join-talentdone-form-success",
                    action: "chatbot join talentdone form success",
                    label: "chatbot join talentdone form success"
                })
                this.props.onSubmitJoinTalentDomeForm()
                client.resetStore();
            })
    }
    getMutationGql() {
        return `mutation directApplyApplicant($applicantDetails: ApplicantInput) {
            directApplyApplicant(applicantDetails: $applicantDetails) { 
                applicantId
            }
        }  
        `
    }
    getMutationForApplyCandidate() {
        return `mutation addApplicant($applicantDetails: ApplicantInput) {
            addApplicant(applicantDetails: $applicantDetails) {
                applicantId 
            }
        }`;
    }
    setSubmitObject() {
        const {
            skills,
            number,
            emailId,
            address,
            lastName,
            resumeId,
            firstName,
            yearInExp,
            monthInExp,
            referrerEmail,
            referrerLastName,
            referrerFirstName,
            referrerPhoneNumber
        } = this.state
        const { jobPositionId, formTitle } = this.props;
        let referrerDeatils = {};

        const candidateDetails = {
            vendorUuid: UUID ? UUID : this.props.vendorUUID,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            contactDetails: {
                emails: [
                    {
                        email: emailId,
                        isPrimary: true
                    }],
                phoneNumbers: [{
                    type: "Mobile",
                    number: this.removeNumberFormat(number),
                    isPrimary: true
                }]
            },
            location: address,
            primarySkills: skills.map(({ value: skillId, label: name }) => ({ skillId, name })),
            resumeId: resumeId,
            experience: {
                years: Number(yearInExp),
                months: Number(monthInExp)
            }

        }
        if (formTitle === "Refer a Friend") {
            referrerDeatils = {
                firstName: referrerFirstName.trim(),
                lastName: referrerLastName.trim(),
                email: referrerEmail,
                phoneNumber: this.removeNumberFormat(referrerPhoneNumber)
            }
            candidateDetails.referralDetails = referrerDeatils;
        }
        if (formTitle === "Candidate Details" || formTitle === "Refer a Friend") {
            candidateDetails.jobPositionKey = jobPositionId
            if (formTitle === "Refer a Friend") {
                candidateDetails.source = "Referred through chatbot"
            }
            if (formTitle === "Candidate Details") {
                candidateDetails.source = "Applied from chatbot"
            }
        }
        else {
            candidateDetails.source = "Joined through chatbot"
        }
        return candidateDetails;
    }
    _validate() {
        const {
            number,
            skills,
            address,
            emailId,
            resumeId,
            lastName,
            firstName,
            yearInExp,
            monthInExp,
            referrerEmail,
            referrerLastName,
            referrerFirstName,
            referrerPhoneNumber } = this.state
        const { formTitle } = this.props;
        const locationInputFieldValue = address && address.address
            ? this._getAddress(address.address) : '';

        const errorInstance = ErrorStore();
        errorInstance.add('firstName', firstName, [required]);
        errorInstance.add('lastName', lastName, [required]);
        errorInstance.add('skills', skills.length > 0, [required]);
        errorInstance.add('emailId', emailId, [required, email]);
        errorInstance.add('contactNumber', number, [required, minLength10]);
        errorInstance.add('resume', resumeId, [required]);
        errorInstance.add('location', locationInputFieldValue, [required])
        errorInstance.add('yearInExp', yearInExp, [required])
        errorInstance.add('monthInExp', monthInExp, [required])

        if (formTitle === "Refer a Friend") {
            errorInstance.add('referFirstName', referrerFirstName, [required]);
            errorInstance.add('referLastName', referrerLastName, [required]);
            errorInstance.add('referEmail', referrerEmail, [required, email]);
            errorInstance.add('referPhoneNumber', referrerPhoneNumber, [required, minLength10])
        }
        return errorInstance.get();
    }
    onRemoveResume() {
        this.setState({
            resumeId: '',
            selectedFile: {
                file: null,
                isUploaded: false,
                isFileScanning: null,
            }
        })
        this.fileUploading = false
    }
    onUploadFile(event) {
        const fileFormat = event.target.files[0].type;
        if (fileFormat === 'application/pdf' || fileFormat === 'application/msword' || fileFormat === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.onFileUpload(event.target.files[0]);
            this.props.onResumeUploadedError(false);
        } else {
            this.props.onResumeUploadedError(true);
        }
    }
    _getAddress({ addressLine1 = '', cityName = '', stateName = '', country = '' }) {
        const data = [];
        if (cityName) {
            data.push(cityName);
        } else if (addressLine1) {
            data.push(addressLine1);
        }

        if (stateName) {
            data.push(stateName);
        }
        if (country) {
            data.push(country);
        }
        return data.join(', ');
    }
    _onReferrerChangeContactNumberText(value) {
        let _value = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
        this.setState({
            referrerPhoneNumber: _value
        })
    }
    removeNumberFormat(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        return _modifiedValue
    }
    _onReferrerContactNumberFocus(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        this.setState({
            referrerPhoneNumber: _modifiedValue
        })
    }
    _onChangeContactNumberText(value) {
        let _value = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
        this.setState({
            number: _value
        })
    }
    _onContactNumberFocus(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        this.setState({
            number: _modifiedValue
        })
    }
    render() {
        const {
            formTitle,
            keySkills,
            handleSubmit,
            formRef = null,
            clientConfig,
            vendorUUID,
            isResumeUploadedError
        } = this.props;
        const {
            number,
            skills,
            emailId,
            address,
            lastName,
            formError,
            yearInExp,
            firstName,
            monthInExp,
            selectedFile,
            referrerEmail,
            referrerLastName,
            referrerFirstName,
            referrerPhoneNumber } = this.state
        const locationInputFieldValue = address && address.address
            ? this._getAddress(address.address) : '';

        const _keySkills = keySkills && keySkills.length > 0 ? keySkills.map(({ skillId: value, name: label }) => ({ value, label })) : []
        if (this.fileUploading === false && !selectedFile.isUploaded && selectedFile.file !== null && selectedFile.isFileScanning === null) {
            this.fileUploading = true;
            const data = new FormData();
            data.append('file', selectedFile.file, selectedFile.file.name);
            const uploadFileApiUrl = `${FILE_API_URL}/upload`;
            if (selectedFile.isFileScanning === null) {
                this.onFileScanning(true);
            }
            axios.post(uploadFileApiUrl, data)
                .then(res => {
                    this.onFileScanning(null);
                    this.updateResumeId(res.data.data.uuid);
                })
                .catch(error => {
                    this.onFileScanning(false);
                });
        }
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        let trident = ua.indexOf('Trident/');
        let edge = ua.indexOf('Edge/');

        const formThemeBgColor = {
            background: clientConfig.style.serverResponseBgColor,
            color: clientConfig.style.formTitleFontColor
        }
        const submitBtnStyle = {
            color: clientConfig.style.headerFontcolor,
            background: clientConfig.style.headerBgColor
        }

        return (
            <div style={formThemeBgColor}
                className="chatbot-job-apply-from"
                ref={formRef} id="chatbot-form">
                <PositionGetFiltersQuery vendorUUID={vendorUUID} />
                {
                    formTitle === "Refer a Friend"
                        ? <div className="chatbot-job-apply-from__heading-refer">
                            <div className="title-border">
                                <h6 style={formThemeBgColor} className="form-title">{formTitle}</h6>
                            </div>
                        </div>
                        : <div className="chatbot-job-apply-from__heading">
                            <h6 style={formThemeBgColor}> {formTitle}</h6>
                        </div>
                }
                <div className="chatbot-job-apply-from__details">
                    <form id="ApplyJobForm" name='ApplyJobForm' onSubmit={handleSubmit(_ => this.onSubmitApply())}>
                        {
                            formTitle === "Refer a Friend" ?
                                <div>
                                    <div> <span className="sub-heading"> your details </span> </div>
                                    <div className={formError.hasOwnProperty('referFirstName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'> First Name <span> * </span> </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="refer-first-name"
                                                name="referFirstName"
                                                isRequired={true}
                                                autoComplete="off"
                                                regex="^[a-zA-Z '-]+$"
                                                component={InputField}
                                                placeholder="First Name"
                                                inputFieldValue={referrerFirstName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("referrerFirstName", value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={formError.hasOwnProperty('referLastName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'> Last Name <span> * </span> </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="refer-last-name"
                                                name="referLastName"
                                                isRequired={true}
                                                autoComplete="off"
                                                regex="^[a-zA-Z '-]+$"
                                                component={InputField}
                                                placeholder="Last Name"
                                                inputFieldValue={referrerLastName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("referrerLastName", value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={formError.hasOwnProperty('referPhoneNumber') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'> Phone number<span> * </span> </div>
                                        {
                                            edge > 0 || trident > 0 || msie > 0 ?
                                                <div className="form-fields">
                                                    <Field
                                                        min="0"
                                                        type="text"
                                                        minLength="10"
                                                        maxLength="10"
                                                        regex='[0-9\b]+$'
                                                        autoComplete="off"
                                                        id="contact-number"
                                                        component={InputField}
                                                        name="referPhoneNumber"
                                                        placeholder="(xxx) xxx - xxx"
                                                        inputFieldValue={referrerPhoneNumber}
                                                        onFieldValueChange={(value) =>
                                                            this.onChangeInputValue("referrerPhoneNumber", value)}
                                                    />
                                                </div> :
                                                <div className="form-fields">
                                                    <Field
                                                        min="0"
                                                        type="text"
                                                        minLength="10"
                                                        maxLength="10"
                                                        regex='[0-9\b]+$'
                                                        autoComplete="off"
                                                        id="contact-number"
                                                        component={InputField}
                                                        name="referPhoneNumber"
                                                        placeholder="Contact Number"
                                                        inputFieldValue={referrerPhoneNumber}
                                                        onInputFocus={this._onReferrerContactNumberFocus}
                                                        onInputChangeFocus={this._onReferrerChangeContactNumberText}
                                                        onFieldValueChange={(value) =>
                                                            this.onChangeInputValue("referrerPhoneNumber", value)}
                                                    />
                                                </div>
                                        }
                                    </div>
                                    <div className={formError.hasOwnProperty('referEmail') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'> E-mail Address <span> * </span> </div>
                                        <div className="form-fields">
                                            <Field
                                                id="email"
                                                type="text"
                                                name="referEmail"
                                                maxLength="50"
                                                isRequired={true}
                                                component={InputField}
                                                placeholder="E-mail Address"
                                                inputFieldValue={referrerEmail}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("referrerEmail", value)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="sub-heading">friend's details </span>
                                    </div>
                                </div> : null
                        }
                        <div className={formError.hasOwnProperty('firstName')
                            ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> First Name <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    type="text"
                                    maxLength="50"
                                    id="first-name"
                                    name="firstName"
                                    isRequired={true}
                                    autoComplete="off"
                                    regex="^[a-zA-Z '-]+$"
                                    component={InputField}
                                    placeholder="First Name"
                                    inputFieldValue={firstName}
                                    onFieldValueChange={(value) =>
                                        this.onChangeInputValue("firstName", value)}
                                />
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('lastName')
                            ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> Last Name <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    type="text"
                                    maxLength="50"
                                    id="last-name"
                                    name="lastName"
                                    isRequired={true}
                                    autoComplete="off"
                                    regex="^[a-zA-Z '-]+$"
                                    component={InputField}
                                    placeholder="Last Name"
                                    inputFieldValue={lastName}
                                    onFieldValueChange={(value) =>
                                        this.onChangeInputValue("lastName", value)}
                                />
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('location')
                            ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> Location <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    type="text"
                                    id="location"
                                    name="location"
                                    isRequired={true}
                                    component={Address}
                                    locationDetails={address}
                                    placeholder="Search by Location"
                                    inputFieldValue={locationInputFieldValue}
                                    onAddressChange={(value) =>
                                        this.onChangeInputValue("address", value)}
                                />
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('emailId')
                            ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> E-mail Address <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    id="email"
                                    type="text"
                                    name="emailId"
                                    maxLength="50"
                                    isRequired={true}
                                    component={InputField}
                                    inputFieldValue={emailId}
                                    placeholder="E-mail Address"
                                    onFieldValueChange={(value) =>
                                        this.onChangeInputValue("emailId", value)}
                                />
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('contactNumber')
                            ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> Phone <span> * </span> </div>
                            <div className="form-fields">
                                {
                                    edge > 0 || trident > 0 || msie > 0 ?

                                        <Field
                                            min="0"
                                            type="text"
                                            minLength="10"
                                            maxLength="10"
                                            autoComplete="off"
                                            regex='[0-9\b]+$'
                                            id="contact-number"
                                            name="contactNumber"
                                            component={InputField}
                                            inputFieldValue={number}
                                            placeholder="(xxx) xxx - xxx"
                                            onFieldValueChange={(value) =>
                                                this.onChangeInputValue("number", value)}
                                        /> :
                                        <Field
                                            min="0"
                                            type="text"
                                            minLength="10"
                                            maxLength="10"
                                            autoComplete="off"
                                            regex='[0-9\b]+$'
                                            id="contact-number"
                                            name="contactNumber"
                                            component={InputField}
                                            inputFieldValue={number}
                                            placeholder="(xxx) xxx - xxx"
                                            onInputFocus={this._onContactNumberFocus}
                                            onInputChangeFocus={this._onChangeContactNumberText}
                                            onFieldValueChange={(value) =>
                                                this.onChangeInputValue("number", value)}
                                        />
                                }
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('yearInExp') ||
                            formError.hasOwnProperty('monthInExp')
                            ? "experience-div fieldset error-field" : "experience-div fieldset"}>
                            <div style={formThemeBgColor} className='label'>Experience<span> * </span> </div>
                            <div className="exp-field">
                                <div className="form-fields">
                                    <Field
                                        min="0"
                                        type="text"
                                        maxLength="2"
                                        autoComplete="off"
                                        regex='[0-9\b]+$'
                                        id="years-of-exp"
                                        name="yearInExp"
                                        placeholder="Years"
                                        component={InputField}
                                        inputFieldValue={yearInExp}
                                        onFieldValueChange={(value) =>
                                            this.onChangeInputValue("yearInExp", value)}
                                    />
                                </div>
                            </div>
                            <div className="month-field">
                                <div className="form-fields">
                                    <Field
                                        type="text"
                                        min="0"
                                        minLength="1"
                                        maxLength="2"
                                        id="month-of-exp"
                                        name="monthInExp"
                                        autoComplete="off"
                                        placeholder="Months"
                                        component={InputField}
                                        regex='^([0-9]|10|11)$'
                                        inputFieldValue={monthInExp}
                                        onFieldValueChange={(value) =>
                                            this.onChangeInputValue("monthInExp", value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={formError.hasOwnProperty('skills')
                            ? 'fieldset error-field skills-fieldset' : 'fieldset skills-fieldset'}>
                            <div style={formThemeBgColor} className='label'> Skills <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    height="160"
                                    type="text"
                                    maxItems={3}
                                    id="Skills"
                                    name="skills"
                                    isRequired={true}
                                    isSearchable={true}
                                    options={_keySkills}
                                    isMultiSelected={true}
                                    component={Autocomplete}
                                    selectedValue={skills}
                                    onChangeInput={(value) =>
                                        this.onChangeInputValue("skills", value)}
                                />
                            </div>
                            {
                                formError.hasOwnProperty('skills') ? null :
                                    <div className={skills.length >= 1
                                        ? "info-msg error-msg" : "info-msg"}>
                                        <span>You can add maximum three skills </span>
                                    </div>
                            }
                        </div>
                        <div className={formError.hasOwnProperty('resume')
                            ? 'fieldset error-field resume-error' : 'resume-fieldset fieldset'}>
                            <div style={formThemeBgColor} className='label'> Resume <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    name="resume"
                                    id="file-upload"
                                    isRequired={true}
                                    component={FileUpload}
                                    accept=".pdf, .doc, .docx"
                                    onFileUpload={this.onUploadFile}
                                    selectedFile={selectedFile.file}
                                    onRemoveUploadedFile={this.onRemoveResume}
                                    isFileScanning={selectedFile.isFileScanning}
                                    isFileUploadedAnotherFormat={isResumeUploadedError}
                                />
                            </div>
                        </div>
                        <ActionButton
                            themeStyle={submitBtnStyle}
                            type="submit"
                            title="submit"
                            className='submit-btn'
                            showDisabledActionButton={this.disable} />
                    </form>
                </div>
            </div>
        )
    }
}


applyJobForm = reduxForm({
    'form': 'ApplyJobForm'
})(applyJobForm);

const mapStateToProps = ({ chatbot }) => {
    return {
        keySkills: chatbot.positionFilters.skills,
        isResumeUploadedError: chatbot.isResumeUploadedError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onResumeUploadedError(value) {
            dispatch(onResumeUploadedError(value))
        }
    }
}

const applyJobFormApollo = withApollo(applyJobForm);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(applyJobFormApollo));