import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SaveCalculatorFooter from 'App/shared/components/SaveCalculatorFooter/SaveCalculatorFooter';
import Logo from 'App/shared/images/logo.png'
import './CalculatorLayout.scss';

const calculatorLayout = ({
    isMobile,
    isDesktop,
    desktopComponent: DesktopComponent,
    mobileComponent: MobileComponent,
}) => {
        return (
            <div className='login-calculator-container'>
                <div className='calculator-layout'>
                    {isDesktop === true
                        ? (<div className="desktop-body" id="app">
                            <div className="calculator-layout__header">
                                <div>
                                    <Link to="/login">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                                {/* <div className="calculator-layout__header__infojini">
                                    <img src={SecondLogo} alt="logo" />
                                </div> */}

                            </div>
                            <div className="calculator-layout__main">
                                <div className="desktop-component" >
                                    <DesktopComponent />
                                </div>
                            </div>
                            <SaveCalculatorFooter/>
                        </div>)
                        : ''}


                    {isMobile === true
                        ? (<div className='mobile-layout'>
                            <div className="mobile-header">
                                <div>
                                    <Link to="/login">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                                {/* <div className="mobile-header__infojini">
                                    <img src={SecondLogo} alt="logo" />
                                </div> */}

                            </div>
                            <div className="calculator-layout__main">
                                <div className="desktop-component">
                                    <MobileComponent />
                                </div>
                            </div>
                            <SaveCalculatorFooter/>
                        </div>)
                        : ''}
                </div>
            </div >
        )
    }

const mapStateWithProps = (state) => {
    return {
        isMobile: state.deviceIdentifier.isMobile,
        isDesktop: state.deviceIdentifier.isDesktop,
        vendorName: state.career.vendorName
    };
};

export default connect(mapStateWithProps, null)(withRouter(calculatorLayout));