import parser from "query-string";
import queryString from "query-string";

export function addUrlParams(url, queryParams, additionalParams) {
  let urlParams = [];
  if (additionalParams) {
    queryParams = {
      ...queryParams,
      ...additionalParams,
    };
  }
  for (let key in queryParams) {
    if (
      !additionalParams ||
      !additionalParams.hasOwnProperty(key) ||
      additionalParams[key] !== null
    ) {
      urlParams.push(`${key}=${queryParams[key]}`);
    }
  }
  if (urlParams.length > 0) {
    return `${url}?${urlParams.join("&")}`;
  }
  return url;
}

export function addRedirectUrlParams(url) {
  let params = queryString.parse(window.location.search);
  if (params.redirect_url) {
    const value = queryString.parseUrl(params.redirect_url);
    return addUrlParams(url, value.query);
  } else {
    return addUrlParams(url, params);
  }
}

export function redirect(history, redirectUrl) {
  const queryString = history.location.search;
  if (queryString) {
    const queryParams = parser.parse(queryString);
    if (queryParams.redirect_url) {
      history.push(queryParams.redirect_url);
    } else {
      history.push(redirectUrl);
    }
  } else {
    history.push(redirectUrl);
  }
}
