import React from "react";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "../../../../constants";
import { getCareerUrl } from "App/shared/utils/CareerUrl";
import { addRedirectUrlParams } from "App/shared/utils/Url";
import SettingsGetQuery from "App/shared/settings/SettingsGetQuery";
import Chatbot from "../../../shared/components/talentdome-chatbot/index";
import CareerFooter from "App/shared/components/CareerFooter/CareerFooter";
import UserProfileName from "App/shared/components/UserProfileName/UserProfileName";
import CandidateHeader from "App/shared/components/CandidateHeader/CandidateHeader";
import { onClickMobileMenubarAction } from "App/shared/components/Layout/LayoutStore";
import UserProfileImage from "App/shared/components/UserProfileImage/UserProfileImage";
import {
  getNotificationList,
  resetNotificationCount,
} from "../stores/CandidateStore";
import {
  getCandidateUserDetails,
  isCandidateAuthenticated,
} from "App/shared/utils/Authentication";
import CandidateMobileHeader from "App/shared/components/CandidateMobileHeader/CandidateMobileHeader";
import CandidateMobileMenubar from "App/shared/components/CandidateMobileMenubar/CandidateMobileMenubar";

let candidateLayout = ({
  match,
  theme,
  category,
  client,
  history,
  isMobile,
  isDesktop,
  showMenubar,
  activeMenuName,
  notificationList,
  notificationCount,
  getNotificationList,
  hideShowMobileMenubar,
  resetNotificationCount,
  loadingNotificationList,
  vendorDetails,
  mobileComponent: MobileComponent,
  desktopComponent: DesktopComponent,
}) => {
  const vendorUUID = match.params.vendorUUID || match.params.uuid || null;
  const isCandidateLoggedIn = isCandidateAuthenticated(vendorUUID);
  const userDetails = getCandidateUserDetails(vendorUUID);
  let uuid = userDetails? userDetails.uuid :'';
  let accountId = userDetails ? userDetails.accountId : "";
  let showMobileMenubarClassName = "show-menubar";
  let mobileMenubarDefaultClassName = "app-mobile-menubar";
  mobileMenubarDefaultClassName = showMenubar
    ? `${mobileMenubarDefaultClassName} ${showMobileMenubarClassName}`
    : "app-mobile-menubar";
  const candidateHeaderBaseUrl = `/${vendorUUID}/candidate/`;
  let candidateHeaderUrl = addRedirectUrlParams(getCareerUrl(vendorUUID));
  let loginUrl = addRedirectUrlParams(`${candidateHeaderBaseUrl}login/`);
  let registerUrl = addRedirectUrlParams(`${candidateHeaderBaseUrl}register/`);

  function getActiveMenu(menuItems, activeMenuName) {
    const activeMenu =
      menuItems && menuItems.find((menu) => menu.name === activeMenuName);
    return activeMenu.name;
  }
  function MobileMenuListItem({ item }) {
    return (
      <div key={item.name}>
        <span> {item.displayName} </span>
      </div>
    );
  }
  function UserProfile({ theme, userDetails }) {
    const fontColor = { color: theme.primaryColor };
    return (
      <div className="user-profile">
        <div className="user-profile-image">
          <UserProfileImage style={fontColor} />
        </div>
        <div className="user-profile-name">
          <UserProfileName
            style={fontColor}
            firstName={
              userDetails === undefined ? "Test" : userDetails.firstName
            }
            lastName={userDetails === undefined ? "User" : userDetails.lastName}
          />
        </div>
      </div>
    );
  }
  function _getDropdownMenuOptions(isCandidateHeader) {
    const dropdownMenus = [
      {
        name: "candidateDashboard",
        displayName: "Dashboard",
        path: candidateHeaderUrl,
      },
      {
        name: "vouchers",
        displayName: "Vouchers",
        path: addRedirectUrlParams(`/${vendorUUID}/vouchers/`),
      },
      {
        name: "redemptionHistory",
        displayName: "Redemption History",
        path: addRedirectUrlParams(
          `${candidateHeaderBaseUrl}redemption-history/`
        ),
      },
      {
        name: "referralPathway",
        displayName: "Referral Pathway",
        path: addRedirectUrlParams(
          `${candidateHeaderBaseUrl}referral-pathway/`
        ),
      },
      {
        name: "assessmentHistory",
        displayName: "Assessment History",
        path: addRedirectUrlParams(
          `${candidateHeaderBaseUrl}assessment-history/`
        ),
      },
      {
        name: "myProfile",
        displayName: "My Profile",
        path: addRedirectUrlParams(`${candidateHeaderBaseUrl}${accountId}/details/`),
      },
      {
        name: "resetPassword",
        displayName: "Reset Password",
        path: addRedirectUrlParams(`/${vendorUUID}/reset-password/${uuid}`),
      },
    ];
    if (isCandidateHeader) {
      dropdownMenus.push({ name: "Logout", displayName: "logout" });
    } else {
      dropdownMenus.push({ name: "Logout", displayName: "Logout", path: `` });
    }
    return dropdownMenus;
  }
  function getMobileMenus(vendorUUID) {
    const unAuthorizedMenu = [
      {
        name: "login",
        displayName: "Login",
        path: `${loginUrl}`,
      },
      {
        name: "createAnAccount",
        displayName: "Create an Account",
        path: `${registerUrl}`,
      },
      {
        name: "candidateDashboard",
        displayName: "Current Job Openings",
        path: candidateHeaderUrl,
      },
    ];
    let menus = isCandidateLoggedIn
      ? _getDropdownMenuOptions(false)
      : unAuthorizedMenu;
    const activeMenu = getActiveMenu(menus, activeMenuName);
    menus = menus.map((menu) => ({
      component: <MobileMenuListItem item={menu} />,
      className: menu.name === activeMenu ? "active-mobile-menu" : "",
      path: menu.path,
    }));
    menus = isCandidateLoggedIn
      ? [
          {
            component: (
              <UserProfile
                theme={theme}
                userDetails={getCandidateUserDetails(vendorUUID)}
              />
            ),
            className: "user-profile-mobile-menubar",
          },
          ...menus,
        ]
      : [
          {
            component: <UserProfile theme={theme} userDetails="" />,
            className: "user-profile-mobile-menubar",
          },
          ...menus,
        ];
    return menus;
  }
  function onNotificationClick(value) {
    const candidateCode = value.code;
    !value.isNotificationRead && updateNotificationStatus(value);
    value.score !== null
      ? history.push(
          addRedirectUrlParams(`/${vendorUUID}/candidate/assessment-history`)
        )
      : history.push(
          addRedirectUrlParams(
            `/${vendorUUID}/candidate/${candidateCode}/assessment/rules`
          )
        );
  }
  function updateNotificationStatus(value) {
    return client
      .mutate({
        mutation: gql`
          mutation updateNotificationReadStatus(
            $code: String!
            $uuid: String!
          ) {
            updateNotificationReadStatus(code: $code, uuid: $uuid) {
              notificationId
            }
          }
        `,
        variables: {
          code: value.code,
          uuid: vendorUUID,
        },
      })
      .then((_) => {})
      .catch((err) => console.log(err));
  }
  function onDropDownClick(value) {
    if (!value) {
      getNotificationList(false);
    }
    return "";
  }
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  const _iosDevice = iOS();
  const showChatBot =
    theme && theme.hasOwnProperty("showChatbot") ? theme.showChatBot : true;

  return (
    <div className="career-body-container">
      <SettingsGetQuery uuid={vendorUUID} />
      {showChatBot ? (
        <Chatbot clientType="talentdome" UUID={vendorUUID} baseUrl={BASE_URL} />
      ) : null}
      <div className="app-layout" id="app">
        {/* DeskTop Layout */}
        {isDesktop === true ? (
          <div className="app-desktop-layout">
            <CandidateHeader
              theme={theme}
              loginUrl={loginUrl}
              registerUrl={registerUrl}
              count={notificationCount}
              activeMenuName={activeMenuName}
              onDropDownClick={onDropDownClick}
              resetCount={resetNotificationCount}
              notificationList={notificationList}
              candidateHeaderUrl={candidateHeaderUrl}
              onNotificationClick={onNotificationClick}
              isCandidateLoggedIn={isCandidateLoggedIn}
              dropdownOptions={_getDropdownMenuOptions(true)}
              loadingNotificationList={loadingNotificationList}
              onCandidateLogoutUrl={(val) => addRedirectUrlParams(val)}
              category={category}
              vendorDetails={vendorDetails}
            />
            <div className="main">
              <div className="app-component career-app-component">
                <div className="desktop-component">
                  <DesktopComponent />
                </div>
              </div>
            </div>
            <CareerFooter />
          </div>
        ) : null}
        {/* Mobile Layout */}
        {isMobile === true ? (
          <div className="app-mobile-layout">
            <CandidateMobileHeader
              theme={theme}
              uuid={vendorUUID}
              count={notificationCount}
              onDropDownClick={onDropDownClick}
              notificationList={notificationList}
              resetCount={resetNotificationCount}
              onMenuClick={hideShowMobileMenubar}
              candidateHeaderUrl={candidateHeaderUrl}
              isCandidateLoggedIn={isCandidateLoggedIn}
              onNotificationClick={onNotificationClick}
              loadingNotificationList={loadingNotificationList}
            />
            <div className="main">
              <div
                onClick={hideShowMobileMenubar}
                className={mobileMenubarDefaultClassName}
              >
                <CandidateMobileMenubar
                  theme={theme}
                  list={getMobileMenus(vendorUUID)}
                  onCandidateLogoutUrl={(val) => addRedirectUrlParams(val)}
                />
              </div>
              <MobileComponent />
            </div>

            {_iosDevice ? null : (
              <div className="mobile-footer">
                <CareerFooter />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateWithProps = ({
  layout,
  setting,
  candidate,
  deviceIdentifier,
}) => {
  return {
    theme: setting.theme,
    category: setting.category,
    showMenubar: layout.showMenubar,
    isMobile: deviceIdentifier.isMobile,
    isDesktop: deviceIdentifier.isDesktop,
    notificationList: candidate.notificationList,
    notificationCount: candidate.notificationCount,
    vendorDetails: setting.vendorDetails,
    loadingNotificationList: candidate.NotifiactionListLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideShowMobileMenubar: (event) =>
      dispatch(onClickMobileMenubarAction(event)),
    resetNotificationCount() {
      dispatch(resetNotificationCount());
    },
    getNotificationList(status) {
      dispatch(getNotificationList(status));
    },
  };
};
candidateLayout = withApollo(candidateLayout);
export default connect(
  mapStateWithProps,
  mapDispatchToProps
)(withRouter(candidateLayout));
