import React from 'react';
import './AppFooter.scss';

const appFooter = () => {
    return (
        <div className='app-footer'>
            <h3>&copy; {new Date().getFullYear()} TalentDome. all rights reserved</h3>
        </div>
    )
}

export default appFooter;