import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import ErrorStore from '../../../../shared/utils/ErrorStore';
import { onResumeUploadedError } from '../stores/ChatbotStore';
import { Field, SubmissionError, reduxForm } from 'redux-form';
import { FILE_API_URL, API_URL } from '../../../../../constants';
import { required, email } from '../../../../shared/utils/Validator';
import InputField from '../../../../shared/components/InputField/InputField';
import FileUpload from '../../../../shared/components/FileUpload/FileUpload';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import './SubmitRequirements.scss';

class SubmitRequirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "",
            notes: "",
            emailId: "",
            resumeId: "",
            lastName: "",
            firstName: "",
            phoneNumber: "",
            companyName: "",
            employeeName: "",
            selectedFile: {
                file: null,
                isUploaded: false,
                isFileScanning: null
            },
            formError: {},
            eitherResumeOrLinkError: false
        }
        this.disable = false;
        this.fileUploading = false;
        this._validate = this._validate.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onSubmitApply = this.onSubmitApply.bind(this);
        this.onRemoveResume = this.onRemoveResume.bind(this);
        this.onFileScanning = this.onFileScanning.bind(this);
        this.updateResumeId = this.updateResumeId.bind(this);
        this.setDetailsObjects = this.setDetailsObjects.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this._onPhoneNumberFocus = this._onPhoneNumberFocus.bind(this);
        this._onChangePhoneNumberText = this._onChangePhoneNumberText.bind(this);
    }
    onChangeInputValue(type, value) {
        switch (type) {
            case "firstName":
                this.setState({ firstName: value });
                break;
            case "lastName":
                this.setState({ lastName: value });
                break;
            case "email":
                this.setState({ emailId: value });
                break;
            case "notes":
                this.setState({ notes: value });
                break;
            case "link":
                this.setState({ link: value });
                break;
            case "phoneNumber":
                this.setState({ phoneNumber: value });
                break;
            case "companyName":
                this.setState({ companyName: value });
                break;
            case "employeeName":
                this.setState({ employeeName: value });
                break;
            default:
                break;
        }
    }
    onFileScanning(status) {
        const { selectedFile } = this.state
        const { file, isUploaded } = selectedFile
        this.setState({
            selectedFile: {
                file: file,
                isUploaded: isUploaded,
                isFileScanning: status
            }
        })
    }
    updateResumeId(uuid) {
        this.setState({ resumeId: uuid });
    }
    onFileUpload(file) {
        this.setState({
            selectedFile: {
                file: file,
                isUploaded: false,
                isFileScanning: null,
            }
        })
    }
    onUploadFile(event) {
        const fileFormat = event.target.files[0].type;
        if (fileFormat === 'application/pdf' || fileFormat === 'application/msword' || fileFormat === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            this.onFileUpload(event.target.files[0]);
            this.props.onResumeUploadedError(false);
        } else {
            this.props.onResumeUploadedError(true);
        }
    }
    onRemoveResume() {
        this.setState({
            resumeId: '',
            selectedFile: {
                file: null,
                isUploaded: false,
                isFileScanning: null,
            }
        })
        this.fileUploading = false
    }
    _onChangePhoneNumberText(value) {
        let _value = value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
        this.setState({ phoneNumber: _value });
    }
    _onPhoneNumberFocus(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        this.setState({ phoneNumber: _modifiedValue });
    }
    onSubmitApply() {
        const { formTitle } = this.props
        let errors = null;
        const submitError = this._validate();
        if (submitError) {
            errors = { ...(errors || {}), ...submitError };
        }
        if (errors) {
            let _eitherResumeOrLinkError = errors.hasOwnProperty('resume');
            this.setState({
                formError: errors,
                eitherResumeOrLinkError: _eitherResumeOrLinkError
            })
            throw new SubmissionError({ ...errors });
        } else {
            this.disable = true;
            this.onSubmitForm(formTitle);
        }
    }
    onSubmitForm(formTitle) {
        const details = this.setDetailsObjects();
        let apiUrl;
        const baseUrl = `${API_URL}/chatbot/`
        if (formTitle === "Submit Requirements / RFX") {
            apiUrl = `${baseUrl}rfx`;
        } else if (formTitle === "Contact Form") {
            apiUrl = `${baseUrl}contact`;
        } else if (formTitle === "Create Ticket") {
            apiUrl = `${baseUrl}ticket`;
        }
        fetch(apiUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(details)
        }).then((res) => {
            if (res.ok) {
                res.json()
                    .then((data) => {
                        this.props.onSubmitRequirementsFormSuccess(data.data);
                    })
                    .catch(err => console.log("error", err));
            }
        })
    }
    setDetailsObjects() {
        const { formTitle, userType, chatbotText } = this.props;
        const {
            link,
            notes,
            emailId,
            resumeId,
            lastName,
            firstName,
            phoneNumber,
            companyName,
            employeeName } = this.state;
        let details = {};

        details.email = emailId;
        if (formTitle !== "Create Ticket") {
            details.userType = userType;
        }
        if (formTitle === "Contact Form") {
            details.chatbotText = chatbotText;
        }
        if (formTitle === "Drop a line" || formTitle === "Contact Form") {
            details.notes = notes;
            details.name = firstName.trim();
            if (phoneNumber) { details.phoneNumber = this.removeNumberFormat(phoneNumber); }
        } else if (formTitle === "Subscribe To Blogs") {
            details.lastName = lastName.trim();
            details.firstName = firstName.trim();
        } else if (formTitle === "Create Ticket") {
            details.notes = notes;
            details.name = employeeName;
        } else {
            details.name = firstName.trim();
            details.companyName = companyName;
            if (link) { details.link = link; }
            if (notes) { details.notes = notes; }
            if (resumeId) { details.fileUuid = resumeId; }
            if (phoneNumber) { details.phoneNumber = this.removeNumberFormat(phoneNumber); }
        }
        return details;
    }
    removeNumberFormat(value) {
        let _value = value.replace(/[|&;$%@"<>()+,-]/g, "");
        let _modifiedValue = _value.replace(/\s+/g, '')
        return _modifiedValue
    }
    _validate() {
        const {
            link,
            notes,
            emailId,
            resumeId,
            lastName,
            firstName,
            companyName,
            employeeName } = this.state;
        const { formTitle } = this.props;
        const errorInstance = ErrorStore();

        if (formTitle === "Drop a line" || formTitle === "Contact Form") {
            errorInstance.add('firstName', firstName, [required]);
            errorInstance.add('email', emailId, [required, email]);
            errorInstance.add('notes', notes, [required]);
        } else if (formTitle === "Subscribe To Blogs") {
            errorInstance.add('firstName', firstName, [required]);
            errorInstance.add('lastName', lastName, [required]);
            errorInstance.add('email', emailId, [required, email]);
        } else if (formTitle === "Create Ticket") {
            errorInstance.add('employeeName', employeeName, [required]);
            errorInstance.add('email', emailId, [required, email]);
            errorInstance.add('notes', notes, [required]);
        } else {
            errorInstance.add('firstName', firstName, [required]);
            errorInstance.add('email', emailId, [required, email]);
            errorInstance.add('companyName', companyName, [required]);
            if (!link && !resumeId) {
                errorInstance.add('resume', resumeId, [required]);
            }
        }
        return errorInstance.get();

    }

    render() {
        const {
            link,
            notes,
            emailId,
            resumeId,
            lastName,
            formError,
            firstName,
            phoneNumber,
            companyName,
            employeeName,
            selectedFile,
            eitherResumeOrLinkError } = this.state;

        const { formTitle, handleSubmit, formRef = null, isResumeUploadedError, clientConfig } = this.props;
        if (this.fileUploading === false && !selectedFile.isUploaded && selectedFile.file !== null && selectedFile.isFileScanning === null) {
            this.fileUploading = true;
            const data = new FormData();
            data.append('file', selectedFile.file, selectedFile.file.name);
            const uploadFileApiUrl = `${FILE_API_URL}/upload`;
            if (selectedFile.isFileScanning === null) {
                this.onFileScanning(true);
            }
            axios.post(uploadFileApiUrl, data)
                .then(res => {
                    this.onFileScanning(null);
                    this.updateResumeId(res.data.data.uuid);
                })
                .catch(error => {
                    this.onFileScanning(false);
                });
        }
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        let trident = ua.indexOf('Trident/');
        let edge = ua.indexOf('Edge/');
        const formThemeBgColor = {
            background: clientConfig.style.serverResponseBgColor,
            color: clientConfig.style.formTitleFontColor
        }

        const submitBtnStyle = {
            color: clientConfig.style.headerFontcolor,
            background: clientConfig.style.headerBgColor
        }

        return (
            <div style={formThemeBgColor} className="submit-requirements" ref={formRef}>
                <div style={formThemeBgColor} className="submit-requirements__heading">
                    <h6> {formTitle}</h6>
                </div>
                <div className="fields-msg"> (Field marked as <span> * </span> are mandatory) </div>
                <div className="submit-requirements__details">
                    <form id="submitRequirements" name='submitRequirements'>
                        {
                            formTitle === "Create Ticket"
                                ? (
                                    <div className={formError.hasOwnProperty('employeeName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'>
                                            Employee Name <span> * </span>
                                        </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="employee-name"
                                                name="employeeName"
                                                regex="^[a-zA-Z '-]+$"
                                                component={InputField}
                                                inputFieldValue={employeeName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("employeeName", value)}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        {
                            formTitle !== "Create Ticket"
                                ? (
                                    <div className={formError.hasOwnProperty('firstName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'>
                                            {
                                                formTitle === "Subscribe To Blogs" ? "First Name" : "Name"
                                            } <span> * </span>
                                        </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="first-name"
                                                name="firstName"
                                                regex={formTitle === "Subscribe To Blogs"
                                                    ? '^[a-zA-Z]+$' : '^[a-zA-Z ]+$'}
                                                component={InputField}
                                                inputFieldValue={firstName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("firstName", value)}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        {
                            formTitle === "Submit Requirements / RFX"
                                ? (
                                    <div className={formError.hasOwnProperty('companyName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'>
                                            Company Name <span> * </span>
                                        </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="company-name"
                                                name="companyName"
                                                regex='^[a-zA-Z ]+$'
                                                component={InputField}
                                                inputFieldValue={companyName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("companyName", value)}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        {
                            formTitle === "Subscribe To Blogs"
                                ? (
                                    <div className={formError.hasOwnProperty('lastName') ? 'fieldset error-field' : 'fieldset'}>
                                        <div style={formThemeBgColor} className='label'> Last Name <span> * </span> </div>
                                        <div className="form-fields">
                                            <Field
                                                type="text"
                                                maxLength="50"
                                                id="last-name"
                                                name="lastName"
                                                regex="^[a-zA-Z '-]+$"
                                                component={InputField}
                                                inputFieldValue={lastName}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("lastName", value)}
                                            />
                                        </div>
                                    </div>
                                ) : null
                        }
                        <div className={formError.hasOwnProperty('email') ? 'fieldset error-field' : 'fieldset'}>
                            <div style={formThemeBgColor} className='label'> E-mail Address <span> * </span> </div>
                            <div className="form-fields">
                                <Field
                                    id="email"
                                    type="text"
                                    name="email"
                                    maxLength="50"
                                    component={InputField}
                                    inputFieldValue={emailId}
                                    onFieldValueChange={(value) =>
                                        this.onChangeInputValue("email", value)}
                                />
                            </div>
                        </div>
                        {
                            formTitle === "Subscribe To Blogs" || formTitle === "Create Ticket" ? null
                                : (
                                    <div className='fieldset'>
                                        <div style={formThemeBgColor} className='label'> Phone
                                        </div>
                                        <div className="form-fields">
                                            {
                                                edge > 0 || trident > 0 || msie > 0 ?
                                                    <Field
                                                        min="0"
                                                        type="text"
                                                        minLength="10"
                                                        maxLength="10"
                                                        regex='[0-9\b]+$'
                                                        id="phone-number"
                                                        name="phoneNumber"
                                                        component={InputField}
                                                        inputFieldValue={phoneNumber}
                                                        onFieldValueChange={(value) =>
                                                            this.onChangeInputValue("phoneNumber", value)}
                                                    /> :
                                                    <Field
                                                        min="0"
                                                        type="text"
                                                        minLength="10"
                                                        maxLength="10"
                                                        regex='[0-9\b]+$'
                                                        id="phone-number"
                                                        name="phoneNumber"
                                                        component={InputField}
                                                        inputFieldValue={phoneNumber}
                                                        onInputFocus={this._onPhoneNumberFocus}
                                                        onInputChangeFocus={this._onChangePhoneNumberText}
                                                        onFieldValueChange={(value) =>
                                                            this.onChangeInputValue("phoneNumber", value)}
                                                    />
                                            }
                                        </div>
                                    </div>
                                )
                        }
                        {
                            (formTitle !== "Submit Requirements / RFX") ? null
                                : (
                                    <React.Fragment>
                                        <div className="fieldset resume-field">
                                            <div style={formThemeBgColor} className="label"> Attach file </div>
                                            <div className="form-fields">
                                                <Field
                                                    name="resume"
                                                    id="file-upload"
                                                    component={FileUpload}
                                                    accept=".pdf, .doc, .docx"
                                                    onFileUpload={this.onUploadFile}
                                                    selectedFile={selectedFile.file}
                                                    onRemoveUploadedFile={this.onRemoveResume}
                                                    isFileScanning={selectedFile.isFileScanning}
                                                    isFileUploadedAnotherFormat={isResumeUploadedError}
                                                />
                                            </div>
                                        </div>
                                        <div className="fieldset">
                                            <div style={formThemeBgColor} className="label"> Attach Link </div>
                                            <div className="form-fields">
                                                <Field
                                                    type="text"
                                                    name="link"
                                                    id="link-upload"
                                                    component={InputField}
                                                    inputFieldValue={link}
                                                    onFieldValueChange={(value) =>
                                                        this.onChangeInputValue("link", value)}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                        }
                        {
                            formTitle === "Subscribe To Blogs" ? null
                                : (
                                    <div className={formError.hasOwnProperty('notes')
                                        ? 'fieldset error-field notes-fieldset' : 'fieldset notes-fieldset'}>
                                        <div style={formThemeBgColor} className='label'>
                                            {
                                                formTitle === "Drop a line" || formTitle === "Contact Form"
                                                    ? "Comment" : formTitle === "Create Ticket" ? "Description" : "Notes"
                                            }
                                            {formTitle !== "Submit Requirements / RFX" ? <span> * </span> : null}
                                        </div>
                                        <div className="form-fields">
                                            <Field
                                                id="notes"
                                                name="notes"
                                                type="textarea"
                                                component={InputField}
                                                inputFieldValue={notes}
                                                onFieldValueChange={(value) =>
                                                    this.onChangeInputValue("notes", value)}
                                            />
                                        </div>
                                    </div>
                                )
                        }
                        {
                            ((eitherResumeOrLinkError) && (!link && !resumeId))
                                ? <div className="error-msg">
                                    Either Attachment or Link is required.
                                </div> : null
                        }
                        <div className="submit-btn-div">
                            <ActionButton
                                themeStyle={submitBtnStyle}
                                type="button"
                                title="submit"
                                className='submit-btn'
                                showDisabledActionButton={this.disable}
                                onClick={handleSubmit(values => this.onSubmitApply())} />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

SubmitRequirements = reduxForm({
    'form': 'submitRequirements',
    'enableReinitialize': true
})(SubmitRequirements);

const mapStateToProps = ({ chatbot }) => {
    return {
        isResumeUploadedError: chatbot.isResumeUploadedError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onResumeUploadedError(value) {
            dispatch(onResumeUploadedError(value))
        }
    }
}

const SubmitRequirementsApollo = withApollo(SubmitRequirements);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmitRequirementsApollo));

