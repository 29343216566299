const defaultState = {
  cityNames: [],
  jobDetails: {},
  jobListData: [],
  vendorDetails: {},
  jobPositionKey: "",
  positionFilters: {},
  distributionList: [],
  jobDepartmentIds: [],
  jobSubDepartmentIds: [],
  clientSelectedIndex: 0,
  clientSelectedValue: null,
  jobListDetailsLoaded: false,
  jobDetailsDataLoaded: false,
  loadingJobListDetails: false,
  loadingJobDetailsData: false,
  isResumeUploadedError: false,
  positionFiltersLoaded: false,
  loadingPositionFilters: false,
};

//actions
const CHATBOT_RESET_STATE = "CHATBOT_RESET_STATE";
const ON_RESUME_UPLOAD_ERROR = "ON_RESUME_UPLOAD_ERROR";
const FETCH_CHATBOT_JOB_LIST = "FETCH_CHATBOT_JOB_LIST";
const LOADING_POSITION_FILTERS = "LOADING_POSITION_FILTERS";
const ON_UPDATE_JOB_CITY_NAMES = "ON_UPDATE_JOB_CITY_NAMES";
const ON_LOAD_CHATBOT_JOB_LIST = "ON_LOAD_CHATBOT_JOB_LIST";
const FETCH_CHATBOT_JOB_DETAILS = "FETCH_CHATBOT_JOB_DETAILS";
const ON_UPDATE_JOB_DEPARTMENT_ID = "ON_UPDATE_JOB_DEPARTMENT_ID";
const UPDATE_CLIENT_SELECTED_INDEX = "UPDATE_CLIENT_SELECTED_INDEX";
const UPDATE_CLIENT_SELECTED_VALUE = "UPDATE_CLIENT_SELECTED_VALUE";
const ON_UPDATE_JOB_SUB_DEPARTMENT_ID = "ON_UPDATE_JOB_SUB_DEPARTMENT_ID";
const ON_LOAD_CHATBOT_JOB_DETAILS_DATA = "ON_LOAD_CHATBOT_JOB_DETAILS_DATA";

//dispatchers

// client chatbot functions
function updateClientSelectedIndex(index = null) {
  return {
    type: UPDATE_CLIENT_SELECTED_INDEX,
    index,
  };
}

function updateClientSelectedValue(value) {
  return {
    type: UPDATE_CLIENT_SELECTED_VALUE,
    value,
  };
}

function chatbotResetState() {
  return {
    type: CHATBOT_RESET_STATE,
  };
}

function onResumeUploadedError(value) {
  return {
    type: ON_RESUME_UPLOAD_ERROR,
    value,
  };
}

function fetchChatbotJobList() {
  return {
    type: FETCH_CHATBOT_JOB_LIST,
  };
}

function fetchChatbotJobDetails(jobPositionKey) {
  return {
    type: FETCH_CHATBOT_JOB_DETAILS,
    jobPositionKey,
  };
}

function onLoadChatbotJobList(status, data) {
  return {
    type: ON_LOAD_CHATBOT_JOB_LIST,
    data,
    status,
  };
}

function onLoadChatbotJobDetailsData(status, data) {
  return {
    type: ON_LOAD_CHATBOT_JOB_DETAILS_DATA,
    data,
    status,
  };
}

function onUpdateJobCityNames(cityNames) {
  return {
    type: ON_UPDATE_JOB_CITY_NAMES,
    cityNames,
  };
}

function onUpdateJobDepartmentId(id) {
  return {
    type: ON_UPDATE_JOB_DEPARTMENT_ID,
    id,
  };
}

function onUpdateJobSubDepartmentId(id) {
  return {
    type: ON_UPDATE_JOB_SUB_DEPARTMENT_ID,
    id,
  };
}

function updatePositionFiltersLoadingStatus(status, filters) {
  return {
    type: LOADING_POSITION_FILTERS,
    status: status,
    filters: filters,
  };
}

//reducers
const chatbotReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHATBOT_RESET_STATE:
      return {
        ...defaultState,
        cityNames: state.cityNames,
        jobDepartmentIds: state.jobDepartmentIds,
        jobSubDepartmentIds: state.jobSubDepartmentIds,
      };
    case FETCH_CHATBOT_JOB_LIST:
      return {
        ...state,
        loadingJobListDetails: true,
      };
    case ON_RESUME_UPLOAD_ERROR:
      return {
        ...state,
        isResumeUploadedError: action.value,
      };
    case FETCH_CHATBOT_JOB_DETAILS:
      return {
        ...state,
        loadingJobDetailsData: true,
        jobPositionKey: action.jobPositionKey,
      };
    case ON_LOAD_CHATBOT_JOB_LIST:
      let jobListDetailsLoaded = false;
      if (action.status === false) {
        jobListDetailsLoaded = true;
      }
      const vendorDetails = action.data ? action.data.vendorDetails : {};
      vendorDetails.settings = vendorDetails.settings
        ? JSON.parse(vendorDetails.settings)
        : {};
      return {
        ...state,
        jobListDetailsLoaded,
        loadingJobListDetails: action.status,
        jobListData: action.data
          ? action.data.positionList
          : [...state.jobListData],
        vendorDetails,
      };
    case ON_UPDATE_JOB_CITY_NAMES:
      return {
        ...state,
        cityNames: action.cityNames ? action.cityNames : [...state.cityNames],
      };
    case ON_UPDATE_JOB_DEPARTMENT_ID:
      return {
        ...state,
        jobDepartmentIds: action.id ? [action.id] : [...state.jobDepartmentIds],
      };
    case ON_UPDATE_JOB_SUB_DEPARTMENT_ID:
      return {
        ...state,
        jobSubDepartmentIds: action.id
          ? [action.id]
          : [...state.jobSubDepartmentIds],
      };
    case ON_LOAD_CHATBOT_JOB_DETAILS_DATA:
      let jobDetailsDataLoaded = false;
      if (action.status === false) {
        jobDetailsDataLoaded = true;
      }
      return {
        ...state,
        jobDetailsDataLoaded,
        loadingJobDetailsData: action.status,
        jobDetails: action.data ? action.data : state.jobDetails,
      };
    case LOADING_POSITION_FILTERS:
      let positionFiltersLoaded = false;
      let distributionList =
        (action.filters && action.filters.distributionList) || [];

      if (action.status === false) {
        positionFiltersLoaded = true;
        // convert data structure to our format
        const formattedDistributionList = distributionList.map(
          ({ distributionId: id, title: value, description }) => ({
            id,
            value,
            description,
          })
        );
        //Adding All onLoad
        distributionList = [
          { id: new Date().getTime(), value: "All" },
          ...formattedDistributionList,
        ];
      }
      return {
        ...state,
        loadingPositionFilters: action.status,
        positionFiltersLoaded,
        positionFilters: action.filters ? { ...action.filters } : {},
        distributionList,
      };
    case UPDATE_CLIENT_SELECTED_INDEX:
      const _clientSelectedIndex =
        action.index === null ? state.clientSelectedIndex + 1 : action.index;
      return {
        ...state,
        clientSelectedIndex: _clientSelectedIndex,
      };
    case UPDATE_CLIENT_SELECTED_VALUE:
      return {
        ...state,
        clientSelectedValue: action.value,
      };
    default:
      return state;
  }
};

export {
  chatbotReducer as ChatbotReducer,
  chatbotResetState,
  fetchChatbotJobList,
  onLoadChatbotJobList,
  onUpdateJobCityNames,
  onResumeUploadedError,
  fetchChatbotJobDetails,
  onUpdateJobDepartmentId,
  updateClientSelectedIndex,
  updateClientSelectedValue,
  onUpdateJobSubDepartmentId,
  onLoadChatbotJobDetailsData,
  updatePositionFiltersLoadingStatus,
};
