import Actions from "./actions";

const defaultState = {
    categoryListFetchFail: false,
    categoryListFetched: false,
    categoryListFetchSuccess: false,
    categoryList: null
};

function sharedReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.ON_CATEGORY_LIST_FETCH:
            return {
                ...state,
                categoryListFetchFail: false,
                categoryListFetched: true,
                categoryListFetchSuccess: false
            };
        case Actions.ON_CATEGORY_LIST_FETCH_FAIL:
            return {
                ...state,
                categoryListFetchFail: true,
                categoryListFetched: false,
                categoryListFetchSuccess: false
            };
        case Actions.ON_CATEGORY_LIST_FETCH_SUCCESS:
            return {
                ...state,
                categoryListFetchSuccess: true,
                categoryListFetchFail: false,
                categoryListFetched: false,
                categoryList: action.details
            };
        default:
            return {
                ...state
            };
    }
}

export {
    sharedReducer as SharedReducer
}