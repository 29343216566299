import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CalculatorLayout from 'App/screens/LoginSavingCalculater/shared/CalculatorLayout/CalculatorLayout';

const LoginSavingCalculatorAsync = lazyLoad(() => {
    return import('./components/LoginSavingCalculator');
});

const routePath = '/saving-calculator';

const loginSavingCalcultorRouteDetails = {
    name: 'careerDirectApply',
    exact: true,
    path: routePath,
    render() {
        return <CalculatorLayout 
            name= {loginSavingCalcultorRouteDetails.name}
            desktopComponent= {LoginSavingCalculatorAsync}
            mobileComponent= {LoginSavingCalculatorAsync} />
    }
};

export { loginSavingCalcultorRouteDetails as LoginSavingCalcultorRouteDetails };