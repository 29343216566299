import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';
import './shared/CareerAdd.scss';

const CareerAddComponentAsync = lazyLoad(() => {
    return import('./components/CareerAdd');
});

const routePath = '/careers/:uuid/:positionKey/:type';

const careerAddRouteDetails = {
    name: 'careerAdd',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName="candidateDashboard"
            name={careerAddRouteDetails.name}
            desktopComponent={CareerAddComponentAsync}
            mobileComponent={CareerAddComponentAsync} />
    }
};

export { careerAddRouteDetails as CareerAddRouteDetails };