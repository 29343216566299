import React from "react";
import ProfileImage from "../../images/default_user.png";
import "./UserProfileImage.scss";

const userProfileImage = ({ image, style }) => {
  return (
    <div className="user-profile-image">
      {image ? (
        <img src={image} alt="Profile" />
      ) : style ? (
        <span className="icon-user-circle Profile" style={style} />
      ) : (
        <img src={ProfileImage} alt="Profile" />
      )}
    </div>
  );
};

export default userProfileImage;
