import React from 'react';
import './index.scss';

const saveCalculatorFooter = ({
    companyName,
    poweredBy
}) => {
    const year = new Date().getFullYear();
    return (
        <div className='app-footer saving-footer'>
            <div className="desktop-footer">
                <h3>&copy; {`${year} talentdome. all rights reserved`}</h3>
            </div>
            <div className="mobile-footer">
                
                <h3>&copy; {`${year} talentdome. all rights reserved`}</h3>
            </div>
        </div>
    );
};

export default saveCalculatorFooter;