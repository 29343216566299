import React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { FILE_API_URL } from "App/../constants";
import { Link, withRouter } from "react-router-dom";
import { addRedirectUrlParams } from "../../utils/Url";
import { getCareerUrl } from "App/shared/utils/CareerUrl";
import ApplicationLogo from "../ApplicationLogo/ApplicationLogo";
import "./CareerHeader.scss";

const careerHeader = ({ vendorUUID, vendorFileUUID }) => {
  const vendorLogo = vendorFileUUID ? `${FILE_API_URL}/${vendorFileUUID}` : "";

  return (
    <div className="app-header career-header">
      <div className="app-header-logo">
        <h1 className="vendor">
          <ApplicationLogo logo={vendorLogo} />
        </h1>
        <Link to={addRedirectUrlParams(getCareerUrl(vendorUUID))}>
          <h2>Careers</h2>
        </Link>
      </div>
      <div className="vendor-logo">
        <div className="app-header-logo product-logo">
          <h1>
            <ApplicationLogo logo={"/images/talentdome-logo.png"} />
          </h1>
        </div>
      </div>
    </div>
  );
};

const mapStateWithProps = ({ career }) => {
  return {
    vendorUUID: career.vendorUUID,
    vendorFileUUID: career.vendorFileUUID,
  };
};
const careerHeaderWithApollo = withApollo(careerHeader);
export default connect(
  mapStateWithProps,
  null
)(withRouter(careerHeaderWithApollo));
