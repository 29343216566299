import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const DistributionDetailsComponentAsync = lazyLoad(() => {
    return import('./components/DistributionDetails');
});

const routePath = '/distribution/:id/details';

const distributionDetailsRouteDetails = {
    name: 'distributionDetails',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'distribution',
            breadcrumbName: 'distributionDetails',
            desktopComponent: DistributionDetailsComponentAsync,
            mobileComponent: DistributionDetailsComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'distributionDetails',
    displayName: 'distribution details',
    path: routePath,
    parentName: 'distribution'
};

export { distributionDetailsRouteDetails, breadcrumbDetails as distributionDetailsBreadcrumbDetails };