import React from 'react';
import { connect } from 'react-redux';

import './ListVerticalScrollerBar.scss';

function ListVerticalScollerBar({ children, userListHeight = 500, className }) {
    return (
        <div className={className ? `col-md-4 col-lg-4 list-verical-scroller-bar`
            : 'list-verical-scroller-bar'} style={{ height: `${userListHeight}px` }}>
            <div>
                {children}
            </div>
        </div>
    )
}

const mapStateWithProps = ({ publicTalentPool }) => {
    return {
        userListHeight: publicTalentPool.userListHeight
    }
}


export default connect(mapStateWithProps, null)(ListVerticalScollerBar)