import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { PositionReducer } from './stores/PositionStore';
import './shared/Position.scss';
import Layout from 'App/shared/components/Layout/Layout';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';
import { PositionAddRouteDetails, positionAddBreadcrumbDetails } from 'App/screens/Position/screens/PositionAdd/index';
import { PositionEditRouteDetails, positionEditBreadcrumbDetails } from 'App/screens/Position/screens/PositionEdit/index';
import { PositionDetailsRouteDetails, positionDetailsBreadcrumbDetails } from 'App/screens/Position/screens/PositionDetails/index';
import { PositionShareRouteDetails, positionShareBreadcrumbDetails } from 'App/screens/Position/screens/PositionShare/index';

const PositionDesktopComponentAsync = lazyLoad(() => {
    return import('./components/PositionDesktopPage');
});
const PositionMobileComponentAsync = lazyLoad(() => {
    return import('./components/PositionMobilePage');
});
const routePath = '/positions';
const breadcrumbName = 'positions';
const positionRouteDetails = {
    name: 'positions',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'positions',
            breadcrumbName,
            desktopComponent: PositionDesktopComponentAsync,
            mobileComponent: PositionMobileComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'Jobs',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export {
    positionRouteDetails as PositionRouteDetails,
    PositionReducer,
    breadcrumbDetails as positionBreadcrumbDetails,
    PositionAddRouteDetails,
    positionAddBreadcrumbDetails,
    PositionEditRouteDetails,
    positionEditBreadcrumbDetails,
    PositionDetailsRouteDetails,
    positionDetailsBreadcrumbDetails,
    PositionShareRouteDetails,
    positionShareBreadcrumbDetails
};