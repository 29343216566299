import React from 'react';
import './DescriptionText.scss';

const descriptionText = ({
    description,
    customClass = ''
}) => {
    return (
        <div className="description-text">
            <pre className={`${customClass}`}>
                {description}
            </pre>
        </div>
    );
}

export default descriptionText;