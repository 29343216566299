import React, { Component } from 'react';
import './index.scss';
import { GOOGLE_SEARCH_RESULTS_URL } from '../../../../constants';
export default class GoogleSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResultType: 'linkedIn', // can be linkedIn or other
        }
        this.onSiteChanged = this.onSiteChanged.bind(this)
        this.formGoogleSearchQuery = this.formGoogleSearchQuery.bind(this);
    }
    formGoogleSearchQuery() {
        const searchQuery = [];
        const { searchResultType } = this.state;
        const { stateCode = '', stateName = '' } = this.props.location;
        if (searchResultType === 'linkedIn') {
            searchQuery.push('site:linkedin.com');
            searchQuery.push('(inurl:in OR inurl:pub) -inurl:dir -inurl:job -inurl:jobs');
        } else {
            searchQuery.push('-site:linkedin.com');
        }
        
        let keySkills = this.props.skills.map(skill => `(${skill.label.toLowerCase()})`);
        searchQuery.push(keySkills.join(' '));        
        searchQuery.push(`(${stateCode} OR ${stateName})`);
        if(searchResultType !== 'linkedIn') {
            searchQuery.push('-job, -jobs (filetype:pdf OR filetype:doc OR filetype:docx OR filetype:rtf) (resume OR cv OR biodata OR "bio data" OR "bio-data" OR "curriculum vitae")');            
        }
        return searchQuery.join(' ');
    }
    getGoogleSearchUrl(searchQuery) {
        return `${GOOGLE_SEARCH_RESULTS_URL}?q=${encodeURIComponent(searchQuery)}`
    }
    onSiteChanged(event) {
        const selectedValue = event.target.value;
        this.setState({
            searchResultType: selectedValue
        });
    }
    render() {
        const { searchResultType } = this.state;
        const formGoogleSearchQuery = this.formGoogleSearchQuery();
        const googleSearchUrl = this.getGoogleSearchUrl(formGoogleSearchQuery);        
        return (
            <div className="google-search">
                <div className="google-search__option">
                    <div className="google-search__option__radio-button">
                        <label htmlFor="linkedIn">
                            <input type="radio" name="search"
                                value="linkedIn"
                                id="linkedIn"
                                checked={searchResultType === 'linkedIn' ? true : false}
                                onChange={(e) => this.onSiteChanged(e)} />
                            <span> linkedIn results </span>
                        </label>

                    </div>
                    <div className="google-search__option__radio-button">
                        <label htmlFor="all">
                            <input type="radio" name="search"
                                value="others"
                                id="all"
                                checked={searchResultType === 'others' ? true : false}
                                onChange={(e) => this.onSiteChanged(e)} />
                            <span>
                                other results</span>
                        </label>
                    </div>
                </div>
                <div className="google-search__content">
                    <iframe title="google search" src={googleSearchUrl}
                        className="google-search__content__results"></iframe>
                </div>
            </div>
        )
    }
}



