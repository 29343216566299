const defaultState = {
    distributionList: [],
    loadingDistributionList: false,
    distributionListLoaded: false,
    showMobileFilter: false,
    loadingDistributionDetails: false,
    distributionDetailsLoaded: false,
    sortByValue: 0,
    contactListPageId: 1,
    totalContactCount: null,
    distributionListLimit: 10,
    distributionListPageId: 1,
    totalCount: null,
    distributionDetails: {
        title: '',
        description: ''
    },
    contactListDetails: [],
    distributionListDetails: [],
    contactListTags: [],
    contactList: [],
    distributionDetailsWithdata: {
        title: 'ABC',
        contactList: [{ value: 1, label: "name1" }]
    },
    tableColumns: [
        {
            name: 'Name',
            key: 'title',
            className: 'width-20',
            sortOnHeading: true
        },
        {
            name: 'Description',
            key: 'description',
            className: 'width-40 description-height'
        },
        {
            name: 'Number Of candidates',
            key: 'numberOfContacts',
            className: 'width-20 central-text',
            sortOnHeading: true
        },
        {
            name: 'Date Created',
            key: 'createdAt',
            className: 'width-20',
            sortOnHeading: true
        }

    ],
    sortByFilter: {
        name: 'sortBy',
        displayName: 'Sort By',
        title: 'Sort By'
    },
    filteredSortByItem: [],
    filteredSearchKeys: [],
    sortByOptions: [
        { value: 'title', label: 'Name(Descending)', type: 'sortBy', id: 1 },
        { value: 'title', label: 'Name(Ascending)', type: 'sortBy', id: 2 },
        { value: 'numberOfContacts', label: 'Number Of Contact(Max. First)', type: 'sortBy', id: 3 },
        { value: 'numberOfContacts', label: 'Number Of Contact(Min. First)', type: 'sortBy', id: 4 },
        { value: 'createdAt', label: 'Date Created(Newest First)', type: 'sortBy', id: 5 },
        { value: 'createdAt', label: 'Date Created(Oldest First)', type: 'sortBy', id: 6 }
    ],
    sortByOptionsContact: [
        { value: 'name', label: 'Name(Ascending )', type: 'sortBy', id: 1 },
        { value: 'name', label: 'Name(Descending)', type: 'sortBy', id: 2 },
        { value: 'organizationName', label: 'Organization(Descending)', type: 'sortBy', id: 3 },
        { value: 'organizationName', label: 'Organization(Ascending)', type: 'sortBy', id: 4 },
        { value: 'createdAt', label: 'Date Created(Oldest First)', type: 'sortBy', id: 5 },
        { value: 'createdAt', label: 'Date Created(Newest First)', type: 'sortBy', id: 6 }
    ]
}
const LOADING_DISTRIBUTION_LIST = 'LOADING_DISTRIBUTION_LIST';
const RESET_DISTRIBUTION_STATE = 'RESET_DISTRIBUTION_STATE';
const ON_CHANGE_DISTRIBUTION_SORT_BY_FILTER = 'ON_CHANGE_DISTRIBUTION_SORT_BY_FILTER';
const CLEAR_ALL_DISTRIBUTION_FILTERS = 'CLEAR_ALL_DISTRIBUTION_FILTERS';
const SHOW_HIDE_MOBILE_DISTRIBUTION_FILTER = 'SHOW_HIDE_MOBILE_DISTRIBUTION_FILTER';
const ON_DISTRIBUTION_PAGINATE = 'ON_DISTRIBUTION_PAGINATE';
const APPLY_DISTRIBUTION_MOBILE_FILTERS = 'APPLY_DISTRIBUTION_MOBILE_FILTERS';
const ON_CHANGE_DISTRIBUTION_SEARCH_FILTER = 'ON_CHANGE_DISTRIBUTION_SEARCH_FILTER';
const ON_CHANGE_DISTRIBUTION_CONTACT_LIST = 'ON_CHANGE_DISTRIBUTION_CONTACT_LIST';
const ON_CHANGE_DISTRIBUTION_TITLE_TEXT = 'ON_CHANGE_DISTRIBUTION_TITLE_TEXT';
const LOADING_DISTRIBUTION_DETAILS = 'LOADING_DISTRIBUTION_DETAILS';
const GET_DISTRIBUTION_LIST = 'GET_DISTRIBUTION_LIST';
const ON_DISTRIBUTION_CONTACT_LIST = 'ON_DISTRIBUTION_CONTACT_LIST';
const ON_DISTRIBUTION_CONTACT_PAGINATE = 'ON_DISTRIBUTION_CONTACT_PAGINATE';
const ON_CHANGE_ADD_CONTACT_LIST = 'ON_CHANGE_ADD_CONTACT_LIST';
const ON_CHANGE_DISTRIBUTION_DESCRIPTION = 'ON_CHANGE_DISTRIBUTION_DESCRIPTION';
const ON_CHANGE_LIMIT = 'ON_CHANGE_LIMIT';

//contact list
function onChangeAddContactList(selectedCheckboxContactList) {
    return {
        type: ON_CHANGE_ADD_CONTACT_LIST,
        selectedCheckboxContactList
    }
}
function onChangeDistributionDescription(description) {
    return {
        type: ON_CHANGE_DISTRIBUTION_DESCRIPTION,
        description
    }
}
function updatedistributionListLimit(limit) {

    return {
        type: ON_CHANGE_LIMIT,
        limit
    }
}
function resetState() {
    return {
        type: RESET_DISTRIBUTION_STATE
    };
}
function onChangePagination(pageIndex, isScrollEvent = false) {
    return {
        type: ON_DISTRIBUTION_PAGINATE,
        pageIndex,
        isScrollEvent
    }
}

function updateDistributionDetailsLoadingStatus(status, distributionListDetails) {
    return {
        type: LOADING_DISTRIBUTION_DETAILS,
        status,
        distributionListDetails
    };
}

function onChangeSearchFilter(filteredSearchKeys, loadData = true) {
    return {
        type: ON_CHANGE_DISTRIBUTION_SEARCH_FILTER,
        filteredSearchKeys,
        loadData
    }
}
function onChangeSortByFilter(filteredSortByItem, loadData = true) {
    return {
        type: ON_CHANGE_DISTRIBUTION_SORT_BY_FILTER,
        filteredSortByItem,
        loadData
    }
}
function onClearAllFilters() {
    return {
        type: CLEAR_ALL_DISTRIBUTION_FILTERS
    }
}
function onClickMobileFilter() {
    return {
        type: SHOW_HIDE_MOBILE_DISTRIBUTION_FILTER
    }
}

function applyMobileFilters() {
    return {
        type: APPLY_DISTRIBUTION_MOBILE_FILTERS
    };
}
function updateDistributionListLoadingStatus(status, distribution = null) {

    return {
        type: LOADING_DISTRIBUTION_LIST,
        status,
        distribution
    };
}

function updateDistributionList(status, contactListDetails = null) {
    return {
        type: GET_DISTRIBUTION_LIST,
        status,
        contactListDetails
    }
}

function onSelectContactListName(selectedContact) {
    return {
        type: ON_DISTRIBUTION_CONTACT_LIST,
        selectedContact
    }
}
function onChangeDistributionTitleText(distributionTitleName) {
    return {
        type: ON_CHANGE_DISTRIBUTION_TITLE_TEXT,
        distributionTitleName
    }
}

function _getContactListObjectFromDistributionListDetails(contactListDetails, selectedContactList) {
    let modifiSelectedContactList = [];

    selectedContactList.map((contact) => {
        contactListDetails.find(contactObject => {
            if (contactObject.contactId === contact.value) {
                modifiSelectedContactList.push(contactObject);
            }
            return null;
        });
        return null
    });
    return modifiSelectedContactList;
}


function onChangeDetailsPagination(pageIndex, isScrollEvent = false) {
    return {
        type: ON_DISTRIBUTION_CONTACT_PAGINATE,
        pageIndex,
        isScrollEvent
    }
}

function _getFormattedContactList(contactList) {
    const _contactList = contactList.filter(option => option.value !== 'All');
    return _contactList.map(({ id, candidateId, value, type }) => ({ id: id, firstName: value, type: type, candidateId: candidateId }))
}

const DistributionReducer = (state = defaultState, action) => {
    let distributionDetailsLoaded = false;
    switch (action.type) {
        case RESET_DISTRIBUTION_STATE:
            return {
                ...defaultState
            };
        case ON_CHANGE_DISTRIBUTION_SORT_BY_FILTER:
            return {
                ...state,
                sortByValue: action.filteredSortByItem.id ? action.filteredSortByItem.id : [],
                distributionListLoaded: action.loadData,
                distributionDetailsLoaded: action.loadData,
                distributionList: action.loadData === true ? [] : [...state.distributionList],
                distributionListPageId: 1,
                filteredSortByItem: action.filteredSortByItem ? [action.filteredSortByItem] : []
            };
        case CLEAR_ALL_DISTRIBUTION_FILTERS:
            return {
                ...state,
                filteredSortByItem: [],
                filteredJobCategoryItems: [],
                filteredStatusItems: [],
                filteredJobTypeItems: [],
                filterSelectedDateRange: [],
                filteredSearchKeys: [],
                selectedDateRangeObject: {
                    startDate: null,
                    endDate: null
                }
            };
        case SHOW_HIDE_MOBILE_DISTRIBUTION_FILTER:
            return {
                ...state,
                showMobileFilter: !state.showMobileFilter
            };
        case ON_DISTRIBUTION_PAGINATE:
            return {
                ...state,
                distributionListPageId: action.pageIndex,
                distributionList: action.isScrollEvent === true ? [...state.distributionList] : [],
                distributionListLoaded: false,
            };
        case APPLY_DISTRIBUTION_MOBILE_FILTERS:
            return {
                ...state,
                distributionListLoaded: false,
                showMobileFilter: false
            };
        case LOADING_DISTRIBUTION_LIST:
            let distributionListLoaded = false;

            if (action.status === false) {
                distributionListLoaded = true;
            }
            const distribution = action.distribution || {};
            const distributionList = distribution.distributionList || [];
            const totalItemCount = state.distributionListPageId === 1
                ? distribution.totalCount
                : state.totalCount;

            return {
                ...state,
                loadingDistributionList: action.status,
                distributionListLoaded,
                distributionList: [...distributionList],
                totalCount: totalItemCount
            };

        case ON_CHANGE_ADD_CONTACT_LIST:
            //here
            return {
                ...state,
                distributionDetails: {
                    ...state.distributionDetails,
                    contactDetails: {
                        ...state.distributionDetails.contactDetails,
                        contactList: [..._getFormattedContactList(action.selectedCheckboxContactList)]
                    }
                }
            };

        case ON_CHANGE_LIMIT:
            return {
                ...state,
                distributionListLimit: action.limit
            };

        case LOADING_DISTRIBUTION_DETAILS:
            if (action.status === false) {
                distributionDetailsLoaded = true;
            }
            const distributionDetailsObject = action.distributionListDetails || {};

            const totalItemContactCount = state.contactListPageId === 1
                ? distributionDetailsObject.contactDetails && distributionDetailsObject.contactDetails.totalCount
                : state.totalContactCount;

            return {
                ...state,
                loadingDistributionDetails: action.status,
                distributionDetailsLoaded,

                distributionDetails: { ...distributionDetailsObject },
                totalContactCount: totalItemContactCount

            }

        case ON_DISTRIBUTION_CONTACT_PAGINATE:
            return {
                ...state,
                contactListPageId: action.pageIndex,
                distributionDetailsLoaded: false,
            };

        case ON_CHANGE_DISTRIBUTION_SEARCH_FILTER:
            return {
                ...state,
                distributionListLoaded: !action.loadData,
                distributionList: action.loadData === true ? [] : [...state.distributionList],
                distributionListPageId: 1,
                filteredSearchKeys: [...action.filteredSearchKeys]
            }

        case ON_CHANGE_DISTRIBUTION_CONTACT_LIST:
            distributionDetailsLoaded = false;
            if (action.status === false) {
                distributionDetailsLoaded = true;
            }
            const distributionListDetails = action.distributionListDetails || {};
            const contactDetails = distributionListDetails.contactDetails || {};

            return {
                ...state,
                loadingDistributionDetails: action.status,
                distributionDetailsLoaded,
                distributionListDetails: { ...distributionListDetails },
                contactList: contactDetails.contactList,

            }
        case ON_DISTRIBUTION_CONTACT_LIST:
            const contactList = state.contactListDetails;
            const modifiSelectedContactList = _getContactListObjectFromDistributionListDetails(contactList, action.selectedContact);
            return {
                ...state,
                distributionDetails: {
                    ...state.distributionDetails,
                    contactDetails: {
                        ...state.distributionDetails.contactDetails,
                        contactList: [...modifiSelectedContactList]
                    }
                }
            }

        case ON_CHANGE_DISTRIBUTION_TITLE_TEXT:
            return {
                ...state,
                distributionDetails: {
                    ...state.distributionDetails,
                    title: action.distributionTitleName
                }
            }
        case ON_CHANGE_DISTRIBUTION_DESCRIPTION:
            return {
                ...state,
                distributionDetails: {
                    ...state.distributionDetails,
                    description: action.description
                }
            }
        case GET_DISTRIBUTION_LIST:
            return {
                ...state,
                //Adding All onload
                contactListDetails: [
                    { contactId: new Date().getTime(), firstName: 'All', lastName: '' },
                    ...action.contactListDetails
                ]
            }
        default:
            return state;
    }
}
export {
    resetState,
    DistributionReducer,
    onChangeSortByFilter,
    onClearAllFilters,
    onClickMobileFilter,
    onChangePagination,
    applyMobileFilters,
    onChangeSearchFilter,
    updateDistributionListLoadingStatus,
    onSelectContactListName,
    onChangeDistributionTitleText,
    updateDistributionDetailsLoadingStatus,
    updateDistributionList,
    onChangeDetailsPagination,
    onChangeAddContactList,
    updatedistributionListLimit,
    onChangeDistributionDescription
}