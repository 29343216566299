import React from 'react';

export default function CategoryBoxContainer({
    categoryList,
    onClickCategory
}) {
    return (
        <div className="category-list-conatiner">
            {
                categoryList.length > 0 ?

                    <div>
                        {
                            categoryList.map((category, index) => {
                                const imgName = category.displayName.toLowerCase()
                                return (
                                    <div key={index} className="col-sm-12 col-xs-12 col-md-6 col-lg-4 pl-0 category-block">
                                        <div className="category-body" onClick={e => onClickCategory(category)}>
                                            <div className="category-icon" >
                                                
                                            {/* <img src={require(`../../../shared/images/system adminitrators.svg`)} alt={category.name} /> */}
                                                <img src={require(`../../../shared/images/${imgName}.svg`)} alt={category.displayName} />
                                            </div>
                                            <div className="category-body-title">
                                                <span>
                                                    {category.displayName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> : null

            }
        </div>
    )
}