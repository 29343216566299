import React from 'react';
import './CareerFooter.scss';

const careerFooter = ({
    companyName,
    poweredBy
}) => {
    const year = new Date().getFullYear();

    function conversion(l) {
        let _linkedin_partner_id = "4028068";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        if (!l) {
            window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
            window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s)

    }


    return (
        <div className='app-footer career-footer'>
            <script type="text/javascript">
            </script><script type="text/javascript">
                {
                    conversion('')
                }
            </script>
            <noscript>
                <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4028068&fmt=gif" />
            </noscript>
            <div className="desktop-footer">
                <h3>&copy; {`${year}. all right reserved`}</h3>
                <p> Powered by  <strong>Talentdome</strong> - </p>
                <a href='https://www.infojiniconsulting.com/'><strong>Infojini Consulting</strong></a>
            </div>
            <div className="mobile-footer">
                <h3>&copy; {`${year}. all right reserved`}</h3>
                <p>Powered by  <strong>Talentdome</strong></p>
                <a><strong>Infojini Consulting</strong></a>
            </div>
        </div>
    );
};

export default careerFooter;