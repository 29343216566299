import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import UserListCardBody from './userListCardBody';
import Card from 'App/shared/components/Card/Card';
import UserlistCardHeader from './userlistCardHeader';
import { onUserSelect } from '../../../stores/PublicStore';
import ListView from 'App/shared/components/ListView/ListView';
import ApplicationDateFormat from 'App/shared/components/ApplicationDateFormat/ApplicationDateFormat';
import ApplicationNameFormat from 'App/shared/components/ApplicationNameFormat/ApplicationNameFormat';
import ApplicationLocationFormat from 'App/shared/components/ApplicationLocationFormat/ApplicationLocationFormat';
import ApplicationExperienceYearsAndMonths from 'App/shared/components/ApplicationExperienceYearsAndMonths/ApplicationExperienceYearsAndMonths';
import './userList.scss';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.onUserClick = this.onUserClick.bind(this);
        this.getUserListForListView = this.getUserListForListView.bind(this);
    }
    onUserClick(id) {
        const { history, match, onUserSelect, isMobile } = this.props;
        const { categoryId } = match.params
        if (!isMobile) {
            onUserSelect(id)
        }
        history.push(`/public/talent-pool/${categoryId}/users/${id}`);
    }
    getUserListForListView(_userList) {
        const { selectedUserId } = this.props;
        return _userList.map(({ userId, header, body }) => {
            const details = {
                id: userId,
                header: [{
                    label: '',
                    value: header
                }],
                body: [{
                    label: '',
                    value: body,
                    wrapperClass: ''
                }],
                footer: [{
                    label: '',
                    value: ''
                }]
            };
            const cardClass = (parseInt(selectedUserId) === userId) ? 'user-list-card__border' : ""
            return <Card
                details={details}
                className={`card user-list-card ${cardClass}`}
            />
        });
    }

    render() {
        const { userList } = this.props;
        const updatedUserList = userList.map(({
            candidateId,
            firstName,
            lastName,
            categoryList,
            location,
            experience,
            availableFrom
        }) => ({
            userId: candidateId,
            header: <UserlistCardHeader
                name={<ApplicationNameFormat
                    firstName={firstName}
                    lastName={lastName}
                />}
                position={
                    (categoryList && (categoryList.length > 0))
                        ? categoryList[0].displayName
                        : "-"
                }
                onClick={() => this.onUserClick(candidateId)} />,
            body: <UserListCardBody
                onClick={() => this.onUserClick(candidateId)}
                location={location && location.address ? <ApplicationLocationFormat
                    location={location.address}
                    type="city and state"
                /> : "-"}
                experience={experience ? <ApplicationExperienceYearsAndMonths
                    years={experience.years}
                    months={experience.months}
                    onlyInYear="true" /> : "-"}
                availableFrom={availableFrom ? <ApplicationDateFormat
                    date={availableFrom} shortMonth={true} /> : "ASAP"} />
        }));

        return (
            <div className="user-list-container">
                <ListView list={this.getUserListForListView(updatedUserList)} />
            </div>
        );
    }
}

const mapStateWithProps = ({ deviceIdentifier, publicTalentPool }) => {
    return {
        userList: publicTalentPool.userList,
        isMobile: deviceIdentifier.isMobile,
        selectedUserId: publicTalentPool.selectedUserId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUserSelect(userId) {
            dispatch(onUserSelect(userId))
        }
    };
};

const userListApollo = withApollo(UserList);
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(userListApollo));