import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import Layout from 'App/shared/components/Layout/Layout';
import { DashboardReducer } from './stores/DashboardStore';
import './shared/Dashboard.scss';

const DashboardDesktopComponentAsync = lazyLoad(() => {
    return import('./components/Dashboard');
});

const DashboardMobileComponentAsync = lazyLoad(() => {
    return import('./components/Dashboard');
});

const routePath = '/';
const breadcrumbName = 'dashboard';
const dashboardRouteDetails = {
    name: 'dashboard',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'dashboard',
            breadcrumbName,
            desktopComponent: DashboardDesktopComponentAsync,
            mobileComponent: DashboardMobileComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'dashboard',
    displayName: 'Dashboard',
    path: routePath,
    parentName: null
};

export { 
    dashboardRouteDetails as DashboardRouteDetails, 
    breadcrumbDetails as dashboardBreadcrumbDetails,
    DashboardReducer
};