import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { UUID } from '../../../../../constants';
import { updatePositionFiltersLoadingStatus } from '../stores/ChatbotStore';

const POSITION_FILTERS_QUERY = gql`
    query getPositionFilters($vendorUuid: String!){
        getPositionFilters (
            vendorUuid: $vendorUuid
        ){
            jobDepartments {
                name,
                jobDepartmentId
            },
            skills {
                skillId,
                name
            },
            payTypes {
                payTypeId,
                name
            },
            organisations {
                organisationId,
                name
            },
            distributionList {
                distributionId,
                description,
                title
            }
        }
    }
`;

let positionGetFiltersQuery = ({
    client,
    loadingPositionFilters,
    vendorUUID,
    positionFiltersLoaded,
    updatePositionFiltersLoadingStatus
}) => {
    const uuid = UUID ? UUID : vendorUUID;
    if (loadingPositionFilters === false && positionFiltersLoaded === false) {
        updatePositionFiltersLoadingStatus(true);
        client.query({
            query: POSITION_FILTERS_QUERY,
            variables: {
                vendorUuid: uuid
            }
        })
            .then(details => {
                updatePositionFiltersLoadingStatus(false, details.data.getPositionFilters);
            });
    }
    return '';
};

const mapStateWithProps = ({ chatbot }) => {
    return {
        loadingPositionFilters: chatbot.loadingPositionFilters,
        positionFiltersLoaded: chatbot.positionFiltersLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePositionFiltersLoadingStatus(status, filters = null) {
            dispatch(updatePositionFiltersLoadingStatus(status, filters));
        }
    };
};

positionGetFiltersQuery = withApollo(positionGetFiltersQuery);
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(positionGetFiltersQuery));


