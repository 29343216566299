import React from 'react';
import './SearchInput.scss';

export default class SearchInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: '',
            searchOptions: []
        }
        this.getSearchOptions = this.getSearchOptions.bind(this);
        this.onItemClick = this.onItemClick.bind(this)
    }

    getSearchOptions(event) {
        const { list } = this.props
        let results = [];
        const key = event.target.value;
        if (key.length !== 0) {
            list.filter((option) => {
                if (option.displayName.toLowerCase().indexOf(key.toLowerCase()) !== -1) {
                    results.push(option);
                }
                return null
            })
        }
        else {
            results = []
        }
        this.setState({
            searchKey: key,
            searchOptions: results
        })
    }
    onItemClick(item) {
        this.setState({
            searchKey: item.displayName,
            searchOptions: []
        })
    }

    render() {
        const { clientConfig } = this.props;
        const { searchKey, searchOptions } = this.state;
        const style = {
            background: clientConfig.style.serverResponseBgColor
        }

        return (
            <div className='search-input-container' style={style}>
                <input
                    type="text"
                    id='search-area'
                    value={searchKey}
                    className='search-input-container__searchbox'
                    placeholder={'Enter your course'}
                    onChange={this.getSearchOptions}
                />
                {
                    searchOptions.length > 0 &&
                    <div>
                        <ul className='scrollable-list-details'>
                            {
                                searchOptions.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            onClick={_ => this.onItemClick(item)}
                                            className="list-value">
                                            {item.displayName}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }

            </div>
        )
    }
}
