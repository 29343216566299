import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const AssessmentQuestionsPage = lazyLoad(() => {
    return import('./components/AssessmentQuestionPage.js');
});

const routePath = '/:vendorUUID/candidate/:candidateCode/assessment/questions';
const AssessmentQuestionsRouteDetails = {
    exact: true,
    path: routePath,
    name: 'assessmentResult',
    render() {
        return <CandidateLayout
            activeMenuName = 'candidateDashboard'
            name = {AssessmentQuestionsRouteDetails.name}
            desktopComponent = {AssessmentQuestionsPage}
            mobileComponent = {AssessmentQuestionsPage} 
        />
    }
};

export { AssessmentQuestionsRouteDetails };