import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import './shared/Contact.scss';
import Layout from 'App/shared/components/Layout/Layout';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';
import { ContactReducer } from './stores/ContactStore';
import { ContactAddRouteDetails, contactAddBreadcrumbDetails} from 'App/screens/Contact/screens/ContactAdd/index';
import { ContactEditRouteDetails, contactEditBreadcrumbDetails} from 'App/screens/Contact/screens/ContactEdit/index';
import { ContactDetailsRouteDetails, contactDetailsBreadcrumbDetails} from 'App/screens/Contact/screens/ContactDetails/index';
import { ContactIndividualAddRouteDetails, contactIndividualAddBreadcrumbDetails} from 'App/screens/Contact/screens/ContactIndividualAdd/index';
import { ContactOrganizationAddRouteDetails, ContactOrganizationAddBreadcrumbDetails} from 'App/screens/Contact/screens/ContactOrganizationAdd/index';


const ContactDesktopComponentAsync = lazyLoad(() => {
    return import('./components/ContactDesktopPage');
});
const ContactMobileComponentAsync = lazyLoad(() => {
    return import('./components/ContactMobilePage');
});

const routePath = '/contact';
const breadcrumbName = "candidate profiles";
const contactRouteDetails = {
    name: 'candidate profiles',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'candidateProfiles',
            breadcrumbName,
            desktopComponent: ContactDesktopComponentAsync,
            mobileComponent: ContactMobileComponentAsync
        });
    }
};
const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'candidate profiles',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export {
    contactRouteDetails as ContactRouteDetails,
    ContactReducer,
    breadcrumbDetails as contactBreadcrumbDetails,
    ContactAddRouteDetails,
    contactAddBreadcrumbDetails,
    ContactDetailsRouteDetails,
    contactDetailsBreadcrumbDetails,
    ContactEditRouteDetails,
    contactEditBreadcrumbDetails,
    contactIndividualAddBreadcrumbDetails,
    ContactIndividualAddRouteDetails,
    ContactOrganizationAddBreadcrumbDetails,
    ContactOrganizationAddRouteDetails
};