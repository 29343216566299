import React, { Component } from 'react';
import { Route } from 'react-router';
import UserList from './UserList';
import { connect } from 'react-redux';
import UserDetails from './UserListDetails';
import { withRouter } from 'react-router-dom';
import Loader from 'App/shared/components/Loader/Loader';
import GetCategoryListQuery from '../../../components/getCategoryList'
import { updateUserLodingStatus } from '../../../stores/PublicStore'
import GetUserList from './getUserList'
import ListVerticalScrollerBar from './ListVerticalScollerBar';
import './userListAndDetails.scss'

class UserListAndDetails extends Component {
    componentWillMount() {
        const { history, match, userList, isMobile, userListLoaded } = this.props;
        if (userListLoaded) {
            const userId = userList.length > 0 && parseInt(userList[0].candidateId)
            const { categoryId } = match.params
            if (history.location.pathname === match.url && !isMobile) {
                history.push(`/public/talent-pool/${categoryId}/users/${userId}`);
            }
        }
    }
    componentDidUpdate() {
        const { history, match, userList, isMobile, userListLoaded } = this.props;
        if (userListLoaded) {
            const userId = userList.length > 0 && parseInt(userList[0].candidateId)
            const { categoryId } = match.params
            if (history.location.pathname === match.url && !isMobile) {
                history.push(`/public/talent-pool/${categoryId}/users/${userId}`);
            }
        }
    }

    render() {
        const {
            match,
            history,
            isMobile,
            isDesktop,
            userListLoaded,
            loadingUserList,
            categoryListLoaded,
            loadingCategoryList
        } = this.props
        return (
            <div>
                {
                    userListLoaded === false && loadingUserList === false
                        ? <GetUserList />
                        : null
                }
                {
                    categoryListLoaded === false && loadingCategoryList === false ?
                        <GetCategoryListQuery />
                        : null
                }
                {
                    userListLoaded === false || categoryListLoaded === false ?
                        <div className="public-talentpool-loader">
                            <Loader />
                        </div>
                        : <div className="row">
                            {
                                isDesktop ?
                                    <React.Fragment>
                                        <ListVerticalScrollerBar className={'col-sm-12 col-xs-12 col-md-12 col-lg-4'}>
                                            <UserList />
                                        </ListVerticalScrollerBar>
                                        <div className='col-md-12 col-lg-8 user-list-and-details'>
                                            <Route path="/public/talent-pool/:categoryId/users/:userId"
                                                component={UserDetails} />
                                        </div>
                                    </React.Fragment> : null
                            }
                            {
                                isMobile ?
                                    <React.Fragment>
                                        {
                                            match.url === history.location.pathname ?
                                                <React.Fragment>
                                                    <UserList />
                                                </React.Fragment> : null
                                        }
                                        {
                                            match.url !== history.location.pathname ?
                                                <div className='col-sm-12 col-xs-12 col-md-12 col-lg-6 user-list-and-details'>
                                                    <Route exact path="/public/talent-pool/:categoryId/users/:userId" component={UserDetails} />
                                                </div> : null
                                        }
                                    </React.Fragment> : null
                            }
                        </div>
                }
            </div>

        )
    }
}

const mapStateWithProps = ({ deviceIdentifier, publicTalentPool }) => {
    return {
        isMobile: deviceIdentifier.isMobile,
        isDesktop: deviceIdentifier.isDesktop,
        selectedUserId: publicTalentPool.selectedUserId,
        userList: publicTalentPool.userList,
        userListLoaded: publicTalentPool.userListLoaded,
        loadingUserList: publicTalentPool.loadingUserList,
        categoryListLoaded: publicTalentPool.categoryListLoaded,
        loadingCategoryList: publicTalentPool.loadingCategoryList
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateUserLodingStatus(status) {
            dispatch(updateUserLodingStatus(status))
        }
    }
}

export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(UserListAndDetails))
