import React from "react";
import { connect } from "react-redux";
import "./Loader.scss";

const Loader = ({ theme }) => {
  const imagePath = (theme && theme.loaderUrl) || "/images/loader.gif";
  return (
    <div className="loader">
      <img src={imagePath} alt="loader-img" />
    </div>
  );
};

const mapStateWithProps = ({ setting }) => setting;

export default connect(
  mapStateWithProps,
  null
)(Loader);
