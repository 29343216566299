import React from "react";
import { withRouter, Link } from "react-router-dom";
import ApplicationCustomDropdown from "App/shared/components/ApplicationCustomDropdown";
import NotificationlistGetQuery from "App/screens/Candidate/screens/Login/components/notificationListGetQuery";
import NotificationCountGetQuery from "App/screens/Candidate/screens/Login/components/notificationCountGetQuery";
import "./CandidateMobileHeader.scss";
import VendorLogo from "../VendorLogo";

const CandidateMobileHeader = ({
  uuid,
  count,
  theme,
  resetCount,
  onMenuClick,
  vendorFileUUID,
  onDropDownClick,
  notificationList,
  candidateHeaderUrl,
  onNotificationClick,
  isCandidateLoggedIn,
  loadingNotificationList,
}) => {
  const headerTheme = theme ? { color: theme.primaryColor } : {};
  const notificationBell = (
    <div>
      <span className="icon-bell bell-icon" style={headerTheme} />
      {count ? (
        <div className="candidate-info-mobile__notifications__count">
          {" "}
          {count}{" "}
        </div>
      ) : null}
    </div>
  );
  const notificationsList =
    notificationList.length > 0
      ? notificationList
      : [{ displayName: "No Result Found" }];
  const isEmptyList = notificationList.length > 0 ? false : true;

  const backgroundColor = theme
    ? {
        backgroundColor: theme.primaryColor,
        borderColor: theme.primaryColor,
      }
    : {};

  return (
    <div className="candidate-mobile-header">
      <div className="candidate-mobile-header__menu">
        <div className="menubar">
          <div className="hamburger" onClick={onMenuClick}>
            <div className="hamburger-menu" style={backgroundColor} />
            <div className="hamburger-menu" style={backgroundColor} />
            <div className="hamburger-menu" style={backgroundColor} />
          </div>
          {isCandidateLoggedIn && (
            <React.Fragment>
              <NotificationlistGetQuery uuid={uuid} />
              <NotificationCountGetQuery uuid={uuid} />
              <div className="candidate-info-mobile__notifications">
                <ApplicationCustomDropdown
                  theme={theme}
                  activeMenuName={""}
                  isNotification={true}
                  resetCount={resetCount}
                  title={notificationBell}
                  isEmptyList={isEmptyList}
                  onDropDownClick={onDropDownClick}
                  dropdownOptions={notificationsList}
                  loadingList={loadingNotificationList}
                  className="application-custom-notifiaction-dropdown-content"
                  onDropdownOptionClick={(value) => onNotificationClick(value)}
                />
              </div>
            </React.Fragment>
          )}
          <Link to={candidateHeaderUrl}>
            <h2 style={headerTheme}> Careers </h2>
          </Link>
        </div>
      </div>
      <div className="app-header-logo">
        <VendorLogo />
      </div>
    </div>
  );
};

export default withRouter(CandidateMobileHeader);
