import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/PositionDetails.scss';

const PositionDetailsComponentAsync = lazyLoad(() => {
    return import('./components/PositionDetails');
});

const routePath = '/positions/details/:id';

const positionDetailsRouteDetails = {
    name: 'positionDetails',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'positions',
            breadcrumbName: 'positionDetails',
            desktopComponent: PositionDetailsComponentAsync,
            mobileComponent: PositionDetailsComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'positionDetails',
    displayName: 'job details',
    path: routePath,
    parentName: 'positions'
};

export { positionDetailsRouteDetails as PositionDetailsRouteDetails, breadcrumbDetails as positionDetailsBreadcrumbDetails };