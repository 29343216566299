import { FILE_API_URL } from 'App/../constants';

const defaultState = {
    contactList: [],
    contactListLoaded: false,
    contactListLimit: 10,
    contactListPageId: 1,
    distributionListPageId: 1,
    downloadResumeUrl: '',

    loadingContactList: false,
    loadingDistributionList: false,

    loadingContactDetails: false,
    contactDetailsLoaded: false,

    totalCount: null,
    totalDistributionCount: null,
    sortByValue: 0,
    contactFormDetails: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        organizationName: '',
        location: {},
        primarySkillsList: '',
        secondarySkillsList: '',
        locationNearbyStates: '',
        selectedFile: {
            file: null,
            isUploaded: false,
            isFileScanning: null,
            resumeId: '',
            isUploadedError: false
        },
        distributionDetails: {},
        experience: {
            months: '',
            years: ''
        },
        linkedInProfileLink: ''
    },
    distributionList: [],
    contactDetailsAddress: {},
    distributionListTags: [],
    tableColumns: [
        {
            name: 'Name',
            key: 'name',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2',
            sortOnHeading: true
        },
        {
            name: 'Email',
            key: 'email',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2 lowercase'
        },
        {
            name: 'Contact No.',
            key: 'contactNo',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
        },
        {
            name: 'Organization',
            key: 'organizationName',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2',
            sortOnHeading: true
        },
        {
            name: 'Location',
            key: 'location',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2'
        },
        {
            name: 'Date Created',
            key: 'createdAt',
            className: 'col-lg-2 col-md-2 col-sm-2 col-xs-2',
            sortOnHeading: true
        }
    ],
    sortByFilter: {
        name: 'sortBy',
        displayName: 'Sort By',
        title: 'Sort By'
    },
    filteredSortByItem: [],
    filteredSearchKeys: '',
    sortByOptions: [
        { value: 'name', label: 'Name(   Descending)', type: 'sortBy', id: 1 },
        { value: 'name', label: 'Name(Ascending)', type: 'sortBy', id: 2 },
        { value: 'organizationName', label: 'Organization(Descending)', type: 'sortBy', id: 3 },
        { value: 'organizationName', label: 'Organization(Ascending)', type: 'sortBy', id: 4 },
        { value: 'createdAt', label: 'Date Created(Newest First)', type: 'sortBy', id: 5 },
        { value: 'createdAt', label: 'Date Created(Oldest First)', type: 'sortBy', id: 6 }
    ],
    sortByDistributionOptions: [
        { value: 'title', label: 'Name(Descending)', type: 'sortBy', id: 1 },
        { value: 'title', label: 'Name(Ascending)', type: 'sortBy', id: 2 },
        { value: 'numberOfContacts', label: 'Number Of Contact(Max. First)', type: 'sortBy', id: 3 },
        { value: 'numberOfContacts', label: 'Number Of Contact(Min. First)', type: 'sortBy', id: 4 },
        { value: 'createdAt', label: 'Date Created(Newest First)', type: 'sortBy', id: 5 },
        { value: 'createdAt', label: 'Date Created(Oldest First)', type: 'sortBy', id: 6 }
    ],
    showMobileFilter: false,
    yesNoOptions: [
        {
            id: 2,
            label: "No",
        },
        {
            id: 1,
            label: "Yes",
        },
    ],
    contractTypeOptions: [
        {
            id: 2,
            label: "W2",
        },
        {
            id: 1,
            label: "C2C",
        },
        {
            id: 3,
            label: "1990",
        },
    ],
    visaStatusOptions: [
        {
            value: 2,
            label: "H1B",
        },
        {
            value: 1,
            label: "OPT EAD",
        },
        {
            value: 3,
            label: "USC",
        },
        {
            value: 4,
            label: "GC",
        },
        {
            value: 5,
            label: "L2S",
        },
        {
            value: 6,
            label: "Canadian citizen",
        },
        {
            value: 7,
            label: "Others",
        }
    ],
    AvailablilityOfStudentOptions: [
        {
            value: 1,
            label: "Immediate",
        },
        {
            value: 2,
            label: "With in a week",
        },
        {
            value: 3,
            label: "With in 2 weeks",
        },
        {
            value: 4,
            label: "Less than a month",
        },
    ],
    remoteWorkcms: 2,
    contractTypeCms: 2,
    visaStatusCms: '',
    AvailablilityOfStudentSelectedCms: ''

}
const RESET_CONTACT_STATE = 'RESET_CONTACT_STATE';

const ON_CHANGE_FIRST_NAME_TEXT = 'ON_CHANGE_FIRST_NAME_TEXT';
const ON_CHANGE_LAST_NAME_TEXT = 'ON_CHANGE_LAST_NAME_TEXT';
const ON_CHANGE_EMAIL_TEXT = 'ON_CHANGE_EMAIL_TEXT';
const ON_CHANGE_CONTACT_NUMBER_TEXT = 'ON_CHANGE_CONTACT_NUMBER_TEXT';
const ON_CHANGE_ORGANIZATION_NAME_TEXT = 'ON_CHANGE_ORGANIZATION_NAME_TEXT';
const ON_CHANGE_LOCATION_DETAILS_TEXT = 'ON_CHANGE_LOCATION_DETAILS_TEXT';
const ON_CHANGE_DISTRIBUTED_GROUP_NAME_TEXT = 'ON_CHANGE_DISTRIBUTED_GROUP_NAME_TEXT';

const LOADING_CONTACT_LIST = 'LOADING_CONTACT_LIST';
const ON_CHANGE_CONTACT_SORT_BY_FILTER = 'ON_CHANGE_CONTACT_SORT_BY_FILTER';
const CLEAR_ALL_CONTACT_FILTERS = 'CLEAR_ALL_CONTACT_FILTERS';
const SHOW_HIDE_MOBILE_CONTACT_FILTER = 'SHOW_HIDE_MOBILE_CONTACT_FILTER';
const ON_CONTACT_PAGINATE = 'ON_CONTACT_PAGINATE';
const APPLY_CONTACT_MOBILE_FILTERS = 'APPLY_CONTACT_MOBILE_FILTERS';
const ON_CHANGE_CONTACT_SEARCH_FILTER = 'ON_CHANGE_CONTACT_SEARCH_FILTER'
const LOADING_CONTACT_DETAILS = 'LOADING_CONTACT_DETAILS';
const ON_DISTRIBUTION_LIST_DETAILS = 'ON_DISTRIBUTION_LIST_DETAILS';
const ON_CONTACT_DISTRIBUTION__PAGINATE = 'ON_CONTACT_DISTRIBUTION__PAGINATE';
const ON_CHANGE_PRIMARYSKILLS = 'ON_CHANGE_PRIMARYSKILLS';
const ON_CHANGE_SECONDARYSKILLS = 'ON_CHANGE_SECONDARYSKILLS';
const ON_CHANGE_STATES = 'ON_CHANGE_STATES'; //used for state change is called
const ON_UPLOAD_FILE = 'ON_UPLOAD_FILE';
const ON_REMOVE_FILE = 'ON_REMOVE_FILE';
const ON_FILE_SCANNING = 'ON_FILE_SCANNING';
const UPDATE_RESUME_ID = 'UPDATE_RESUME_ID';
const ON_DOWNLOAD_RESUME = 'ON_DOWNLOAD_RESUME';
const ON_UPLOAD_FILE_ERROR = 'ON_UPLOAD_FILE_ERROR';
const ON_CHANGE_CONTANCT_EXPERINCE_YEAR = 'ON_CHANGE_CONTANCT_EXPERINCE_YEAR';
const ON_CHANGE_CONTANCT_EXPERINCE_MONTH = 'ON_CHANGE_CONTANCT_EXPERINCE_MONTH';
const ON_CHANGE_CONTACT_LINKEDIN_PROFILE_LINK = 'ON_CHANGE_CONTACT_LINKEDIN_PROFILE_LINK';
const ON_CHANGE_REMOTE_WORK_OPTION = 'ON_CHANGE_REMOTE_WORK_OPTION';
const ON_CHANGE_CONTRACT_TYPE_OPTION = 'ON_CHANGE_CONTRACT_TYPE_OPTION';
const ON_CHANGE_VISA_STATUS_OPTION = 'ON_CHANGE_VISA_STATUS_OPTION';
const ON_CHANGE_AVAILABILITY_STUDENT_OPTION = 'ON_CHANGE_AVAILABILITY_STUDENT_OPTION';

function resetState() {
    return {
        type: RESET_CONTACT_STATE
    };
}

function onChangeRemoteWorkOption({ id }) {
    // console.log('here remote');
    return {
        type: ON_CHANGE_REMOTE_WORK_OPTION,
        id,
    };
}

function onChangeContractTypeOption({ id }) {
    // console.log('here remote');
    return {
        type: ON_CHANGE_CONTRACT_TYPE_OPTION,
        id,
    };
}

function onChangeVisaStatusOption({ label }) {
    // console.log('here remote');
    return {
        type: ON_CHANGE_VISA_STATUS_OPTION,
        label,
    };
}

function onChangeAvailabilityStudent({ label }) {
    // console.log('here remote');
    return {
        type: ON_CHANGE_AVAILABILITY_STUDENT_OPTION,
        label,
    };
}



function onChangeContactExperienceYears(experinceYear) {
    return {
        type: ON_CHANGE_CONTANCT_EXPERINCE_YEAR,
        experinceYear
    }
}
function onChangeContactExperienceMonths(experinceMonth) {
    return {
        type: ON_CHANGE_CONTANCT_EXPERINCE_MONTH,
        experinceMonth
    }
}

function onChangeContactLinkedInProfileLink(linkedinUrl) {
    return {
        type: ON_CHANGE_CONTACT_LINKEDIN_PROFILE_LINK,
        linkedinUrl
    }
}

function onChangePagination(pageIndex, isScrollEvent = false) {
    return {
        type: ON_CONTACT_PAGINATE,
        pageIndex,
        isScrollEvent
    }
}
function updateDistributionList(status, distributionList) {
    return {
        type: ON_DISTRIBUTION_LIST_DETAILS,
        status,
        distributionList
    }
}
function onChangeSearchFilter(filteredSearchKeys, loadData = true) {
    return {
        type: ON_CHANGE_CONTACT_SEARCH_FILTER,
        filteredSearchKeys,
        loadData
    }
}
function onChangeSortByFilter(filteredSortByItem, loadData = true) {
    return {
        type: ON_CHANGE_CONTACT_SORT_BY_FILTER,
        filteredSortByItem,
        loadData
    }
}

function onDownloadResume(resumeId) {
    return {
        type: ON_DOWNLOAD_RESUME,
        resumeId
    }
}

function onClearAllFilters() {
    return {
        type: CLEAR_ALL_CONTACT_FILTERS
    }
}
function onClickMobileFilter() {
    return {
        type: SHOW_HIDE_MOBILE_CONTACT_FILTER
    }
}

function applyMobileFilters() {
    return {
        type: APPLY_CONTACT_MOBILE_FILTERS
    };
}
function updateContactListLoadingStatus(status, contact = null) {
    return {
        type: LOADING_CONTACT_LIST,
        status,
        contact
    };
}
function updateContactDetailsLoadingStatus(status, contactDetails = null) {
    return {
        type: LOADING_CONTACT_DETAILS,
        status,
        contactDetails
    };
}

function onChangeFirstNameText(firstName) {
    return {
        type: ON_CHANGE_FIRST_NAME_TEXT,
        firstName
    }
}
function onChangeLastNameText(lastName) {
    return {
        type: ON_CHANGE_LAST_NAME_TEXT,
        lastName
    }
}
function onChangeEmailText(email) {
    return {
        type: ON_CHANGE_EMAIL_TEXT,
        email
    }
}
function onChangeContactNumberText(contactNumber) {
    return {
        type: ON_CHANGE_CONTACT_NUMBER_TEXT,
        contactNumber
    }
}
function onChangeOrganizationNameText(organizationName) {
    return {
        type: ON_CHANGE_ORGANIZATION_NAME_TEXT,
        organizationName
    }
}
function onChangeContactLocation(location) {
    return {
        type: ON_CHANGE_LOCATION_DETAILS_TEXT,
        location
    }
}

function onSelectDistributedGroup(distributedGroupName) {
    return {
        type: ON_CHANGE_DISTRIBUTED_GROUP_NAME_TEXT,
        distributedGroupName
    }
}

function _getFormatedDistributionList(distributionList) {
    const _distributionList = distributionList.filter(option => option.value !== 'All');
    return _distributionList.map(({ id: distributionId, value: title }) => ({ distributionId, title }));
}

function updateResumeId(resumeId) {
    return {
        type: UPDATE_RESUME_ID,
        resumeId
    }
}



function onSelectPrimarySkills(primarySkills) {
    return {
        type: ON_CHANGE_PRIMARYSKILLS,
        primarySkills
    }
}
function onSelectSecondarySkills(secondarySkills) {
    return {
        type: ON_CHANGE_SECONDARYSKILLS,
        secondarySkills
    }
}

function onSelectStates(selectedStates) {
    return {
        type: ON_CHANGE_STATES,
        selectedStates
    }
}


function onFileUpload(file) {
    return {
        type: ON_UPLOAD_FILE,
        file
    }
}

function onFileUploadError(value) {
    return {
        type: ON_UPLOAD_FILE_ERROR,
        value
    }
}

function onRemoveUploadedFile() {
    return {
        type: ON_REMOVE_FILE
    }
}
function onChangeDetailsPagination(pageIndex, isScrollEvent = false) {
    return {
        type: ON_CONTACT_DISTRIBUTION__PAGINATE,
        pageIndex,
        isScrollEvent
    }
}
function onFileScanning(status) {
    return {
        type: ON_FILE_SCANNING,
        status
    }
}
const ContactReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_CONTACT_STATE:
            return {
                ...defaultState
            };
        case ON_CHANGE_FIRST_NAME_TEXT:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    firstName: action.firstName.trim() !== '' ? action.firstName : ''
                }
            };
        case ON_CHANGE_LAST_NAME_TEXT:

            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    lastName: action.lastName.trim() !== '' ? action.lastName : ''
                }
            };
        case ON_CHANGE_EMAIL_TEXT:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    email: action.email.trim() !== '' ? action.email : ''
                }
            };
        case ON_CHANGE_CONTACT_NUMBER_TEXT:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    phoneNumber: action.contactNumber.trim() !== '' ? action.contactNumber : ''
                }
            };
        case ON_CHANGE_ORGANIZATION_NAME_TEXT:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    organizationName: action.organizationName.trim() !== '' ? action.organizationName : ''
                }
            };
        case ON_CHANGE_LOCATION_DETAILS_TEXT:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    location: {
                        ...action.location
                    }
                }
            };
        case ON_CHANGE_DISTRIBUTED_GROUP_NAME_TEXT:
            //here
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    distributionDetails: {
                        ...state.distributionDetails,
                        distributionList: [..._getFormatedDistributionList(action.distributedGroupName)]
                    }
                }
            }
        case ON_CHANGE_CONTANCT_EXPERINCE_MONTH:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    experience: {
                        ...state.contactFormDetails.experience,
                        months: action.experinceMonth
                    }
                }
            }
        case ON_CHANGE_CONTACT_LINKEDIN_PROFILE_LINK:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    linkedInProfileLink: action.linkedinUrl
                }
            }
        case ON_CHANGE_REMOTE_WORK_OPTION:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    remoteWorkcms: action.id
                }
            }
        case ON_CHANGE_CONTRACT_TYPE_OPTION:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    contractTypeCms: action.id
                }
            }
        case ON_CHANGE_VISA_STATUS_OPTION:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    visaStatusCms: action.label
                }
            }
        case ON_CHANGE_AVAILABILITY_STUDENT_OPTION:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    AvailablilityOfStudentSelectedCms: action.label
                }
            }
        case ON_CHANGE_CONTANCT_EXPERINCE_YEAR:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    experience: {
                        ...state.contactFormDetails.experience,
                        years: action.experinceYear
                    }
                }
            }
        case ON_DOWNLOAD_RESUME:
            function getDownloadResumeUrl() {
                return `${FILE_API_URL}/${action.uuid}`;
            }
            return {
                ...state,
                downloadResumeUrl: getDownloadResumeUrl()
            };
        case ON_CHANGE_PRIMARYSKILLS:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    primarySkillsList: [...action.primarySkills]
                }
            }

        case ON_CHANGE_SECONDARYSKILLS:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    secondarySkillsList: [...action.secondarySkills]
                }
            }
        case ON_CHANGE_STATES:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    locationNearbyStates: [...action.selectedStates]
                }
            }

        case ON_UPLOAD_FILE:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    selectedFile: {
                        ...state.contactFormDetails.selectedFile,
                        file: action.file
                    }
                }
            }
        case ON_UPLOAD_FILE_ERROR:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    selectedFile: {
                        ...state.contactFormDetails.selectedFile,
                        isUploadedError: action.value
                    }
                }
            }
        case ON_REMOVE_FILE:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    selectedFile: {
                        ...state.contactFormDetails.selectedFile,
                        file: null,
                        isUploaded: false,
                        resumeId: ''
                    }
                }
            }
        case ON_FILE_SCANNING:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    selectedFile: {
                        ...state.contactFormDetails.selectedFile,
                        isFileScanning: action.status
                    }
                }

            };
        case UPDATE_RESUME_ID:
            return {
                ...state,
                contactFormDetails: {
                    ...state.contactFormDetails,
                    selectedFile: {
                        ...state.contactFormDetails.selectedFile,
                        resumeId: action.resumeId,
                        isUploaded: true
                    }
                }
            }

        case ON_CHANGE_CONTACT_SORT_BY_FILTER:
            return {
                ...state,
                sortByValue: action.filteredSortByItem.id ? action.filteredSortByItem.id : 1,
                contactListLoaded: action.loadData,
                contactDetailsLoaded: action.loadData,
                contactList: action.loadData === true ? [] : [...state.contactList],
                contactListPageId: 1,
                filteredSortByItem: action.filteredSortByItem ? [action.filteredSortByItem] : []
            };

        case CLEAR_ALL_CONTACT_FILTERS:
            return {
                ...state,
                filteredSortByItem: [],
                filteredJobCategoryItems: [],
                filteredStatusItems: [],
                filteredJobTypeItems: [],
                filterSelectedDateRange: [],
                filteredSearchKeys: [],
                selectedDateRangeObject: {
                    startDate: null,
                    endDate: null
                }
            };

        case SHOW_HIDE_MOBILE_CONTACT_FILTER:
            return {
                ...state,
                showMobileFilter: !state.showMobileFilter
            };

        case ON_CONTACT_PAGINATE:
            return {
                ...state,
                contactListLoaded: false,
                contactListPageId: action.pageIndex,
                contactList: action.isScrollEvent === true ? [...state.contactList] : []
            };

        case APPLY_CONTACT_MOBILE_FILTERS:
            return {
                ...state,
                contactListLoaded: false,
                showMobileFilter: false
            };
        case LOADING_CONTACT_LIST:
            let contactListLoaded = false;
            if (action.status === false) {
                contactListLoaded = true;
            }
            const contact = action.contact || {};
            const contactList = contact.contactList || [];
            const totalItemCount = state.contactListPageId === 1
                ? contact.totalCount
                : state.totalCount;

            return {
                ...state,
                loadingContactList: action.status,
                contactListLoaded,
                contactList: [...contactList],
                totalCount: totalItemCount
            };

        case LOADING_CONTACT_DETAILS:
            let selectedFile;
            let experience;
            let phoneNumber;
            let contactDetailsLoaded = false;
            if (action.status === false) {
                contactDetailsLoaded = true;
            }
            const contactDetails = action.contactDetails || {};
            //console.log(action);
            //console.log(action.contactDetails);
            //console.log(state.distributionDetails);
            const distributionDetails = (state.distributionDetails && action.contactDetails && action.contactDetails.distributionDetails) || ''
            const _address = action.contactDetails && action.contactDetails.location && action.contactDetails.location.address;
            if (contactDetails.resumeDetails && contactDetails.resumeDetails !== null) {
                selectedFile = {
                    file: {
                        name: contactDetails.resumeDetails.maskName,
                        id: contactDetails.resumeDetails.resumeId
                    },
                    isUploaded: contactDetails.resumeDetails.maskName && contactDetails.resumeDetails.uuid ? true : false,
                    isFileScanning: null,
                    resumeId: contactDetails.resumeDetails.uuid
                }
            } else {
                selectedFile = {
                    file: null,
                    isUploaded: false,
                    isFileScanning: null,
                    resumeId: ''
                }
            }
            if (action.contactDetails && !action.contactDetails.phoneNumber) {
                phoneNumber = ''
            } else {
                phoneNumber = action.contactDetails && action.contactDetails.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
            }
            if (action.contactDetails && !action.contactDetails.experience) {
                experience = {
                    years: '',
                    months: ''
                }
            } else {
                experience = {
                    years: action.contactDetails && action.contactDetails.experience.years
                        ? action.contactDetails.experience.years : '0',
                    months: action.contactDetails && action.contactDetails.experience.months
                        ? action.contactDetails.experience.months : '0'
                }
            }
            if (action.contactDetails && action.contactDetails.socialDetails && action.contactDetails.socialDetails.length > 0) {
                contactDetails.linkedInProfileLink = action.contactDetails.socialDetails[0].value
            }
            const totalCountItem = state.contactListPageId === 1
                ? contactDetails.distributionDetails && contactDetails.distributionDetails.totalCount
                : state.totalCount;

            let addressData = [];
            if (_address) {
                const { lat, lng, address: { addressLine1 = '', cityName = '', stateName = '', stateCode = '', zipCode = '', country = '' } } = action.contactDetails.location;
                contactDetails.location = {
                    lat,
                    lng,
                    address: {
                        addressLine1,
                        cityName,
                        stateName,
                        stateCode,
                        country,
                        zipCode
                    }
                };

                if (_address.addressLine1) {
                    addressData.push(_address.addressLine1)
                }
                if (_address.cityName) {
                    addressData.push(_address.cityName);
                }
                if (_address.stateCode) {
                    addressData.push(_address.stateCode);
                }
                if (_address.country) {
                    addressData.push(_address.country);
                }
                if (_address.zipCode) {
                    addressData.push(_address.zipCode);
                }
            }
            return {
                ...state,
                contactFormDetails: { ...contactDetails, selectedFile, experience, phoneNumber },
                distributionDetails: { ...distributionDetails },
                loadingContactDetails: action.status,
                contactDetailsLoaded,
                totalDistributionCount: totalCountItem,
                contactDetailsAddress: addressData.join(', ')
            }
        case ON_CONTACT_DISTRIBUTION__PAGINATE:

            return {
                ...state,
                distributionListPageId: action.pageIndex,
                // contactList: action.isScrollEvent === true ? [...state.contactList] : [],
                contactDetailsLoaded: false,
            };
        case ON_CHANGE_CONTACT_SEARCH_FILTER:
            return {
                ...state,
                contactListLoaded: !action.loadData,
                contactList: action.loadData === true ? [] : [...state.contactList],
                contactListPageId: 1,
                filteredSearchKeys: action.filteredSearchKeys
            };
        case ON_DISTRIBUTION_LIST_DETAILS:
            return {
                ...state,
                distributionList: [
                    //Adding all on load
                    { distributionId: new Date().getTime(), title: 'All' },
                    ...action.distributionList]
            }

        default:
            return state;
    }
}


export {
    resetState,
    ContactReducer,
    onChangeFirstNameText,
    onChangeLastNameText,
    onChangeEmailText,
    onChangeContactNumberText,
    onChangeOrganizationNameText,
    onChangeContactLocation,
    onSelectDistributedGroup,
    onChangeSortByFilter,
    onClearAllFilters,
    onClickMobileFilter,
    onChangePagination,
    applyMobileFilters,
    onChangeSearchFilter,
    updateContactListLoadingStatus,
    updateContactDetailsLoadingStatus,
    onChangeDetailsPagination,
    updateDistributionList,
    onSelectPrimarySkills,
    onSelectSecondarySkills,
    onFileUpload,
    onFileUploadError,
    onRemoveUploadedFile,
    onFileScanning,
    updateResumeId,
    onDownloadResume,
    onChangeContactExperienceYears,
    onChangeContactExperienceMonths,
    onChangeContactLinkedInProfileLink,
    onChangeRemoteWorkOption,
    onChangeContractTypeOption,
    onChangeVisaStatusOption,
    onChangeAvailabilityStudent,
    onSelectStates// added for states option method selected
}