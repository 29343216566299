import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import './JobDetails.scss'

export default class JobDetails extends Component {
    componentWillMount() {
        this.props.chatbotResetState();
    }
    onApplyClick() {
        localStorage.removeItem("jobPositionKey")
        // Google Analytics Event
        ReactGA.event({
            category: "chatbot-job-apply-form",
            action: "chatbot job apply form",
            label: "chatbot job apply form"
        })
        const { details, onClickJobApply } = this.props;
        onClickJobApply(details.jobPositionKey)
    }
    onReferClick() {
        localStorage.removeItem("jobPositionKey")
        ReactGA.event({
            category: "chatbot-job-refer-form",
            action: "chatbot job refer form",
            label: "chatbot job refer form"
        })
        const { details, onClickReferJob } = this.props;
        onClickReferJob(details.jobPositionKey)
    }
    onJobDetailsClick(details) {
        // Google Analytics Event
        ReactGA.event({
            category: "chatbot-job-details-page",
            action: "chatbot job details page",
            label: "chatbot job details page"
        })
        const { onChatbotJobClick } = this.props;
        if (onChatbotJobClick) {
            onChatbotJobClick(details)
        }
    }
    render() {
        const { isScrollableList = false, details, detailsRef = null } = this.props;
        const { totalExperience, title, subTitle, primarySkills, location, description } = details;
        const totalExperienceDetails = totalExperience && totalExperience !== null
            ? totalExperience.type === "Single"
                ? totalExperience.value.totalExperience
                : `${totalExperience.value.minExperience} - ${totalExperience.value.maxExperience}`
            : null
        return (
            isScrollableList
                ? (
                    <div className='chatbot-job-details scrollable-job-list'
                        onClick={(e) => this.onJobDetailsClick(details)}>
                        <div className="ellipsis">
                            {
                                title
                                    ? <div className="ellipsis">
                                        <span className="job_title">{title}</span>
                                    </div> : null
                            }
                            <div className='ellipsis'>
                                <span className="description-text">{description} </span>
                            </div>
                        </div>
                        <div className='job-list-experience-and-location'>
                            {
                                totalExperience && totalExperience.type === "NotRequired"
                                    ? null
                                    : (totalExperienceDetails && totalExperienceDetails !== null)
                                    && <div className='job-list-experience'>
                                        <div className="details-div">
                                            <span className="icon-briefcase icomo-icons" />
                                            <span className="details-span">
                                                {totalExperienceDetails} years
                                        </span>
                                        </div>
                                    </div>
                            }

                            <div className={totalExperience && totalExperience.type === "NotRequired" ? "job-list-details details-span-full-width" : "job-list-details"}>
                                {
                                    location && location.address
                                        ? <div className="details-div">
                                            <span className="icon-location2 icomo-icons" />
                                            <span className={totalExperience && totalExperience.type === "NotRequired" ? "details-span details-span-full-width" : "details-span"}>
                                                {`${location.address.addressLine1}, ${location.address.country}`}
                                            </span>
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div ref={detailsRef} className="chatbot-job-details">
                        <div className="chatbot-job-details__header">
                            {
                                title
                                    ? <div className="chatbot-job-details__header__title">
                                        <span className="job_title">{title}</span>
                                    </div> : null
                            }
                            <div className="chatbot-job-details__header__action">
                                <div className="apply-button-div">
                                    <ActionButton
                                        title={"refer"}
                                        onClick={this.onReferClick.bind(this)}
                                        className='chatbot-action-button chatbot-action-button-cancel'
                                    />
                                </div>
                                <div className="apply-button-div">
                                    <ActionButton
                                        title={"apply"}
                                        onClick={this.onApplyClick.bind(this)}
                                        className='chatbot-action-button'
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            subTitle
                                ? <div className="chatbot-job-details__sub-header">
                                    <span className="company_name">{subTitle}</span>
                                </div> : null
                        }
                        <div className="chatbot-job-details__job-deatils">
                            {
                                totalExperience && totalExperience.type === "NotRequired" ? null :
                                    totalExperienceDetails && totalExperienceDetails !== null
                                        ? <div className="details-div">
                                            <span className="icon-briefcase icomo-icons" />
                                            <span className="details-span">
                                                {totalExperienceDetails} years
                                            </span>
                                        </div> : null
                            }
                            {
                                location && location.address
                                    ? <div className="details-div">
                                        <span className="icon-location2 icomo-icons" />
                                        <span className="details-span">
                                            {`${location.address.addressLine1}, ${location.address.country}`}
                                        </span>
                                    </div> : null
                            }
                        </div>
                        {
                            primarySkills && primarySkills.length > 0
                                ? <div className="skills-div">
                                    <div>
                                        <span className="icon-pen icomo-icons"></span>
                                        {
                                            primarySkills.map(({ skillId, name }, index) => {
                                                return (
                                                    <span className="details-span" key={skillId}>
                                                        {name}
                                                        {
                                                            (index !== (primarySkills.length - 1)) && <span>, </span>
                                                        }
                                                    </span>

                                                )
                                            })
                                        }
                                    </div>

                                </div> : null
                        }
                        {
                            description
                                ? <div className="chatbot-job-details__description">
                                    <div> <span className="details-heding">description </span></div>
                                    <div> <span className="description-text">{description} </span> </div>
                                </div> : null
                        }
                        <div className="chatbot-job-details__footer">
                            <div className="apply-button-div">
                                <ActionButton
                                    title={"refer"}
                                    onClick={this.onReferClick.bind(this)}
                                    className='chatbot-action-button chatbot-action-button-cancel'
                                />
                            </div>
                            <div className="apply-button-div">
                                <ActionButton
                                    title={"apply"}
                                    onClick={this.onApplyClick.bind(this)}
                                    className='chatbot-action-button'
                                />
                            </div>
                        </div>
                    </div>
                )
        )
    }
}