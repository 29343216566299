import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import PublicHeader from '../shared/publicHeader';
import PublicFooter from '../shared/publicFooter';
import PublicTalentpoolCategoryPage from './publicTalentpoolCategoryPage';
import UserListComponent from '../screens/UserDetails/components/userListAndDetails';

class publicTalentPoolRouting extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        const { history } = this.props

        return (
            <div id="publicLayout" className="public-layout">
                <PublicHeader />
                <div className="public-layout-body ">
                    <div className="public-layout-body-header">
                        {
                            history.location.pathname === '/public' || history.location.pathname === '/public/talent-pool' ? null :
                                <div className="header-back">
                                    <Link to={`/public/talent-pool`}>
                                        <span>
                                            &#60; back
                                        </span>
                                    </Link>
                                </div>
                        }
                        <div className="public-layout-body-conatiner col-md-12 col-lg-11 align-items-center justify-content-center">
                            <Switch>
                                <Route exact path='/public/talent-pool'
                                    component={PublicTalentpoolCategoryPage} />
                                <Route path='/public/talent-pool/:categoryId/users'
                                    component={UserListComponent} />
                            </Switch>
                        </div>
                    </div>
                </div>
                <PublicFooter />
            </div>
        );
    }
}

export default publicTalentPoolRouting;