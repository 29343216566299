const defaultState = {
    voucherList: [],
    totalPoints: '',
    voucherListDataLoaded: false,
    loadingVoucherListData: false
}

//dispatchers
function resetState() {
    return {
        type: RESET_STATE
    }
}
function loadVoucherListForCandidate(status, data) {
    return {
        type: LOAD_VOUCHER_LIST_FOR_CANDIDATE,
        data,
        status
    }
}

//actions
const RESET_STATE = 'RESET_STATE';
const LOAD_VOUCHER_LIST_FOR_CANDIDATE = 'LOAD_VOUCHER_LIST_FOR_CANDIDATE';


//reducer
const voucherReducer = (state = defaultState, action) => {
    switch(action.type) {
        case RESET_STATE:
            return {
                ...defaultState
            }
        case LOAD_VOUCHER_LIST_FOR_CANDIDATE:
            let voucherListDataLoaded = false;
            if (action.status === false) {
                voucherListDataLoaded = true;
            }
            return {
                ...state,
                voucherListDataLoaded,
                loadingVoucherListData: action.status,
                totalPoints: action.data ? action.data.totalPoints : '',
                voucherList: action.data ? [...action.data.voucherList] : []
            }
        default:
            return state;
    }
}
export {
    voucherReducer as VoucherReducer,
    resetState,
    loadVoucherListForCandidate
}