import React from 'react';
import './ActionButton.scss';

const actionButton = ({
    type,
    title,
    onClick,
    className,
    themeStyle={},
    showDisabledActionButton
}) => {
    return (
        <button
            style={themeStyle}
            type={type}
            onClick={onClick}
            className={className}
            disabled={showDisabledActionButton}>
            {title}
        </button>
    );
};

export default actionButton;