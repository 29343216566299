import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import { TalentPoolReducer } from './stores/TalentPoolStore';
import './shared/TalentPool.scss';
import Layout from 'App/shared/components/Layout/Layout';
import { dashboardBreadcrumbDetails } from 'App/screens/Dashboard/index';
import { TalentPoolDetailsRouteDetails, talentPoolDetailsBreadcrumbDetails } from 'App/screens/TalentPool/screens/TalentPoolDetails/index';
import { TalentPoolEditRouteDetails, TalentPoolEditBreadcrumbDetails } from 'App/screens/TalentPool/screens/TalentPoolEdit/index'

const TalentPoolDesktopComponentAsync = lazyLoad(() => {
    return import('./components/TalentPoolDesktopPage');
});

const TalentPoolMobileComponentAsync = lazyLoad(() => {
    return import('./components/TalentPoolMobilePage');
});

const routePath = '/talent-pool';
const breadcrumbName = 'talentPool';
const talentPoolRouteDetails = {
    name: 'talentPool',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout, {
            activeMenuName: 'talentPool',
            breadcrumbName,
            desktopComponent: TalentPoolDesktopComponentAsync,
            mobileComponent: TalentPoolMobileComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: breadcrumbName,
    displayName: 'Talent Pool',
    path: routePath,
    parentName: dashboardBreadcrumbDetails.name
};

export {
    TalentPoolReducer,
    breadcrumbDetails as talentPoolBreadcrumbDetails,
    talentPoolRouteDetails as TalentPoolRouteDetails,
    TalentPoolDetailsRouteDetails,
    talentPoolDetailsBreadcrumbDetails,
    TalentPoolEditRouteDetails,
    TalentPoolEditBreadcrumbDetails
};