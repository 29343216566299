const defaultState = {
    resolution: null,
    isDesktop: false,
    isMobile: false
};

const DEVICE_RESOLUTION_CHANGED = 'DEVICE_RESOLUTION_CHANGED';

function onChangeDeviceResolution(resolution) {
    return {
        type: DEVICE_RESOLUTION_CHANGED,
        resolution 
    };
}

const deviceIdentifierReducer = (state = defaultState, action) => {
    switch(action.type) {
        case DEVICE_RESOLUTION_CHANGED:
            const isDesktop = action.resolution > 992 ? true : false;
            const isMobile = !isDesktop;
            return {
                ...state,
                isDesktop,
                isMobile,
                resolution: action.resolution
            };
        default:
            return {...state};
    }
};

export {
    deviceIdentifierReducer,
    onChangeDeviceResolution
};