import { connect } from 'react-redux';
import { onChangeDeviceResolution } from './DeviceIdentifierStore';
const deviceIdentifier = ({
    resolution,
    onChangeDeviceResolution
}) => {
    function handleWindowSizeChange() {
        onChangeDeviceResolution(_getResolution());
    }

    function _getResolution() {
        return window.innerWidth;
    }

    if(resolution === null) {
        // If resolution not set, set current resolution
        onChangeDeviceResolution(_getResolution());
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return '';
};

const mapStateWithProps = (state) => {
    return {
        resolution: state.deviceIdentifier.resolution
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeDeviceResolution(resolution) {
            dispatch(onChangeDeviceResolution(resolution));
        }
    };
};

export default connect(mapStateWithProps, mapDispatchToProps)(deviceIdentifier);