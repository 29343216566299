import React from 'react';
import '../shared/ChatbotText.scss';

export default function ChatbotSelectedOption({
    selectedOption
}) {
    return (
        selectedOption
            ? <div className="chatbot-text chatbot-text-right">
                <span>{selectedOption}</span>
            </div> : null
    )
}