import "react-app-polyfill/ie11";
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
// import rootSaga from './saga';
// adding a test comment for amplify build
import { API_URL, GOOGLE_ANALYTICS_ACCOUNT_ID } from "./constants";
import { Provider } from "react-redux";
import { onError } from "apollo-link-error";
import { App, Reducers } from "./App/index";
import { ApolloClient } from "apollo-client";
import Toastr from "App/shared/utils/Toastr";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter } from "react-router-dom";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createStore, combineReducers } from "redux";
import registerServiceWorker from "./registerServiceWorker";
import {
  redirectToLogin,
  getSessionToken,
  getCandidateSessionToken,
} from "App/shared/utils/Authentication";
import "App/shared/style/_reset.scss";
import "App/shared/style/_common.scss";
import ReactGA from "react-ga4";
import { redirectToCandidateLogin } from "./App/shared/components/talentdome-chatbot/src/App/shared/utils/Authentication";
ReactGA.initialize(GOOGLE_ANALYTICS_ACCOUNT_ID);
// ReactGA.initialize('UA-156802141-1');
const authLink = setContext((request, { headers }) => {
  let token = null;
  if (request.variables.uuid || request.variables.vendorUuid) {
    // get candidate token
    token = getCandidateSessionToken(
      request.variables.uuid || request.variables.vendorUuid
    );
  } else {
    // get recruiter or admin token
    token = getSessionToken();
  }

  return token
    ? {
        headers: {
          ...headers,
          authorization: `${token}`,
        },
      }
    : { headers };
});

const invalidSessionLink = onError(({ operation, graphQLErrors }) => {
  if (
    graphQLErrors.length > 0 &&
    graphQLErrors.find(({ errorCode }) => errorCode === 401)
  ) {
    Toastr.error("Session Expired");
    setTimeout((_) => {
      if (window.location.href.indexOf("/career/")) {
        // if the user is the end user and not recruiter
        // redirect to career page
        if (operation && operation.variables && operation.variables.uuid) {
          redirectToCandidateLogin(operation.variables.uuid);
        } else {
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }
    }, 1000);
  }
});

const httpLink = createHttpLink({
  uri: API_URL,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(invalidSessionLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

const store = createStore(combineReducers(Reducers));
const unsubscribe = store.subscribe(() => {
  // logic to update the favicon dynamically for a theme
  const state = store.getState();
  if (
    state.setting &&
    state.setting.theme &&
    state.setting.theme.talentdomeFavicon
  ) {
    const faviconEl = document.querySelector("link[rel~='icon']");
    faviconEl.href = state.setting.theme.talentdomeFavicon;
    unsubscribe();
  } else {
    if(state.setting && state.setting.settingsDataLoaded) {
      unsubscribe();
    }
  }
});

// sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

registerServiceWorker();
