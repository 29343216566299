import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const ResetPasswordAsync = lazyLoad(() => {
    return import('./components/ResetPassword');
});
// const routePath = '/:vendorUUID/candidate/:code/reset-password';
const routePath = '/:vendorUUID/reset-password/:token';

const ResetPasswordRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateResetPassword',
    render() {
        return (
            <CandidateLayout
                activeMenuName = 'candidateDashboard'
                name = {ResetPasswordRouteDetails.name}
                desktopComponent = {ResetPasswordAsync}
                mobileComponent = {ResetPasswordAsync} 
            />
        )
    }
};

export { ResetPasswordRouteDetails };