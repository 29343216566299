import React from 'react';
import Select from 'react-select';
import { Field } from 'redux-form';
import Error from '../Error/Error';
import { FormGroup, Label } from 'reactstrap';
import InputField from '../InputField/InputField';
import './Autocomplete.scss';

const autoComplete = ({
    name,
    meta,
    label,
    input,
    options,
    isRequired,
    placeholder,
    isSearchable,
    wrapperClass,
    selectedValue,
    onChangeInput,
    isMultiSelected,
    maxItems = false,
    height = null,
    ...rest
}) => {
    let customStyles = {
        container: () => ({
            'div': {
                border: 'none'
            }
        }),
        placeholder: () => ({
            color: '#89969f',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: '400',
            paddingTop: '10px'
        }),
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor: (state.isSelected || state.isFocused) ? '#f4f8fc' : '',
                color: (state.isSelected || state.isFocused) ? '#266dad' : '#000',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                textTransform: 'none !important',
                ':active': {
                    backgroundColor: '#fff'
                }
            }
        },
        multiValueLabel: () => ({
            padding: '7px 16px',
            backgroundColor: '#dddddd',
            color: '#00539d',
            fontSize: '12px',
            textTransform: 'none',
        }),
        multiValueRemove: () => ({
            padding: '4px 9px 0 0px',
            background: '#dddddd',
            color: '#00539d',
            cursor: 'pointer'
        })
    }

    const _onChangeInput = (value) => {
        if (maxItems) {
            return value.length <= maxItems ? onChangeInput(value) : null;
        } else {
            return onChangeInput(value)
        }
    }
    const _options = options && options.map(({ value, label }) => ({ value, label }));
    const hiddenInputValue = selectedValue && selectedValue.length > 0 ? 'ValidAutocomplete' : '';
    // let menuList;
    if (height) {
        let menuList = (base) => ({
            ...base,
            height: height
        })
        customStyles.menuList = menuList
    }
    return (
        <FormGroup className={wrapperClass || 'autocomplete'}>
            <Label className="d-block">
                <span> {label} </span>
                {
                    (isRequired && label) ? <span className="required-field">*</span> : null
                }
                <Select
                    {...rest}
                    {...input}
                    options={_options}
                    value={selectedValue}
                    styles={customStyles}
                    closeMenuOnSelect={true}
                    placeholder={placeholder}
                    onChange={_onChangeInput}
                    isMulti={isMultiSelected}
                    hideSelectedOptions={true}
                    isSearchable={isSearchable}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: 'white',
                        },
                    })} />
                <Field
                    type="text"
                    id="autoComplete"
                    name="autoComplete"
                    isRequired={false}
                    component={InputField}
                    inputFieldValue={hiddenInputValue}
                />
            </Label>
            <Error show={meta.touched && !!meta.error} text={meta.error} />
        </FormGroup>
    );
}

export default autoComplete;