import React from 'react';
import { connect } from 'react-redux';
import AppHeader from 'App/shared/components/AppHeader/AppHeader';
import AppHeaderMobile from 'App/shared/components/AppHeaderMobile/AppHeaderMobile';
import MobileMenubar from 'App/shared/components/MobileMenubar/MobileMenubar';
import AppFooter from 'App/shared/components/AppFooter/AppFooter';
// import Sidebar from 'App/shared/components/Sidebar/Sidebar';
import UserProfileImage from 'App/shared/components/UserProfileImage/UserProfileImage';
import UserProfileName from 'App/shared/components/UserProfileName/UserProfileName';
import Breadcrumb from 'App/shared/components/Breadcrumb/Breadcrumb';
import {
    onClickMobileMenubarAction
} from './LayoutStore';
import { getUserDetails } from 'App/shared/utils/Authentication';
import 'App/shared/components/Layout/Layout.scss';

const layout = ({
    isMobile,
    isDesktop,
    desktopComponent: DesktopComponent,
    mobileComponent: MobileComponent,
    activeMenuName, breadcrumbName,
    layout,
    hideShowMobileMenubar,
    vendorFileUuid
}) => {
    const userDetails = getUserDetails();
    let mobileMenubarDefaultClassName = 'app-mobile-menubar';
    let showMobileMenubarClassName = 'show-menubar';
    mobileMenubarDefaultClassName = layout.showMenubar ? `${mobileMenubarDefaultClassName} ${showMobileMenubarClassName}` : 'app-mobile-menubar';

    function getActiveMenu(menuItems, activeMenuName) {
        const activeMenu = menuItems.find(menu => menu.name === activeMenuName);
        return activeMenu.name;
    }

    //menu list for mobile menubar
    function MobileMenuListItem({ item }) {
        return (
            <div key={item.name}>
                <span>{item.displayName}</span>
            </div>
        );
    }

    // userprofile component for mobile menubar
    function UserProfile({userDetails}) {
        return (
            <div className="user-profile">
                <div className="user-profile-image">
                    <UserProfileImage />
                </div>
                <div className="user-profile-name">
                    <UserProfileName firstName={userDetails.firstName} lastName={userDetails.lastName} />
                </div>
            </div>
        );
    }

    //get menu list for mobile
    function getMobileMenus() {
        const activeMenu = getActiveMenu(layout.sidebarItems, activeMenuName);
        let menus = [...layout.sidebarItems, { name: 'Logout', displayName: 'Logout', path: "" }];

        menus = menus.map(menu => ({
            component: <MobileMenuListItem item={menu} />,
            className: menu.name === activeMenu ? 'active-mobile-menu' : '',
            path: menu.path
        }));
        menus = [{ component: <UserProfile userDetails={userDetails}/>, className: 'user-profile-mobile-menubar' }, ...menus];
        return menus;
    }
    const breadcrumbItems = [...layout.breadcrumbItems];
    return (
        <div className='app-layout'>
            {/* DeskTop Layout */}
            {isDesktop === true
                ? (<div className='app-desktop-layout'>
                    <AppHeader
                        sidebarItems={userDetails.roleName == 'executive' ?  layout.executiveSidebarItems : layout.sidebarItems}
                        activeMenu={getActiveMenu(layout.sidebarItems, activeMenuName)}
                        selectedMenuItem={getActiveMenu(layout.sidebarItems, activeMenuName)}
                        vendorFileUuid={vendorFileUuid} />
                    <div className="main">
                        {/*<div className='app-sidebar'>
                            <Sidebar
                                sidebarItems={layout.sidebarItems}
                                activeMenu={getActiveMenu(layout.sidebarItems, activeMenuName)} />
                        </div>*/}
                        <div className='app-component' id="app-component">
                            {
                                breadcrumbName !== 'dashboard' ? <div className='app-breadcrumb'>
                                    <Breadcrumb
                                        breadcrumbItems={breadcrumbItems}
                                        activeBreadcrumbItem={breadcrumbName} />
                                </div> : null
                            }
                            <div className="desktop-component">
                                <DesktopComponent />
                            </div>
                        </div>
                    </div>
                    <AppFooter />
                </div>)
                : ''}

            {/* Mobile Layout */}
            {isMobile === true
                ? (<div className='app-mobile-layout'>
                    <AppHeaderMobile onMenuClick={hideShowMobileMenubar} />
                    <div className="main">
                        <div className={mobileMenubarDefaultClassName} onClick={hideShowMobileMenubar}>
                            <MobileMenubar list={getMobileMenus()} />
                        </div>
                        <MobileComponent />
                    </div>
                    <AppFooter />
                </div>)
                : ''}
        </div>
    );
};

const mapStateWithProps = (state) => {
    return {
        layout: state.layout,
        isMobile: state.deviceIdentifier.isMobile,
        isDesktop: state.deviceIdentifier.isDesktop,
        vendorFileUuid: state.login.vendorFileUuid
    };
};

const mapDispatchToProps = dispatch => ({
    hideShowMobileMenubar: event => dispatch(onClickMobileMenubarAction(event))
});

export default connect(mapStateWithProps, mapDispatchToProps)(layout);