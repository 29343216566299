import React from "react";
import { connect } from "react-redux";
import ApplicationLogo from "../ApplicationLogo/ApplicationLogo";

function VendorLogo(props) {
  let vendorLogo = "";
  let vendorUrl = "";
  if (props.vendorDetails && props.vendorDetails.settings) {
    if (props.category && props.vendorDetails.settings.categories) {
      // if a vendor has category speciifc logo
      const vendorCategorySettings = props.vendorDetails.settings.categories.find(
        (value) => value.name === props.category
      );
      if (vendorCategorySettings && vendorCategorySettings.logo) {
        vendorLogo = vendorCategorySettings.logo;
        vendorUrl = vendorCategorySettings.websiteUrl;
      } else {
        // if not, then use the default logo
        vendorLogo = props.vendorDetails.settings.logo;
        // default web url of vendor
        vendorUrl = props.vendorDetails.settings.websiteUrl;
      }
    } else {
      // default logo of vendor
      vendorLogo = props.vendorDetails.settings.logo;
      // default web url of vendor
      vendorUrl = props.vendorDetails.settings.websiteUrl;
    }
  }

  return (
    <h1 className="vendor">
      <ApplicationLogo logo={vendorLogo} link={vendorUrl} />
    </h1>
  );
}
const mapStateToProps = ({ setting }) => setting;
export default connect(
  mapStateToProps,
  null
)(VendorLogo);
