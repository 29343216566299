import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const MyProfileEditComponentAsync = lazyLoad(() => {
    return import('./components/MyProfileEdit');
});

const routePath = '/:vendorUUID/candidate/:accountId/edit';

const MyProfileRouteEditDetails = {
    name: 'myProfileEdit',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout
            activeMenuName = 'myProfile'
            name = {MyProfileRouteEditDetails.name}
            desktopComponent = {MyProfileEditComponentAsync}
            mobileComponent = {MyProfileEditComponentAsync} 
        />
    }
};

export { 
    MyProfileRouteEditDetails
};