import React, { Component } from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import Toastr from 'App/shared/utils/Toastr';
import { withRouter } from 'react-router-dom';
import GetInformationForm from './GetInformationForm';
import soldierIcon from 'App/shared/images/soldier1.svg';
import downArrow from 'App/shared/images/close_accordian.png';
import Tags from '../../../../../shared/components/Tags/Tags';
import briefcaseIcon from 'App/shared/images/XMLID_491_.svg';
import locationIcon from 'App/shared/images/placeholder (1).svg';
import willingToRelocateIcon from 'App/shared/images/willing-to-relocate.png';
import DescriptionText from '../../../../../shared/components/DescriptionText/DescriptionText';
import ApplicationDateFormat from 'App/shared/components/ApplicationDateFormat/ApplicationDateFormat';
import ApplicationNameFormat from 'App/shared/components/ApplicationNameFormat/ApplicationNameFormat';
import ApplicationLocationFormat from 'App/shared/components/ApplicationLocationFormat/ApplicationLocationFormat';
import ApplicationExperienceYearsAndMonths from 'App/shared/components/ApplicationExperienceYearsAndMonths/ApplicationExperienceYearsAndMonths';
import {
    onUserSelect,
    updateUserListHeight,
    updateUserLodingStatus,
    updateSelectedCategoryName,
    updateInformationFormCaptcha
} from '../../../stores/PublicStore';
import './UserListDetails.scss';

class UserListDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitSuccess: false,
            getInformationFormDetails: {
                fullName: '',
                email: '',
                phone: ''
            }
        }
        this.detailsRef = React.createRef();
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onChangeFieldValue = this.onChangeFieldValue.bind(this);
    }
    componentDidMount() {
        const { onUserSelect, updateUserListHeight, match, isMobile } = this.props;
        updateUserListHeight(this.detailsRef.current.clientHeight)
        if (!isMobile && !(this.detailsRef.current.clientHeight === 0)) {
            onUserSelect(match.params.userId)
        }
    }
    componentDidUpdate() {
        const { updateUserListHeight } = this.props;
        updateUserListHeight(this.detailsRef.current.clientHeight);
    }
    onCategoryChange(name) {
        const {
            history,
            categoryList,
            userListLoaded,
            updateUserLodingStatus,
            updateSelectedCategoryName,

        } = this.props;
        const category = categoryList.find(({ displayName }) => displayName === name)
        if (userListLoaded) {
            updateUserLodingStatus(false)
        }
        updateSelectedCategoryName(category);
        history.push(`/public/talent-pool/${category.categoryId}/users`);
    }
    onChangeCaptcha(captcha) {
        const { updateInformationFormCaptcha } = this.props
        updateInformationFormCaptcha(captcha);
    }
    onFormSubmit(values) {
        const { match, client, updateUserLodingStatus } = this.props;
        const details = {
            name: values.fullName.trim(),
            email: values.email,
            phoneNumber: values.phone,
            categoryId: parseInt(match.params.categoryId),
            candidateId: parseInt(match.params.userId)
        }
        this.setState({ formSubmitSuccess: true });

        return client.mutate({
            mutation: gql`mutation addHireTalentRequest($hireTalentRequestDetails: HireTalentRequestInput){
                addHireTalentRequest(hireTalentRequestDetails: $hireTalentRequestDetails) {
                  name,
                  email
                }
               }`,
            variables: {
                'hireTalentRequestDetails': details
            }
        })
            .then(_ => {
                updateUserLodingStatus(false);
                Toastr.success("Thank you for your interest in Talentdome.");
            })
            .catch(err => console.log(err));
    }
    onChangeFieldValue(value, type) {
        const { getInformationFormDetails } = this.state;
        switch (type) {
            case 'fullName':
                this.setState({
                    getInformationFormDetails: {
                        ...getInformationFormDetails,
                        fullName: value
                    }
                });
                break;
            case 'email':
                this.setState({
                    getInformationFormDetails: {
                        ...getInformationFormDetails,
                        email: value
                    }
                });
                break;
            case 'phone':
                this.setState({
                    getInformationFormDetails: {
                        ...getInformationFormDetails,
                        phone: value
                    }
                });
                break;
            default: break;
        }
    }

    render() {
        const { formSubmitSuccess, getInformationFormDetails } = this.state;
        const {
            match,
            captcha,
            userList,
            categoryList,
        } = this.props;
        const category = categoryList.find(({ categoryId }) => categoryId === parseInt(match.params.categoryId))
        const userDetails = userList.find(({ candidateId }) => candidateId === parseInt(match.params.userId));
        const primarySkills = userDetails && userDetails.primarySkills &&
            userDetails.primarySkills.map(({ name }) => name);
        const secondarySkills = userDetails && userDetails.secondarySkills &&
            userDetails.secondarySkills.map(({ name }) => name);
        const experience = userDetails && userDetails.experience;

        return (
            <React.Fragment>
                {
                    userDetails ?
                        <div ref={this.detailsRef} className="user-list-details">
                            <div className="header-container">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="primary-color user-name">
                                            <ApplicationNameFormat
                                                firstName={userDetails.firstName}
                                                lastName={userDetails.lastName}
                                            />
                                        </div>
                                        <div className="user-role">
                                            {
                                                (userDetails.categoryList && (userDetails.categoryList.length > 0))
                                                    ? userDetails.categoryList[0].displayName
                                                    : "-"
                                            }
                                        </div>
                                    </div>
                                    <div className="select-talentpool-dropdown desktop-dropdown">
                                        <div className="primary-color text"> Select Talentpool </div>
                                        <select
                                            defaultValue={category.displayName}
                                            onChange={e => { this.onCategoryChange(e.target.value) }}>
                                            {
                                                categoryList.map(({ displayName }, index) => {
                                                    return (
                                                        <option key={index}> {displayName} </option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <img src={downArrow} alt="arrow" />
                                    </div>
                                </div>
                                <div className="experience-availablity-fields font-size-16 d-flex mt-3">
                                    {
                                        experience && (experience.months !== 0 || experience.years !== 0)
                                            ? (
                                                <div className="experience-field">
                                                    <span>
                                                        <img src={briefcaseIcon} alt="briefcase-icon" />
                                                    </span>
                                                    <div>
                                                        <ApplicationExperienceYearsAndMonths
                                                            years={experience.years}
                                                            months={experience.months}
                                                            onlyInYear="true" /> Experience
                                                            </div>
                                                </div>
                                            ) : null
                                    }
                                    <div className="available-date font-size-16">
                                        <span className="icon-calendar" />
                                        <div>
                                            <span className="text">
                                                Available From:
                                        </span>
                                            {
                                                userDetails.availableFrom
                                                    ? <ApplicationDateFormat
                                                        shortMonth={true}
                                                        date={userDetails.availableFrom} />
                                                    : "ASAP"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="location-fields d-flex font-size-16">
                                    <div className="location">
                                        <img src={locationIcon} alt="location-icon" />
                                        {
                                            (userDetails.location && userDetails.location.address)
                                                ? <ApplicationLocationFormat
                                                    location={userDetails.location.address}
                                                    type="city and state" />
                                                : "-"
                                        }
                                    </div>
                                    <div className="willing-to-relocate font-size-16">
                                        <img src={willingToRelocateIcon} alt="location-icon" />
                                        <span> Willing to Relocate: </span>
                                        <span className="color-gray">
                                            {userDetails.willingToRelocate ? "Yes" : "No"}
                                        </span>
                                    </div>
                                </div>
                                {
                                    userDetails && userDetails.veteranStatus && (
                                        <div className="veterian-status font-size-16">
                                            <img src={soldierIcon} alt="solider-icon" />
                                            <span> Veteran/Veteran spouse: </span>
                                            <span className="color-gray"> Yes </span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="select-talentpool-dropdown mobile-dropdown">
                                <div className="primary-color text"> Select Talentpool </div>
                                <select
                                    defaultValue={category.displayName}
                                    onChange={e => { this.onCategoryChange(e.target.value) }}>
                                    {
                                        categoryList.map(({ displayName }, index) => {
                                            return (
                                                <option key={index}> {displayName} </option>
                                            );
                                        })
                                    }
                                </select>
                                <img src={downArrow} alt="arrow" />
                            </div>
                            <div className="user-list-details__summary">
                                <div className="primary-color title"> Summary </div>
                                {
                                    userDetails.summary
                                        ? <DescriptionText description={userDetails.summary} />
                                        : "-"
                                }
                            </div>
                            <div className="user-list-details__key-skills">
                                <div className="primary-color mb-4 title"> Key Skills </div>
                                <div className="skill-tags">
                                    <div className="title">Primary Skills:</div>
                                    <Tags tags={primarySkills} />
                                </div>
                                <div className="skill-tags">
                                    <div className="title">Secondary Skills:</div>
                                    <Tags tags={secondarySkills} />
                                </div>
                            </div>
                            <div className="get-information-form-container">
                                <div className="title">
                                    Enter your information to get more details:
                                </div>
                                <GetInformationForm
                                    captcha={captcha}
                                    onFormSubmit={this.onFormSubmit}
                                    formSubmitSuccess={formSubmitSuccess}
                                    onChangeCaptcha={this.onChangeCaptcha}
                                    formDetails={getInformationFormDetails}
                                    onFieldChange={this.onChangeFieldValue}
                                />
                            </div>
                        </div>
                        : <div ref={this.detailsRef}></div>
                }
            </React.Fragment>

        );
    }
}

const mapStateWithProps = ({ deviceIdentifier, publicTalentPool }) => {
    return {
        captcha: publicTalentPool.captcha,
        isMobile: deviceIdentifier.isMobile,
        userList: publicTalentPool.userList,
        categoryList: publicTalentPool.categoryList,
        userListLoaded: publicTalentPool.userListLoaded,
        selectedUserId: publicTalentPool.selectedUserId,
        selectedCategory: publicTalentPool.selectedCategory,
        getInformationFormValues: publicTalentPool.getInformationFormValues
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUserSelect(userId) {
            dispatch(onUserSelect(userId))
        },
        updateUserListHeight(height) {
            dispatch(updateUserListHeight(height))
        },
        updateUserLodingStatus(status) {
            dispatch(updateUserLodingStatus(status))
        },
        updateSelectedCategoryName(data = null) {
            dispatch(updateSelectedCategoryName(data))
        },
        updateInformationFormCaptcha(value) {
            dispatch(updateInformationFormCaptcha(value))
        }
    }
}
const UserListDetailsWithApollo = withApollo(UserListDetails);
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(UserListDetailsWithApollo));