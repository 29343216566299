import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/PositionShare.scss';

const PositionShareComponentAsync = lazyLoad(() => {
    return import('./components/PositionShare');
});

const routePath = '/positions/share/:provider';

const positionShareRouteDetails = {
    name: 'positionShare',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'positions',
            breadcrumbName: 'positionShare',
            desktopComponent: PositionShareComponentAsync,
            mobileComponent: PositionShareComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'positionShare',
    displayName: 'share job',
    path: routePath,
    parentName: 'positions'
};

export { positionShareRouteDetails as PositionShareRouteDetails, breadcrumbDetails as positionShareBreadcrumbDetails };