import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const CareerDirectApplyAsync = lazyLoad(() => {
    return import('./careerDirectApplyForm/careerDirectApply');
});

const routePath = '/careers/:vendorUUID/direct';

const careerDirectRouteDetails = {
    name: 'careerDirectApply',
    exact: true,
    path: routePath,
    render() {
        return <CandidateLayout 
            activeMenuName = 'candidateDashboard'
            name= {careerDirectRouteDetails.name}
            desktopComponent= {CareerDirectApplyAsync}
            mobileComponent= {CareerDirectApplyAsync} />
    }
};

export { careerDirectRouteDetails as CareerDirectRouteDetails };