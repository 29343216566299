import React, { Component } from 'react';

function _loadFile(getComponent, retryCount = 1) {
    return getComponent()
        .catch(err => {
            console.log('chunk loading failed');
            if(retryCount > 5) {
                throw err;
            } else {
                return _loadFile(getComponent, retryCount + 1);
            }
        });
}

function lazyLoad(getComponent) {
    return class LazyLoad extends Component {
        state = { component:  null };
        
        componentDidMount() {
            _loadFile(getComponent)
                .then(cmp => {
                    this.setState({
                       component: cmp.default
                    });
                })
        }
        render() {
            const C = this.state.component;

            return C ? <C { ...this.props } /> : null;
        }
    }
}

export default lazyLoad;    