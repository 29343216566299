import React from 'react';
import './Card.scss';

const Card = ({ details, className='card' ,theme}) => {
    const { header, body, footer } = details;
    const backgroundColor =theme? {
        backgroundColor:theme.secondaryColor
    }:{}
    return (
        <div className={className}>
            <div className="card-header">
                {
                    header.map(({ label = '', value = '', wrapperClass = '' }, index) => {
                        return (
                            <div key={index} className="card-header-group">
                                <span className='card-header-label'>{label ? `${label}: ` : ''} </span>
                                <span className={wrapperClass || 'card-header-value'}>{value}</span>
                            </div>
                        );
                    })
                }
            </div>
            <div className="card-body">
                {
                    body.map(({ label = '', value = '', wrapperClass = '' }, index) => {
                        return (
                            <div key={index} className="card-body-group">
                                <span className='card-body-label'>{label ? `${label}: ` : ''} </span>
                                <span className={wrapperClass || 'card-body-value'}>{value}</span>
                            </div>
                        );
                    })
                }
            </div>
            <div className="card-footer" style={backgroundColor}>
                {
                    footer.map(({ label = '', value = '', wrapperClass = '' }, index) => {
                        return (
                            <div key={index} className="card-footer-group">
                                <span className='card-footer-label'>{label ? `${label}: ` : ''}</span>
                                <span className={wrapperClass || 'card-footer-value'}>{value}</span>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Card;