import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/ApplicationDetails.scss';

const ApplicationDetailsComponentAsync = lazyLoad(() => {
    return import('./components/ApplicationDetails');
});

const routePath = '/applications/details/:id/jobPosition/:jobPositionId';

const applicationDetailsRouteDetails = {
    name: 'applicationDetails',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'applications',
            breadcrumbName: 'applicationDetails',
            desktopComponent: ApplicationDetailsComponentAsync,
            mobileComponent: ApplicationDetailsComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'applicationDetails',
    displayName: 'application details',
    path: routePath,
    parentName: 'applications'
};

export { applicationDetailsRouteDetails as ApplicationDetailsRouteDetails, breadcrumbDetails as applicationDetailsBreadcrumbDetails };