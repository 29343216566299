import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from 'App/shared/utils/Authentication';
import { LoginReducer } from './stores/LoginStore';
import lazyLoad from '../../shared/components/LazyLoad/LazyLoad';
import './shared/Login.scss';

const LoginComponentAsync = lazyLoad(() => {
    return import('./components/LoginPage');
});

const loginRouteDetails = {
    exact: true,
    path: '/login',
    name: 'login',
    render() {
        return !isAuthenticated() ? <LoginComponentAsync /> : <Redirect to='/' />;
    }
};

export { loginRouteDetails as LoginRouteDetails, LoginReducer };