import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';

const ContactOranizationAddComponentAsync = lazyLoad(() => {
    return import('./components/ContactOrganizationAdd');
});

const routePath = '/contact/organization/add';

const ContactOrganizationAddRouteDetails = {
    name: 'contactOrganizationAdd',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'candidateProfiles',
            breadcrumbName: 'contactOrganizationAdd',
            desktopComponent: ContactOranizationAddComponentAsync,
            mobileComponent: ContactOranizationAddComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'contactOrganizationAdd',
    displayName: 'organization',
    path: routePath,
    parentName: 'contactAdd'
};

export { ContactOrganizationAddRouteDetails, breadcrumbDetails as ContactOrganizationAddBreadcrumbDetails };