import React from 'react';
import './ListView.scss';
import NoResultsFound from '../NoResultsFound/NoResultsFound';

const listView = ({ theme, list, isCareerPage = false }) => {
    return (
        <div className="list-view">
            {
                list.length > 0
                    ? list.map((value, index) => {
                        return (
                            <div className='list' key={index}>
                                {value}
                            </div>
                        );
                    })
                    : <NoResultsFound isCareerPage = {isCareerPage} theme={theme}/>
            }
        </div>
    );
};

export default listView;