import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import './Breadcrumb.scss';

const breadcrumb = ({ breadcrumbItems, activeBreadcrumbItem }) => {
    let breadcrumbList = getBreadcrumbMenuList(activeBreadcrumbItem, breadcrumbItems);

    // generate breadcrumb list
    function getBreadcrumbMenuList(breadcrumbName, breadcrumbItems, breadcrumbList = []) {
        const breadcrumbItemDetails = breadcrumbItems.find(({ name }) => name === breadcrumbName);
       
        breadcrumbList = [breadcrumbItemDetails, ...breadcrumbList];
        if (breadcrumbItemDetails.parentName) {
            return getBreadcrumbMenuList(breadcrumbItemDetails.parentName, breadcrumbItems, breadcrumbList);
        }
        
        return breadcrumbList;
    }

    return (
        <div className="breadcrumb-list">
            <Breadcrumb>
                {
                    breadcrumbList.map(({ name, path, displayName }, index) => {
                        return (
                            index !== (breadcrumbList.length - 1) ?
                                <BreadcrumbItem key={index}>
                                    <Link to={path}>{displayName}</Link>
                                </BreadcrumbItem> :
                                <BreadcrumbItem key={index} active>{displayName}</BreadcrumbItem>
                        );
                    })
                }
            </Breadcrumb>
        </div>
    );
};

export default breadcrumb;