import React from 'react';

const userProfileName = ({ firstName = '', lastName = '',style={} }) => {
    return (
        <div>
            <span style={style}>{firstName} {lastName}</span>
        </div>
    );
};

export default userProfileName;