import React from 'react';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import CandidateLayout from 'App/screens/Candidate/shared/CandidateLayout';

const RegisterPageAsync = lazyLoad(() => {
    return import('./components/Register');
});
const routePath = '/:vendorUUID/candidate/register';
const registerPageRouteDetails = {
    exact: true,
    path: routePath,
    name: 'candidateRegister',
    render() {
        return <CandidateLayout
            activeMenuName = 'createAnAccount'
            name = {registerPageRouteDetails.name}
            desktopComponent = {RegisterPageAsync}
            mobileComponent = {RegisterPageAsync} 
        />
    }
};

export { registerPageRouteDetails };