import React from 'react';
import '../shared/index.scss';

export default function ChatbotCardView({
    children,
    wrapperClass
}) {
    return (
        <div className={wrapperClass ? `chatbot-card-view ${wrapperClass}` : "chatbot-card-view"}>
            {children}
        </div>
    )
}