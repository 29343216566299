const defaultState = {
  category: null,
  currencyList: [],
  vendorDetails: {},
  settingsDataLoaded: false,
  loadingSettingsData: false,
  theme: {
    primaryColor: "#1c5281",
    secondaryColor: "#dbeffb",
    secondaryColor2: "#bdd6ee",
    sidebarBackgroundColor: "#3073b1",
    sidebarTextBackgroundColor: "#1c5281",
    sidebarTextColor: "#ffffff",
  },
};

//actions
const SET_CATEGORY = "SET_CATEGORY";
const LOAD_SETTINGS_DATA = "LOAD_SETTINGS_DATA";

//dispatchers
function setCategory(data) {
  return {
    type: SET_CATEGORY,
    data,
  };
}
function loadSettingsData(status, data) {
  return {
    type: LOAD_SETTINGS_DATA,
    data,
    status,
  };
}

//reducers
const settingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        ...state,
        category: action.data ? action.data : null,
      };
    case LOAD_SETTINGS_DATA:
      let settingsDataLoaded = false;
      if (action.status === false) {
        settingsDataLoaded = true;
      }
      const currencyList =
        action.data && action.data.currencyList
          ? [...action.data.currencyList]
          : [];
      const vendorDetails =
        action.data && action.data.vendorDetails
          ? { ...action.data.vendorDetails }
          : {};
      vendorDetails.settings = vendorDetails.settings
        ? JSON.parse(vendorDetails.settings)
        : {};
      const globalSettings =
        action.data && action.data.globalSettings.length > 0
          ? action.data.globalSettings
          : [];
      const selectedThemeObj =
        globalSettings && globalSettings.length > 0 && state.category
          ? globalSettings.find(
              ({ name }) => name.toLowerCase() === state.category.toLowerCase()
            )
          : null;
      const theme = selectedThemeObj
        ? JSON.parse(selectedThemeObj.value)
        : state.theme;

      return {
        ...state,
        theme,
        vendorDetails,
        settingsDataLoaded,
        currencyList: currencyList,
        loadingSettingsData: action.status,
      };
    default:
      return state;
  }
};
export { settingReducer as SettingReducer, setCategory, loadSettingsData };
