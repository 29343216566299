import AuthenticateRouteRender from 'App/shared/utils/AuthenticateRouteRender';
import Layout from 'App/shared/components/Layout/Layout';
import lazyLoad from 'App/shared/components/LazyLoad/LazyLoad';
import './shared/TalentPoolDetails.scss';

const TalentPoolDetailsComponentAsync = lazyLoad(() => {
    return import('./components/TalentPoolDetails');
});

const routePath = '/talent-pool/details/:id';

const talentPoolDetailsRouteDetails = {
    name: 'talentPoolDetails',
    exact: true,
    path: routePath,
    render() {
        return AuthenticateRouteRender(Layout,{
            activeMenuName: 'talentPool',
            breadcrumbName: 'talentPoolDetails',
            desktopComponent: TalentPoolDetailsComponentAsync,
            mobileComponent: TalentPoolDetailsComponentAsync
        });
    }
};

const breadcrumbDetails = {
    name: 'talentPoolDetails',
    displayName: 'applicant details',
    path: routePath,
    parentName: 'talentPool'
};

export { talentPoolDetailsRouteDetails as TalentPoolDetailsRouteDetails, breadcrumbDetails as talentPoolDetailsBreadcrumbDetails };