import React from 'react';
import ApplicationLogo from '../ApplicationLogo/ApplicationLogo';
import {
    getVendorFileUuid
} from 'App/shared/utils/Authentication';
import { FILE_API_URL } from 'App/../constants';

import logo from '../../images/logo.png';
import hamburger from '../../images/hamburger.png';
import './AppHeaderMobile.scss';

const appHeaderMobile = ({ onMenuClick }) => {
    const vendorFileUuid = getVendorFileUuid();
    // const vendorLogo = vendorFileUuid ? `${FILE_API_URL}/${vendorFileUuid}` : '';
    const vendorLogo = vendorFileUuid ? `https://talentdome.s3.amazonaws.com/logo/infojini-logo.png` : '';
    return (
        <div className='app-header-mobile'>
            <div className='menubar'>
                <div className="" onClick={onMenuClick}>
                    <img src={hamburger} alt='Menubar' />
                </div>
                <h1>
                    <ApplicationLogo logo={logo} link='/' />
                </h1>
            </div>
            <div className='vendor-product-logo'>
                <div className='vendor-logo'>
                    <ApplicationLogo logo={vendorLogo} />
                </div>
            </div>
        </div>
    );
}

export default appHeaderMobile;