import React from 'react';
import { Field } from 'redux-form';
import InputField from '../InputField/InputField';
// import InfoIcon from '../../images/info-icon.png';
import FileIcon from '../../images/icon-resume.png';
import ScanningIcon from '../../images/scanning-icon.gif';
import { convertToAbsoluteUrl } from '../../utils/UrlFormatter';
import './FileUpload.scss';

const fileUpload = ({
    meta,
    input,
    accept,
    isRequired,
    placeholder,
    selectedFile,
    onFileUpload,
    isFileScanning,
    onRemoveUploadedFile,
    removeOrUpdate = 'remove',
    isFileUploadedAnotherFormat,
    ...rest
}) => {
    let _FileIcon = convertToAbsoluteUrl(FileIcon);
    // let _InfoIcon = convertToAbsoluteUrl(InfoIcon);
    function getClassName() {
        return selectedFile === null ? 'placeholder' : '';
    }

    function _getRemoveButtonClassName() {
        return isFileScanning === true ? 'remove disable' : 'remove'
    }

    function renderSelectedFileData() {
        return (
            <div className='file-details'>
                <div className='file-name'>
                    <img src={_FileIcon} alt='Upload' />
                    <span>{selectedFile.name}</span>
                </div>
                <span className={_getRemoveButtonClassName()} onClick={() => onRemoveUploadedFile()}>{removeOrUpdate}</span>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='file-upload'>
                <div className={getClassName()}>
                    {
                        selectedFile === null
                            ? placeholder === "resume" ? 'upload resume' : ''
                            : renderSelectedFileData()
                    }
                </div>
                <div className='file'>
                    <Field
                        id="file"
                        name="file"
                        type="file"
                        accept={accept}
                        inputFieldValue=""
                        label={placeholder}
                        component={InputField}
                        isRequired={isRequired}
                        placeholder={placeholder}
                        onFieldValueChange={(value, event) => onFileUpload(event)}
                    />
                    {
                        meta.touched && selectedFile === null && isRequired && !isFileUploadedAnotherFormat
                            ? <span className='text-danger'>This field is required</span>
                            : null
                    }
                </div>
                {
                    isFileScanning === true
                        ? (
                            <div className="file-scan">
                                <img src={ScanningIcon} alt="error"></img>
                                <span className='text-danger'>Scanning for virus...</span>
                            </div>
                        ) : null
                }
            </div>
            {
                isFileUploadedAnotherFormat
                    ? <div className="allowed-files-error">
                        {/* <img src={_InfoIcon} alt="error"></img> */}
                        <span>
                            Allowed files (Only .pdf, .doc & .docx)
                        </span>
                    </div>
                    : <span className="help-text-file-upload">
                        (Only .pdf, .doc & .docx)
                    </span>
            }
        </React.Fragment>
    );
};

export default fileUpload;