import React, { Component } from "react";
import ApplicationOutsideClick from "../ApplicationOutsideClick";
import notificationLoder from "../../images/Eclipse-2s-200px.gif";
import { convertDateIntoFriendlyFormat } from "../../utils/DateFormat";
import "./index.scss";

export default class ApplicationCustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.customDropdownOptions = props.customDropdownOptions;
    this.state = {
      isDropdownOpen: false,
    };
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.onDropdownOptionChange = this.onDropdownOptionChange.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
  }
  onDropdownClick() {
    this.props.onDropDownClick &&
      this.props.onDropDownClick(this.state.isDropdownOpen);
    this.props.resetCount && this.props.resetCount();
    this.setState((state) => {
      return {
        isDropdownOpen: !state.isDropdownOpen,
      };
    });
  }
  onBackdropClick() {
    this.setState({
      isDropdownOpen: false,
    });
  }
  onDropdownOptionChange(selectedOption, id) {
    const _selectedOption = id
      ? this.props.dropdownOptions.find((value) => value.notificationId === id)
      : this.props.dropdownOptions.find(
          (value) => value.displayName === selectedOption
        );
    this.props.onDropdownOptionClick(_selectedOption);
    this.setState({
      isDropdownOpen: false,
    });
  }

  render() {
    const { isDropdownOpen } = this.state;
    const {
      title,
      theme,
      className,
      dropdownOptions,
      activeMenuName,
      loadingList = true,
      isEmptyList = false,
      isNotification = false,
    } = this.props;

    const DropdownArrow = isNotification ? "disable" : "";
    const listWidth = isNotification && !isEmptyList ? "ul-width" : "";
    const lodinglistClass = !loadingList ? "loderlist" : "";
    const fontColorTheme = theme ? { color: theme.primaryColor } : {};
    const backgroundTheme = theme
      ? { backgroundColor: theme.secondaryColor }
      : {};

    return (
      <ApplicationOutsideClick onOutsideClick={this.onBackdropClick}>
        <div className="application-custom-dropdown">
          <div className={`${className}`} onClick={this.onDropdownClick}>
            <div className={`${className}__title`} style={fontColorTheme}>
              {title}
            </div>
            <div className={`${className}__arrow ${DropdownArrow}`}>
              <span
                className={"icon-sort-desc down-arrow"}
                style={fontColorTheme}
              />
            </div>
          </div>
          <div
            className={
              isDropdownOpen === true
                ? `${className}__list`
                : `${className}__list-hide`
            }
          >
            {
              <ul
                className={`${className}__list__options ${listWidth} ${lodinglistClass}`}
              >
                {!loadingList ? (
                  <div className="notificationLoader">
                    <div className="notificationLoader__div">
                      <img
                        src={notificationLoder}
                        alt="loader-img"
                        className={`notificationLoader__img`}
                      />
                    </div>
                  </div>
                ) : (
                  dropdownOptions.map(
                    (
                      {
                        displayName,
                        notificationId,
                        name,
                        isNotificationRead,
                        createdAt,
                      },
                      index
                    ) => {
                      const listClassName = isNotification
                        ? `${className}__list-items`
                        : name === activeMenuName
                        ? `${className}__selected-item `
                        : ``;

                      const displayClass = isNotificationRead
                        ? `${className}__read-item notification-read-color`
                        : `${className}__unread-item`;
                      const isNotificationReadNotifiaction = isNotificationRead
                        ? "notification-read-color"
                        : "";
                      const date = convertDateIntoFriendlyFormat(
                        createdAt,
                        new Date()
                      );
                      const style =
                        name === activeMenuName ? fontColorTheme : {};
                      const backgroundStyle =
                        name === activeMenuName ? backgroundTheme : {};
                      return (
                        <li
                          key={index}
                          id={index}
                          value={displayName}
                          style={backgroundStyle}
                          className={listClassName}
                          onClick={(_) =>
                            this.onDropdownOptionChange(
                              displayName,
                              notificationId
                            )
                          }
                        >
                          <div className={`${className}__displayName `}>
                            <div
                              style={style}
                              className={` ${displayClass} overflow-text`}
                            >
                              {displayName}
                            </div>
                            {!isNotificationRead &&
                              isNotification &&
                              !isEmptyList && (
                                <div className="blue-circle">
                                  <div
                                    style={backgroundTheme}
                                    className={`${className}__notify-circle`}
                                  />
                                </div>
                              )}
                          </div>
                          {isNotification && !(date === "NaN years") && (
                            <div
                              className={`${className}__time-ago ${isNotificationReadNotifiaction}`}
                            >
                              {date === "Just now" ? `${date}` : ` ${date} ago`}
                            </div>
                          )}
                        </li>
                      );
                    }
                  )
                )}
              </ul>
            }
          </div>
        </div>
      </ApplicationOutsideClick>
    );
  }
}
