import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import logo from '../../images/logo.png';
import { FILE_API_URL } from 'App/../constants';
import { graphql, withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import ApplicationLogo from '../ApplicationLogo/ApplicationLogo';

import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { removeSession, getVendorFileUuid } from 'App/shared/utils/Authentication';
import { getUserDetails } from 'App/shared/utils/Authentication';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './AppHeader.scss';

const appHeader = ({
    history,
    mutate,
    activeMenu,
    sidebarItems
}) => {
    const vendorFileUuid = getVendorFileUuid();
    // const vendorLogo = vendorFileUuid ? `${FILE_API_URL}/${vendorFileUuid}` : '';
    const vendorLogo = vendorFileUuid ? `https://talentdome.s3.amazonaws.com/logo/infojini-logo.png` : '';

    function getActiveMenuItemClassName(item) {
        return activeMenu === item ? `menu-${item} active` : `menu-${item}`;
    }

    function logout() {
        return mutate()
            .then(resp => {
                removeSession();
                window.location.href = '/login';
            })
            .catch(err => {
                console.log(err);
            })
    }

    const userDetails = getUserDetails()

    const styleForRole = { cursor: 'default' }
    return (
        <div className='app-header'>
            <div className='app-header-logo'>
                <h1>
                    <ApplicationLogo logo={logo} link='/' />
                </h1>
                <nav>
                    <ul>
                        {sidebarItems && sidebarItems.map(item => {
                            return <li key={item.name} className={getActiveMenuItemClassName(item.name)}>
                                <Link to={item.path}><span>{item.displayName}</span></Link>
                            </li>
                        })}
                    </ul>
                </nav>
            </div>
            <div className='vendor-info'>
                <div className='vendor-logo'>
                    <ApplicationLogo logo={vendorLogo} />
                    <div className='user-info'>
                        <div className='user-name-dropdown'>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>
                                    <UserProfileImage />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem style={styleForRole}>

                                        {userDetails.roleName ? `Role : ${userDetails.roleName}` : ''}

                                    </DropdownItem>
                                    <hr style={{margin:'3px'}}>
                                    </hr>
                                    <DropdownItem onClick={logout}>logout</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LOGOUT_MUTATION = gql`
    mutation logout {
        logout {
            accountId
        }
    }
`;

appHeader.propTypes = {
    'mutate': PropTypes.func.isRequired
};

const appHeaderApollo = withApollo(appHeader);

export default graphql(LOGOUT_MUTATION)(withRouter(appHeaderApollo));